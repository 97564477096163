import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { filter, find, includes, isEmpty, lowerCase, map, sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import HeatmapItem from './HeatmapItem';
import { UnpublishResultsButton } from './UnpublishResultsButton';
import { useSelectHeatmap } from './selectHeatmap';
import { useUpdateRasterHeatmapsSettingsOnChange } from './useUpdateRasterHeatmapsSettingsOnChange';

interface HeatmapTreeProps {
  viewerIndex: number;
  slideId: string;
  stainTypeId: string;
  heatmaps: FeatureMetadata[];
  filterText: string;
  hideOrchestrationId?: boolean;
  hideOpacityWhenNotSelected?: boolean;
  onEmptyFilter?: () => void;
}

export const HeatmapTree: React.FC<HeatmapTreeProps> = ({
  viewerIndex,
  slideId,
  stainTypeId,
  heatmaps,
  filterText,
  hideOrchestrationId,
  hideOpacityWhenNotSelected,
  onEmptyFilter,
}) => {
  useSignals();
  useUpdateRasterHeatmapsSettingsOnChange({ viewerIndex, slideId, heatmaps, stainTypeId });
  const [filterHeatmapsExpanded, setFilterHeatmapsExpanded] = useState([]);
  const [heatmapsExpanded, setHeatmapsExpanded] = useState<string[]>([]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setHeatmapsExpanded(nodeIds);
  };

  const heatmapIncludesFilterText = (heatmap: FeatureMetadata) => {
    if (filterText === '') return true;

    const lowerCaseFilter = lowerCase(filterText);

    if (find(heatmap.nestedItems, (nestedItems) => includes(lowerCase(nestedItems.displayName), lowerCaseFilter))) {
      setFilterHeatmapsExpanded((prevDefaultExpanded) => [...prevDefaultExpanded, heatmap.id]);
      return true;
    }

    if (includes(lowerCase(heatmap.displayName), lowerCaseFilter)) {
      return true;
    }

    return false;
  };

  const filterHeatmaps = useMemo(() => {
    setFilterHeatmapsExpanded([]);

    if (filterText === '') {
      return heatmaps;
    } else {
      const filteredHeatmaps = filter(heatmaps, heatmapIncludesFilterText);

      if (!isEmpty(filteredHeatmaps)) onEmptyFilter?.();
      return filteredHeatmaps;
    }
  }, [filterText, heatmaps]); // Could be buggy

  const { changeSelectHeatmap } = useSelectHeatmap();

  const handleChangeSelectHeatmap = React.useCallback(
    (heatmapId: string, checked: boolean) =>
      changeSelectHeatmap({ viewerIndex, slideId, heatmapId, heatmaps, stainTypeId, checked, setHeatmapsExpanded }),
    [viewerIndex, slideId, heatmaps, setHeatmapsExpanded]
  );

  const allExpanded = useMemo(
    () => [...filterHeatmapsExpanded, ...heatmapsExpanded],
    [filterHeatmapsExpanded, heatmapsExpanded]
  );

  return (
    <TreeView
      expanded={allExpanded}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeToggle={handleToggle}
    >
      {map(filterHeatmaps, (heatmap) => {
        return (
          <HeatmapItem
            hideOpacityWhenNotSelected={hideOpacityWhenNotSelected}
            viewerIndex={viewerIndex}
            slideId={slideId}
            stainTypeId={stainTypeId}
            key={heatmap.id}
            heatmap={heatmap}
            filterText={filterText}
            isExpanded={includes(allExpanded, heatmap.id)}
            handleChangeSelectHeatmap={handleChangeSelectHeatmap}
            hideOrchestrationId={hideOrchestrationId}
          />
        );
      })}
    </TreeView>
  );
};

interface HeatmapsProps {
  viewerIndex: number;
  title: string;
  heatmaps: FeatureMetadata[];
  slideId: string;
  stainTypeId: string;
  studyId: string;
  filterText: string;
  expandByDefault?: boolean;
  internalHeatmaps?: boolean;
  isPublishMode: boolean;
  hideOrchestrationId?: boolean;
}

const Heatmaps: React.FunctionComponent<React.PropsWithChildren<HeatmapsProps>> = ({
  viewerIndex,
  title,
  heatmaps: unsortedHeatmaps,
  stainTypeId,
  slideId,
  studyId,
  filterText,
  expandByDefault,
  internalHeatmaps = false,
  isPublishMode,
  hideOrchestrationId,
}) => {
  useSignals();
  const heatmaps = useMemo(() => sortBy(unsortedHeatmaps, 'displayName'), [unsortedHeatmaps]);

  const [expandAccordion, setExpandAccordion] = useState(Boolean(expandByDefault));

  return (
    <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={isPublishMode && !internalHeatmaps ? 7 : 12}>
            <Typography variant={internalHeatmaps ? 'subtitle2' : 'h4'}>{title}</Typography>
          </Grid>
          <UnpublishResultsButton
            slideId={slideId}
            studyId={studyId}
            internalHeatmaps={internalHeatmaps}
            isPublishMode={isPublishMode}
            heatmaps={heatmaps}
          />
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        <HeatmapTree
          viewerIndex={viewerIndex}
          slideId={slideId}
          heatmaps={heatmaps}
          stainTypeId={stainTypeId}
          filterText={filterText}
          onEmptyFilter={() => setExpandAccordion(true)}
          hideOrchestrationId={hideOrchestrationId}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default Heatmaps;
