import { MAX_CHANNELS } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';

import { applyTransparentColor } from '../shader-utils';

export default function colorPaletteModuleFactory(maxChannelsInput) {
  const maxChannels = Math.min(MAX_CHANNELS, maxChannelsInput);
  const fs = `\
uniform vec3 transparentColor;
uniform bool useTransparentColor;
uniform float opacity;

uniform vec3 colors[${maxChannels}];

${applyTransparentColor}

void mutate_color(inout vec3 rgb, float intensities[${maxChannels}]) { 
  for (int i = 0; i < ${maxChannels}; i += 1) {
    rgb += max(0.0, min(1.0, intensities[i])) * vec3(colors[i]);
  }
}

vec4 apply_opacity(vec3 rgb) {
  return vec4(apply_transparent_color(rgb, transparentColor, useTransparentColor, opacity));
}
`;

  const DECKGL_MUTATE_COLOR = `\
vec3 rgb = rgba.rgb;
mutate_color(rgb, intensities);
rgba = apply_opacity(rgb);
`;

  return {
    name: 'color-palette-module',
    fs,
    inject: {
      'fs:DECKGL_MUTATE_COLOR': DECKGL_MUTATE_COLOR,
    },
  };
}
