import { Button, Divider, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import CohortQueryList from 'components/SearchFilters/CohortQueryList';
import { Cohort } from 'interfaces/cohort';
import moment from 'moment';
import React from 'react';

interface Props {
  cohort: Cohort;
  applyFunction?: () => void;
}

const CohortDetails = ({ cohort, applyFunction }: Props) => {
  const theme = useTheme();
  return (
    <Grid container flexDirection="column">
      <Grid
        container
        sx={{
          p: 2,
          color: theme.palette.secondary.main,
          borderRadius: 4,
          maxHeight: '120px',
          overflowY: 'scroll',
        }}
      >
        <Grid container item flexDirection="column" spacing={1}>
          <Grid item>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Created at:
            </Typography>
            <Typography variant="body2">{`${moment(cohort.createdAt).format('ll')}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Filters:
            </Typography>
            <CohortQueryList queryObject={cohort.queryObject} showIcon={false} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid container item justifyContent="center" direction="column" sx={{ px: 2, pb: 2 }} spacing={1}>
        <Grid item>
          <Tooltip
            arrow
            placement="right"
            title="Click to make a query according to the filters that are included in this cohort."
          >
            <Button onClick={applyFunction} variant="contained" color="secondary">
              Apply Cohort Filters
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CohortDetails;
