import { Box, Grid, Popover, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { mainMarginTop } from 'components/atoms/FlexContainers/FlexContainers';
import CasesOverviewDrawer from 'components/CasesOverview/CasesOverviewDrawer';
import AdvancedSearchDrawer from 'components/SearchFilters/AdvancedSearch';
import SiteHeader from 'components/SiteHeader/SiteHeader';
import DashboardFooter from 'components/StudiesDashboard/DashboardFooter';
import { Permission } from 'interfaces/permissionOption';
import { find, flatMap } from 'lodash';
import { stringify } from 'qs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetCheat, setCheatStage } from 'redux/modules/cheatModeSlice';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { isDemoLab } from 'utils/demo';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { useProceduresWithQAExperimentResultsOnly } from 'utils/useProceduresWithQAExperimentResultsOnly';
import { useQueryWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { encodeQueryParams, JsonParam, StringParam } from 'use-query-params';

import { getCohort } from 'api/cohorts';
import { fetchStudies } from 'api/study';
import { MainPageAnnotationDrawer } from 'components/AssignmentsList/MainPageAnnotationDrawer';
import { TableEditingContextProvider } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { RowSelectionContextProvider } from 'components/atoms/RowSelectionContext';
import EditDrawer from 'components/EditDrawer';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import CohortDetails from './Cohort/CohortDetails';
import MainContent from './MainStudyDashboardContent';
import StudyDashboardHeader from './StudyDashboardHeader';

const StudyDashboard: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { hasPermission } = usePermissions();

  const { labId } = useCurrentLabId();

  const { data: studies, status: studiesStatus } = useQueryWithErrorAndRetrySnackbar(
    ['studies', labId, 'withStatistics'],
    fetchStudies(labId, true),
    {
      queriedEntityName: 'studies',
    }
  );

  const demoLab = isDemoLab(labId);

  const { queryParams } = useEncodedFilters();
  const currentStudy = find(studies, { id: queryParams.filters?.studyId });
  const cohortMode = Boolean(queryParams.cohortId);

  const { data: proceduresData, status: proceduresStatus } = useProceduresWithQAExperimentResultsOnly({
    enabled: Boolean(cohortMode),
    loadIds: true,
  });

  const { data: currentCohort, status: cohortStatus } = useQuery({
    queryKey: ['cohort', queryParams.cohortId],
    queryFn: () => getCohort(queryParams.cohortId),
    enabled: cohortMode,
  });

  const [cohortId] = useQueryParam('cohortId', StringParam);

  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);

  const data = cohortId ? currentCohort : proceduresData;

  const { cheatMode, cheatStage } = useAppSelector((state) => state.cheatMode);
  const isCurrentCheatMode = cheatMode.includes(currentStudy?.id);

  const [showCohortDetails, setShowCohortDetails] = useState(false);

  const [, setIsUploadSlidesModalOpen] = useQueryParam('isUploadSlidesModalOpen', BooleanParam);
  const isAnalyzing = Boolean(currentStudy?.analysisStartTime);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowCohortDetails(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setShowCohortDetails(false);
    setAnchorEl(null);
  };

  const applyCohortFilters = () => {
    const basicParams = {
      labId: StringParam,
      cohortId: StringParam,
      cohortName: StringParam,
    };

    const filtersQueryParams = {
      filters: JsonParam,
      features: JsonParam,
      clinicalData: JsonParam,
      compositeFilters: JsonParam,
      searchTerm: StringParam,
    };

    const params = {
      ...basicParams,
      ...filtersQueryParams,
    };

    const encodedQueryWithFilters = encodeQueryParams(params, {
      filters: { ...currentCohort.queryObject.filters },
      features: currentCohort.queryObject.features,
      clinicalData: currentCohort.queryObject.clinicalData,
      compositeFilters: currentCohort.queryObject.compositeFilters,
      labId,
      cohortId: currentCohort.id,
      cohortName: currentCohort.name,
      searchTerm: currentCohort.queryObject.searchTerm,
    });

    navigate({
      pathname: '/procedures',
      search: `?${stringify(encodedQueryWithFilters)}`,
    });
  };

  const insideCohort = cohortMode && Boolean(currentCohort);

  let pageTitle = '';
  if (!insideCohort) {
    pageTitle = currentStudy?.name ? `Study ${currentStudy?.name}` : 'Back to Studies';
  } else {
    pageTitle = currentCohort?.casesSnapshot ? `Cohort ${currentCohort?.name}` : `Query ${currentCohort?.name}`;
  }

  const isAddSlidesDisabled = !currentStudy?.name || isAnalyzing;

  const onCheatMode = (event: KeyboardEvent) => {
    if (event.key === '\\') {
      dispatch(setCheatStage());
    }

    if (event.key === 'q' || event.key === 'Q') {
      dispatch(resetCheat());
    }
  };

  useEffect(() => {
    if (demoLab) {
      document.addEventListener('keydown', onCheatMode);

      return () => {
        document.removeEventListener('keydown', onCheatMode);
      };
    }
  }, [document, demoLab]);

  const specificStudySelected = Boolean(currentStudy);

  const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];

  const proceduresFieldsContext = useProceduresFieldsContext();

  const slides = flatMap(proceduresData?.procedures, (procedure) => procedure.slides);

  const renderCohortDetailsToggle = () => {
    return currentCohort && currentCohort?.casesSnapshot ? (
      <Grid item sx={{ pl: 0, display: 'flex' }}>
        <Button onClick={handleOpenPopover} color="secondary" size="small">
          {showCohortDetails ? 'Hide Details' : 'Show Details'}
        </Button>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <CohortDetails cohort={currentCohort} applyFunction={applyCohortFilters} />
        </Popover>
      </Grid>
    ) : null;
  };

  const showApprovedFilter = hasPermission(Permission.ApproveCases);

  return (
    <RowSelectionContextProvider>
      <TableEditingContextProvider fieldsContext={proceduresFieldsContext}>
        <StaticPage>
          <SiteHeader />
          <Grid
            container
            direction={'column'}
            sx={{
              height: '100vh',
              width: '100%',
              backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
            }}
          >
            <Grid
              item
              sx={{
                height: mainMarginTop,
                width: '100%',
                borderBottom: showCohortDetails ? `none` : `1px solid  ${borderColor}`,
              }}
            >
              <Grid container item>
                <Toolbar />
                <StudyDashboardHeader
                  study={currentStudy}
                  cohortMode={cohortMode}
                  renderCohortDetailsToggle={renderCohortDetailsToggle}
                  pageTitle={pageTitle}
                  tab={queryParams.cohortId ? 'cohorts' : 'studies'}
                  isAnalyzing={isAnalyzing}
                  isCurrentCheatMode={isCurrentCheatMode}
                  cheatStage={cheatStage}
                  tooltipText={
                    currentStudy?.name
                      ? isAnalyzing
                        ? 'Upload slides is already done'
                        : 'Upload slides'
                      : 'You need to choose Study'
                  }
                  isAddSlidesDisabled={isAddSlidesDisabled}
                  onAddSlidesClick={() => {
                    setIsUploadSlidesModalOpen(true);
                  }}
                />
              </Grid>
            </Grid>

            <AdvancedSearchDrawer showApprovedFilter={showApprovedFilter} persistent />
            <Grid
              item
              xs={true}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                overflowY: 'auto',
                width: '100%',
              }}
            >
              <MainContent studies={studies} />
              <DashboardFooter />
            </Grid>
            <CasesOverviewDrawer
              currentStudy={currentStudy}
              totalProcedures={data?.totalProcedures}
              enable={
                specificStudySelected &&
                studiesStatus == 'success' &&
                (cohortMode ? cohortStatus == 'success' : proceduresStatus == 'success')
              }
            />
            {hasPermission(Permission.ViewCasesTable) && (
              <EditDrawer totalItemsToEdit={slidesMode ? slides?.length : proceduresData?.totalProcedures} />
            )}
          </Grid>
          <MainPageAnnotationDrawer />
        </StaticPage>
      </TableEditingContextProvider>
    </RowSelectionContextProvider>
  );
};

export default StudyDashboard;

const StaticPage = styled(Box)(() => ({
  overflow: 'hidden',
}));
