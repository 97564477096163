import { Inputs, ModelInference } from 'interfaces/calculateFeatures';
import GridBasedCalculationParams from 'interfaces/girdBasedCalculationParams';
import { ArtifactResultsConfig } from 'interfaces/inferenceArtifacts';
import { SecondaryAnalysisInput } from 'interfaces/job';
import { MultiplexCellTypingConfig } from 'interfaces/jobs/multiplex/cellTypingParams';
import { MultiplexHistogramConfig } from 'interfaces/jobs/multiplex/histogramParams';
import { MultiplexNormalizationJobParams } from 'interfaces/jobs/multiplex/normalizationParams';
import { MultiplexThresholdConfig } from 'interfaces/jobs/multiplex/thresholdParams';
import { Model, ModelResponse } from 'interfaces/model';
import { PostProcessingActionCreated } from 'interfaces/postProcessingAction';
import { VisualizationCreated } from 'interfaces/visualization';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';
import { CasesParams } from 'utils/useCasesParams';

export const getImages = (signal?: AbortSignal) => {
  return apiRequestHandlerPromise<string[]>({
    url: `docker-images`,
    method: 'GET',
    signal,
  });
};

export const runInference = (
  data: CasesParams & {
    configParams: any;
  }
) => {
  return apiRequestHandlerPromise({
    url: 'run_inference',
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const getModels = (
  queryParams: {
    modelType: string;
    stainType?: string;
    pageSize?: number;
    page?: number;
    studyId?: string;
    modelId?: string;
    trainingType?: string;
    cancerType?: string;
    sort?: number;
  },
  signal?: AbortSignal
) => {
  return apiRequestHandlerPromise<ModelResponse>({
    url: `models?${stringify(queryParams)}`,
    method: 'GET',
    signal,
  });
};

export const getModel = (modelId: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Model>({
    url: `models/${modelId}`,
    method: 'GET',
    signal,
  });
};

export const getArtifactsInference = (
  data: {
    studyId: string;
    caseParams: string;
    configParams?: ArtifactResultsConfig;
  },
  signal?: AbortSignal
) => {
  const configQueryString = data.configParams
    ? `${data.caseParams ? '&' : ''}${stringify(data.configParams, { arrayFormat: 'repeat' })}`
    : '';
  return apiRequestHandlerPromise<ModelInference[]>({
    url: `studies/${data.studyId}/artifacts/inference?${data.caseParams}${configQueryString}`,
    method: 'GET',
    signal,
  });
};

export const runCalculateFeatures = (data: {
  jobName: string;
  jobDescription: string;
  configParams: {
    manifest: Record<string, string[]>;
    inputs: Inputs;
    postprocessing: PostProcessingActionCreated[];
    visualizations: VisualizationCreated[];
    features: PostProcessingActionCreated[];
    gridBasedCalculationParams: { [key: string]: GridBasedCalculationParams };
    studyId: string;
    secondaryAnalysisPolygons?: SecondaryAnalysisInput;
    primaryRunOrchestrationId?: string;
  };
}) => {
  return apiRequestHandlerPromise({
    url: `studies/${data.configParams.studyId}/calculate_features`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const runMultiplexCellSegmentation = (
  data: CasesParams & {
    configParams: {
      jobName: string;
      jobDescription: string;
      modelType: string;
      nuclearChannelNames: string[];
      nonNuclearChannelNames: string[];
    };
  }
) => {
  return apiRequestHandlerPromise({
    url: `studies/${data.studyId}/multiplex_cell_segmentation`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const runMultiplexBinaryClassifier = (data: CasesParams) => {
  return apiRequestHandlerPromise({
    url: `studies/${data.studyId}/multiplex_binary_classifier`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const runMultiplexNormalization = (
  data: CasesParams & {
    jobName: string;
    jobDescription: string;
    configParams: {
      histogramNormalization: MultiplexNormalizationJobParams;
      histogramCreation: MultiplexHistogramConfig;
    };
  }
) => {
  return apiRequestHandlerPromise({
    url: `studies/${data.studyId}/multiplex_normalization`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const runMultiplexThreshold = (
  data: CasesParams & {
    jobName: string;
    jobDescription: string;
    configParams: MultiplexThresholdConfig;
  }
) => {
  return apiRequestHandlerPromise({
    url: `studies/${data.studyId}/multiplex_threshold`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const runMultiplexCellTyping = (
  data: CasesParams & {
    jobName: string;
    jobDescription: string;
    configParams: MultiplexCellTypingConfig;
  }
) => {
  return apiRequestHandlerPromise({
    url: `studies/${data.studyId}/multiplex_threshold`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};
