import { ExperimentResult, Feature, HeatmapItem } from './experimentResults';
import { SlideTagAssignment } from './slideTagAssignment';

export interface Slide {
  id: string;
  labId?: string;
  scanDate?: string;
  format?: string;
  scannerId?: string;
  scannerModel?: string;
  scannerManufacturer?: string;
  maxResolution?: number;
  sizeRows?: number;
  sizeCols?: number;
  objectiveMagnification?: number;
  numLayers?: number;
  createdAt?: string;
  originalFileName?: string;
  biopsySiteId?: number | string; // The string is for the case manifest where we display the raw value if the biopsy site is not found
  stainingType?: string;
  stainingSubType?: string;
  labInternalId?: string;
  biopsyType?: string | null;
  registrations?: Registration[] | null;
  qcFailed?: boolean;
  channels?: Record<string, SlideChannel>;
  channelMarkerTypes?: Record<string, string> | null;
  metastasis?: boolean;
  tsmAreaUm2?: number | null;
  experimentResults?: ExperimentResult[];
  qcLabels?: SlideQcLabel[];
  excluded?: boolean;
  slideTagAssignments?: SlideTagAssignment[];
  cancerTypeId?: number; // This field is only used in ingest, please refer to the cancerType field in the Procedure table
  negativeControl?: boolean;
  positiveControl?: boolean;
  encoding?: 'rgb' | 'uint8' | 'uint16' | 'float';
}

export const slidesEditableKeys: string[] = [
  'biopsySiteId',
  'stainingType',
  'biopsyType',
  'negativeControl',
  'positiveControl',
  'encoding',
];

export interface SlideQcLabel {
  text: string;
  errorCalculatingLabel: boolean;
  errorMessage?: string;
}

export interface SlideChannel {
  name?: string;
  markerType?: string;
  histogram?: number[];
  lut?: number[];
  color?: string;
}

export interface QcResult {
  qcKey: string;
  isPassed: boolean;
}

export interface SidebarInfo {
  features?: Feature[];
  heatmapItems: HeatmapItem[];
  defaultHeatmapKey?: string;
}

export interface Channel {
  key: string;
  value: number | string;
  url?: string;
  heatmapUrl?: string;
}

export interface Registration {
  registrationSlideId: string;
  angle: number;
  zoomRatio: number;
  points1: any;
  points2: any;
  approved: boolean;
}

export interface SlidePrefetchData
  extends Pick<
    Slide,
    'id' | 'labId' | 'sizeCols' | 'sizeRows' | 'maxResolution' | 'originalFileName' | 'channelMarkerTypes'
  > {
  caseId?: number;
  caseLabel?: string;
  slideId: string;
  slideFormat: string;
  stainTypeId?: string;
  numChannels?: number;
}
