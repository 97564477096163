import {
  BoundingBox,
  MultiScaleImageLayerProps,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/types';
import { DecodedPng } from 'fast-png';
import { isArray } from 'lodash';

export interface MultiScaleImageIndex {
  x: number;
  y: number;
  z: number;
}

export interface MultiScaleImageData {
  data: Array<ImageData | DecodedPng | null>;
  format: number;
  dataFormat: number;
  isPng: boolean;
}

// Tiles are exactly fitted to have height and width such that their bounds match that of the actual image (not some padded version).
// Thus the right/bottom given by deck.gl are incorrect since they assume tiles are of uniform sizes, which is not the case for us.
export function getBoundsForTile<S extends string[]>({
  bbox,
  layerSource,
  width,
  height,
  tileSize,
}: {
  bbox: BoundingBox;
  layerSource: MultiScaleImageLayerProps<S>['layerSource'];
  width: number;
  height: number;
  tileSize?: number;
}): [left: number, top: number, right: number, bottom: number] | null {
  tileSize = tileSize ?? layerSource.getTileSize();
  const overlapPixels = layerSource.getOverlapPixels();
  const tileSizeWithoutOverlap = tileSize - (overlapPixels ?? 0);

  const imageWidth = width > tileSizeWithoutOverlap ? tileSizeWithoutOverlap : width;
  const imageHeight = height > tileSizeWithoutOverlap ? tileSizeWithoutOverlap : height;

  const tileWidthRatio = Math.min(imageWidth / tileSizeWithoutOverlap, 1);
  const tileHeightRatio = Math.min(imageHeight / tileSizeWithoutOverlap, 1);

  if (isNaN(tileWidthRatio) || isNaN(tileHeightRatio)) {
    return null;
  }

  let left: number;
  let top: number;
  let right: number;
  let bottom: number;

  if (isArray(bbox)) {
    // Pixel coordinates array
    [left, top, right, bottom] = bbox;
  } else if ('west' in bbox) {
    // Geographic coordinates
    ({ west: left, north: top, east: right, south: bottom } = bbox);
  } else {
    // Pixel coordinates object
    ({ left, top, right, bottom } = bbox);
  }
  bottom = top + (bottom - top) * tileHeightRatio;
  right = left + (right - left) * tileWidthRatio;

  // Tiles are exactly fitted to have height and width such that their bounds match that of the actual image (not some padded version).
  // Thus the right/bottom given by deck.gl are incorrect since they assume tiles are of uniform sizes, which is not the case for us.
  const bounds: [number, number, number, number] = [left, bottom, right, top];

  return bounds;
}
