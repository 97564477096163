import { Link } from '@mui/material';
import { filter } from 'lodash';
import React, { FunctionComponent } from 'react';
import { CaseSearchItem } from 'services/searchIndex';
import { useEncodedFilters } from 'utils/useEncodedFilters';

interface FullSearchHelperTextProps {
  localSearch: string;
  options: CaseSearchItem[];
  isStudyIdSpecific: boolean;
  currentStudyId?: string;
}

const casesAmountDisplay = (amount: number) => {
  if (amount === 0) {
    return 'No matching cases';
  }
  return `${amount} matching case${amount !== 1 ? 's' : ''}`;
};

const slidesAmountDisplay = (amount: number) => {
  if (amount === 0) {
    return 'No matching slides';
  }

  return `${amount} matching slide${amount !== 1 ? 's' : ''}`;
};

const FullSearchHelperText: FunctionComponent<React.PropsWithChildren<FullSearchHelperTextProps>> = ({
  localSearch,
  options,
  isStudyIdSpecific,
  currentStudyId,
}) => {
  const { queryParams, setQueryParams } = useEncodedFilters();
  const open = (localSearch?.trim()?.length ?? 0) > 0;

  if (!open) {
    return <></>;
  }

  // cases count in case mode and slides count in slides mode
  const itemsCount = options?.length ?? 0;
  const itemsCountInCurrentStudy = isStudyIdSpecific ? filter(options, { studyId: currentStudyId }).length : itemsCount;

  const anyMatchingResultsInCurrentStudy = itemsCountInCurrentStudy > 0;

  const helperTextInCurrentStudy = `${
    queryParams.slidesMode
      ? slidesAmountDisplay(itemsCountInCurrentStudy)
      : casesAmountDisplay(itemsCountInCurrentStudy)
  } in current study`;
  const helperTextAcrossStudies = `${
    queryParams.slidesMode ? slidesAmountDisplay(itemsCount) : casesAmountDisplay(itemsCount)
  } across all studies`;

  const clearFiltersExceptSearch = () => {
    setQueryParams({ searchTerm: localSearch, filters: null, features: null, clinicalData: null, queryId: null });
  };

  const linkToAcrossStudies = (
    <Link color="secondary" onClick={clearFiltersExceptSearch}>
      Show {helperTextAcrossStudies}
    </Link>
  );

  if (isStudyIdSpecific) {
    if (anyMatchingResultsInCurrentStudy) {
      return (
        <>
          {helperTextInCurrentStudy} {itemsCount !== itemsCountInCurrentStudy && linkToAcrossStudies}
        </>
      );
    }
    return <>No matching cases in this study &nbsp; {linkToAcrossStudies}</>;
  }

  return <>{helperTextAcrossStudies}</>;
};

export default FullSearchHelperText;
