import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { find, omit, reject, some } from 'lodash';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createClusterTypeOption, deleteClusterTypeOption, updateClusterTypeOption } from 'api/clusterTypes';
import { rowEditingControlsColumn } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { ClusterType } from 'interfaces/clusterType';
import { clusterTypeFields } from 'interfaces/clusterType/clusterTypeFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { ClusterTypeRowChangesSummary } from './ClusterTypeRowChangesSummary';
import { ClusterTypeDraft } from './types';
import { getClusterTypeError, getClusterTypeId } from './utils';

export const useClusterTypesColumns = ({
  apiRef,
  noRows,
  rowsWithChanges,
  draftClusterTypes,
  rowModesModel,
  setDraftClusterTypes,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowsWithChanges: Array<Omit<ClusterType, 'index'>>;
  draftClusterTypes: ClusterTypeDraft[];
  rowModesModel: GridRowModesModel;
  setDraftClusterTypes: React.Dispatch<React.SetStateAction<Array<Omit<ClusterType, 'index'>>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const { clearRowChanges, rowsChanges } = useTableEditingContext<Omit<ClusterType, 'index'>>();
  const confirmWithModal = useConfirmation();

  const queryClient = useQueryClient();

  const createClusterTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newClusterType) => {
      queryClient.invalidateQueries(['clusterTypes']);
      queryClient.setQueryData(['clusterTypes'], (oldData: ClusterType[]) => [...oldData, newClusterType]);
    },
    mutationFn: createClusterTypeOption,
    mutationDescription: 'create cluster type',
  });

  const updateClusterTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(['clusterTypes']);
      queryClient.setQueryData(['clusterTypes'], (oldData: ClusterType[]) => {
        const updatedClusterType = find(rowsWithChanges, { id: mutatingRowId });
        return [...reject(oldData, { id: mutatingRowId }), updatedClusterType];
      });
    },
    mutationFn: updateClusterTypeOption,
    mutationDescription: 'update cluster type',
  });

  const deleteClusterTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['clusterTypes']);
      queryClient.setQueryData(['clusterTypes'], (oldData: ClusterType[]) => reject(oldData, { id: variables }));
    },
    mutationFn: deleteClusterTypeOption,
    mutationDescription: 'delete cluster type',
  });

  const clusterTypeFieldsColumns = useEditableFieldsDataGridColumns<ClusterType | ClusterTypeDraft>({
    fields: clusterTypeFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode: false,
    idGetter: getClusterTypeId,
    useValueSetter: true,
  });

  const isDraftRow = React.useCallback(
    (id: GridRowId) => some(draftClusterTypes, { draftId: id }),
    [draftClusterTypes]
  );

  const columns = React.useMemo(() => {
    const deleteOperation = (id: GridRowId) => async () => {
      if (
        await confirmWithModal({
          title: 'Delete Cluster Type',
          text: 'Are you sure you want to delete this cluster type?',
        })
      ) {
        if (some(draftClusterTypes, { draftId: id })) {
          setDraftClusterTypes(reject(draftClusterTypes, { draftId: id as string }));
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [id]: draftRoleRowMode, ...rest } = rowModesModel;
          setRowModesModel(rest);
        } else if (id) {
          setMutatingRowId(id);
          await deleteClusterTypeMutation.mutateAsync(`${id}`);
          setMutatingRowId(undefined);
        }
        clearRowChanges(id);
        return true;
      } else {
        return false;
      }
    };

    const confirmCancelEdit = async (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      const clusterType = isDraft
        ? (rowsChanges[id] as ClusterType)
        : (find(rowsWithChanges, { id: id as string }) as ClusterType);
      return (
        !rowsChanges[id] ||
        (await confirmWithModal({
          title: `Cancel cluster type ${isDraft ? 'creation' : 'update'}`,
          text: (
            <ClusterTypeRowChangesSummary
              clusterTypeDisplayString={`Cluster Type "${clusterType?.displayName}" (${clusterType?.id ?? id})`}
              changes={rowsChanges[id]}
            />
          ),
        }))
      );
    };

    const saveOperation = async (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      const clusterType = isDraft
        ? (rowsChanges[id] as ClusterType)
        : (find(rowsWithChanges, { id: id as string }) as ClusterType);
      if (
        await confirmWithModal({
          title: `${isDraft ? 'Create' : 'Update'} cluster type`,
          text: (
            <ClusterTypeRowChangesSummary
              clusterTypeDisplayString={`Cluster Type "${clusterType?.displayName}" (${clusterType?.id ?? id})`}
              changes={rowsChanges[id]}
            />
          ),
        })
      ) {
        setMutatingRowId(id);
        if (isDraft) {
          await createClusterTypeMutation.mutateAsync(omit(clusterType, 'index', 'draftId'));
          setDraftClusterTypes(reject(draftClusterTypes, { draftId: id as string }));
        } else {
          await updateClusterTypeMutation.mutateAsync(omit(clusterType, 'index'));
        }
        setMutatingRowId(undefined);
        clearRowChanges(id);
        return true;
      } else {
        return false;
      }
    };

    return [
      ...clusterTypeFieldsColumns,
      rowEditingControlsColumn({
        mutatingRowId,
        rowModesModel,
        setRowModesModel,
        apiRef,
        getRowError: getClusterTypeError,
        deleteOperation,
        isDraftRow,
        onCancelEdit: (id: GridRowId) => {
          const isDraft = isDraftRow(id);
          if (isDraft) {
            setDraftClusterTypes(reject(draftClusterTypes, { draftId: id as string }));
          }
          clearRowChanges(id);
        },
        saveOperation,
        confirmCancelEdit,
        hideRowActions: (id) => {
          const currentRow = apiRef.current.getRowWithUpdatedValues(id, 'deletedAt');
          return currentRow?.deletedAt != null;
        },
      }),
    ];
  }, [
    apiRef,
    clearRowChanges,
    confirmWithModal,
    createClusterTypeMutation,
    deleteClusterTypeMutation,
    updateClusterTypeMutation,
    draftClusterTypes,
    mutatingRowId,
    rowsChanges,
    rowModesModel,
    rowsWithChanges,
    setDraftClusterTypes,
    setRowModesModel,
    clusterTypeFieldsColumns,
  ]);

  return columns;
};
