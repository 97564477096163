import { useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import { defaultStudySlideViewMode } from 'components/StudiesDashboard/StudySettings/GeneralSettings';
import { find } from 'lodash';
import React, { useEffect } from 'react';
import { BooleanParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';
import useStudy from 'utils/useStudy';

export const useStudyPlatformSettings = (
  studyId?: string // This is optional since studyId is not always available on first render
) => {
  const { labId } = useCurrentLabId();

  const [{ displayOneSlideByDefault, defaultStainingType }, setQueryParams] = useQueryParams(
    {
      displayOneSlideByDefault: BooleanParam,
      defaultStainingType: StringParam,
      useDeckGL: BooleanParam,
    },
    { updateType: 'replaceIn' }
  );

  const { isLoading: studiesLoading, data: allStudies } = useQuery(['studies', labId], {
    queryFn: fetchStudies(labId),
    keepPreviousData: true,
    enabled: Boolean(studyId) && Boolean(labId),
  });
  const studyDataInAllStudies = find(allStudies, { id: studyId });
  // Load single study if it is not in all studies, otherwise use the study from all studies to avoid loading it again
  const { study } = useStudy(studyId, { enabled: Boolean(studyId) && !studyDataInAllStudies });

  const loadedStudy = studyDataInAllStudies || study;
  const currentStudySettings = loadedStudy?.id === studyId ? loadedStudy?.settings : null;

  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);

  const lastSetStudyId = React.useRef<string | null>(null);
  useEffect(() => {
    if (studyId && !slidesMode && !studiesLoading && currentStudySettings) {
      const studySettingsSlideViewMode = currentStudySettings.defaultSlideViewMode || defaultStudySlideViewMode;
      const newDisplayOneSlideByDefault = studySettingsSlideViewMode === 'single';
      if (
        newDisplayOneSlideByDefault !== displayOneSlideByDefault ||
        currentStudySettings.defaultStainingType !== defaultStainingType ||
        lastSetStudyId.current !== studyId
      ) {
        setQueryParams(
          {
            displayOneSlideByDefault: newDisplayOneSlideByDefault,
            defaultStainingType: currentStudySettings.defaultStainingType,
            useDeckGL: currentStudySettings.useDeckGL ?? useDeckGL,
          },
          'replaceIn'
        );
        lastSetStudyId.current = studyId;
      }
    }
  }, [studyId, slidesMode, currentStudySettings, studiesLoading, setQueryParams]);

  const canLoadStudySettings = Boolean(studyId);
  const isLoadingStudySettings = canLoadStudySettings && !currentStudySettings && studiesLoading;
  return { isLoadingStudySettings };
};
