import { GridRowId, GridRowModesModel, GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { find, omit, reject, some } from 'lodash';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createExternalLabelOption, deleteExternalLabelOption, updateExternalLabelOption } from 'api/externalLabels';
import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { rowEditingControlsColumn } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { ExternalLabel } from 'interfaces/externalLabel';
import { externalLabelFields } from 'interfaces/externalLabel/externalLabelFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { ExternalLabelRowChangesSummary } from './ExternalLabelRowChangesSummary';

const getExternalLabelError = ({
  id,
  apiRef,
}: GridRowParams<ExternalLabel> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateExternalLabel = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['text'],
  });

  const missingRequiredFields = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['text'],
  });

  return hasDuplicateExternalLabel
    ? 'Duplicate external label'
    : missingRequiredFields
    ? 'Missing required fields'
    : '';
};

export const useExternalLabelsColumns = ({
  apiRef,
  noRows,
  rowsWithChanges,
  draftExternalLabels,
  rowModesModel,
  setDraftExternalLabels,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowsWithChanges: ExternalLabel[];
  draftExternalLabels: ExternalLabel[];
  rowModesModel: GridRowModesModel;
  setDraftExternalLabels: React.Dispatch<React.SetStateAction<ExternalLabel[]>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const { clearRowChanges, rowsChanges } = useTableEditingContext<ExternalLabel>();
  const confirmWithModal = useConfirmation();

  const queryClient = useQueryClient();

  const commonMutationOptions = {
    onError: () => {
      setMutatingRowId(undefined);
    },
  };

  const createExternalLabelMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newExternalLabel) => {
      queryClient.invalidateQueries(['externalLabels']);
      queryClient.setQueryData(['externalLabels'], (oldData: ExternalLabel[]) => [...oldData, newExternalLabel]);
    },
    mutationFn: createExternalLabelOption,
    mutationDescription: 'create external label',
  });
  const updateExternalLabelMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(['externalLabels']);
      queryClient.setQueryData(['externalLabels'], (oldData: ExternalLabel[]) => {
        const updatedExternalLabel = find(rowsWithChanges, { id: mutatingRowId });
        return [...reject(oldData, { id: mutatingRowId }), updatedExternalLabel];
      });
    },
    mutationFn: updateExternalLabelOption,
    mutationDescription: 'update external label',
  });
  const deleteExternalLabelMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['externalLabels']);
      queryClient.setQueryData(['externalLabels'], (oldData: ExternalLabel[]) => reject(oldData, { id: variables }));
    },
    mutationFn: deleteExternalLabelOption,
    mutationDescription: 'delete external label',
  });

  const deleteOperation = (id: GridRowId) => async () => {
    if (
      await confirmWithModal({
        title: 'Delete External Label',
        text: 'Are you sure you want to delete this external label?',
      })
    ) {
      if (some(draftExternalLabels, { id })) {
        setDraftExternalLabels(reject(draftExternalLabels, { id: id as string }));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [id]: draftExternalLabelRowMode, ...rest } = rowModesModel;
        setRowModesModel(rest);
      } else if (id) {
        setMutatingRowId(id);
        await deleteExternalLabelMutation.mutateAsync(`${id}`);
        setMutatingRowId(undefined);
      }
      clearRowChanges(id);
      return true;
    } else {
      return false;
    }
  };

  const isDraftRow = (id: GridRowId) => some(draftExternalLabels, { id });

  const confirmCancelEdit = async (id: GridRowId) => {
    const isDraft = isDraftRow(id);
    return (
      !rowsChanges[id] ||
      (await confirmWithModal({
        title: `Cancel externalLabel ${isDraft ? 'creation' : 'update'}`,
        text: <ExternalLabelRowChangesSummary changes={rowsChanges[id]} />,
      }))
    );
  };

  const saveOperation = async (id: GridRowId) => {
    const isDraft = isDraftRow(id);
    const externalLabel = find(rowsWithChanges, { id: id as string });
    if (
      await confirmWithModal({
        title: `${isDraft ? 'Create' : 'Update'} External Label`,
        text: <ExternalLabelRowChangesSummary changes={rowsChanges[id]} />,
      })
    ) {
      setMutatingRowId(id);
      if (isDraft) {
        await createExternalLabelMutation.mutateAsync(omit(externalLabel, 'id'));
        setDraftExternalLabels(reject(draftExternalLabels, { id: id as string }));
      } else {
        await updateExternalLabelMutation.mutateAsync(externalLabel);
      }
      setMutatingRowId(undefined);
      clearRowChanges(id);
      return true;
    } else {
      return false;
    }
  };

  const externalLabelFieldsColumns = useEditableFieldsDataGridColumns({
    fields: externalLabelFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode: false,
    useValueSetter: true,
  });

  return [
    ...externalLabelFieldsColumns,
    rowEditingControlsColumn({
      mutatingRowId,
      rowModesModel,
      setRowModesModel,
      apiRef,
      getRowError: getExternalLabelError,
      deleteOperation,
      isDraftRow,
      onCancelEdit: (id: GridRowId) => {
        const isDraft = isDraftRow(id);
        if (isDraft) {
          setDraftExternalLabels(reject(draftExternalLabels, { id: id as string }));
        }
        clearRowChanges(id);
      },
      saveOperation,
      confirmCancelEdit,
      hideRowActions: (id) => {
        const currentRow = apiRef.current.getRowWithUpdatedValues(id, 'deletedAt');
        return currentRow?.deletedAt != null;
      },
    }),
  ];
};
