import { useSignals } from '@preact/signals-react/runtime';
import { every, find, forEach, includes, isEmpty, map, without } from 'lodash';
import React from 'react';

import {
  LayerVisualizationSettings,
  defaultHeatmapOpacity,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import {
  FeatureMetadata,
  getAllFlatMapDeepHeatmaps,
} from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { getRasterHeatmapUrlKey } from './rasterHeatmapHelpers';

export const useSelectHeatmap = () => {
  useSignals();

  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const changeSelectHeatmap = ({
    viewerIndex,
    slideId,
    heatmapId,
    heatmaps,
    stainTypeId,
    checked,
    debug = false,
    setHeatmapsExpanded,
  }: {
    viewerIndex: number;
    slideId: string;
    heatmapId: string;
    heatmaps: FeatureMetadata[];
    stainTypeId: string;
    checked: boolean;
    debug?: boolean;
    setHeatmapsExpanded: React.Dispatch<React.SetStateAction<string[]>>;
  }) => {
    if (debug) {
      console.debug('changeSelectHeatmap', {
        viewerIndex,
        slideId,
        heatmapId,
        heatmaps,
        stainTypeId,
        checked,
        debug,
      });
    }
    const allHeatmaps = getAllFlatMapDeepHeatmaps(heatmaps);
    const heatmap = find(allHeatmaps, { id: heatmapId });

    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    if (!viewerSlideLayerVisualizationSettings) {
      console.warn(`Invalid viewerIndex: ${viewerIndex}`);
      return;
    }

    if (!heatmap) {
      console.error('changeSelectHeatmap: heatmap not found', { heatmapId, allHeatmaps });
      return;
    }
    if (checked) {
      setHeatmapsExpanded((prevHeatmapsExpanded) => [...prevHeatmapsExpanded, heatmapId]);
    } else {
      setHeatmapsExpanded((prevHeatmapsExpanded) => without(prevHeatmapsExpanded, heatmapId));
    }

    const newLayerSettings: LayerVisualizationSettings = {
      selected: checked,
      show: checked,
      opacity: checked ? defaultHeatmapOpacity : 0,
    };

    const changes: Array<{
      layerId: string;
      layerUrlKey?: string;
      newSettings: Partial<LayerVisualizationSettings>;
    }> = [];
    const parentHeatmap = find(allHeatmaps, ({ nestedItems }) => includes(map(nestedItems, 'id'), heatmapId));
    changes.push({
      layerId: heatmapId,
      newSettings: newLayerSettings,
      layerUrlKey: getRasterHeatmapUrlKey(heatmap, parentHeatmap),
    });
    if (debug) {
      console.debug('Adding change to heatmap', { layerId: heatmapId, newSettings: newLayerSettings });
    }

    // If the heatmap has children, update the children
    const nestedHeatmapIds = map(heatmap?.nestedItems, 'id');
    if (!isEmpty(nestedHeatmapIds)) {
      const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId] || {};

      forEach(nestedHeatmapIds, (key) => {
        const nestedLayerSettings = slideLayerVisualizationSettings?.[key];
        const previousNestedLayerSettings = nestedLayerSettings?.value;
        const newNestedLayerSettings = {
          ...(previousNestedLayerSettings || {}),
          ...newLayerSettings,
          opacity: previousNestedLayerSettings?.opacity ?? defaultHeatmapOpacity,
        };
        const nestedHeatmap = find(allHeatmaps, { id: key });
        changes.push({
          layerId: key,
          newSettings: newNestedLayerSettings,
          layerUrlKey: getRasterHeatmapUrlKey(nestedHeatmap, parentHeatmap),
        });
        if (debug) {
          console.debug('Adding change to nested heatmap', { layerId: key, newSettings: newNestedLayerSettings });
        }
      });
      applyChangesToSlideLayerVisualizationSettings({ slideId, viewerIndex, changes, stainTypeId, debug });
      return;
    }

    // If the heatmap doesn't have a parent, finish updating
    const parentHeatmapId = parentHeatmap?.id;
    if (!parentHeatmapId) {
      applyChangesToSlideLayerVisualizationSettings({ slideId, viewerIndex, changes, stainTypeId, debug });
      return;
    }

    // If the heatmap has a parent, update the parent and siblings as needed (show parent and hide siblings if all siblings are selected, hide parent if not all siblings are selected)
    const newLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value;
    const newSlideLayerVisualizationSettings = newLayerVisualizationSettings?.[slideId] || {};

    const parentHeatmapSettings = newSlideLayerVisualizationSettings?.[parentHeatmapId];
    const previousParentHeatmapSettings = parentHeatmapSettings?.value;

    const parentNestedHeatmapIds = map(parentHeatmap?.nestedItems, 'id');

    const allNestedHeatmapsVisible = every(
      map(parentNestedHeatmapIds, (nestedHeatmapId) =>
        nestedHeatmapId === heatmapId ? checked : newSlideLayerVisualizationSettings[nestedHeatmapId]?.value?.selected
      )
    );

    if (allNestedHeatmapsVisible) {
      const newParentHeatmapSettings = {
        ...(previousParentHeatmapSettings || {}),
        selected: true,
        show: true,
        opacity: previousParentHeatmapSettings?.opacity ?? defaultHeatmapOpacity,
      };
      changes.push({
        layerId: parentHeatmapId,
        newSettings: newParentHeatmapSettings,
        layerUrlKey: getRasterHeatmapUrlKey(parentHeatmap),
      });
      if (debug) {
        console.debug('Adding change to heatmap parent because all nested heatmaps are visible', {
          layerId: parentHeatmapId,
          newSettings: newParentHeatmapSettings,
        });
      }
      forEach(parentNestedHeatmapIds, (key) => {
        const nestedHeatmap = find(allHeatmaps, { id: key });
        const nestedLayerSettings = newSlideLayerVisualizationSettings?.[key];
        const previousNestedLayerSettings = nestedLayerSettings?.value;
        const newNestedLayerSettings = {
          ...(previousNestedLayerSettings || {}),
          selected: true,
          show: false,
          opacity: previousNestedLayerSettings?.opacity ?? defaultHeatmapOpacity,
        };
        changes.push({
          layerId: key,
          newSettings: newNestedLayerSettings,
          layerUrlKey: getRasterHeatmapUrlKey(nestedHeatmap, parentHeatmap),
        });
        if (debug) {
          console.debug('Adding change to nested heatmap because all nested heatmaps are visible', {
            layerId: key,
            newSettings: newNestedLayerSettings,
          });
        }
      });
      applyChangesToSlideLayerVisualizationSettings({ slideId, viewerIndex, changes, stainTypeId, debug });
      return;
    } else {
      const newParentHeatmapSettings = {
        ...(previousParentHeatmapSettings || {}),
        show: false,
        selected: false,
        opacity: previousParentHeatmapSettings?.opacity ?? defaultHeatmapOpacity,
      };
      changes.push({
        layerId: parentHeatmapId,
        newSettings: newParentHeatmapSettings,
        layerUrlKey: getRasterHeatmapUrlKey(parentHeatmap),
      });
      if (debug) {
        console.debug('Adding change to heatmap parent because not all nested heatmaps are visible', {
          layerId: parentHeatmapId,
          newSettings: newParentHeatmapSettings,
        });
      }

      forEach(parentNestedHeatmapIds, (key) => {
        const nestedHeatmap = find(allHeatmaps, { id: key });
        const nestedLayerSettings = newSlideLayerVisualizationSettings?.[key];
        const previousNestedLayerSettings = nestedLayerSettings?.value;
        const newNestedLayerSettings = {
          selected: false,
          ...(previousNestedLayerSettings || {}),
          show: Boolean(previousNestedLayerSettings?.selected),
        };
        changes.push({
          layerId: key,
          newSettings: newNestedLayerSettings,
          layerUrlKey: getRasterHeatmapUrlKey(nestedHeatmap, parentHeatmap),
        });
        if (debug) {
          console.debug('Adding change to nested heatmap because not all nested heatmaps are visible', {
            layerId: key,
            newSettings: newNestedLayerSettings,
          });
        }
      });

      if (debug) {
        console.debug('Final changes', { slideId, viewerIndex, changes, stainTypeId, heatmapId, heatmaps, checked });
      }

      applyChangesToSlideLayerVisualizationSettings({ slideId, viewerIndex, changes, stainTypeId, debug });
      return;
    }
  };

  return { changeSelectHeatmap };
};
