import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { find, omit, reject, some } from 'lodash';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createModelTypeOption, deleteModelTypeOption, updateModelTypeOption } from 'api/modelTypes';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { rowEditingControlsColumn } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { ModelType } from 'interfaces/modelType';
import { modelTypeFields } from 'interfaces/modelType/modelTypeFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { ModelTypeRowChangesSummary } from './ModelTypeRowChangesSummary';
import { ModelTypeDraft } from './types';
import { getModelTypeError, getModelTypeId } from './utils';

export const useModelTypesColumns = ({
  apiRef,
  noRows,
  rowsWithChanges,
  draftModelTypes,
  rowModesModel,
  setDraftModelTypes,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowsWithChanges: Array<ModelType>;
  draftModelTypes: ModelTypeDraft[];
  rowModesModel: GridRowModesModel;
  setDraftModelTypes: React.Dispatch<React.SetStateAction<Array<ModelType>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const { clearRowChanges, rowsChanges } = useTableEditingContext<ModelType>();
  const confirmWithModal = useConfirmation();

  const queryClient = useQueryClient();

  const createModelTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newModelType) => {
      queryClient.invalidateQueries(['modelTypes']);
      queryClient.setQueryData(['modelTypes'], (oldData: ModelType[]) => [...oldData, newModelType]);
    },
    mutationFn: createModelTypeOption,
    mutationDescription: 'create model type',
  });

  const updateModelTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(['modelTypes']);
      queryClient.setQueryData(['modelTypes'], (oldData: ModelType[]) => {
        const updatedModelType = find(rowsWithChanges, { id: mutatingRowId });
        return [...reject(oldData, { id: mutatingRowId }), updatedModelType];
      });
    },
    mutationFn: updateModelTypeOption,
    mutationDescription: 'update model type',
  });

  const deleteModelTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['modelTypes']);
      queryClient.setQueryData(['modelTypes'], (oldData: ModelType[]) => reject(oldData, { id: variables }));
    },
    mutationFn: deleteModelTypeOption,
    mutationDescription: 'delete model type',
  });

  const modelTypeFieldsColumns = useEditableFieldsDataGridColumns<ModelType | ModelTypeDraft>({
    fields: modelTypeFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode: false,
    idGetter: getModelTypeId,
    useValueSetter: true,
  });

  const isDraftRow = React.useCallback((id: GridRowId) => some(draftModelTypes, { draftId: id }), [draftModelTypes]);

  const columns = React.useMemo(() => {
    const deleteOperation = (id: GridRowId) => async () => {
      if (
        await confirmWithModal({
          title: 'Delete Model Type',
          text: 'Are you sure you want to delete this model type?',
        })
      ) {
        if (some(draftModelTypes, { draftId: id })) {
          setDraftModelTypes(reject(draftModelTypes, { draftId: id as string }));
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [id]: draftRoleRowMode, ...rest } = rowModesModel;
          setRowModesModel(rest);
        } else if (id) {
          setMutatingRowId(id);
          await deleteModelTypeMutation.mutateAsync(`${id}`);
          setMutatingRowId(undefined);
        }
        clearRowChanges(id);
        return true;
      } else {
        return false;
      }
    };

    const confirmCancelEdit = async (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      const modelType = isDraft
        ? (rowsChanges[id] as ModelType)
        : (find(rowsWithChanges, { id: id as string }) as ModelType);
      return (
        !rowsChanges[id] ||
        (await confirmWithModal({
          title: `Cancel model type ${isDraft ? 'creation' : 'update'}`,
          text: (
            <ModelTypeRowChangesSummary
              modelTypeDisplayString={`Model Type "${modelType?.displayName}" (${modelType?.id ?? id})`}
              changes={rowsChanges[id]}
            />
          ),
        }))
      );
    };

    const saveOperation = async (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      const modelType = isDraft
        ? (find(rowsWithChanges, { draftId: id as string }) as ModelType)
        : (find(rowsWithChanges, { id: id as string }) as ModelType);
      if (
        await confirmWithModal({
          title: `${isDraft ? 'Create' : 'Update'} model type`,
          text: (
            <ModelTypeRowChangesSummary
              modelTypeDisplayString={`Model Type "${modelType?.displayName}" (${modelType?.id ?? id})`}
              changes={rowsChanges[id]}
            />
          ),
        })
      ) {
        setMutatingRowId(id);
        if (isDraft) {
          await createModelTypeMutation.mutateAsync(omit(modelType, 'index', 'draftId'));
          setDraftModelTypes(reject(draftModelTypes, { draftId: id as string }));
        } else {
          await updateModelTypeMutation.mutateAsync(omit(modelType, 'index'));
        }
        setMutatingRowId(undefined);
        clearRowChanges(id);
        return true;
      } else {
        return false;
      }
    };

    return [
      ...modelTypeFieldsColumns,
      rowEditingControlsColumn({
        mutatingRowId,
        rowModesModel,
        setRowModesModel,
        apiRef,
        getRowError: getModelTypeError,
        deleteOperation,
        isDraftRow,
        onCancelEdit: (id: GridRowId) => {
          const isDraft = isDraftRow(id);
          if (isDraft) {
            setDraftModelTypes(reject(draftModelTypes, { draftId: id as string }));
          }
          clearRowChanges(id);
        },
        saveOperation,
        confirmCancelEdit,
      }),
    ];
  }, [
    apiRef,
    clearRowChanges,
    confirmWithModal,
    createModelTypeMutation,
    deleteModelTypeMutation,
    updateModelTypeMutation,
    draftModelTypes,
    mutatingRowId,
    rowsChanges,
    rowModesModel,
    rowsWithChanges,
    setDraftModelTypes,
    setRowModesModel,
    modelTypeFieldsColumns,
  ]);

  return columns;
};
