export type QualityControlSource = 'slide' | 'experimentResult';

interface AutomaticConditionBase {
  conditionType: string;
}

export interface AutomaticConditionRange {
  field: string;
  min?: number;
  max?: number;
  source: QualityControlSource;
}

export interface AutomaticConditionLogical {
  operator: ConditionOperator;
  operands: AutomaticCondition[];
}

export interface AutomaticConditionTag {
  tagId: string;
  isAssigned: boolean; // true to assert that the tag is assigned, false to assert that the tag is not assigned
}

export interface AutomaticConditionCell extends AutomaticConditionBase {
  mappingFilter: string;
  filterAction: string;
  toFilter: string[] | number[];
  displayValue?: string;
}

export type AutomaticCondition =
  | AutomaticConditionRange
  | AutomaticConditionLogical
  | AutomaticConditionTag
  | AutomaticConditionCell;

export enum ConditionType {
  CELL = 'CellCondition',
  RANGE = 'RangeCondition',
  LOGICAL = 'LogicalCondition',
  TAG = 'TagCondition',
}

export enum ConditionOperator {
  AND = 'AND',
  OR = 'OR',
}

export const conditionOperatorFilterAction = {
  AND: '$and',
  OR: '$or',
};

export const conditionOperatorFilterActionOptions: {
  label: ConditionOperator;
  value: string;
}[] = [
  {
    label: ConditionOperator.AND,
    value: '$and',
  },
  {
    label: ConditionOperator.OR,
    value: '$or',
  },
];

export enum CategoricalActions {
  IS = 'is',
  IS_NOT = 'is not',
}

export const allCategoricalActions = [CategoricalActions.IS, CategoricalActions.IS_NOT];

export const filterActionCategorical = {
  categorical: [
    { label: CategoricalActions.IS, id: '$in' },
    { label: CategoricalActions.IS_NOT, id: '$nin' },
  ],
};
