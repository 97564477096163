import { COORDINATE_SYSTEM } from '@deck.gl/core/typed';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { Feature } from '@turf/helpers';
import { cloneDeep, entries, filter, find, flatMap, fromPairs, map } from 'lodash';
import { useMemo } from 'react';

import { defaultMultiplexChannelColors } from 'components/Procedure/Infobar/SlideInfobar/Multiplex/colorSettings';
import { getAnnotationSettingsKey } from 'components/Procedure/Infobar/SlideInfobar/SlideAnnotation/helpers';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Permission } from 'interfaces/permissionOption';
import annotationsService from 'services/annotations/annotationsService';
import { hexToRgb } from 'utils/helpers';
import { usePermissions } from 'utils/usePermissions';
import { useSlideAnnotationLayersData } from './useSlideAnnotationLayersData';

export const useViewAnnotationLayers = ({ slide }: { slide: SlideWithChannelAndResults }) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canViewAnnotations = hasPermission(Permission.ViewAnnotations);

  const { slideAnnotations, isLoading } = useSlideAnnotationLayersData({ slideId: slide?.id });

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];
  const slideLayerVisualizationSettings = fromPairs(
    map(entries(viewerSlideLayerVisualizationSettings?.value?.[slide.id]), ([key, settingsSignal]) => [
      key,
      cloneDeep(settingsSignal?.value),
    ])
  );

  const viewAnnotationFeatures = useMemo(() => {
    if (isLoading || !canViewAnnotations || (slideAnnotations?.length || 0) === 0) {
      return [];
    }
    return flatMap(slideAnnotations, (annotation) =>
      flatMap(annotation.annotationsData, (annotationData, annotationIndex): Feature[] => {
        const innerTodo = annotationsService.deserializeAnnotationObject(cloneDeep(annotationData), true);
        if (!innerTodo?.markers) {
          return [];
        }

        const filteredFeatures = filter(innerTodo.markers?.features, (feature) =>
          Boolean(
            slideLayerVisualizationSettings?.[getAnnotationSettingsKey(annotation, feature?.properties?.diagnosis)]
              ?.selected
          )
        );

        return map(filteredFeatures, (feature) => ({
          ...feature,
          properties: {
            ...feature.properties,
            annotationIndex,
            annotationId: annotation.annotationId,
            todo: innerTodo.todo,
          },
        })) as Feature[];
      })
    );
  }, [canViewAnnotations, slideAnnotations, JSON.stringify(slideLayerVisualizationSettings)]);

  const selectedAnnotationFeatures = filter(viewAnnotationFeatures, (feature) => {
    const annotationId = feature?.properties?.annotationId;
    const diagnosis = feature?.properties?.diagnosis;
    const annotation = find(slideAnnotations, { annotationId });

    const featureSettings = slideLayerVisualizationSettings?.[getAnnotationSettingsKey(annotation, diagnosis)];
    return featureSettings?.selected;
  });

  const viewAnnotationLayers = map(selectedAnnotationFeatures, (feature, dataIdx) => {
    const annotationId = feature?.properties?.annotationId;
    const diagnosis = feature?.properties?.diagnosis;
    const annotation = find(slideAnnotations, { annotationId });

    const featureKey = getAnnotationSettingsKey(annotation, diagnosis);
    const featureSettings = slideLayerVisualizationSettings?.[featureKey];

    return new GeoJsonLayer({
      id: `annotation-view-${slide.id}-${featureKey}-${dataIdx}`,
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      data: feature,
      opacity: featureSettings?.show ? featureSettings?.opacity / 100 : 0,
      getFillColor: hexToRgb(
        featureSettings?.color?.hex ||
          featureSettings?.color ||
          defaultMultiplexChannelColors[dataIdx % defaultMultiplexChannelColors.length]
      ),
      getLineColor: hexToRgb(
        featureSettings?.color?.hex ||
          featureSettings?.color ||
          defaultMultiplexChannelColors[dataIdx % defaultMultiplexChannelColors.length]
      ),
      filled: false,
      pointRadiusMinPixels: 5,
      pointRadiusScale: 2,
      lineWidthScale: 2,
      lineWidthMinPixels: 2,
    });
  });

  return viewAnnotationLayers;
};
