import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { Box, IconButton, Tooltip } from '@mui/material';
import { TableEditingContextProvider } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { RowSelectionContextProvider } from 'components/atoms/RowSelectionContext';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';

import React, { FunctionComponent, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import StudyManifestDialog from './CaseManifestDialog';

interface StudyManifestControlProps {
  studyId: string;
}

const StudyManifestControl: FunctionComponent<React.PropsWithChildren<StudyManifestControlProps>> = ({ studyId }) => {
  const [open, setOpen] = useState(false);
  const [slidesMode, setSlidesMode] = useQueryParam('slidesMode', BooleanParam);
  const previousSlidesMode = slidesMode;
  const procedureFieldContext = useProceduresFieldsContext();

  return (
    <>
      <Tooltip title="Manifest" placement="top">
        <Box>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setSlidesMode(true);
              setOpen(true);
            }}
          >
            <AutoFixHighIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <RowSelectionContextProvider>
        <TableEditingContextProvider fieldsContext={procedureFieldContext}>
          <StudyManifestDialog
            open={open}
            onClose={(e) => {
              e?.stopPropagation();
              setOpen(false);
              setSlidesMode(previousSlidesMode);
            }}
            studyId={studyId}
          />
        </TableEditingContextProvider>
      </RowSelectionContextProvider>
    </>
  );
};

export default StudyManifestControl;
