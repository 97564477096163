import InsertChartIcon from '@mui/icons-material/InsertChart';

import { Box, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PageHeader from 'components/PageHeader';
import { Permission } from 'interfaces/permissionOption';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isPfizerDemoLab } from 'utils/demo';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';
import SlideStatus from './SlideStatus';

import { MainButton } from 'components/atoms/Buttons/MainButton';
import JobsControl from 'components/Pages/Jobs/JobsControl';
import StudySettingsControl from 'components/StudiesDashboard/StudySettings/StudySettingsControl';
import { Study } from 'interfaces/study';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import StudyManifestControl from './CaseManifest/CaseManifestControl';

interface Props {
  cohortMode: boolean;
  renderCohortDetailsToggle?: () => React.ReactNode;
  pageTitle: string;
  tab: string;
  isAnalyzing: boolean;
  isCurrentCheatMode: boolean;
  cheatStage: number;

  tooltipText?: string;
  isAddSlidesDisabled?: boolean;
  study: Study;
  onAddSlidesClick?: () => void;
}

const StudyDashboardHeader = ({
  cohortMode,
  renderCohortDetailsToggle,
  pageTitle,
  tab,
  isAnalyzing,
  isCurrentCheatMode,
  cheatStage,
  tooltipText,
  isAddSlidesDisabled,
  study,
  onAddSlidesClick,
}: Props) => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const canAccessAnalysisStudio = hasPermission(Permission.AccessAnalysisStudio);

  const { queryParams, encodedFilters } = useEncodedFilters();

  const { labId, stringifyWithLab } = useCurrentLabId();
  const pfizerDemoLab = isPfizerDemoLab(labId);

  return (
    <Grid container my={4} px={3} justifyContent="space-between">
      <Grid item xl={8} lg={7} md={7} sm={12}>
        <Grid container alignItems="center" spacing={2}>
          <PageHeader
            isCohort={cohortMode}
            renderCohortDetailsToggle={renderCohortDetailsToggle}
            pageTitle={pageTitle}
            onBackClick={() => {
              const search = stringifyWithLab({
                tab,
                resultsMode: queryParams.resultsMode,
              });
              navigate({
                pathname: `/`,
                search: `?${search}`,
              });
            }}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="row" spacing={1}>
          {isAnalyzing && isCurrentCheatMode ? (
            cheatStage === 2 ? (
              <>
                <span className="gray"> Processing mode</span>
                <span className="ellipsis">...</span>
              </>
            ) : (
              <>
                <span className="gray"> Results are ready</span>
                <i className="icon-success" />
              </>
            )
          ) : (
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <SlideStatus
                    isCheatMode={isCurrentCheatMode}
                    cheatStage={cheatStage}
                    ingested={study?.countIngestedInManifest}
                    total={study?.countInManifest}
                    navigateToUploadsDashboard
                  />
                </Box>

                {!pfizerDemoLab && (
                  <Grid item>
                    <Tooltip title={tooltipText} placement="top" arrow>
                      <MainButton
                        disableElevation
                        variant="contained"
                        color="secondary"
                        disabled={isAddSlidesDisabled}
                        onClick={onAddSlidesClick}
                      >
                        Add Slides
                      </MainButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item>
                  <MainButton
                    disabled={!canAccessAnalysisStudio}
                    endIcon={<InsertChartIcon />}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      navigate({
                        pathname: `/features-dashboard`,
                        search: encodedFilters,
                      });
                    }}
                  >
                    Analysis Studio
                  </MainButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Grid container direction="row">
              {hasPermission(Permission.ViewJobs) && !cohortMode && Boolean(study) && (
                <Grid item>
                  <JobsControl studyId={study?.id} cameFrom="/procedures" />
                </Grid>
              )}
              {hasPermission(Permission.ViewStudySettings) && !cohortMode && Boolean(study) && (
                <Grid item>
                  <StudySettingsControl study={study} />
                </Grid>
              )}
              {hasPermission(Permission.ApplyCaseManifest) && !cohortMode && Boolean(study) && (
                <Grid item>
                  <StudyManifestControl studyId={study.id} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StudyDashboardHeader;
