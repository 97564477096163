import TuneIcon from '@mui/icons-material/Tune';
import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { Study } from 'interfaces/study';
import React, { FunctionComponent, useState } from 'react';
import StudySettingsModal from './StudySettingsModal';

interface StudySettingsControlProps {
  study: Study;
}

const StudySettingsControl: FunctionComponent<React.PropsWithChildren<StudySettingsControlProps>> = ({ study }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title="Settings" placement="top">
        <Box>
          <IconButton
            onClick={(e) => {
              setOpen(true);
              e.stopPropagation();
            }}
          >
            <TuneIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <StudySettingsModal
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        study={study}
      />
    </>
  );
};

export default StudySettingsControl;
