import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PieChartIcon from '@mui/icons-material/PieChart';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Collapse, Tooltip } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { QueryFunctionContext, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { flatMap, isEmpty, map, some, times } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { getFeatureKeysByStudyAndOrchestration } from 'api/features';
import { getProcedures } from 'api/procedures';
import { ControlledChartOptions } from 'components/FeaturesDashboard/ControlledChart';
import Loader from 'components/Loader';
import ChartsSection, { ChartConfigurationParam } from 'components/Pages/AnalysisStudio/ChartsSection';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { mainMarginTop, overviewDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import PresetSection, { PresetPermissions } from 'components/atoms/PresetSection';
import { BasePreset } from 'interfaces/basePreset';
import { ChartType } from 'interfaces/chart';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { OverviewPreset, getFeaturesInPresetCharts } from 'interfaces/overviewPreset';
import { Permission } from 'interfaces/permissionOption';
import { ProcedureResponse } from 'interfaces/procedure';
import { Study } from 'interfaces/study';
import { useAppSelector } from 'redux/hooks';
import { uuidv4 } from 'utils/helpers';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import useStudyMutations from 'utils/useStudyMutations';
import {
  useDeleteOverviewPresetMutations,
  useOverviewPresets,
  useSaveOverviewPresetMutations,
  useUpdateOverviewPresetMutations,
} from './useOverviewPresets';

interface Props {
  currentStudy: Study;
  totalProcedures: number;
  enable?: boolean;
}

const CasesOverviewDrawer: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  currentStudy,
  totalProcedures,
  enable,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { labId } = useCurrentLabId();
  const { name: userName } = useAppSelector((state) => state.auth.profile);
  const { queryParams, generateEncodedParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.FeatureValues,
  });

  const [casesOverviewDrawerOpen, setCasesOverviewDrawerOpen] = useQueryParam('casesOverviewDrawerOpen', BooleanParam);

  const {
    overviewPresets,
    isPresetsLoading,
    getPresetById,
    getUserDefaultPreset,
    defaultPreset,
    userLocalCasesOverviewPresets,
    setUserLocalCasesOverviewPresets,
  } = useOverviewPresets(currentStudy, {
    enabled: Boolean(currentStudy) && casesOverviewDrawerOpen,
  });

  const userDefaultPreset = useMemo(() => {
    return getUserDefaultPreset();
  }, [currentStudy, overviewPresets, userLocalCasesOverviewPresets]);

  const [presetsSectionOpen, setPresetsSectionOpen] = useState(false);
  const [currentPreset, setCurrentPreset] = useState<Partial<OverviewPreset>>(userDefaultPreset);

  useEffect(() => {
    setCurrentPreset(userDefaultPreset);
    setExistingChartsMap(userDefaultPreset?.preset || {});
  }, [userDefaultPreset]);

  const pageSize = DEFAULT_PAGE_SIZE;

  const getEncodedParams = ({
    page,
    featureSelection,
    isSingleFeature = false,
  }: {
    page?: number;
    featureSelection?: string[];
    isSingleFeature?: boolean;
  }) => {
    return generateEncodedParams(
      {
        page: page,
        pageSize: pageSize,
      },
      { isAnalysis: true, isSingleFeature: isSingleFeature, featuresSelection: (featureSelection || []).sort() },
      { isAnalysis: BooleanParam, isSingleFeature: BooleanParam }
    );
  };

  const [existingChartsMap, setExistingChartsMap] = useQueryParam<Record<number, ControlledChartOptions>>(
    'overviewCharts',
    ChartConfigurationParam
  );

  const [cohortWithSelectedFeatures, setCohort] = useState<CohortWithSelectedFeatures>({
    id: `cohort${currentStudy?.id}`,
    labId: currentStudy?.labId,
    name: currentStudy?.name,
    highlightedFeatures: currentStudy?.highlightedFeatures,
    inferredFeaturesConfig: currentStudy?.inferredFeaturesConfig,
    procedures: [],
    survivalAnalysis: undefined,
    dendrogram: undefined,
  });

  useEffect(() => {
    setCohort((prevCohort) => ({
      ...prevCohort,
      id: `cohort${currentStudy?.id}`,
      labId: currentStudy?.labId,
      name: currentStudy?.name,
      highlightedFeatures: currentStudy?.highlightedFeatures,
      inferredFeaturesConfig: currentStudy?.inferredFeaturesConfig,
    }));
  }, [currentStudy]);

  const totalPages = totalProcedures ? Math.ceil(totalProcedures / pageSize) : 0;

  const proceduresQueries = useQueries({
    queries: times(totalPages, (index) => {
      const encodedFilters = getEncodedParams({
        page: index + 1,
        featureSelection: [
          ...(getFeaturesInPresetCharts(userDefaultPreset?.preset) ?? []),
          ...map(cohortWithSelectedFeatures.inferredFeaturesConfig, 'featureName'),
        ],
      });
      const basicEncodedFilters = getEncodedParams({
        page: index + 1,
      });

      return {
        queryKey: ['procedures', encodedFilters],
        queryFn: ({ signal }: QueryFunctionContext) => getProcedures(encodedFilters, signal),
        enabled:
          enable &&
          casesOverviewDrawerOpen &&
          Boolean(userDefaultPreset) &&
          queryClient.getQueryData(['procedures', basicEncodedFilters]) === undefined,
        initialData: queryClient.getQueryData(['procedures', basicEncodedFilters]),
        onSuccess(data: ProcedureResponse) {
          queryClient.setQueryData(['procedures', basicEncodedFilters], data);
        },
      };
    }),
  });

  useEffect(() => {
    return () => {
      queryClient.cancelQueries(['procedures']);
    };
  }, []);

  const procedures = flatMap(proceduresQueries, (query) => query.data?.procedures);

  const finishLoadingBasicData = !isEmpty(proceduresQueries) && !some(proceduresQueries, 'isLoading');
  const orchestrationId = queryParams.filters?.orchestrationId;

  const { data: featureKeys, isLoading: isLoadingFeatures } = useQuery({
    queryKey: [
      'featureKeys',
      {
        studyId: currentStudy?.id,
        orchestrationId,
        includeInferredFeatures: false,
        resultsMode: queryParams.resultsMode,
      },
    ],
    queryFn: () =>
      getFeatureKeysByStudyAndOrchestration(currentStudy?.id, orchestrationId, false, false, queryParams.resultsMode),
    enabled: Boolean(currentStudy) && casesOverviewDrawerOpen,
  });

  const allFeatureKeys: string[] = featureKeys?.featuresKeys || [];

  useEffect(() => {
    if (finishLoadingBasicData) {
      setCohort((prevCohort) => ({
        ...prevCohort,
        procedures: procedures,
      }));
    }
  }, [finishLoadingBasicData]);

  const handleClose = () => {
    setCasesOverviewDrawerOpen(false);
  };

  const { savePresetMutation } = useSaveOverviewPresetMutations(currentStudy, {
    onSuccess: (newPreset: Partial<OverviewPreset>) => {
      setCurrentPreset(newPreset);
      setUserLocalCasesOverviewPresets({ ...userLocalCasesOverviewPresets, [currentStudy?.id]: newPreset.id });
    },
    onError: () => {
      setCurrentPreset(userDefaultPreset);
    },
  });

  const { handleFieldSave } = useStudyMutations(currentStudy, {});

  const { updatePresetMutation } = useUpdateOverviewPresetMutations(currentStudy);

  const { deletePresetMutation } = useDeleteOverviewPresetMutations(currentStudy, {
    onMutate: () => setCurrentPreset(defaultPreset),
  });

  const onSaveOverviewPreset = (name: string) => {
    const newPreset: Partial<OverviewPreset> = {
      id: uuidv4(),
      name: name,
      createdBy: userName,
      preset: existingChartsMap,
      labId: labId,
      studyId: currentStudy.id,
    };

    setCurrentPreset(newPreset);
    savePresetMutation.mutate(newPreset);
  };

  const onUpdateOverviewPreset = () => {
    updatePresetMutation.mutate({
      id: currentPreset?.id,
      preset: existingChartsMap,
    });
  };

  const onDeleteOverviewPreset = () => {
    deletePresetMutation.mutate(currentPreset?.id);
  };

  const onUpdateDefaultPreset = () => {
    handleFieldSave('defaultOverviewPresetId', currentPreset?.id);
  };

  const onSelectOverviewPreset = (preset: Partial<BasePreset>) => {
    const fullPreset: Partial<OverviewPreset> = getPresetById(preset.id);

    setExistingChartsMap(fullPreset.preset);
    setCurrentPreset(fullPreset);

    if (currentStudy?.id) {
      setUserLocalCasesOverviewPresets({ ...userLocalCasesOverviewPresets, [currentStudy.id]: preset.id });
    }
  };

  const { hasPermission, isLoading } = usePermissions();
  const permissionsForPreset: PresetPermissions = {
    canEditDefaultPreset: !isLoading && hasPermission(Permission.EditStudyDefaultOverviewPreset),
    canEditOthersPresets: !isLoading && hasPermission(Permission.EditOtherUsersOverviewPreset),
    canDeleteOthersPreset: !isLoading && hasPermission(Permission.DeleteOtherUsersOverviewPreset),
  };
  const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];

  const disablePresets = isPresetsLoading || !currentPreset || !finishLoadingBasicData;

  return (
    <Drawer
      anchor={'right'}
      open={casesOverviewDrawerOpen}
      onClose={handleClose}
      variant="persistent"
      sx={{
        width: overviewDrawerWidth,
        [`& .MuiDrawer-paper`]: {
          backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
          width: overviewDrawerWidth,
          zIndex: theme.zIndex.appBar - 1,
          height: `calc(100% - ${mainMarginTop}px)`,
          marginTop: `${mainMarginTop}px`,
        },
      }}
    >
      <Grid container direction={'column'} item xs={12}>
        <Grid item container alignItems="center" sx={{ borderBottom: '1px solid ' + borderColor }}>
          <Grid item container direction="row" p={1} alignItems="center" justifyContent="space-between">
            <Grid item container direction="row" xs={8} alignItems={'center'}>
              <BarChartIcon />
              <Typography variant="h3">Overview</Typography>
            </Grid>
            <Grid item container direction="row" xs={4} justifyContent="end">
              <Grid item textAlign="right">
                <Tooltip title={presetsSectionOpen ? 'Hide Overview Presets' : 'Overview Presets'} enterDelay={500}>
                  <span>
                    <IconButton
                      onClick={() => {
                        setPresetsSectionOpen((prevEditChannelColorPresetsMode) => !prevEditChannelColorPresetsMode);
                      }}
                      disabled={disablePresets}
                    >
                      {presetsSectionOpen ? <KeyboardArrowUpIcon /> : <SettingsIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item textAlign="right">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Collapse in={presetsSectionOpen}>
            <>
              <Grid item px={1} py={2}>
                {!disablePresets && (
                  <PresetSection
                    label="Overview Presets"
                    defaultPreset={defaultPreset}
                    permissions={permissionsForPreset}
                    selectedPreset={currentPreset}
                    presets={overviewPresets}
                    onSavePreset={onSaveOverviewPreset}
                    onUpdatePreset={onUpdateOverviewPreset}
                    onSelectPreset={onSelectOverviewPreset}
                    onDeletePreset={onDeleteOverviewPreset}
                    onUpdateDefaultPreset={onUpdateDefaultPreset}
                  />
                )}
              </Grid>
            </>
          </Collapse>
        </Grid>
        <Grid item xs={true} px={1} sx={{ overflow: 'auto', width: '100%' }}>
          {userDefaultPreset ? (
            <ChartsSection
              cohort={cohortWithSelectedFeatures}
              cohortAllFeatures={allFeatureKeys}
              isLoading={isLoadingFeatures || !finishLoadingBasicData}
              addChartActions={addChartActions}
              setCohort={setCohort}
              layout={'small'}
            />
          ) : (
            <Box height={'100%'} width={'100%'}>
              <Loader />
            </Box>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default CasesOverviewDrawer;

const addChartActions: { key: ChartType; icon: any; name: string }[] = [
  { key: ChartType.Histogram, icon: <BarChartIcon />, name: 'Histogram' },
  { key: ChartType.Pie, icon: <PieChartIcon />, name: 'Pie' },
];
