import React from 'react';
import { IconButton, TextField } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface Props {
  onChange(textSearch: string): void;
  closeSearchMode(): void;
}

const SearchBar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ onChange, closeSearchMode }) => {
  return (
    <TextField
      placeholder="Search"
      helperText="Filter overlays and channels"
      autoFocus
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value)}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => {
              onChange('');
              closeSearchMode();
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchBar;
