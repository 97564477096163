import { useQuery } from '@tanstack/react-query';
import { getExternalLabelOptions } from 'api/externalLabels';
import { ExternalLabel } from 'interfaces/externalLabel';
import { filter } from 'lodash';
import { useMemo } from 'react';

export const useSlideLabels = () => {
  const externalLabelsQueryResult = useQuery(['externalLabels'], () => getExternalLabelOptions());

  const externalLabelsMap: Record<string, ExternalLabel> = useMemo(() => {
    if (!externalLabelsQueryResult.data) {
      return {};
    }
    const externalLabelsMapBuilder: Record<string, ExternalLabel> = {};
    for (const label of externalLabelsQueryResult.data) {
      externalLabelsMapBuilder[label.id] = label;
    }
    return externalLabelsMapBuilder;
  }, [externalLabelsQueryResult.data]);

  const getExternalLabelDisplayName = (id: string) => {
    return externalLabelsMap[id]?.text || null;
  };

  // labelText is an external label id, or a free text
  const getLabelDisplayName = (labelText: string) => {
    return getExternalLabelDisplayName(labelText) || labelText;
  };

  return {
    ...externalLabelsQueryResult,
    externalLabelOptions: filter(externalLabelsQueryResult.data, (label) => label.deletedAt == null),
    getExternalLabelDisplayName,
    getLabelDisplayName,
  };
};
