import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Grid, IconButton, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  pageTitle: string;
  onBackClick?: () => void;
  isCohort?: boolean;
  renderCohortDetailsToggle?: () => React.ReactNode;
}

const PageHeader = ({ pageTitle, onBackClick, isCohort, renderCohortDetailsToggle }: Props) => {
  const theme = useTheme();
  return (
    <Grid container item>
      <Grid item display="flex" alignItems="center">
        <IconButton
          onClick={onBackClick}
          size="small"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            mr: 2,
            borderRadius: '50%',

            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.secondary.dark,
            },
          }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </Grid>
      <Grid item lg={10} xs={10} md={11}>
        <Grid container spacing={2}>
          <Grid item sx={{ maxWidth: isCohort ? '70%' : '100%' }}>
            <Tooltip title={pageTitle} arrow placement="top">
              {pageTitle ? (
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    whiteSpace: 'nowrap',
                  }}
                  color="text.primary"
                >
                  {pageTitle}
                </Typography>
              ) : (
                <Skeleton variant="rectangular" width={400} height={40} />
              )}
            </Tooltip>
          </Grid>
          {isCohort && (
            <Grid
              item
              xs={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <CollectionsBookmarkIcon fontSize="large" />
            </Grid>
          )}

          {renderCohortDetailsToggle && renderCohortDetailsToggle()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
