import { Grid, IconButton, TextField } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridApi,
  GridColDef,
  GridRowModes,
  GridRowModesModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { isEmpty, keys, last, reject, some, trim, values } from 'lodash';
import React from 'react';

import Clear from '@mui/icons-material/Clear';
import { useQuery } from '@tanstack/react-query';
import { getUserCredentialsPaginatedResponse } from 'api/userCredentials';
import { useCommonDataGridStyles } from 'components/atoms/BaseDataGrid/commonStyles';
import { autoGridRowHeight } from 'components/atoms/EditableDataGrid/helpers';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import { getCheckboxColumn } from 'components/atoms/RowSelectionContext/checkboxColumn';
import { UserCredentials } from 'interfaces/userCredentials';
import { UserCredentialsFieldsContext } from 'interfaces/userCredentials/UserCredentialsFieldsContext';
import { useDebounce } from 'use-debounce';
import { uuidv4 } from 'utils/helpers';
import { DEFAULT_SORT_FIELD, ROWS_PER_PAGE_OPTIONS } from './constants';
import { useUserCredentialsColumns } from './useUserCredentialsColumns';

const auth0FieldConversion: { [field: string]: string } = {
  emails: 'email',
};

const bulkEditMode = false;

export const UserCredentialsDataGrid: React.FC<React.PropsWithChildren<{ manageNucleaiUsers: boolean }>> = ({
  manageNucleaiUsers,
}) => {
  const { isRowSelected } = useRowSelectionContext();
  const { fieldsContext, getRowsWithChanges } = useTableEditingContext<UserCredentials, UserCredentialsFieldsContext>();
  const gridApiRef = React.useRef<GridApi>(null);
  const { labId, isLoadingRoles, isLoadingPermissions } = fieldsContext;
  const [ascending, setAscending] = React.useState(true);
  const [sortField, setSortField] = React.useState(DEFAULT_SORT_FIELD);
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(ROWS_PER_PAGE_OPTIONS[0]);
  const [localSearch, setLocalSearch] = React.useState('');

  const [debouncedLocalSearch] = useDebounce(localSearch, 500);

  const isSearchRelevant = trim(debouncedLocalSearch).length >= 3;

  const transformedSearchQuery = isSearchRelevant ? trim(debouncedLocalSearch) : undefined;

  const paginatedUserCredentialsQueryKey = [
    'paginatedUsers',
    labId,
    manageNucleaiUsers,
    { page, perPage, ascending, sortField, searchQuery: transformedSearchQuery },
  ];
  const { data: paginatedUsers, isLoading: isLoadingUserCredentials } = useQuery(paginatedUserCredentialsQueryKey, () =>
    getUserCredentialsPaginatedResponse(labId, {
      page,
      perPage,
      ascending,
      searchQuery: transformedSearchQuery,
      sortField: auth0FieldConversion[sortField] || sortField,
      listNucleaiUsers: Boolean(manageNucleaiUsers),
    })
  );

  const rowsWithChanges = getRowsWithChanges(paginatedUsers?.users || [], isRowSelected);

  const [totalRows, setTotalRows] = React.useState(0);

  React.useEffect(() => {
    const newTotal = paginatedUsers?.total ?? totalRows;
    if (paginatedUsers && totalRows !== newTotal) {
      setTotalRows(newTotal);
    }
  }, [paginatedUsers, totalRows]);

  const isLoading = isLoadingUserCredentials || isLoadingRoles || isLoadingPermissions;

  const commonDataGridStyles = useCommonDataGridStyles();

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const isEditingRow = keys(rowModesModel).length > 0 && some(values(rowModesModel), { mode: GridRowModes.Edit });
  const handleRowModesModelChange: DataGridProps<UserCredentials>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel) => {
      // Support data grid events to start editing a new row, but not while another row is being edited.
      // Suppress switching the row to view without pressing the action buttons.
      if (reject(values(newRowModesModel), { mode: GridRowModes.Edit }).length == 1) {
        setRowModesModel(newRowModesModel);
      }
    },
    [setRowModesModel]
  );

  const userCredentialsColumns = useUserCredentialsColumns({
    manageNucleaiUsers,
    rowsWithChanges,
    isEditingRow,
    noRows: isEmpty(rowsWithChanges),
    bulkEditMode,
    rowModesModel,
    setRowModesModel,
    shouldApplyBulkChangesToRow: isRowSelected,
    paginatedUserCredentialsQueryKey,
  });

  const columns: GridColDef<UserCredentials>[] = [
    getCheckboxColumn(totalRows, bulkEditMode),
    ...userCredentialsColumns,
  ];

  return (
    <Grid item container spacing={1} flexDirection="row" width="100%">
      <Grid item xs={4}>
        <TextField
          label="Search"
          value={localSearch}
          helperText={trim(localSearch).length < 3 ? 'Enter at least 3 characters' : ' '}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setLocalSearch('')} size="small">
                <Clear />
              </IconButton>
            ),
          }}
          onChange={(e) => setLocalSearch(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid<UserCredentials>
          apiRef={gridApiRef}
          loading={isLoading || !rowsWithChanges}
          editMode="row"
          pagination
          autoHeight
          sortModel={[{ field: sortField, sort: ascending ? 'asc' : 'desc' }]}
          sortingMode="server"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onSortModelChange={(models: GridSortModel) => {
            const model = last(models);
            setSortField(model?.field || DEFAULT_SORT_FIELD);
            setAscending(!model?.sort || model?.sort === 'asc');
          }}
          columns={columns}
          // Stop editing a row when the user presses escape
          onCellKeyDown={({ id, field }, event) => {
            if (event.code === 'Escape' && rowModesModel[id]?.mode === GridRowModes.Edit) {
              gridApiRef.current?.stopCellEditMode({ id, field });
              gridApiRef.current?.stopRowEditMode({ id, field, ignoreModifications: true });
            }
          }}
          rows={rowsWithChanges}
          getRowId={(row) => row?.id ?? uuidv4()}
          paginationModel={{ page, pageSize: perPage }}
          onPaginationModelChange={(newPaginationModel) => {
            setPage(newPaginationModel.page);
            setPerPage(newPaginationModel.pageSize);
          }}
          rowCount={totalRows}
          paginationMode="server"
          rowSelectionModel="none"
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
          onProcessRowUpdateError={console.error}
          hideFooterSelectedRowCount
          onCellClick={({ hasFocus }, event) => {
            if (!hasFocus && isEditingRow) {
              event.defaultMuiPrevented = true;
              event.preventDefault();
              event.stopPropagation();
            }
          }}
          sx={commonDataGridStyles}
          getRowHeight={autoGridRowHeight}
        />
      </Grid>
    </Grid>
  );
};
