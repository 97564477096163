import { Layer } from '@deck.gl/core/typed';
import { IconLayer } from '@deck.gl/layers/typed';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { useQuery } from '@tanstack/react-query';
import { FeatureCollection } from '@turf/helpers';
import { Dictionary, compact, map, max, times, uniqBy } from 'lodash';
import { DrawLineStringMode, DrawPointMode, DrawPolygonMode, EditableGeoJsonLayer } from 'nebula.gl';

import { getProcedureSlidePinComments, procedureSlidePinCommentsQueryKey } from 'api/procedureSlidePinComments';
import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import Pin from 'interfaces/pin';
import { useMemo } from 'react';
import { uuidv4 } from 'utils/helpers';

export const viewerDraftComments = times(MAX_VIEWERS, () => signal<Dictionary<Pin | null>>({}));

export interface PinCommentsLayerProps {
  slideId: string;
  procedureId: number;
  viewerIndex: number;
  mode?: 'addPoint' | 'addLine' | 'addPolygon' | 'edit';
}

function createSVGIcon(idx: number) {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <circle cx="33" cy="18" r="14.5" fill="#333" fill-rule="evenodd" stroke="#FFF" stroke-width="3" transform="translate(-17 -2)"/>
        <text x="16" y="22" fill="white" text-anchor="middle">${idx}</text>
    </svg>

  `;
}

function svgToDataURL(svg: string) {
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
}

export const useExistingCommentLayer = ({
  slideId,
  procedureId,
  viewerIndex,
  editable,
}: PinCommentsLayerProps & { editable?: boolean }): Layer => {
  useSignals();

  const { data: result } = useQuery(procedureSlidePinCommentsQueryKey(procedureId, slideId), {
    queryFn: (params) => getProcedureSlidePinComments({ procedureId, slideId, signal: params.signal }),
  });

  const currentSlideDraftComments = viewerDraftComments[viewerIndex]?.value?.[slideId];

  const pinsWithDraft = useMemo(
    () => uniqBy(compact([...map(result?.pins, (pin) => ({ ...pin, draft: false })), currentSlideDraftComments]), 'id'),
    [result?.pins, currentSlideDraftComments]
  );

  return new IconLayer<Pin>({
    id: 'comments-edit-layer',
    data: pinsWithDraft,
    pickable: true,
    getIcon: (d, { index }) => ({
      url: svgToDataURL(createSVGIcon(index + 1)),
      width: 32,
      height: 32,
    }),
    sizeScale: 10,
    getSize: 4,
    getPosition: (draft) => [draft.x, draft.y],
    onClick: editable
      ? ({ object }) => {
          if (!object) {
            return;
          }
          const comment = object as Pin;
          const currentViewerDraftComments = viewerDraftComments[viewerIndex];
          if (!currentViewerDraftComments) {
            return;
          }
          const currentDraftComments = currentViewerDraftComments.value;
          currentViewerDraftComments.value = { ...currentDraftComments, [slideId]: comment };
        }
      : undefined,
  });
};

export const usePinCommentsLayer = ({ slideId, procedureId, viewerIndex, mode = 'edit' }: PinCommentsLayerProps) => {
  useSignals();

  const { data: result, isLoading } = useQuery(procedureSlidePinCommentsQueryKey(procedureId, slideId), {
    queryFn: (params) => getProcedureSlidePinComments({ procedureId, slideId, signal: params.signal }),
  });

  const currentViewerDraftComments = viewerDraftComments[viewerIndex];
  const currentSlideDraftComments = currentViewerDraftComments?.value?.[slideId];

  const addCommentLayer: Layer | undefined =
    !currentSlideDraftComments && mode !== 'edit' && !isLoading
      ? // @ts-ignore
        (new EditableGeoJsonLayer({
          id: 'comments-add-layer',
          data: { type: 'FeatureCollection', features: [] },
          mode: mode === 'addLine' ? DrawLineStringMode : mode === 'addPolygon' ? DrawPolygonMode : DrawPointMode,
          onEdit: ({ updatedData }: { updatedData: FeatureCollection; editType: string; editContext: any }) => {
            const dataGeometry = updatedData?.features?.[0]?.geometry;
            if (!dataGeometry || dataGeometry.type !== 'Point') {
              return;
            }
            const coordinates = dataGeometry.coordinates as [number, number];
            if (!currentViewerDraftComments) {
              return;
            }
            const currentDraftComments = currentViewerDraftComments.value;
            currentViewerDraftComments.value = {
              ...currentDraftComments,
              [slideId]: {
                x: coordinates[0],
                y: coordinates[1],
                comment: '',
                draft: true,
                id: uuidv4(),
                pinIndex: max(map(result?.pins, 'pinIndex')) + 1,
              },
            };
          },
        }) as Layer)
      : undefined;

  const editCommentLayer = useExistingCommentLayer({ slideId, procedureId, viewerIndex, editable: mode === 'edit' });

  return { addCommentLayer, editCommentLayer };
};
