import { Divider, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FormResponseWithAuthor } from 'interfaces/reviewForm';
import React, { FunctionComponent } from 'react';

interface ResponseSummaryProps {
  response: FormResponseWithAuthor;
}

const ResponseSummary: FunctionComponent<ResponseSummaryProps> = ({ response }) => {
  const reviewerName = response?.author?.name ?? 'no author';

  return (
    <>
      <Divider />
      <Grid container>
        <Grid item>Reviewer: {reviewerName}</Grid>
      </Grid>
      {response?.submissionDate && (
        <Grid item>
          <Typography variant="body2">Last submitted at {dayjs(response.submissionDate).format('lll')}</Typography>
        </Grid>
      )}
    </>
  );
};

export default ResponseSummary;
