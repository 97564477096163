import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EditIcon from '@mui/icons-material/Edit';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';
import ImageAspectRatioTwoToneIcon from '@mui/icons-material/ImageAspectRatioTwoTone';
import PolylineIcon from '@mui/icons-material/Polyline';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import SquareIcon from '@mui/icons-material/Square';
import StraightenIcon from '@mui/icons-material/Straighten';
import { Box, Divider, Fade, Grid, IconProps, Popper, SvgIconTypeMap, ToggleButton } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { flatMap, forEach, fromPairs, keys, map, some, times } from 'lodash';
import React, { useCallback } from 'react';
import { BooleanParam, NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';

import { viewerAnnotationData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { secondaryAnalysisPolygons } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisLayer';
import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';
import { SlideInteractionOption } from './SlideInteractionOption';

interface SlideInteractionMenuOptions {
  editType: string;
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  color?: IconProps['color'];
  sx?: IconProps['sx'];
}

const slideAnnotationOptions: SlideInteractionMenuOptions[] = [
  {
    editType: 'delete',
    title: 'Delete Individual Annotations',
    icon: DeleteOutlineIcon,
  },
  {
    editType: 'polygon',
    title: 'Polygon',
    icon: PolylineIcon,
  },
  {
    editType: 'roi',
    title: 'ROI Selection',
    icon: SquareIcon,
  },
  {
    editType: 'point',
    title: 'Point',
    icon: PushPinIcon,
  },
];

const secondaryAnalysisOptions: SlideInteractionMenuOptions[] = [
  {
    editType: 'delete-polygon',
    title: 'Delete Individual Polygon / ROI',
    icon: DeleteOutlineIcon,
  },
  {
    editType: 'include-roi',
    title: 'Include ROI',
    icon: ImageAspectRatioIcon,
  },
  {
    editType: 'exclude-roi',
    title: 'Exclude ROI',
    icon: ImageAspectRatioTwoToneIcon,
    sx: { transform: 'scale(-100%, 100%)' },
  },
  {
    editType: 'include-polygon',
    title: 'Include Polygon',
    icon: PolylineIcon,
  },
  {
    editType: 'exclude-polygon',
    title: 'Exclude Polygon',
    icon: PolylineOutlinedIcon,
  },
];

export const SlideInteractionMenu: React.FunctionComponent = () => {
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);
  const [measureToolActive, setMeasureToolActive] = useQueryParam('measureToolActive', BooleanParam);
  const [commentMode, setCommentMode] = useQueryParam('commentMode', StringParam);
  // Check if any of the viewers is currently annotating
  const annotationsQuerySchema = fromPairs(
    times(MAX_VIEWERS, (viewerIndex) => [`activeAnnotationAssignmentIdViewer${viewerIndex}`, NumberParam])
  );
  const [annotationQueryParams, setAnnotationQueryParams] = useQueryParams(annotationsQuerySchema);
  const annotationQueryKeys = keys(annotationQueryParams);
  const annotationsActive = some(annotationQueryKeys, (key) => !isNaN(annotationQueryParams[key]));
  const [editAnnotationsMode, setEditAnnotationMode] = useQueryParam('editAnnotationsMode', StringParam);

  // Check if any of the viewers is currently performing a secondary analysis
  const secondaryAnalysisQuerySchema = fromPairs(
    times(MAX_VIEWERS, (viewerIndex) => [`secondaryAnalysisOrchestrationId${viewerIndex}`, StringParam])
  );
  const [secondaryAnalysisQueryParams, setSecondaryAnalysisQueryParams] = useQueryParams(secondaryAnalysisQuerySchema);
  const secondaryAnalysisQueryKeys = keys(secondaryAnalysisQueryParams);
  const secondaryAnalysisActive = some(secondaryAnalysisQueryKeys, (key) => Boolean(secondaryAnalysisQueryParams[key]));
  const [secondaryAnalysisAreaSelectionMode, setSecondaryAnalysisAreaSelectionMode] = useQueryParam(
    'secondaryAnalysisAreaSelectionMode',
    StringParam
  );

  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  const clearSlideInteraction = useCallback(() => {
    setMeasureToolActive(false);
    setCommentMode(null);
    setAnnotationQueryParams(fromPairs(map(annotationQueryKeys, (key) => [key, null])));
    forEach(viewerAnnotationData, (annotationDraftSignal) => {
      annotationDraftSignal.value = null;
    });
    setSecondaryAnalysisQueryParams(fromPairs(map(secondaryAnalysisQueryKeys, (key) => [key, null])));
    forEach(secondaryAnalysisPolygons, (secondaryAnalysisSignal) => {
      secondaryAnalysisSignal.value = null;
    });
    setEditAnnotationMode(null);
    setSecondaryAnalysisAreaSelectionMode(null);
  }, [setMeasureToolActive, setCommentMode]);

  const clearNonAnnotationInteractions = useCallback(() => {
    setMeasureToolActive(false);
    setCommentMode(null);
  }, [setMeasureToolActive, setCommentMode]);

  const areDrawingModesActive = annotationsActive || secondaryAnalysisActive;
  const [isDrawingToolSelectionOpenState, setIsDrawingToolSelectionOpen] = React.useState(areDrawingModesActive);
  const isDrawingToolSelectionOpen = areDrawingModesActive && Boolean(anchorEl) && isDrawingToolSelectionOpenState;

  React.useEffect(() => {
    // Open/Close the drawing tool selection if the drawing modes are active/inactive
    setIsDrawingToolSelectionOpen(areDrawingModesActive);
  }, [areDrawingModesActive]);

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const hasPermissionsToDrawingTools = canAnnotateSlides || canRunSecondaryAnalysis;

  const slideInteractionOptions = secondaryAnalysisActive ? secondaryAnalysisOptions : slideAnnotationOptions;
  const activeDrawingMode = secondaryAnalysisActive ? secondaryAnalysisAreaSelectionMode : editAnnotationsMode;
  const setActiveDrawingMode = secondaryAnalysisActive ? setSecondaryAnalysisAreaSelectionMode : setEditAnnotationMode;

  const confirmWithModal = useConfirmation();

  return (
    useDeckGL && (
      <>
        {hasPermissionsToDrawingTools && areDrawingModesActive && (
          <>
            <ToggleButton
              ref={setAnchorEl}
              value="slideActions"
              onChange={() => setIsDrawingToolSelectionOpen(!isDrawingToolSelectionOpen)}
              title={
                isDrawingToolSelectionOpen
                  ? secondaryAnalysisActive
                    ? 'Open selections for secondary analysis'
                    : 'Open slide annotation tools'
                  : 'Hide'
              }
              disabled={Boolean(commentMode)}
            >
              {isDrawingToolSelectionOpen ? <ArrowForwardIosIcon /> : <EditIcon />}
            </ToggleButton>
            <Popper anchorEl={anchorEl} open={isDrawingToolSelectionOpen} placement="left" transition disablePortal>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Box sx={{ bgcolor: 'background.paper' }}>
                    <Grid item container direction="row" p={0} m={0}>
                      <SlideInteractionOption
                        title={
                          secondaryAnalysisActive
                            ? 'Clear secondary analysis selections'
                            : 'Clear current slide annotations'
                        }
                        onClick={async () => {
                          if (
                            await confirmWithModal({
                              title: secondaryAnalysisActive
                                ? 'Clear secondary analysis selections'
                                : 'Clear current slide annotations changes',
                              text: secondaryAnalysisActive
                                ? 'Exit secondary analysis mode?'
                                : "Unsaved changes will be lost. Are you sure you want to clear the current slide's annotations?",
                            })
                          ) {
                            clearSlideInteraction();
                          }
                        }}
                        value="clearSlideInteraction"
                        icon={<DeleteSweepIcon />}
                      />
                      <Divider orientation="vertical" />
                      {flatMap(slideInteractionOptions, ({ editType, title, icon: Icon, color, sx }) => (
                        <SlideInteractionOption
                          key={editType}
                          value={editType}
                          title={title}
                          selected={activeDrawingMode === editType}
                          onClick={() => {
                            clearNonAnnotationInteractions();
                            if (editType === activeDrawingMode) {
                              setActiveDrawingMode(null);
                            } else {
                              setActiveDrawingMode(editType);
                            }
                          }}
                          icon={
                            <Icon
                              color={color ? color : activeDrawingMode === editType ? 'primary' : 'inherit'}
                              sx={sx}
                            />
                          }
                        />
                      ))}
                    </Grid>
                  </Box>
                </Fade>
              )}
            </Popper>
          </>
        )}
        <ToggleButton
          value="measureTool"
          selected={measureToolActive}
          onClick={() => {
            clearNonAnnotationInteractions();
            setMeasureToolActive(!measureToolActive);
          }}
          title="Measure tool"
        >
          <StraightenIcon color={measureToolActive ? 'primary' : 'inherit'} />
        </ToggleButton>
        <ToggleButton
          value="commentMode"
          selected={Boolean(commentMode)}
          onClick={() => {
            clearNonAnnotationInteractions();
            setCommentMode(!commentMode ? 'addPoint' : null);
          }}
          title="Add comments to slide"
        >
          <CommentIcon color={commentMode ? 'primary' : 'inherit'} />
        </ToggleButton>
      </>
    )
  );
};
