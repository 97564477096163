import React from 'react';

import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { OrthographicMapViewState } from '../OrthographicMapview';
import ColorMapLegendOverlay from './ColorMapLegendOverlay';
import { DeckGLScaleIndicator } from './DeckGLScaleIndicator';
import LayerLoadingOverlay from './LayerLoadingOverlay';
import MousePositionOverlay from './MousePositionOverlay';
import SlideIdOverlay from './SlideIdOverlay';

interface SlideViewerOverlaysProps {
  displaySlideId: boolean;
  slide: SlideWithChannelAndResults;
  viewState: OrthographicMapViewState;
  viewSize: { width: number; height: number };
  numLoadingBaseLayers: number;
  numBaseLayers: number;
  numLoadingHeatmapLayers: number;
  numHeatmapLayers: number;
}

export const SlideViewerOverlays: React.FC<SlideViewerOverlaysProps> = ({
  displaySlideId,
  slide,
  viewSize,
  viewState,
  numLoadingBaseLayers,
  numBaseLayers,
  numLoadingHeatmapLayers,
  numHeatmapLayers,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 100,
        bottom: '20px',
        right: '16px',
        left: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        height: '30px',
      }}
    >
      {displaySlideId && <SlideIdOverlay slideId={slide.id} />}
      <ColorMapLegendOverlay slide={slide} />
      <div style={{ display: 'flex' }}>
        <LayerLoadingOverlay
          numLoadingBaseLayers={numLoadingBaseLayers}
          numBaseLayers={numBaseLayers}
          numLoadingHeatmapLayers={numLoadingHeatmapLayers}
          numHeatmapLayers={numHeatmapLayers}
        />

        <MousePositionOverlay viewerIndex={slide.viewerIndex} />

        {Boolean(!isNaN(slide.maxResolution) && !isNaN(slide.sizeCols) && viewState && viewSize) && (
          <DeckGLScaleIndicator viewState={viewState} maxResolution={slide.maxResolution} />
        )}
      </div>
    </div>
  );
};
