import WarningIcon from '@mui/icons-material/Warning';
import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import { TaxonomyTree } from 'interfaces/taxonomy';
import { slice } from 'lodash';
import React from 'react';
import AddTaxonomyButton from './TaxonomyActions/AddTaxonomyButton';
import CopyTaxonomyPathButton from './TaxonomyActions/CopyTaxonomyPathButton';
import DeleteTaxonomyButton from './TaxonomyActions/DeleteTaxonomyButton';
import EditTaxonomyButton from './TaxonomyActions/EditTaxonomyButton';

interface TaxonomyTreeViewItemLabelProps {
  taxonomyTree: TaxonomyTree;
  searchValue?: string;
}

const getNameWithHighlight = (name: string, searchValue: string) => {
  const index = name?.toLowerCase()?.indexOf(searchValue?.toLowerCase());
  if (index === -1) return { beforeMatch: name, match: '', afterMatch: '' };

  const beforeMatch = slice(name, 0, index);
  const match = slice(name, index, index + searchValue?.length);
  const afterMatch = slice(name, index + searchValue?.length);

  return { beforeMatch, match, afterMatch };
};

const TaxonomyTreeViewItemLabel: React.FC<React.PropsWithChildren<TaxonomyTreeViewItemLabelProps>> = ({
  taxonomyTree,
  searchValue,
}) => {
  const { beforeMatch, match, afterMatch } = getNameWithHighlight(taxonomyTree.name, searchValue);

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item container width="unset">
        <Grid item>
          <Typography display="inline">{beforeMatch}</Typography>
          <Typography display="inline" fontWeight={700}>
            {match}
          </Typography>
          <Typography display="inline">{afterMatch}</Typography>
        </Grid>
        {!taxonomyTree.exists && (
          <Grid item>
            <Tooltip title="Taxonomy is not defined but has other taxonomies referencing it as a parent, define it by creating a new taxonomy with this path">
              <WarningIcon color="error" sx={{ marginLeft: 1 }} />
            </Tooltip>
          </Grid>
        )}
        {taxonomyTree.isAbstract && (
          <Grid item>
            <Tooltip title="This taxonomy is abstract, it cannot be directly used in the system">
              <Chip label="Abstract" color="primary" sx={{ marginLeft: 1 }} variant="outlined" size="small" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {taxonomyTree.exists && (
          <>
            <EditTaxonomyButton taxonomyTree={taxonomyTree} />
            <AddTaxonomyButton taxonomyTree={taxonomyTree} />
            <DeleteTaxonomyButton path={taxonomyTree.path} />
            <CopyTaxonomyPathButton taxonomyPath={taxonomyTree.path} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default TaxonomyTreeViewItemLabel;
