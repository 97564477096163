import { deepClone } from '@mui/x-data-grid/utils/utils';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { clone, compact, filter, first, includes, isEmpty, map } from 'lodash';
import { enqueueSnackbar } from 'notistack';

import { bulkUpdateResultsByExperimentResultIds, bulkUpdateResultsByOrchestration } from 'api/experimentResults';
import { getStudyProcedureQueryKey } from 'api/study';
import { ExperimentResultUpdate, ExperimentResultsResultType } from 'interfaces/experimentResults';
import { Procedure } from 'interfaces/procedure';
import queryClient from 'utils/queryClient';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';

const replaceUpdatedResults = ({
  orchestrationId,
  experimentResultIds,
  resultTypes: explicitResultTypes,
  flowClassNames: explicitFlowClassNames,
  slideId,
  resultUpdates,
  procedure,
}: {
  orchestrationId?: string;
  experimentResultIds?: number[];
  resultTypes?: string[];
  flowClassNames?: string[];
  slideId: string;
  resultUpdates: Partial<ExperimentResultUpdate>;
  procedure: Procedure;
}) => {
  const updatedProcedure: Procedure = deepClone(procedure);

  updatedProcedure.slides = map(updatedProcedure.slides, (slide) => {
    // We may have to change other results matching the types of the updated results' orchestration, so we'll get them here
    const resultsWithUpdates = filter(compact(slide.experimentResults), (result) =>
      experimentResultIds
        ? includes(experimentResultIds, result.experimentResultId)
        : orchestrationId && result.orchestrationId === orchestrationId
    );

    const resultWithUpdatesIds = map(resultsWithUpdates, 'experimentResultId');

    // If we provided explicit filters, we only want to update the results that match the filters
    // Otherwise, we may want to update all results that have the same same type as the selected results
    const resultTypes = explicitFlowClassNames
      ? explicitResultTypes || []
      : explicitResultTypes || map(resultsWithUpdates, 'resultType');
    const flowClassNames = explicitResultTypes
      ? explicitFlowClassNames || []
      : explicitFlowClassNames || map(resultsWithUpdates, 'flowClassName');

    const newResults =
      slide.id != slideId
        ? slide.experimentResults
        : map(slide.experimentResults, (oldResult) => {
            if (!includes(resultTypes, oldResult.resultType) && !includes(flowClassNames, oldResult.flowClassName)) {
              // If the result is not of the correct type, we won't be affecting it
              return oldResult;
            }

            const result = clone(oldResult);
            // If we are approving, we want to unapproved all previous approved results
            if (
              // If we approved a new result
              resultUpdates.approved &&
              // If the result is already approved
              result.approved
            ) {
              result.approved = false;
            }
            if (
              // If we internally approving a new result
              resultUpdates.internallyApproved &&
              // If the result is already internally approved
              result.internallyApproved &&
              // If the update is not just for normalization results
              !isEmpty(resultTypes) &&
              includes(resultTypes, ExperimentResultsResultType.Normalization)
            ) {
              result.internallyApproved = false;
            }

            if (includes(resultWithUpdatesIds, result.experimentResultId)) {
              return { ...result, ...resultUpdates };
            }

            return result;
          });

    return { ...slide, experimentResults: newResults };
  });

  return {
    procedure: updatedProcedure,
  };
};

// Case ID is currently required for the API call
const useResultsMutation = (caseId: number, studyId: string, onSuccess?: () => void) => {
  const { labId } = useCurrentLabId();

  const { queryParams } = useEncodedFilters();
  const procedureQueryKey = getStudyProcedureQueryKey(queryParams?.filters?.studyId, caseId, queryParams);

  const mutationOptions: Omit<UseMutationOptions<unknown, any, any, any>, 'mutationFn'> = {
    onError: (err, _, context: any) => {
      enqueueSnackbar('Failed to update results', { variant: 'error' });
      queryClient.setQueryData(procedureQueryKey, context.previousValue);
    },
    onSuccess: () => {
      queryClient.resetQueries(['procedures']);

      enqueueSnackbar('Successfully updated results', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['procedure', caseId], exact: false });
      queryClient.invalidateQueries(['orchestrations']);
      onSuccess?.();
    },
  };

  const resultsByOrchestrationMutation = useMutation(bulkUpdateResultsByOrchestration, {
    onMutate: async ({ orchestrationId, slideIds, updatedData: resultUpdates, flowClassNames, resultTypes }) => {
      await queryClient.cancelQueries({ queryKey: ['procedure', caseId], exact: false });
      const previousValue = queryClient.getQueryData(procedureQueryKey);

      if (previousValue) {
        queryClient.setQueryData(procedureQueryKey, ({ procedure }: { procedure: Procedure }) =>
          replaceUpdatedResults({
            orchestrationId,
            slideId: first(slideIds),
            resultUpdates,
            procedure,
            flowClassNames,
            resultTypes,
          })
        );
      }
      return { previousValue };
    },
    ...mutationOptions,
  });

  const resultsByExperimentResultIdsMutation = useMutation(bulkUpdateResultsByExperimentResultIds, {
    onMutate: async ({ experimentResultIds, slideIds, updatedData: resultUpdates }) => {
      await queryClient.cancelQueries({ queryKey: ['procedure', caseId], exact: false });
      const previousValue = queryClient.getQueryData(procedureQueryKey);

      if (previousValue) {
        queryClient.setQueryData(procedureQueryKey, ({ procedure }: { procedure: Procedure }) =>
          replaceUpdatedResults({
            experimentResultIds,
            slideId: first(slideIds),
            resultUpdates,
            procedure,
          })
        );
      }
      return { previousValue };
    },
    ...mutationOptions,
  });

  function handleFieldSave<T extends keyof ExperimentResultUpdate>({
    orchestrationId,
    experimentResultIds,
    slideId,
    fieldName,
    value,
    flowClassNames,
    resultTypes,
  }: {
    orchestrationId?: string;
    experimentResultIds?: number[];
    slideId: string;
    fieldName: T;
    value: ExperimentResultUpdate[T];
    flowClassNames?: string[];
    resultTypes?: string[];
  }) {
    // Case ID is currently required for the API call
    if (isNaN(Number(caseId))) {
      console.error('A valid case ID is required, got', { caseId });
      return;
    }
    if (!studyId) {
      console.error('Study ID is required');
      return;
    }
    if (!slideId) {
      console.error('Slide ID is required');
      return;
    }
    if (!orchestrationId && !experimentResultIds) {
      console.error('Orchestration ID or Experiment Result IDs are required');
      return;
    } else if (orchestrationId && experimentResultIds) {
      console.error('Only one of Orchestration ID or Experiment Result IDs is required');
      return;
    }

    const updatedData: ExperimentResultUpdate = { [fieldName]: value };
    if (orchestrationId) {
      resultsByOrchestrationMutation.mutate({
        slideIds: [slideId],
        studyId,
        orchestrationId,
        updatedData,
        flowClassNames,
        resultTypes,
        labId,
      });
    } else {
      resultsByExperimentResultIdsMutation.mutate({
        slideIds: [slideId],
        studyId,
        experimentResultIds,
        updatedData,
        labId,
      });
    }
  }

  return { handleFieldSave };
};

export default useResultsMutation;
