import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ReopenIcon from '@mui/icons-material/RestartAlt';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, Tooltip } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { cloneDeep, filter, get, includes, map, slice } from 'lodash';
import React from 'react';

import { OBJECT_HANDLER_TYPES } from 'services/annotations/markerAnnotationService';
import { Feature, FeatureCollection } from '../../NebulaGLExtensions/geojson-types';
import { viewerAnnotationData } from '../useActiveAnnotationDraft';

export interface StickyItem {
  text: string;
  icon: React.ReactNode;
  menuAction: React.MouseEventHandler<HTMLButtonElement>;
  color?: any; // TODO: PropTypes.Color;
}

export const StickyMenuItems: React.FC<{
  viewerIndex: number;
  annotationDraft: FeatureCollection;
  featureIndex: number;
  isSelectFeaturesOn?: boolean;
  // TODO: is this necessary?
  isOnlyNonCanvasEditingEnabled?: boolean;
}> = ({ annotationDraft, viewerIndex, featureIndex, isSelectFeaturesOn, isOnlyNonCanvasEditingEnabled }) => {
  useSignals();
  const onHideClicked = console.debug;
  const onEditClicked = console.debug;
  const reopenFeature = console.debug;
  const feature = get(annotationDraft, `features[${featureIndex}]`) as Feature;
  const onRemoveClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!annotationDraft?.features?.[featureIndex]) {
      return;
    }
    const currentDraft = cloneDeep(annotationDraft);
    currentDraft.features = [
      ...slice(currentDraft.features, 0, featureIndex),
      ...slice(currentDraft.features, featureIndex + 1),
    ];
    if (viewerAnnotationData[viewerIndex]) {
      viewerAnnotationData[viewerIndex].value = currentDraft;
    }
  };
  const onRemoveSelectedFeatures = console.debug;
  const stickyMenuItems: StickyItem[] = isSelectFeaturesOn
    ? [
        {
          menuAction: onRemoveSelectedFeatures,
          icon: <DeleteForeverIcon />,
          text: 'Delete',
          color: 'secondary',
        },
      ]
    : [
        { menuAction: onHideClicked, icon: <VisibilityOffIcon />, text: 'Hide' },
        { menuAction: onEditClicked, icon: <EditIcon />, text: 'Edit' },
        { menuAction: reopenFeature, icon: <ReopenIcon />, text: 'Reopen' },
        { menuAction: onRemoveClicked, icon: <DeleteForeverIcon />, text: 'Delete', color: 'secondary' },
      ];

  const stickyMenu =
    feature?.properties?.shapeSubType === OBJECT_HANDLER_TYPES.POLYGON && !isOnlyNonCanvasEditingEnabled
      ? stickyMenuItems
      : filter(stickyMenuItems, (item) => includes(['Hide', 'Delete'], item.text));

  return (
    stickyMenu && (
      <Box>
        {map(
          stickyMenu,
          ({ icon, text, color, menuAction }) =>
            menuAction && (
              <Tooltip title={text} key={`${text}-${color}`}>
                <Button color={color} onClick={menuAction} role="button" tabIndex={0}>
                  {icon}
                </Button>
              </Tooltip>
            )
        )}
      </Box>
    )
  );
};
