import { some } from 'lodash';
import { ModifyMode } from 'nebula.gl';

import { Feature } from '@turf/helpers';
import { FeatureOf, LineString, Point } from './geojson-types';
import { NearestPointType, nearestOrthographicPointOnLine, nearestPointOnOrthographicProjectedLine } from './helpers';

export class ModifyOrthographicMode extends ModifyMode {
  // Add support to only edit a subset of features
  getGuides(props: Parameters<ModifyMode['getGuides']>[0]) {
    if (props?.modeConfig?.selectedIndexes) {
      props.selectedIndexes = props.modeConfig.selectedIndexes;
    }
    return super.getGuides(props);
  }

  // turf.js does not support elevation for nearestPointOnLine and works in a geographic coordinate system
  getNearestPoint(line: FeatureOf<LineString>, inPoint: FeatureOf<Point>): NearestPointType {
    const { coordinates } = line.geometry;
    if (some(coordinates, (coord) => coord.length > 2)) {
      // Seems to assume `this` is `EditableGeoJsonLayer`
      const modeConfig = (this as any).getModeConfig();
      if (modeConfig && modeConfig.viewport) {
        // This line has elevation, we need to use alternative algorithm
        return nearestPointOnOrthographicProjectedLine(line, inPoint, modeConfig.viewport);
      }
      console.debug('Editing 3D point but modeConfig.viewport not provided. Falling back to 2D logic.');
    }
    return nearestOrthographicPointOnLine(line as Feature<LineString>, inPoint as Feature<Point>);
  }

  _dragEditHandle(
    editType: Parameters<ModifyMode['_dragEditHandle']>[0],
    props: Parameters<ModifyMode['_dragEditHandle']>[1],
    editHandle: Parameters<ModifyMode['_dragEditHandle']>[2],
    event: Parameters<ModifyMode['_dragEditHandle']>[3]
  ) {
    if (isNaN(editHandle?.properties?.featureIndex)) {
      console.warn('Invalid featureIndex', editHandle);
      return;
    }

    const featureIndex = editHandle.properties.featureIndex;
    if (!props?.data?.features?.[featureIndex]) {
      console.warn('Feature not found', { features: props?.data?.features, featureIndex });
      return;
    }

    return super._dragEditHandle(editType, props, editHandle, event);
  }
}
