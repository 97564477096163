import { GridRowId, GridRowModesModel, GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { find, omit, reject, some } from 'lodash';
import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createRole, deleteRole, updateRole } from 'api/roles';
import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { rowEditingControlsColumn } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { Role } from 'interfaces/roles';
import { rolesFields } from 'interfaces/roles/rolesFields';
import { RolesFieldsContext } from 'interfaces/roles/rolesFieldsContext';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { RoleRowChangesSummary } from './RoleRowChangesSummary';

const getRoleError = ({
  id,
  apiRef,
}: GridRowParams<Role> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateRole = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['name', 'labSpecific'],
  });

  const missingRequiredFields = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['name'],
  });

  return hasDuplicateRole ? 'Duplicate role' : missingRequiredFields ? 'Missing required fields' : '';
};

export const useRolesColumns = ({
  apiRef,
  noRows,
  rowsWithChanges,
  draftRoles,
  rowModesModel,
  setDraftRoles,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowsWithChanges: Role[];
  draftRoles: Role[];
  rowModesModel: GridRowModesModel;
  setDraftRoles: React.Dispatch<React.SetStateAction<Role[]>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const { clearRowChanges, rowsChanges, fieldsContext } = useTableEditingContext<Role, RolesFieldsContext>();
  const confirmWithModal = useConfirmation();

  const { labId } = useCurrentLabId();

  const queryClient = useQueryClient();

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(['roles', labId]);
    },
  };

  const createRoleMutation = useMutation(createRole, mutationOptions);
  const updateRoleMutation = useMutation(updateRole, mutationOptions);
  const deleteRoleMutation = useMutation(deleteRole, mutationOptions);

  const deleteOperation = (id: GridRowId) => async () => {
    if (
      await confirmWithModal({
        title: 'Delete Role',
        text: 'Are you sure you want to delete this role?',
      })
    ) {
      if (some(draftRoles, { id })) {
        setDraftRoles(reject(draftRoles, { id: id as string }));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [id]: draftRoleRowMode, ...rest } = rowModesModel;
        setRowModesModel(rest);
      } else {
        deleteRoleMutation.mutate({ id: `${id}`, labId });
      }
      clearRowChanges(id);
      return true;
    } else {
      return false;
    }
  };

  const isDraftRow = (id: GridRowId) => some(draftRoles, { id });

  const confirmCancelEdit = async (id: GridRowId) => {
    const isDraft = isDraftRow(id);
    const role = find(rowsWithChanges, { id: id as string });
    return (
      !rowsChanges[id] ||
      (await confirmWithModal({
        title: `Cancel role ${isDraft ? 'creation' : 'update'}`,
        text: (
          <RoleRowChangesSummary
            fieldsContext={fieldsContext}
            changes={rowsChanges[id]}
            roleDisplayString={`Role "${role?.name}" ${role?.labSpecific ? ' (lab-specific)' : ''}`}
          />
        ),
      }))
    );
  };

  const saveOperation = async (id: GridRowId) => {
    const isDraft = isDraftRow(id);
    const role = find(rowsWithChanges, { id: id as string });
    if (
      await confirmWithModal({
        title: `${isDraft ? 'Create' : 'Update'} Role`,
        text: (
          <RoleRowChangesSummary
            fieldsContext={fieldsContext}
            changes={rowsChanges[id]}
            roleDisplayString={`Role "${role?.name}" ${role?.labSpecific ? ' (lab-specific)' : ''}`}
          />
        ),
      })
    ) {
      setMutatingRowId(id);
      if (isDraft) {
        await createRoleMutation.mutateAsync({ role: { ...omit(role, 'id') }, labId });
      } else {
        await updateRoleMutation.mutateAsync({ role: { ...role }, labId });
      }
      setMutatingRowId(undefined);
      clearRowChanges(id);
      return true;
    } else {
      return false;
    }
  };

  const rolesFieldsColumns = useEditableFieldsDataGridColumns({
    fields: rolesFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode: false,
    useValueSetter: true,
  });

  return [
    ...rolesFieldsColumns,
    rowEditingControlsColumn({
      mutatingRowId,
      rowModesModel,
      setRowModesModel,
      apiRef,
      getRowError: getRoleError,
      deleteOperation,
      isDraftRow,
      onCancelEdit: (id: GridRowId) => {
        const isDraft = isDraftRow(id);
        if (isDraft) {
          setDraftRoles(reject(draftRoles, { id: id as string }));
        }
        clearRowChanges(id);
      },
      saveOperation,
      confirmCancelEdit,
    }),
  ];
};
