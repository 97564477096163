import { ClinicalData } from 'interfaces/clinical';
import { CaseSlideThumbnailData, Procedure, ProcedureResponse } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';
import { downloadBlob } from 'utils/exports';
import { SlideUpdate } from './slides';
import { CaseUpdate } from './study';

export const getProcedures = (queryParams: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<ProcedureResponse>({
    url: `procedures?${queryParams}`,
    method: 'GET',
    signal,
  });
};

export const approveProcedure = (caseId: number) => {
  return apiRequestHandlerPromise({
    url: `procedures/${caseId}/approve`,
    method: 'PATCH',
  });
};

export const unapproveProcedure = (caseId: number) => {
  return apiRequestHandlerPromise({
    url: `procedures/${caseId}/unapprove`,
    method: 'PATCH',
  });
};

export const getProcedureIds = (queryParams: string, caseId?: number, signal?: AbortSignal) => {
  const hasCaseId = Boolean(caseId) && !isNaN(caseId);
  return apiRequestHandlerPromise<number[]>({
    url: `procedures/ids?${queryParams}`,
    method: 'GET',
    data: hasCaseId ? stringify({ procedure_id: caseId }) : undefined,
    signal,
  });
};

export const getCaseSlideThumbnailsData = (queryParams: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<CaseSlideThumbnailData[]>({
    url: `procedures/slide_thumbnails?${queryParams}`,
    method: 'GET',
    signal,
  });
};

export const bulkUpdateProcedures = (
  data: {
    procedureUpdates: Partial<CaseUpdate>;
    features?: any;
    filters?: any;
    clinicalData?: ClinicalData;
    labId?: string;
  } & (
    | {
        caseIdsToInclude?: number[];
      }
    | {
        caseIdsToExclude?: number[];
      }
  )
) => {
  return apiRequestHandlerPromise<Procedure[]>({
    url: `procedures/bulk_update`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const bulkUpdateProcedureSlides = (data: {
  slideUpdates: Partial<SlideUpdate>;
  features?: any;
  filters?: any;
  clinicalData?: ClinicalData;
  labId?: string;
  slideIdsToInclude?: string[];
  slideIdsToExclude?: string[];
}) => {
  return apiRequestHandlerPromise<Slide[]>({
    url: `procedures/slides/bulk_update`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const downloadCaseManifestTemplateFile = async () => {
  const blob = await apiRequestHandlerPromise({
    method: 'GET',
    url: 'case_manifest_template',
    responseType: 'blob',
    mediaType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const fileName = 'case_manifest_template.xlsx';
  downloadBlob(blob, fileName);
};
