import { useSignals } from '@preact/signals-react/runtime';
import { find, values } from 'lodash';
import React, { FC, useEffect, useMemo, useTransition } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

import {
  baseSlidesVisualSettings,
  BaseSlideVisualSettings,
  defaultBaseSlideVisualSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import ToggleableSlider from 'components/Procedure/ToggleableSlider/ToggleableSlider';

export const BaseSlideControl: FC<{
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
}> = (props) => {
  useSignals();

  const { slideId, viewerIndex } = props;

  // In the URL, we store the settings for each stain type and viewer index to allow for sharing the settings when navigating to a different slide,
  // as well as to allow for sharing the settings when users share the URL.
  const [urlStainToBaseSlideSettings, setUrlStainToBaseSlideSettings] = useQueryParam<{
    [stainTypeId: string]: { [viewerIndex: number]: BaseSlideVisualSettings };
  }>('stainToBaseSlideSettings', JsonParam);

  const viewerBaseSlideSettings = baseSlidesVisualSettings[viewerIndex];

  const settingsFromUrl = useMemo(
    () =>
      props.stainTypeId
        ? {
            [slideId]:
              urlStainToBaseSlideSettings?.[props.stainTypeId]?.[viewerIndex] ||
              // If the settings for the current stain type and viewer index are not found, use any viewer's settings for the stain type, if available
              find(values(urlStainToBaseSlideSettings?.[props.stainTypeId])),
          }
        : undefined,
    [slideId, viewerIndex, urlStainToBaseSlideSettings, props.stainTypeId]
  );

  const baseSlideVisualSettings = {
    ...defaultBaseSlideVisualSettings,
    ...(settingsFromUrl || {}),
    ...(viewerBaseSlideSettings.value?.[slideId] || {}),
  };

  // Reset the base slide settings when the slide in the viewer changes
  useEffect(() => {
    if (viewerBaseSlideSettings) {
      viewerBaseSlideSettings.value = settingsFromUrl || {};
    }
  }, [slideId, viewerIndex, settingsFromUrl, viewerBaseSlideSettings]);

  const [, startTransition] = useTransition();

  const applyNewViewSettings = (newViewSettings: BaseSlideVisualSettings) => {
    baseSlidesVisualSettings[viewerIndex].value = {
      ...baseSlidesVisualSettings[viewerIndex].value,
      [slideId]: newViewSettings,
    };

    if (props.stainTypeId) {
      startTransition(() => {
        setUrlStainToBaseSlideSettings({
          ...urlStainToBaseSlideSettings,
          [props.stainTypeId]: {
            ...urlStainToBaseSlideSettings?.[props.stainTypeId],
            [viewerIndex]: newViewSettings,
          },
        });
      });
    }
  };

  const handleToggleShowSlide = () => {
    const viewerBaseSlideSettingsInner = baseSlidesVisualSettings[viewerIndex];

    const baseSlideVisualSettingsInner = {
      ...defaultBaseSlideVisualSettings,
      ...(viewerBaseSlideSettingsInner.value?.[slideId] || {}),
    };

    const newViewSettings = {
      ...baseSlideVisualSettingsInner,
      show: !baseSlideVisualSettingsInner?.show,
    };

    applyNewViewSettings(newViewSettings);
  };

  const handleChangeSlideOpacity = (newOpacity: number) => {
    const viewerBaseSlideSettingsInner = baseSlidesVisualSettings[viewerIndex];

    const baseSlideVisualSettingsInner = {
      ...defaultBaseSlideVisualSettings,
      ...(viewerBaseSlideSettingsInner.value?.[slideId] || {}),
    };
    const newSettings = { ...baseSlideVisualSettingsInner, opacity: newOpacity };
    applyNewViewSettings(newSettings);
  };

  return (
    <ToggleableSlider
      value={baseSlideVisualSettings?.opacity ?? 0}
      disabled={false}
      checked={Boolean(baseSlideVisualSettings?.show)}
      onToggleChange={handleToggleShowSlide}
      onValueChange={handleChangeSlideOpacity}
    />
  );
};
