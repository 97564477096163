import { Skeleton } from '@mui/material';
import { find, findIndex, flatMap, map } from 'lodash';
import React from 'react';
import { ReactImageGalleryItem, ReactImageGalleryProps } from 'react-image-gallery';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getProcedures } from 'api/procedures';
import { Procedure, ProcedureResponse } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { getSlideOriginalUrl, getSlideThumbnailUrl } from 'utils/helpers';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { DEFAULT_PAGE_SIZE } from '../ProcedurePagination';
import { getGalleryImageRenderer } from './GalleryImage';
import { getGalleryThumbnailRenderer } from './GalleryThumbnail';

export interface PaddingSlide {
  id: 'padding';
  stainingType?: string;
}

interface PaddingCase {
  id: -1;
  slides: [PaddingSlide];
}

const paddingCase: PaddingCase = {
  id: -1,
  slides: [{ id: 'padding' }],
};

export const useGallerySlides = ({
  fullScreenActive,
  totalCases,
  totalSlides,
}: {
  fullScreenActive: boolean;
  totalCases: number;
  totalSlides: number;
}) => {
  const [{ galleryPage, galleryWindowCaseId, galleryWindowSlideId }, setQueryParams] = useQueryParams(
    {
      galleryPage: NumberParam,
      galleryWindowCaseId: NumberParam,
      galleryWindowSlideId: StringParam,
    },
    { updateType: 'replaceIn' }
  );

  const { queryParams, generateEncodedParams } = useEncodedFilters({
    // To hit the cache for the cases in the paginated queries
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const slidesMode = Boolean(queryParams?.slidesMode);

  const zeroIndexedPage = galleryPage - 1;
  const resultsPerPage = queryParams.pageSize || DEFAULT_PAGE_SIZE;
  const totalPages = slidesMode ? Math.ceil(totalSlides / resultsPerPage) : Math.ceil(totalCases / resultsPerPage);
  const nextPage = (zeroIndexedPage + 1) % totalPages;
  // We add the total pages to avoid negative numbers when the current page is 0
  const previousPage = (zeroIndexedPage + totalPages - 1) % totalPages;

  const previousPageEncodedFilters = generateEncodedParams({
    ...queryParams,
    page: previousPage + 1,
  });

  const currentPageEncodedFilters = generateEncodedParams({
    ...queryParams,
    page: galleryPage,
  });

  const nextPageEncodedFilters = generateEncodedParams({
    ...queryParams,
    page: nextPage + 1,
  });

  const { isLoading: isLoadingCasesInPage, data: casesInPageResponse } = useQuery({
    queryKey: ['procedures', currentPageEncodedFilters],
    queryFn: ({ signal }: QueryFunctionContext): Promise<ProcedureResponse> =>
      getProcedures(currentPageEncodedFilters, signal),
  });
  const casesInPage = casesInPageResponse?.procedures;
  const slidesInPreviousPages = casesInPageResponse?.slidesInPreviousPages;
  const currentCase = find(casesInPage, { id: galleryWindowCaseId });

  const multiplePagesFilter = totalPages > 1;

  const { data: previousPageCases } = useQuery({
    queryKey: ['procedures', previousPageEncodedFilters],
    queryFn: ({ signal }: QueryFunctionContext): Promise<ProcedureResponse> =>
      getProcedures(previousPageEncodedFilters, signal),
    enabled: multiplePagesFilter,
  });

  const { data: nextPageCases } = useQuery({
    queryKey: ['procedures', nextPageEncodedFilters],
    queryFn: ({ signal }: QueryFunctionContext): Promise<ProcedureResponse> =>
      getProcedures(nextPageEncodedFilters, signal),
    enabled: multiplePagesFilter,
  });

  const cachedGalleryCases = React.useMemo(() => {
    const paddedPreviousPagesCases = multiplePagesFilter ? previousPageCases?.procedures ?? [paddingCase] : [];
    const paddedCurrentPageCases = casesInPage ?? [paddingCase];
    const paddedNextPagesCases = multiplePagesFilter ? nextPageCases?.procedures ?? [paddingCase] : [];

    return [...paddedPreviousPagesCases, ...paddedCurrentPageCases, ...paddedNextPagesCases];
  }, [multiplePagesFilter, casesInPage, previousPageCases, nextPageCases]);

  const caseSlidePairs = flatMap(cachedGalleryCases, ({ id, slides }) =>
    map(slides, (slide: Slide | PaddingSlide) => ({ case: id, slide }))
  );

  const slideImageUrls = useSlideImageUrls(map(caseSlidePairs, 'slide'));

  const slideImages: ReactImageGalleryProps['items'] = generateSlideImages(slideImageUrls, fullScreenActive);

  const currentPairIndex = findIndex(
    caseSlidePairs,
    (pair) => pair.case === galleryWindowCaseId && pair.slide.id === galleryWindowSlideId
  );

  const currentSlide =
    currentPairIndex >= 0 ? (caseSlidePairs[currentPairIndex].slide as Slide | undefined) : undefined;

  const slidesInPage = flatMap(casesInPage, ({ slides }) => slides);
  const slideIndexInCurrentPage = findIndex(slidesInPage, { id: galleryWindowSlideId });

  const changeImageIndex = (newIndex: number) => {
    // Don't allow the user to go to the padding slides, wait for them to load
    const newPair = caseSlidePairs[newIndex];
    if (newPair?.case !== -1) {
      const newGalleryWindowCaseId = newPair.case;
      const newGalleryWindowSlideId = newPair.slide.id;
      let newPage = galleryPage;
      const caseInNextPage = find(nextPageCases?.procedures, { id: newPair.case });
      const caseInPreviousPage = find(previousPageCases?.procedures, { id: newPair.case });
      if (find(caseInNextPage?.slides, { id: newPair.slide.id })) {
        newPage = nextPage + 1;
      } else if (find(caseInPreviousPage?.slides, { id: newPair.slide.id })) {
        newPage = previousPage + 1;
      }
      setQueryParams(
        {
          galleryPage: newPage,
          galleryWindowCaseId: newGalleryWindowCaseId,
          galleryWindowSlideId: newGalleryWindowSlideId,
        },
        'pushIn'
      );
    }
  };
  return {
    changeImageIndex,
    isLoadingCasesInPage,
    slideIndexInCurrentPage,
    currentImageIndex: currentPairIndex,
    currentCase,
    currentSlide,
    currentSlideIndex: slidesInPreviousPages + 1 + slideIndexInCurrentPage,
    slideImages,
    disableLeftNav: caseSlidePairs[currentPairIndex - 1]?.case === -1,
    disableRightNav: caseSlidePairs[currentPairIndex + 1]?.case === -1,
  };
};

const useSlideImageUrls = (slides: (Slide | PaddingSlide)[]) => {
  return React.useMemo(() => {
    return map(slides, (slide) => {
      const isMultiplex = slide.stainingType === 'mplex';
      const slideEncoding = (slide as Slide).encoding || 'uint8';
      return {
        thumbnail: slide.id !== 'padding' ? getSlideThumbnailUrl(slide as Slide) : undefined,
        original: slide.id !== 'padding' ? getSlideOriginalUrl(slide as Slide) : undefined,
        renderThumbInner: getGalleryThumbnailRenderer(isMultiplex, slideEncoding),
        renderItem: getGalleryImageRenderer(isMultiplex, slideEncoding),
      };
    });
  }, [slides]);
};

const generateSlideImages = (
  slidesImageUrls: {
    original: string;
    thumbnail: string;
    renderItem?(item: ReactImageGalleryItem): React.ReactNode;
    renderThumbInner?(item: ReactImageGalleryItem): React.ReactNode;
  }[],
  fullScreenActive: boolean
): ReactImageGalleryProps['items'] => {
  return map(slidesImageUrls, ({ original, thumbnail, renderItem, renderThumbInner }) =>
    original && thumbnail
      ? { original, thumbnail, renderItem, renderThumbInner }
      : {
          original: '',
          thumbnail: '',
          renderItem: () => (
            <Skeleton
              sx={{ height: fullScreenActive ? 'calc(100vh - 90px)' : 'calc(100vh - 170px)' }}
              variant="rectangular"
              animation="wave"
            />
          ),
          renderThumbInner: () => <Skeleton variant="rectangular" animation="wave" width={75} height={75} />,
        }
  );
};

export const useGallerySlidesUnpaginated = ({
  slideCasePairs,
  fullScreenActive,
}: {
  slideCasePairs: {
    slide: Slide;
    case: Procedure;
  }[];
  fullScreenActive: boolean;
}) => {
  const slideImageUrls = useSlideImageUrls(map(slideCasePairs, 'slide'));

  const slideImages = generateSlideImages(slideImageUrls, fullScreenActive);

  const [currentPairIndex, setCurrentPairIndex] = React.useState(0);

  const currentCase = slideCasePairs[currentPairIndex]?.case;
  const currentSlide = slideCasePairs[currentPairIndex]?.slide;

  const changeImageIndex = (newIndex: number) => {
    setCurrentPairIndex((slideCasePairs.length + newIndex) % slideCasePairs.length);
  };

  return {
    changeImageIndex,
    currentImageIndex: currentPairIndex,
    currentSlideIndex: currentPairIndex + 1,
    currentCase,
    currentSlide,
    slideImages,
  };
};
