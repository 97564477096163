import { HighlightAlt, LocalOffer } from '@mui/icons-material';
import { Drawer, Grid, IconButton, Stack, useTheme } from '@mui/material';
import AssignmentList from 'components/AssignmentsList';
import ReviewPanel from 'components/ReviewPanel';
import SlideTagsPanel from 'components/SlideTagsPanel';
import { caseToolbarHeight } from 'components/atoms/FlexContainers/FlexContainers';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { FormType } from 'interfaces/reviewForm';
import { compact, find, first, includes, map } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useCurrentForm } from 'utils/queryHooks/form/useCurrentForm';
import { useFormButtons } from 'utils/queryHooks/form/useFormButtons';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';
import useReviewSidebarWidth from './useReviewSidebarWidth';

interface ReviewSidebarProps {
  selectedSlideIds: string[];
  procedure: Procedure;
  refetchProcedure: () => void;
  isPlaceholderData?: boolean;
}

const tagsButton = {
  tab: 'tags',
  icon: <LocalOffer />,
  label: 'Tags',
} as const;

const annotationButton = {
  tab: 'annotations',
  icon: <HighlightAlt />,
  label: 'Annotations',
} as const;

const reviewSidebarOtherButtons = [tagsButton] as const;

type ReviewTab = (typeof tagsButton)['tab'] | (typeof annotationButton)['tab'] | FormType;

const ReviewSidebar: FunctionComponent<ReviewSidebarProps> = ({
  selectedSlideIds,
  procedure,
  isPlaceholderData,
  refetchProcedure,
}) => {
  const theme = useTheme();

  const [reviewTab, setReviewTab] = useQueryParam<string>('reviewTab', StringParam);

  const { labId } = useCurrentLabId();

  const currentFormContext = useMemo(
    () => ({
      slideId: first(selectedSlideIds),
      caseId: procedure.id,
      studyId: procedure.studyId,
      labId,
    }),
    [first(selectedSlideIds), procedure.studyId, labId]
  );

  const { hasPermission } = usePermissions();

  const hasTaggingPermissions = hasPermission(Permission.EditSlideTagsAssignments);

  const canViewAnnotationsList = hasPermission(Permission.ViewAnnotationsList);

  const formButtonsWithForms = useFormButtons(currentFormContext);

  const allButtons = useMemo(
    () =>
      [
        ...reviewSidebarOtherButtons,
        ...(canViewAnnotationsList ? [annotationButton] : []),
        ...formButtonsWithForms,
      ] as const,
    [formButtonsWithForms]
  );

  const handleClick = (tab: ReviewTab | undefined) => {
    if (reviewTab === tab) {
      setReviewTab(undefined);
    } else {
      setReviewTab(tab);
    }
  };

  const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];

  const closeDrawer = () => handleClick(undefined);

  const drawerPaperSx = {
    backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
    zIndex: theme.zIndex.appBar - 2,
    height: `calc(100% - ${caseToolbarHeight}px)`,
    marginTop: `${caseToolbarHeight}px`,
  };

  const { reviewTabWidthPx, reviewSidebarWidthPx, rightDrawerWidthPx } = useReviewSidebarWidth();

  const drawerSx = {
    marginTop: `${caseToolbarHeight}px`,
    '& .MuiDrawer-paper': {
      right: reviewSidebarWidthPx,
      width: reviewTabWidthPx,
      ...drawerPaperSx,
    },
    zIndex: theme.zIndex.appBar - 1,
  };

  const sidebarSx = {
    marginTop: `${caseToolbarHeight}px`,
    '& .MuiDrawer-paper': {
      marginTop: `${caseToolbarHeight}px`,
    },
    width: rightDrawerWidthPx,
    zIndex: theme.zIndex.appBar - 2,
  };

  const isButtonDisabled = (button: (typeof allButtons)[number]) => {
    if (button.tab === 'tags') {
      return !hasTaggingPermissions;
    } else if (button.tab === 'annotations') {
      return !canViewAnnotationsList;
    }
    return (button as (typeof formButtonsWithForms)[number]).form === undefined;
  };

  const { currentForm, isFormSlideAgnostic } = useCurrentForm(currentFormContext);

  const [slideIdForReviewing, setSlideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);

  const slideForReviewing = find(procedure.slides, { id: slideIdForReviewing });
  const selectedSlides = compact(map(selectedSlideIds, (slideId) => find(procedure.slides, { id: slideId })));
  const slideIdToSetForReviewing = first(selectedSlides)?.id || first(procedure?.slides)?.id;

  React.useEffect(() => {
    if (
      slideIdToSetForReviewing &&
      (!slideIdForReviewing || !includes(map(selectedSlides, 'id'), slideIdForReviewing))
    ) {
      setSlideIdForReviewing(isFormSlideAgnostic ? undefined : slideIdToSetForReviewing, 'replaceIn');
    } else if (isFormSlideAgnostic) {
      setSlideIdForReviewing(undefined, 'replaceIn');
    }
  }, [procedure?.slides, slideIdToSetForReviewing, slideIdForReviewing, isFormSlideAgnostic, selectedSlides]);

  return (
    <>
      <Drawer anchor={'right'} open={Boolean(reviewTab)} onClose={closeDrawer} variant="persistent" sx={drawerSx}>
        <Grid container direction="column" flexWrap="nowrap" height="100%" p={2}>
          <Grid container item alignItems="center" height="100%" sx={{ borderBottom: '1px solid ' + borderColor }}>
            {reviewTab === 'tags' ? (
              <SlideTagsPanel
                currentCase={procedure}
                currentSlide={slideForReviewing}
                selectedSlideIds={selectedSlideIds}
                isPlaceholderCaseData={isPlaceholderData}
                refetchProcedure={refetchProcedure}
                onClose={() => handleClick(undefined)}
              />
            ) : reviewTab === 'annotations' ? (
              <AssignmentList />
            ) : (
              false
            )}
            {currentForm && <ReviewPanel form={currentForm} context={currentFormContext} />}
          </Grid>
        </Grid>
      </Drawer>
      <Drawer variant="permanent" open anchor="right" sx={sidebarSx}>
        <Stack spacing={0} direction="column" alignItems="center" justifyContent="flex-start" sx={{ height: '100%' }}>
          {map(allButtons, (button) => (
            <IconButton
              sx={{
                borderRadius: '0',
                backgroundColor: reviewTab === button.tab ? theme.palette.secondary.light : 'transparent',
              }}
              key={button.tab}
              disabled={isButtonDisabled(button)}
              onClick={() => handleClick(button.tab)}
            >
              {button.icon}
            </IconButton>
          ))}
        </Stack>
      </Drawer>
    </>
  );
};

export default ReviewSidebar;
