import { useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import { additionalNavigationParamsSchema } from 'components/Procedure/Header/NavigationPanel/additionalNavigationParamsSchema';
import { defaultStudySlideViewMode } from 'components/StudiesDashboard/StudySettings/GeneralSettings';
import { find, isEmpty, omit, slice } from 'lodash';
import {
  BooleanParam,
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params';
import { useCurrentLabId } from './useCurrentLab';
import { useEncodedFilters } from './useEncodedFilters';

export interface CasePageUrlProps {
  caseId: number;
  caseStudyId?: string;
  slideIdForReviewing?: string;
  selectedSlideId?: string;
  newPage?: number;
  labId?: string;
}

export interface SlidePageUrlProps {
  slideId: string;
  caseId?: number; // caseId and caseStudyId is optional because pending slide doesn't have a case
  caseStudyId?: string;
  newPage?: number;
  labId?: string;
  activeAnnotationAssignment?: number;
}

export const useNavigationToViewerPage = () => {
  const { queryParams, generateEncodedParams } = useEncodedFilters();
  const [searchTerm] = useQueryParam('searchTerm', StringParam);
  const { labId: currentLabId } = useCurrentLabId();
  const { data: studies } = useQuery(['studies', currentLabId], fetchStudies(currentLabId));
  const [additionalNavigationParams] = useQueryParams(additionalNavigationParamsSchema);
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const [selectedSlideIdsFromParams] = useQueryParam('selectedSlideIds', DelimitedArrayParam);
  const [caseIdParam] = useQueryParam('caseId', NumberParam);

  const getSearchStringForViewerPage = ({
    slideIdForReviewing,
    selectedSlideId,
    newPage,
    caseId,
    caseStudyId,
    labId,
    activeAnnotationAssignment,
  }: {
    slideIdForReviewing?: string;
    selectedSlideId?: string;
    newPage?: number;
    caseId?: number; // this is for slide page (no need to save this twice in case page)
    caseStudyId?: string;
    labId?: string;
    activeAnnotationAssignment?: number;
  }): string => {
    newPage = newPage ?? queryParams.page;

    const filtersWithCaseStudyId = {
      ...omit(queryParams.filters, 'studyId'),
      ...(caseStudyId ? { studyId: caseStudyId } : {}),
    };

    const loadedStudy = find(studies, { id: caseStudyId });

    const displayOneSlideByDefault =
      additionalNavigationParams.displayOneSlideByDefault ??
      (loadedStudy?.settings?.defaultSlideViewMode || defaultStudySlideViewMode) === 'single';

    // when on case page with multiple slides selected, we want to keep the selected slides
    const selectedSlideIds =
      !isEmpty(selectedSlideIdsFromParams) && caseIdParam == caseId && selectedSlideIdsFromParams.length > 1
        ? [selectedSlideId, ...slice(selectedSlideIdsFromParams, 1)]
        : [selectedSlideId];

    const fromStudyFilter = additionalNavigationParams.fromStudyFilter ?? queryParams.filters?.studyId;

    const encodedParams = generateEncodedParams(
      {
        ...(filtersWithCaseStudyId ? { filters: filtersWithCaseStudyId } : {}),
        ...(newPage ? { page: newPage } : {}),
        ...(labId ? { labId } : {}),
      },
      {
        ...additionalNavigationParams,
        displayOneSlideByDefault,
        ...(fromStudyFilter ? { fromStudyFilter: fromStudyFilter } : {}),
        ...(slideIdForReviewing ? { slideIdForReviewing: slideIdForReviewing } : {}),
        ...(selectedSlideId ? { selectedSlideIds: selectedSlideIds } : {}),
        ...(caseId ? { caseId: caseId } : {}),
        ...(searchTerm ? { searchTerm } : {}),
        pendingSlidesMode: pendingSlidesMode,
        // 0 is for the first viewer
        activeAnnotationAssignmentIdViewer0: activeAnnotationAssignment,
      },
      {
        ...additionalNavigationParamsSchema,
        pendingSlidesMode: BooleanParam,
        searchTerm: StringParam,
        caseId: NumberParam,
        selectedSlideIds: DelimitedArrayParam,
        activeAnnotationAssignmentIdViewer0: NumberParam,
      }
    );

    return encodedParams;
  };

  const getLinkToCasePage = ({
    caseId,
    caseStudyId,
    slideIdForReviewing,
    selectedSlideId,
    newPage,
    labId,
  }: CasePageUrlProps) => {
    const encodedParams = getSearchStringForViewerPage({
      slideIdForReviewing,
      selectedSlideId,
      newPage,
      caseStudyId,
      labId,
    });

    return {
      pathname: `/procedures/${caseId}`,
      search: `?${encodedParams}`,
      hash: '',
    };
  };

  const getUrlToCasePage = (props: CasePageUrlProps) => {
    const linkToCasePage = getLinkToCasePage(props);
    return `${linkToCasePage.pathname}${linkToCasePage.search}`;
  };

  const getLinkToSlidePage = ({
    slideId,
    caseId,
    caseStudyId,
    newPage,
    labId,
    activeAnnotationAssignment,
  }: SlidePageUrlProps) => {
    const newSearchString = getSearchStringForViewerPage({
      slideIdForReviewing: slideId,
      selectedSlideId: slideId,
      newPage,
      caseId,
      caseStudyId,
      labId,
      activeAnnotationAssignment,
    });

    return {
      pathname: `/slides/${slideId}`,
      search: `?${newSearchString}`,
      hash: '',
    };
  };

  const getUrlToSlidePage = (props: SlidePageUrlProps) => {
    const linkToSlidePage = getLinkToSlidePage(props);
    return `${linkToSlidePage.pathname}${linkToSlidePage.search}`;
  };

  return {
    getLinkToCasePage,
    getUrlToCasePage,
    getLinkToSlidePage,
    getUrlToSlidePage,
  };
};
