import { LinearInterpolator } from '@deck.gl/core/typed';
import { Matrix4 } from '@math.gl/core';
import { signal } from '@preact/signals-react';
import { Dictionary, isEqual, times } from 'lodash';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { Registration } from 'interfaces/slide';
import { calculateZoomFromMagnification } from 'utils/slideTransformation';
import { applyRegistrationAffineTransformToPoint } from '../registration';
import { OrthographicMapViewState } from './OrthographicMapview';

export const DIGITAL_ZOOM_FACTOR = 6;
export const ADDITIONAL_ZOOM_OUT_FACTOR = 1.5;

export const deckGLViewers = times(MAX_VIEWERS, () => signal<Dictionary<OrthographicMapViewState>>({}));

/**
 * Apply the registration angle to a view state's bearing
 * @param {Object} options - Parameters required to apply the registration bearing
 * @param {boolean} options.isRegisteredFromSlide - Whether the registration is from the slide who's bearing we are updating
 * @param {number} options.angle - The angle to apply
 * @returns {number} The updated bearing
 */
const applyRegistrationBearing = ({
  isRegisteredFromSlide,
  angle,
}: {
  isRegisteredFromSlide?: boolean;
  angle?: number;
}) => ((isRegisteredFromSlide ? angle ?? 0 : -(angle ?? 0)) + 360) % 360;

/**
 * Calculates the initial zoom level for the Deck GL Orthographic view.
 *
 * @param viewportWidth - The width of the viewport in pixels.
 * @param viewportHeight - The height of the viewport in pixels.
 * @param imageWidth - The width of the image in pixels.
 * @param imageHeight - The height of the image in pixels.
 * @returns The initial zoom level for the Deck GL Orthographic view.
 */
export const calculateInitialZoomLevel = (
  viewportWidth: number,
  viewportHeight: number,
  imageWidth: number,
  imageHeight: number
): number => {
  // Calculate the size ratio of the viewport to the image in both the width and height dimensions.
  // This provides a measure of how much the image needs to be scaled to fit the viewport.
  const widthRatio = viewportWidth / imageWidth;
  const heightRatio = viewportHeight / imageHeight;

  // Determine the limiting dimension (width or height) by taking the smaller of the two ratios.
  const limitingDimensionRatio = Math.min(widthRatio, heightRatio);

  // Take the logarithm base 2 of the ratio of the viewport's width to the image's width if the width is the limiting factor,
  // or the ratio of the viewport's height to the image's height if the height is the limiting factor.
  // The logarithm base 2 is used because zoom levels in many mapping libraries, including Deck GL, are often expressed as powers of 2.
  return Math.log2(limitingDimensionRatio);
};

/**
 * Get the initial view state for a slide
 * @param {Object} options - Parameters required to get the initial view state
 * @param {number} options.minLevel - The minimum zoom level of the slide's dzi
 * @param {number} options.maxLevel - The maximum zoom level of the slide's dzi
 * @param {Object} options.imageSize - The size of the slide in pixels (width, height)
 * @param {boolean} options.isRegisteredFromSlide - Whether the registration is from the slide we are getting the view state for
 * @param {Registration} options.registration - The registration to apply
 * @param {Object} options.viewSize - The size of the view
 * @returns {OrthographicMapViewState} The initial view state
 */
export const getInitialViewState = ({
  minLevel,
  maxLevel,
  imageSize: { width: pixelWidth, height: pixelHeight } = { width: 1, height: 1 },
  isRegisteredFromSlide,
  registration,
  viewSize,
  maxResolution,
  magnificationValue,
}: {
  minLevel: number;
  maxLevel: number;
  imageSize: { width: number; height: number };
  isRegisteredFromSlide?: boolean;
  registration?: Registration;
  viewSize?: { width: number; height: number };
  maxResolution: number;
  magnificationValue: number;
}): OrthographicMapViewState => {
  // We allow for zooming out further than the min level of the dzi
  const minZoom = minLevel - maxLevel - ADDITIONAL_ZOOM_OUT_FACTOR;
  // We add DIGITAL_ZOOM_FACTOR to the max zoom level to allow for zooming in further than the max level of the dzi
  const maxZoom = DIGITAL_ZOOM_FACTOR;

  const zoomFromMagnification =
    !isNaN(magnificationValue) && !isNaN(maxResolution)
      ? calculateZoomFromMagnification(magnificationValue, maxResolution)
      : undefined;

  const zoom = Number.isFinite(zoomFromMagnification)
    ? zoomFromMagnification
    : viewSize?.height && viewSize?.width
    ? calculateInitialZoomLevel(viewSize.width, viewSize.height, pixelWidth, pixelHeight)
    : minZoom;

  const target = new Matrix4().transform(
    [pixelWidth / 2, pixelHeight / 2, 0],
    undefined
  ) as OrthographicMapViewState['target'];

  const initialViewState: OrthographicMapViewState = {
    target,
    zoom,
    minZoom,
    maxZoom,
    ignorePitch: true,
    bearing: applyRegistrationBearing({ isRegisteredFromSlide, angle: registration?.angle }),
    transitionDuration: 100,
    transitionInterpolator: new LinearInterpolator({
      transitionProps: {
        compare: ['zoom', 'bearing', 'pitch', 'target'],
        required: ['target', 'zoom'],
      },
    }),
  };

  return initialViewState;
};

/**
 * Apply the registration from one slide to another slide's view state
 * @param {Object} options - Parameters required to apply the registration
 * @param {boolean} options.isRegisteredFromSlide - Whether the registration is from the 'leading' slide
 * @param {Registration} options.registration - The registration to apply
 * @param {OrthographicMapViewState} options.otherSlideState - The view state of the other slide
 * @param {OrthographicMapViewState} options.leadingSlideState - The view state of the leading slide
 * @param {number} options.zoomRatio - The zoom ratio to apply
 * @param {boolean} options.shouldApplyBearing - Whether to update the bearing
 * @param {boolean} options.shouldApplyZoom - Whether to update the zoom
 * @returns {OrthographicMapViewState} The updated view state
 */
export const applyRegistrationToOtherViewerState = ({
  isRegisteredFromSlide,
  registration,
  otherSlideState,
  leadingSlideState,
  zoomRatio = registration?.zoomRatio ?? 1,
  shouldApplyBearing,
  shouldApplyZoom,
}: {
  registration?: Registration;
  isRegisteredFromSlide?: boolean;
  otherSlideState: OrthographicMapViewState;
  leadingSlideState: OrthographicMapViewState;
  zoomRatio?: number;
  shouldApplyBearing?: boolean;
  shouldApplyZoom?: boolean;
}): OrthographicMapViewState => {
  if (!leadingSlideState) {
    console.error('leadingSlideState is not defined');
    return otherSlideState;
  }

  if (!otherSlideState) {
    console.error('otherSlideState is not defined');
    return leadingSlideState;
  }

  if (!registration) {
    return { ...leadingSlideState };
  }

  const otherViewerUpdates: OrthographicMapViewState = { ...otherSlideState };
  if (shouldApplyBearing && registration) {
    otherViewerUpdates.bearing =
      (leadingSlideState.bearing + applyRegistrationBearing({ isRegisteredFromSlide, angle: registration?.angle })) %
      360;
  }
  otherViewerUpdates.pitch = leadingSlideState.pitch;

  if (registration) {
    if (shouldApplyZoom) {
      const leadingZoom = !isNaN(leadingSlideState.zoom as number)
        ? (leadingSlideState.zoom as number)
        : otherSlideState.minZoom || 0;
      if (leadingZoom !== undefined) {
        const otherViewerZoomLevel = isRegisteredFromSlide
          ? leadingZoom + Math.log2(zoomRatio)
          : leadingZoom - Math.log2(zoomRatio);

        otherViewerUpdates.zoom = otherViewerZoomLevel;
      }
    }
    const transformedPoint = applyRegistrationAffineTransformToPoint({
      isViewerRegisteredViewer: isRegisteredFromSlide,
      registration,
      point: { x: leadingSlideState.target[0], y: leadingSlideState.target[1] },
      slideRegistrationPoints1: registration.points1,
      slideRegistrationPoints2: registration.points2,
    });

    otherViewerUpdates.target = [transformedPoint.x, transformedPoint.y, 0];
  }

  return otherViewerUpdates;
};

/**
 * Equality check for view states - focuses on the bearing, zoom, pitch, and target. Used to prevent unnecessary updates to the view state
 * @param {OrthographicMapViewState} viewState1 - The first view state
 * @param {OrthographicMapViewState} viewState2 - The second view state
 * @returns {boolean} Whether the view states are equal
 */
export const areViewStatesEqual = (viewState1: OrthographicMapViewState, viewState2: OrthographicMapViewState) => {
  return (
    (!viewState1 && !viewState2) ||
    (viewState1?.bearing === viewState2?.bearing &&
      viewState1?.zoom === viewState2?.zoom &&
      viewState1?.pitch === viewState2?.pitch &&
      isEqual(viewState1?.target, viewState2?.target))
  );
};
