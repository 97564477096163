import { Tooltip } from '@mui/material';
import { ConditionalTextEditor } from 'components/atoms/DataGridRenderers/ConditionalTextEditor';
import { DisplayedField } from 'interfaces/genericFields';
import { StainType } from 'interfaces/stainType';
import React from 'react';

const idRegex = /^[a-z\d/]+(?:[-_][a-z\d/]+)*$/;

export const idField: DisplayedField<StainType & { draftId?: string }, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'id',
  label: 'Stain Type ID',
  columnWidth: { width: 200 },
  getError: ({ value }) =>
    !idRegex.test(value)
      ? 'Stain type id must be lowercase and contain only letters, numbers. ' +
        'Hyphens, and underscores are allowed as separators.'
      : undefined,
  customCellEditor:
    (field, context) =>
    ({ isEditable: isEditableParam, ...params }) => {
      const { value, row } = params;
      const isEditable = (stain: StainType & { draftId?: string }) => isEditableParam && !!stain.draftId;
      const error = field.getError?.({ context, value, row });
      const hasError = isEditable(row) && Boolean(error);
      return (
        <Tooltip open={Boolean(hasError)} color="error" title={hasError ? error : undefined}>
          <ConditionalTextEditor {...params} isEditable={isEditable} />
        </Tooltip>
      );
    },
};

export const displayNameField: DisplayedField<StainType, string> = {
  cellEditType: 'text',
  filterType: 'text', // TODO: change to multiSelect (based on search filter perhaps) or text search
  dataKey: 'displayName',
  label: 'Display Name',
  columnWidth: { width: 200 },
};

export const indexField: DisplayedField<StainType, number> = {
  filterType: 'multiSelect',
  dataKey: 'index',
  label: 'Index',
  columnWidth: { width: 150 },
};

export const canBeMifMarkerField: DisplayedField<StainType, boolean> = {
  cellEditType: 'checkbox',
  filterType: 'checkbox',
  dataKey: 'canBeMifMarker',
  label: 'Can be MIF Marker',
  columnWidth: { width: 150 },
};

export const stainTypeFields: Array<DisplayedField<StainType, any>> = [
  idField,
  displayNameField,
  indexField,
  canBeMifMarkerField,
];
