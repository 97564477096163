import { fromPairs, times } from 'lodash';
import { BooleanParam, JsonParam, NumberParam, StringParam } from 'use-query-params';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';

export const additionalNavigationParamsSchema = {
  displayOneSlideByDefault: BooleanParam,
  defaultStainingType: StringParam,
  slideQualityActionsDrawerOpen: BooleanParam,
  slideIdForReviewing: StringParam,
  showSlidesThumbnails: BooleanParam,
  slideTagsDrawerOpen: BooleanParam,
  useDeckGL: BooleanParam,
  fullscreenViewer: BooleanParam,
  reviewTab: StringParam,
  fromStudyFilter: StringParam,
  annotationsListVisibility: StringParam,
  stainToBaseSlideSettings: JsonParam,
  layerSettingsPerStainAndDisplayName: JsonParam,
  markerChannelColorSettings: JsonParam,
  markerChannelToggle: JsonParam,
  ...fromPairs(times(MAX_VIEWERS, (viewerIndex) => [`activeAnnotationAssignmentIdViewer${viewerIndex}`, NumberParam])),
};
