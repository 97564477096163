import { Field } from 'interfaces/genericFields';
import { isPlainObject } from 'lodash';
import { CreatedAt } from 'services/filters';
import { RangeValue } from './caseQuery';

export enum MainPageFilterKey {
  searchTerm = 'searchTerm',
  studyId = 'studyId',
  stains = 'stains',
  cancerTypes = 'cancerTypes',
  cancerSubtypes = 'cancerSubtypes',
  orchestrationId = 'orchestrationId',
}

export enum GeneralFilterKey {
  approvement = 'approvement',
  qcLabels = 'qcLabels',
  qcLabelsOperator = 'qcLabelsOperator',
  inferredFeatureId = 'inferredFeatureId',
  inferredFeatureValueId = 'inferredFeatureValueId',
  slideTags = 'slideTags',
  excludedSlides = 'excludedSlides',
}

export type UncategorizedFilterKey = GeneralFilterKey | MainPageFilterKey;

export type FilterKey = string;

export type CompositeFilterKey =
  | 'queryRange'
  | 'dataType'
  | 'cellTypeSource'
  | 'areaType'
  | 'cellTypeTarget'
  | 'ihcSource'
  | 'ihcTarget';

export type QueryFilter = (
  | {
      filterType: 'range' | 'date-range';
      value: { start: string; end: string };
    }
  | {
      filterType: 'multiSelect' | 'checkbox-group';
      value: string[];
    }
  | {
      filterType: 'select';
      value: string;
    }
) & { category: Field['dataCategory'] };

export const isQueryFilter = (filter: any): filter is QueryFilter => isPlainObject(filter) && filter?.filterType;

export type FeatureFilter = {
  featureKey: string;
  value: RangeValue;
};

export type AllFilters = Partial<Record<UncategorizedFilterKey, QueryFilter['value'] | string>>;

export interface QueryObject extends Partial<Record<FilterKey, QueryFilter>> {}

export interface CohortQuerySelection {
  caseIdsToInclude?: number[];
  caseIdsToExclude?: number[];
  slideIdsToInclude?: string[];
  slideIdsToExclude?: string[];
  slidesMode?: boolean;
}
export const cohortQuerySelectionKeys = [
  'caseIdsToInclude',
  'caseIdsToExclude',
  'slideIdsToInclude',
  'slideIdsToExclude',
  'slidesMode',
];
export interface CohortQueryObject extends CohortQuerySelection {
  clinicalData?: Record<FilterKey, string>;
  filters?: Record<FilterKey, string>;
  features?: Record<FilterKey, string>;
  searchTerm?: string;
}

type ProcedureToSlides = Record<'slides', string[]>;
export interface Cohort {
  id: string;
  name: string;
  description: string;
  labId: string;
  queryObject: CohortQueryObject;
  createdBy?: string;
  createdAt?: string;
  casesSnapshot?: Record<string, ProcedureToSlides>[];
  deletedAt?: string;
}

export interface CohortInputData extends Cohort {
  saveSnapshot?: boolean;
}

export interface BasicFiltersQueryParams {
  studyId?: string;
  cancerType?: string;
  stain?: string;
  approvement?: string;
  createdAt?: CreatedAt;
  createdAtTo?: string;
  createdAtFrom?: string;
}
