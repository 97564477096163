import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useQueryClient } from '@tanstack/react-query';
import { find, isEmpty, omit, reject, some } from 'lodash';
import React from 'react';

import { createStainTypeOption, deleteStainTypeOption, updateStainTypeOption } from 'api/stainTypes';
import { rowEditingControlsColumn } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { StainType } from 'interfaces/stainType';
import { stainTypeFields } from 'interfaces/stainType/stainTypeFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { StainTypeRowChangesSummary } from './StainTypeRowChangesSummary';
import { StainTypeDraft } from './types';
import { getStainTypeError, getStainTypeId } from './utils';

export const useStainTypesColumns = ({
  apiRef,
  noRows,
  rowsWithChanges,
  draftStainTypes,
  rowModesModel,
  setDraftStainTypes,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowsWithChanges: Array<Omit<StainType, 'index'>>;
  draftStainTypes: StainTypeDraft[];
  rowModesModel: GridRowModesModel;
  setDraftStainTypes: React.Dispatch<React.SetStateAction<Array<Omit<StainType, 'index'>>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const { clearRowChanges, rowsChanges } = useTableEditingContext<Omit<StainType, 'index'>>();
  const confirmWithModal = useConfirmation();

  const queryClient = useQueryClient();

  const createStainTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newStainType) => {
      queryClient.invalidateQueries(['stainTypes']);
      queryClient.setQueryData(['stainTypes'], (oldData: StainType[]) => [...oldData, newStainType]);
    },
    mutationFn: createStainTypeOption,
    mutationDescription: 'create stain type',
  });

  const updateStainTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(['stainTypes']);
      queryClient.setQueryData(['stainTypes'], (oldData: StainType[]) => {
        const updatedStainType = find(rowsWithChanges, { id: mutatingRowId });
        return [...reject(oldData, { id: mutatingRowId }), updatedStainType];
      });
    },
    mutationFn: updateStainTypeOption,
    mutationDescription: 'update stain type',
  });

  const deleteStainTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['stainTypes']);
    },
    mutationFn: deleteStainTypeOption,
    mutationDescription: 'delete stain type',
  });

  const stainTypeFieldsColumns = useEditableFieldsDataGridColumns<StainType | StainTypeDraft>({
    fields: stainTypeFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode: false,
    idGetter: getStainTypeId,
    useValueSetter: true,
  });
  const isDraftRow = React.useCallback((id: GridRowId) => some(draftStainTypes, { draftId: id }), [draftStainTypes]);
  const isDeletedRow = React.useCallback(
    (id: GridRowId) => !isEmpty((find(rowsWithChanges, { id: id as string }) as StainType)?.deletedAt),
    [rowsWithChanges]
  );

  const columns = React.useMemo(() => {
    const deleteOperation = (id: GridRowId) => async () => {
      if (
        await confirmWithModal({
          title: 'Delete Stain Type',
          text: 'Are you sure you want to delete this stain type?',
        })
      ) {
        if (some(draftStainTypes, { draftId: id })) {
          setDraftStainTypes(reject(draftStainTypes, { draftId: id as string }));
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [id]: draftRoleRowMode, ...rest } = rowModesModel;
          setRowModesModel(rest);
        } else if (id) {
          setMutatingRowId(id);
          await deleteStainTypeMutation.mutateAsync(`${id}`);
          setMutatingRowId(undefined);
        }
        clearRowChanges(id);
        return true;
      } else {
        return false;
      }
    };

    const confirmCancelEdit = async (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      const stainType = isDraft
        ? (rowsChanges[id] as StainType)
        : (find(rowsWithChanges, { id: id as string }) as StainType);
      return (
        !rowsChanges[id] ||
        (await confirmWithModal({
          title: `Cancel stain type ${isDraft ? 'creation' : 'update'}`,
          text: (
            <StainTypeRowChangesSummary
              stainTypeDisplayString={`Stain Type "${stainType?.displayName}" (${stainType?.id ?? id})`}
              changes={rowsChanges[id]}
            />
          ),
        }))
      );
    };

    const saveOperation = async (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      const stainType = isDraft
        ? (rowsChanges[id] as StainType)
        : (find(rowsWithChanges, { id: id as string }) as StainType);
      if (
        await confirmWithModal({
          title: `${isDraft ? 'Create' : 'Update'} stain type`,
          text: (
            <StainTypeRowChangesSummary
              stainTypeDisplayString={`Stain Type "${stainType?.displayName}" (${stainType?.id ?? id})`}
              changes={rowsChanges[id]}
            />
          ),
        })
      ) {
        setMutatingRowId(id);
        if (isDraft) {
          await createStainTypeMutation.mutateAsync(omit(stainType, 'draftId'));
          setDraftStainTypes(reject(draftStainTypes, { draftId: id as string }));
        } else {
          await updateStainTypeMutation.mutateAsync(stainType);
        }
        setMutatingRowId(undefined);
        clearRowChanges(id);
        return true;
      } else {
        return false;
      }
    };

    return [
      ...stainTypeFieldsColumns,
      rowEditingControlsColumn({
        mutatingRowId,
        rowModesModel,
        setRowModesModel,
        apiRef,
        getRowError: getStainTypeError,
        deleteOperation,
        isDeletedRow,
        isDraftRow,
        onCancelEdit: (id: GridRowId) => {
          const isDraft = isDraftRow(id);
          if (isDraft) {
            setDraftStainTypes(reject(draftStainTypes, { draftId: id as string }));
          }
          clearRowChanges(id);
        },
        saveOperation,
        confirmCancelEdit,
      }),
    ];
  }, [
    apiRef,
    clearRowChanges,
    confirmWithModal,
    createStainTypeMutation,
    deleteStainTypeMutation,
    updateStainTypeMutation,
    draftStainTypes,
    mutatingRowId,
    rowsChanges,
    rowModesModel,
    rowsWithChanges,
    setDraftStainTypes,
    setRowModesModel,
    stainTypeFieldsColumns,
  ]);

  return columns;
};
