import { Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { compact, Dictionary, every, forEach, isEmpty, map, some } from 'lodash';
import React from 'react';

import {
  defaultHeatmapOpacity,
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import ToggleableSlider from 'components/Procedure/ToggleableSlider/ToggleableSlider';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';

export const LayerGroupControl: React.FC<
  React.PropsWithChildren<{
    slideId: string;
    viewerIndex: number;
    parentKey: string;
    layerIdsToDisplayNames?: Dictionary<string>;
    layerIdsToUrlKeys: Dictionary<string>;
    layers: string[];
    stainTypeId: string;
    displayName?: string;
    defaultLayerSettings?: Partial<LayerVisualizationSettings>;
    disableToggle?: boolean;
    orchestrationId?: string;
    hideOrchestrationId?: boolean;
  }>
> = ({
  slideId,
  viewerIndex,
  parentKey,
  displayName,
  layerIdsToDisplayNames,
  layerIdsToUrlKeys,
  stainTypeId,
  layers,
  disableToggle,
  defaultLayerSettings = { opacity: defaultHeatmapOpacity },
  orchestrationId,
  hideOrchestrationId,
}) => {
  useSignals();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const { hasPermission } = usePermissions();
  const canSeeOrchestrationId = !hideOrchestrationId && hasPermission(Permission.SeeOrchestrationId);

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];

  const slideLayerSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];
  const currentLayerSettings = compact(map(layers, (layer) => slideLayerSettings?.[`${parentKey}-${layer}`]?.value));
  const isEveryLayerSelected =
    disableToggle || (!isEmpty(currentLayerSettings) && every(currentLayerSettings, 'selected'));
  const isSomeLayerSelected = !isEveryLayerSelected && some(currentLayerSettings, 'selected');
  const isEveryLayerShown = every(currentLayerSettings, ({ show, opacity }) => show && opacity > 0);
  const isSomeLayerShown = some(currentLayerSettings, ({ show, opacity }) => show && opacity > 0);

  const maxSlideLayerOpacity = Math.max(...map(currentLayerSettings, 'opacity'));

  const applyChangeForLayers = (
    getNewSettings: (prev?: LayerVisualizationSettings) => Partial<LayerVisualizationSettings>
  ) => {
    if (!viewerSlideLayerVisualizationSettings) {
      console.warn(`Invalid viewerIndex: ${viewerIndex}`);
      return;
    }
    const previousSettings = viewerSlideLayerVisualizationSettings.value;
    const previousSlideSettings = previousSettings?.[slideId];
    const changes: Array<{
      layerId: string;
      layerUrlKey?: string;
      newSettings: Partial<LayerVisualizationSettings>;
    }> = [];
    forEach(layers, (layerName) => {
      const layerKey = `${parentKey}-${layerName}`;
      const layerSettings = previousSlideSettings?.[layerKey];
      const layerPreviousSettings = layerSettings?.value;
      changes.push({
        layerId: layerKey,
        newSettings: getNewSettings(layerPreviousSettings),
        layerUrlKey: layerIdsToUrlKeys?.[layerKey] || layerKey,
      });
    });
    applyChangesToSlideLayerVisualizationSettings({ slideId, viewerIndex, changes, stainTypeId });
  };

  const onToggleChange = () => {
    const newShowValue = !isEveryLayerShown;
    applyChangeForLayers((prev) => ({
      show: newShowValue,
      opacity:
        newShowValue && !prev?.opacity && !isNaN(defaultLayerSettings?.opacity)
          ? defaultLayerSettings.opacity
          : prev?.opacity || 0,
    }));
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    applyChangeForLayers((prev) => ({
      ...prev,
      selected: event.target.checked,
      ...(event.target.checked ? { show: true, ...defaultLayerSettings } : {}),
    }));
  };

  const onSliderValueChange = (value: number) => {
    applyChangeForLayers((prev) => ({ opacity: value, show: prev?.show ?? value > 0 }));
  };

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid container wrap="nowrap" alignItems="center">
        <Grid item md={2}>
          <Checkbox
            disabled={disableToggle || isEmpty(layers)}
            checked={isEveryLayerSelected}
            indeterminate={isSomeLayerSelected}
            onChange={onCheckboxChange}
            onClick={(e) => e.stopPropagation()}
          />
        </Grid>
        <Grid
          item
          container
          md={5}
          direction="column"
          sx={{
            '&>.MuiGrid-item': {
              maxWidth: '100%',
            },
          }}
        >
          <Grid item>
            <Tooltip title={displayName || layerIdsToDisplayNames?.[parentKey] || parentKey} placement="top">
              <Typography
                variant="caption"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                component="div"
              >
                {displayName || layerIdsToDisplayNames?.[parentKey] || parentKey}
              </Typography>
            </Tooltip>
          </Grid>
          {canSeeOrchestrationId && (
            <Grid maxWidth="100%">
              <Tooltip title={orchestrationId} placement="top">
                <Typography
                  variant="caption"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  component="div"
                >
                  {orchestrationId}
                </Typography>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Grid item md={5}>
          <ToggleableSlider
            value={maxSlideLayerOpacity}
            disabled={!isEveryLayerSelected && !isSomeLayerSelected}
            checked={isSomeLayerShown}
            onToggleChange={onToggleChange}
            onValueChange={onSliderValueChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
