import { ScreenGridLayer } from '@deck.gl/aggregation-layers/typed';
import { COORDINATE_SYSTEM, Layer } from '@deck.gl/core/typed';
import { Feature } from '@turf/helpers';
import geojsonvt from 'geojson-vt';
import { Dictionary, compact, filter, flatMap, fromPairs, indexOf, map, slice, some, toPairs } from 'lodash';
import { useMemo } from 'react';
// @ts-ignore
import vtpbf from 'vt-pbf';

import { MVTLayer, MVTLayerProps } from '@deck.gl/geo-layers/typed';
import { load } from '@loaders.gl/core';
import { Signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { defaultMultiplexChannelColors } from 'components/Procedure/Infobar/SlideInfobar/Multiplex/colorSettings';
import { LayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { ImagePyramid } from 'components/Procedure/useSlideImages';
import { hexToRgb } from 'utils/helpers';
import { useGeoJSONFiles } from 'utils/useGeoJSONFile';
import { DIGITAL_ZOOM_FACTOR } from '../slidesViewerState';

export const useDeckGLGeoJSONCellLayers = ({
  baseImagePyramids,
  geoJSONUrls,
  slideLayerVisualizationSettings,
}: {
  baseImagePyramids: ImagePyramid;
  geoJSONUrls: string[];
  slideLayerVisualizationSettings: Dictionary<Signal<LayerVisualizationSettings>>;
}): Layer[] => {
  useSignals();
  const geoJSONFiles = useGeoJSONFiles(geoJSONUrls);

  const geoJSONLayerFeatureSettings = fromPairs(
    flatMap(geoJSONUrls, (geoJSONUrl, idx) => {
      const parsedGeoJSON = geoJSONFiles?.[idx]?.data;
      return flatMap(parsedGeoJSON?.features, (feature: Feature) => {
        const layerName = feature?.properties?.class_name;
        const cellKey = `${geoJSONUrl}-cells-${layerName}`;
        const heatmapKey = `${geoJSONUrl}-heatmap-${layerName}`;
        return [
          [cellKey, slideLayerVisualizationSettings?.[cellKey]?.value],
          [heatmapKey, slideLayerVisualizationSettings?.[heatmapKey]?.value],
        ] as Array<[string, LayerVisualizationSettings]>;
      });
    })
  );

  const stableGeoJSONLayerFeatureSettings = useMemo(
    () => geoJSONLayerFeatureSettings,
    [JSON.stringify(geoJSONLayerFeatureSettings)]
  );

  const layerToggleStates = fromPairs(
    map(toPairs(stableGeoJSONLayerFeatureSettings), ([layerName, featureSettings]) => [
      layerName,
      Boolean(featureSettings?.show && featureSettings?.selected),
    ])
  );

  const stableLayerToggleStates = useMemo(() => layerToggleStates, [JSON.stringify(layerToggleStates)]);

  const parsedGeoJSONs = map(geoJSONFiles, 'data');

  const layerSource = baseImagePyramids.layerSource;

  const layerInputs = useMemo(
    () =>
      compact(
        map(geoJSONUrls, (geoJSONUrl, idx) => {
          const parsedGeoJSON = parsedGeoJSONs?.[idx];
          try {
            const tileIndex = geojsonvt(parsedGeoJSON as any, {
              extent: layerSource.getTileSize(), // tile extent (both width and height)
              promoteId: 'class_name', // name of a feature property to promote to feature.id. Cannot be used with `generateId`
              generateId: false, // whether to generate feature ids. Cannot be used with `promoteId`
            });

            const fetchData: MVTLayerProps['fetch'] = (url, { layer, loadOptions }) => {
              const urlParts = url.split('/');
              const x = parseInt(urlParts[0]);
              const y = parseInt(urlParts[1]);
              const z = parseInt(urlParts[2]);

              const actualZoom = z + layerSource.maxLevel - layerSource.minLevel;

              const tile = tileIndex.getTile(actualZoom, x, y);
              if (!tile) {
                return null;
              }
              var buff = vtpbf.fromGeojsonVt({ geojsonLayer: tile });

              return load(buff, layer.props.loaders, loadOptions);
            };

            return {
              geoJSONUrl,
              parsedGeoJSON,
              tileIndex,
              fetchData,
              layerNames: compact(map(parsedGeoJSON?.features, 'properties.class_name')) as string[],
              flatPointsWithFeatures: flatMap(
                parsedGeoJSON?.features,
                (feature: Feature): Array<{ coordinates: [number, number]; className: string }> => {
                  if (!feature?.geometry) {
                    console.error('Feature has no geometry', feature);
                    return [];
                  }
                  const className = feature?.properties?.class_name;
                  if (feature.geometry.type === 'Point') {
                    return [
                      {
                        coordinates: feature.geometry.coordinates as [number, number],
                        className,
                      },
                    ];
                  } else if (feature.geometry.type === 'MultiPoint') {
                    return map(feature.geometry.coordinates, (coordinates: [number, number]) => ({
                      coordinates,
                      className,
                    }));
                  }
                  return [];
                }
              ),
            };
          } catch (e) {
            console.error('Error parsing geojson', e, { geoJSONUrl, parsedGeoJSON });
            return null;
          }
        })
      ),
    [JSON.stringify(parsedGeoJSONs), layerSource]
  );

  return useMemo(
    () =>
      flatMap(layerInputs, ({ geoJSONUrl, fetchData, flatPointsWithFeatures, layerNames }) => {
        const isSomeCellLayerToggled = some(
          layerNames,
          (layerName) => stableLayerToggleStates?.[`${geoJSONUrl}-cells-${layerName}`]
        );
        const tiledGeoJSONLayer =
          isSomeCellLayerToggled &&
          new MVTLayer({
            id: `${geoJSONUrl}-cells`,
            data: '{x}/{y}/{z}',
            coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
            tileSize: 512,
            minZoom: layerSource.minLevel - layerSource.maxLevel,
            maxZoom: 0,
            fetch: fetchData,
            pickable: true,
            filled: true,
            lineWidthMinPixels: 1,
            onClick: function (info) {
              // Get the feature that was clicked on.
              console.debug({ layer: this, info });
            },

            getLineColor: (feature: Feature) => {
              const layerName = feature?.id || (feature as any)?.properties?.class_name;
              const featureSettings = stableGeoJSONLayerFeatureSettings?.[`${geoJSONUrl}-cells-${layerName}`];
              const opacity = featureSettings?.show ? featureSettings?.opacity / 100 : 0;
              return [
                ...hexToRgb(
                  featureSettings?.color?.hex ||
                    featureSettings?.color ||
                    defaultMultiplexChannelColors[
                      (indexOf(layerNames, layerName) + defaultMultiplexChannelColors.length) %
                        defaultMultiplexChannelColors.length
                    ]
                ),
                opacity,
              ];
            },
            getFillColor: (feature: Feature) => {
              const layerName = feature?.id || (feature as any)?.properties?.class_name;
              const featureSettings = stableGeoJSONLayerFeatureSettings?.[`${geoJSONUrl}-cells-${layerName}`];
              const opacity = featureSettings?.show ? featureSettings?.opacity / 100 : 0;
              return [
                ...hexToRgb(
                  featureSettings?.color?.hex ||
                    featureSettings?.color ||
                    defaultMultiplexChannelColors[
                      (indexOf(layerNames, layerName) + defaultMultiplexChannelColors.length) %
                        defaultMultiplexChannelColors.length
                    ]
                ),
                opacity,
              ];
            },
            stroked: true,
            opacity: 0.5,
          });

        const geoJSONPointHeatmapLayers = compact(
          map(layerNames, (layerName) => {
            const featureSettings = stableGeoJSONLayerFeatureSettings?.[`${geoJSONUrl}-heatmap-${layerName}`];
            if (!featureSettings?.selected) {
              return null;
            }
            const data = filter(flatPointsWithFeatures, { className: layerName });
            const opacity = featureSettings?.show ? featureSettings?.opacity / 100 : 0;
            return new ScreenGridLayer<{ coordinates: [number, number]; layerName: string }>({
              id: `${geoJSONUrl}-heatmap-${layerName}`,
              data,
              getPosition: (point) => point.coordinates,
              getWeight: () => 1,
              colorRange: [
                [255, 255, 255, 0],
                slice(
                  hexToRgb(featureSettings?.color?.hex || featureSettings?.color || defaultMultiplexChannelColors[0]),
                  0,
                  3
                ).concat([opacity * 255]) as [number, number, number, number],
              ],
              aggregation: 'SUM',
              gpuAggregation: true,
              cellSizePixels: DIGITAL_ZOOM_FACTOR,
            });
          })
        );
        return [...(tiledGeoJSONLayer ? [tiledGeoJSONLayer] : []), ...geoJSONPointHeatmapLayers];
      }),
    [layerInputs, stableLayerToggleStates, stableGeoJSONLayerFeatureSettings]
  );
};
