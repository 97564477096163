import { Dictionary, flatten, isEmpty, isEqual, keyBy, map, values } from 'lodash';

import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { ChannelMetadata, channelsMetadataFromSlideChannels } from './channelMetadata';
import {
  emptyParsedResults,
  FeatureMetadata,
  getAllFlatMapDeepHeatmaps,
  parseSlideExperimentResults,
} from './featureMetadata';

export interface SlideChannelsAndResults {
  id: string;
  heatmapResults: {
    publishedResults: FeatureMetadata[];
    internalResults: { [key: string]: FeatureMetadata[] };
  };
  featuresResults: {
    publishedResults: FeatureMetadata[];
    internalResults: { [key: string]: FeatureMetadata[] };
  };
  internalHeatmaps: { [key: string]: FeatureMetadata[] };
  channelsMetadata: ChannelMetadata[];
  studyId: string;
}

export interface SlideWithChannelAndResults extends Slide, Partial<Omit<SlideChannelsAndResults, 'id'>> {
  viewerIndex: number;
}

export const getSlidesChannelsAndResults = (
  procedure: Procedure,
  formatBracketsOptions: FormatBracketsOptions
): Dictionary<SlideChannelsAndResults> => {
  return keyBy(
    map(procedure?.slides, (slide) => {
      const { channels, channelMarkerTypes, id, labId, experimentResults } = slide;
      const results = !formatBracketsOptions?.isLoading
        ? parseSlideExperimentResults(experimentResults || [], formatBracketsOptions)
        : emptyParsedResults;

      const channelsMetadata = channelsMetadataFromSlideChannels({
        channels,
        channelMarkerTypes,
        id,
        labId,
      });

      if (!isEmpty(experimentResults) && isEqual(results, emptyParsedResults) && !formatBracketsOptions?.isLoading) {
        console.warn(`Failed to parse results for slide ${id}`, { experimentResults, formatBracketsOptions });
      }

      return {
        id,
        heatmapResults: results.heatmaps,
        internalHeatmaps: results.internalHeatmaps,
        featuresResults: results.features,
        channelsMetadata,
        studyId: procedure.studyId,
      };
    }),
    'id'
  );
};

export const getAllFlatMapDeepHeatmapsFromSlide = (
  slide: Pick<SlideWithChannelAndResults, 'heatmapResults' | 'internalHeatmaps'>
): FeatureMetadata[] => {
  const baseLayerVisualizationSettings = [
    ...(slide?.heatmapResults?.publishedResults || []),
    ...flatten(values(slide?.heatmapResults?.internalResults) || []),
    ...flatten(values(slide?.internalHeatmaps) || []),
  ];
  return getAllFlatMapDeepHeatmaps(baseLayerVisualizationSettings);
};
