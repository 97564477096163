export interface ArtifactResultsConfig {
  resultTypes?: string[];
  filterArtifactsWithTsmOnly?: boolean;
  groupByModel?: boolean;
}

export enum ResultTypes {
  MarkersProbabilities = 'slide_inference_multiplex_markers_probabilities',
  MarkerPositivities = 'multiplex_thresholds',
}
export const BinaryClassifierConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.MarkersProbabilities],
  filterArtifactsWithTsmOnly: false,
};

export const CellTypingConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.MarkerPositivities],
  filterArtifactsWithTsmOnly: false,
  groupByModel: false,
};
