import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid } from '@mui/material';
import {
  ClassificationBinningParams,
  InferenceModelData,
  ModelInference,
  OrchestrationInference,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import { Dictionary, map } from 'lodash';
import React from 'react';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { ModelClassificationById, OrchestrationBySlideByType } from '..';
import InferenceModel from './InferenceModel';

export interface InferenceStainProps {
  studyId: string;
  stain: string;
  stainModelInferences: {
    // model type
    [key: string]: {
      // model url
      [key: string]: ModelInference;
    };
  };
  slides: Dictionary<SlideInferenceResults>;
  selectedOrchestrations: OrchestrationBySlideByType;
  setSelectedOrchestrations: (
    slideIds: string[],
    model: InferenceModelData,
    modelType: string,
    orchestration: OrchestrationInference
  ) => void;
  setModelClassificationByModelId?: (modelId: string, classification: string) => void;
  modelClassificationByModelId?: ModelClassificationById;
  setSelectedOrchestrationsByClassificationType?: (modelType: string, previousModelType?: string) => void;
  setIntensityClassificationBinning?: (modelId: string, binning: ClassificationBinningParams) => void;
}

const InferenceStain: React.FC<React.PropsWithChildren<InferenceStainProps>> = ({
  studyId,
  stain,
  stainModelInferences,
  slides,
  selectedOrchestrations,
  setSelectedOrchestrations,
  modelClassificationByModelId,
  setModelClassificationByModelId,
  setSelectedOrchestrationsByClassificationType,
  setIntensityClassificationBinning,
}) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isLoadingStainTypeOptions ? <CircularProgress /> : stainTypeIdToDisplayName(stain)}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {map(stainModelInferences, (modelInferences, modelType) => {
            return (
              <Grid item xs={12} key={modelType}>
                <InferenceModel
                  key={modelType}
                  stain={stain}
                  modelType={modelType}
                  modelInferences={modelInferences}
                  slides={slides}
                  selectedOrchestrations={selectedOrchestrations}
                  setSelectedOrchestrations={setSelectedOrchestrations}
                  studyId={studyId}
                  modelClassificationByModelId={modelClassificationByModelId}
                  setModelClassificationByModelId={setModelClassificationByModelId}
                  setSelectedOrchestrationsByClassificationType={setSelectedOrchestrationsByClassificationType}
                  setIntensityClassificationBinning={setIntensityClassificationBinning}
                />
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default InferenceStain;
