import { FormControl, FormLabel, TextField } from '@mui/material';
import { TextReviewField } from 'interfaces/reviewForm';
import React, { FunctionComponent } from 'react';

interface FormTextFieldProps {
  field: TextReviewField;
  value: string;
  onChange: (value: string) => void;
}

const FormTextField: FunctionComponent<FormTextFieldProps> = ({ field, value, onChange }) => {
  const isNumerical = field.options?.numerical;

  return (
    <FormControl>
      <FormLabel
        sx={{
          marginBottom: 1,
        }}
      >
        {field.label}
      </FormLabel>
      <TextField
        size="small"
        placeholder={field.options?.placeholder}
        type={isNumerical ? 'number' : 'text'}
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
      />
    </FormControl>
  );
};

export default FormTextField;
