import SearchIcon from '@mui/icons-material/Search';
import {
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  compact,
  concat,
  filter,
  first,
  fromPairs,
  groupBy,
  isEmpty,
  join,
  keys,
  map,
  some,
  sumBy,
  uniq,
} from 'lodash';
import React, { useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';

import { useSignals } from '@preact/signals-react/runtime';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { useSlideLabels } from 'components/StudiesDashboard/StudySettings/StudyLabel/useSlideLabels';
import { ExperimentResultsResultType, FlowClassName } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import { ArrayParam, BooleanParam, useQueryParam } from 'use-query-params';
import { getStainStrWithNegPosControl } from 'utils/helpers';
import { getLabelsWithoutErrorText } from 'utils/qcLabels';
import { usePermissions } from 'utils/usePermissions';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import useStudy from 'utils/useStudy';
import { BaseSlideControl } from './BaseSlideControl';
import Features from './Features/Features';
import Heatmaps from './Heatmaps';
import { GeoJSONTestControl } from './Heatmaps/GeoJSONTest';
import { ProtomapHeatmapControl, ProtomapTileControl } from './Heatmaps/ProtomapTileControl';
import Multiplex from './Multiplex';
import InternalHeatmaps from './Results/InternalHeatmaps';
import SecondaryAnalysisTrigger from './Results/SecondaryAnalysisTrigger';
import SlideResults from './Results/SlideResults';
import SearchBar from './SearchBar';
import SlideAnnotation from './SlideAnnotation';

interface Props {
  slide: SlideWithChannelAndResults;
  slideNumber: number;
  isPlaceholderData: boolean;
  doesCaseHaveMultipleStainResults: boolean;
}

const SlideInfobar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slide,
  slideNumber,
  isPlaceholderData,
  doesCaseHaveMultipleStainResults,
}) => {
  useSignals();
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();
  const [textSearch, setTextSearch] = useState('');
  const [onSearch, setOnSearch] = useState(false);
  const [isPublishMode, setIsPublishMode] = useState(false);

  const { hasPermission } = usePermissions();

  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  // Pending slides mode doesn't allow publishing results since the endpoints currently require a caseId
  const canPublishResults = hasPermission(Permission.PublishResults) && !pendingSlidesMode;

  const canViewUnpublishedResults = hasPermission(Permission.ViewUnpublishedResults);
  const canSeeOrchestrationId = hasPermission(Permission.SeeOrchestrationId);

  const { study } = useStudy(slide.studyId);

  const clipboard = useClipboard({ copiedTimeout: 1000 });

  const toggleSearchMode = (isOnnSearch: boolean) => {
    setOnSearch(isOnnSearch);
  };

  const experimentResultsFeaturesNum = sumBy(slide?.featuresResults?.publishedResults || [], 'numFeatures');

  const internalResultKeys = uniq(
    concat(keys(slide?.heatmapResults?.internalResults || {}), keys(slide?.featuresResults?.internalResults || {}))
  );

  const internalResultPublishStateByKey = fromPairs(
    map(internalResultKeys, (key) => {
      const publishedFeaturesMatchingKey = filter(
        slide?.featuresResults?.publishedResults,
        ({ resultType, flowClassName }) => resultType == key || (!resultType && flowClassName == key)
      );
      const publishedHeatmapsMatchingKey = filter(
        slide?.heatmapResults?.publishedResults,
        ({ resultType, flowClassName }) => resultType == key || (!resultType && flowClassName == key)
      );
      const publishedFeaturesNum = sumBy(filter(publishedFeaturesMatchingKey, 'approved'), 'numFeatures');
      const doesSlideHaveApprovedResults = some(publishedHeatmapsMatchingKey, 'approved') || publishedFeaturesNum > 0;

      const publishedOrchestrationIds = uniq(
        concat(
          map(filter(publishedHeatmapsMatchingKey, 'approved'), 'orchestrationId'),
          map(filter(publishedFeaturesMatchingKey, 'approved'), 'orchestrationId')
        )
      );

      if (publishedOrchestrationIds.length > 1) {
        console.warn(
          `Found multiple published ${key} results for slide ${slide.id} study ID ${slide.studyId}: ${publishedOrchestrationIds}`
        );
      }

      return [key, doesSlideHaveApprovedResults];
    })
  );

  const internalHeatmapsKeys = keys(slide?.internalHeatmaps || {});

  const [pmtTestUrls] = useQueryParam('pmtTestUrl', ArrayParam);
  const [geoJSONTestUrls] = useQueryParam('geoJSONTestUrls', ArrayParam);
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);

  const canViewAnnotations = hasPermission(Permission.ViewAnnotations);
  const { getLabelDisplayName } = useSlideLabels();

  const publishedOrchestrationIds = uniq(
    map(
      compact(
        concat(
          slide?.heatmapResults?.publishedResults,
          slide?.featuresResults?.publishedResults,
          // for now, DeconvSlideProcessor is considered published results although it is not marked as approved
          slide?.heatmapResults?.internalResults?.[FlowClassName.DeconvSlideProcessor]
        )
      ),
      'orchestrationId'
    )
  );

  const publishedCalculateFeaturesOrchestrationIds = uniq(
    map(
      filter(
        slide?.featuresResults?.publishedResults,
        (result) => result.flowClassName == FlowClassName.CalculateFeatures && result.approved
      ),
      'orchestrationId'
    )
  );

  if (publishedCalculateFeaturesOrchestrationIds.length > 1) {
    console.warn(
      `Found multiple published CalculateFeatures results for slide ${slide.id} study ID ${slide.studyId}: ${publishedCalculateFeaturesOrchestrationIds}`
    );
  }

  const { dzi: dziPublishedHeatmaps, pmt: pmtPublishedHeatmaps } = groupBy(
    slide?.heatmapResults?.publishedResults,
    'heatmapType'
  );

  return (
    <Grid container direction="column" mb="20px" spacing={2} flexWrap={'nowrap'}>
      <Grid item mx={1}>
        <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between" sx={{ height: '60px' }}>
          {onSearch ? (
            <Grid item md={12}>
              <SearchBar
                onChange={setTextSearch}
                closeSearchMode={() => {
                  toggleSearchMode(false);
                }}
              />
            </Grid>
          ) : (
            <Grid container wrap="nowrap" direction="column">
              <Grid container item wrap="nowrap" alignItems="center" justifyContent="space-between">
                <Grid item md={6}>
                  <Typography variant="h3" variantMapping={{ h3: 'h2' }}>
                    {isLoadingStainTypeOptions
                      ? `Loading...`
                      : getStainStrWithNegPosControl(
                          stainTypeIdToDisplayName(slide?.stainingType),
                          slide?.negativeControl,
                          slide?.positiveControl
                        )}{' '}
                    ({slideNumber})
                  </Typography>
                </Grid>
                <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-around" md={6}>
                  <Grid item md={8}>
                    {Boolean(slide) && isEmpty(slide?.channelsMetadata) && (
                      <BaseSlideControl
                        slideId={slide.id}
                        viewerIndex={slide.viewerIndex}
                        stainTypeId={slide.stainingType}
                      />
                    )}
                  </Grid>
                  <Grid item md={2}>
                    <IconButton
                      onClick={() => {
                        toggleSearchMode(true);
                      }}
                      color="inherit"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="caption">{slide.originalFileName}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {canPublishResults && (
          <>
            <Grid item container alignItems="center" justifyContent="space-between" pt={1}>
              <Grid item>
                <Typography variant="h3">Results</Typography>
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        onClick={() => {
                          setIsPublishMode((prevIsPublishMode) => !prevIsPublishMode);
                        }}
                      />
                    }
                    label="Publish Mode"
                  />
                  <Divider />
                </FormGroup>
              </Grid>
            </Grid>
            {canSeeOrchestrationId && publishedOrchestrationIds.length > 0 && (
              <Grid item>
                <Tooltip title={clipboard.copied ? 'Copied!' : 'Copy orch_id'} placement="top">
                  <Typography
                    variant="caption"
                    onClick={(e) => {
                      clipboard.copy(join(publishedOrchestrationIds, ', ') as string);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    orch_id: {join(publishedOrchestrationIds, ', ')}
                  </Typography>
                </Tooltip>
              </Grid>
            )}

            {!isEmpty(publishedCalculateFeaturesOrchestrationIds) && (
              <SecondaryAnalysisTrigger
                slideId={slide.id}
                viewerIndex={slide?.viewerIndex}
                orchestrationId={first(publishedCalculateFeaturesOrchestrationIds)}
                hasMultiplePublishedOrchestrationIds={publishedCalculateFeaturesOrchestrationIds.length > 1}
                studyId={slide?.studyId}
              />
            )}
          </>
        )}

        {getLabelsWithoutErrorText(slide.qcLabels || [])?.length > 0 && (
          <Grid item container key={`${slide.id}-qc-labels`} direction="column" spacing={1}>
            <Grid item>
              <Typography variant="subtitle1">Quality Control</Typography>
            </Grid>
            <Grid item container spacing={1}>
              {map(getLabelsWithoutErrorText(slide.qcLabels || []), (labelText) => (
                <Grid item key={`${slide.id}-${labelText}`}>
                  <Chip label={getLabelDisplayName(labelText)} size="small" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* if the slide is QC failed, we don't want to show the results, unless the user has permissions */}
      {(!slide.qcFailed || canViewUnpublishedResults) &&
        (isPlaceholderData ? (
          <Grid item container alignItems="center" mx={1}>
            <Grid item mr={1}>
              <CircularProgress size={24} />
            </Grid>
            <Grid item>Loading results...</Grid>
          </Grid>
        ) : (
          <>
            {!isEmpty(dziPublishedHeatmaps) && (
              <Grid item>
                <Heatmaps
                  hideOrchestrationId
                  viewerIndex={slide.viewerIndex}
                  key="publishedResults"
                  title="Overlays"
                  heatmaps={dziPublishedHeatmaps ?? []}
                  slideId={slide.id}
                  studyId={slide.studyId}
                  stainTypeId={slide?.stainingType}
                  filterText={textSearch}
                  isPublishMode={isPublishMode}
                  expandByDefault
                />
              </Grid>
            )}
            {useDeckGL && !isEmpty(pmtPublishedHeatmaps) && (
              <Grid item>
                <ProtomapHeatmapControl
                  hideOrchestrationId
                  key="publishedResults"
                  title="Vector Overlays"
                  heatmaps={pmtPublishedHeatmaps}
                  slideId={slide?.id}
                  studyId={slide?.studyId}
                  stainTypeId={slide?.stainingType}
                  viewerIndex={slide?.viewerIndex}
                  textSearch={textSearch}
                  isPublishMode={isPublishMode}
                  expandByDefault
                />
              </Grid>
            )}
            {experimentResultsFeaturesNum > 0 && (
              <Grid item>
                <Features
                  highlightedFeatures={study?.highlightedFeatures || []}
                  hiddenFeatures={study?.hiddenFeatures || []}
                  experimentResultIds={map(
                    filter(
                      slide?.featuresResults?.publishedResults,
                      ({ numFeatures, experimentResultId }) => numFeatures > 0 && experimentResultId !== undefined
                    ),
                    'experimentResultId'
                  )}
                  slideStainTypeId={slide?.stainingType}
                  doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
                />
              </Grid>
            )}

            {
              // for now, DeconvSlideProcessor is considered published results although it is not marked as approved
              map(
                internalResultKeys,
                (key) =>
                  !isEmpty(slide?.heatmapResults?.internalResults[key]) &&
                  key == FlowClassName.DeconvSlideProcessor && (
                    <Grid item key={key}>
                      <Heatmaps
                        viewerIndex={slide?.viewerIndex}
                        title={'Synthetic DAB'}
                        heatmaps={slide?.heatmapResults?.internalResults[key]}
                        slideId={slide?.id}
                        studyId={slide?.studyId}
                        stainTypeId={slide?.stainingType}
                        filterText={textSearch}
                        isPublishMode={isPublishMode}
                        expandByDefault
                      />
                    </Grid>
                  )
              )
            }

            {map(internalResultKeys, (key) => {
              const keyHeatmaps = slide?.heatmapResults?.internalResults?.[key];
              const keyFeatures = slide?.featuresResults?.internalResults?.[key];

              const matchingResultTypes = uniq(
                compact(concat(map(keyHeatmaps, 'resultType'), map(keyFeatures, 'resultType')))
              );
              // Use flow class names when result type is not available
              const matchingFlowClassNames = uniq(
                compact(
                  concat(
                    map(keyHeatmaps, ({ resultType, flowClassName }) => (!resultType ? flowClassName : null)),
                    map(keyFeatures, ({ resultType, flowClassName }) => (!resultType ? flowClassName : null))
                  )
                )
              );

              if (
                first(
                  concat<FlowClassName | ExperimentResultsResultType>(matchingFlowClassNames, matchingResultTypes)
                ) !== (key as FlowClassName | ExperimentResultsResultType)
              ) {
                console.warn(
                  `Expected all result types and flow class names to be ${key}, but found ${matchingResultTypes} and ${matchingFlowClassNames}`
                );
              }

              return (
                (!isEmpty(keyHeatmaps) || !isEmpty(keyFeatures)) &&
                // for now, DeconvSlideProcessor is considered published results although it is not marked as approved
                key != FlowClassName.DeconvSlideProcessor && (
                  <Grid item key={key}>
                    <SlideResults
                      slideId={slide.id}
                      viewerIndex={slide.viewerIndex}
                      title={key}
                      heatmaps={keyHeatmaps ?? []}
                      features={keyFeatures ?? []}
                      filterText={textSearch}
                      isPublishMode={isPublishMode}
                      highlightedFeatures={study?.highlightedFeatures || []}
                      hiddenFeatures={study?.hiddenFeatures || []}
                      doesHaveApprovedResultType={internalResultPublishStateByKey[key]}
                      resultTypes={matchingResultTypes}
                      flowClassNames={matchingFlowClassNames}
                      studyId={slide.studyId}
                      doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
                      slideStainTypeId={slide.stainingType}
                    />
                  </Grid>
                )
              );
            })}

            {map(internalHeatmapsKeys, (key) => {
              const internalHeatmaps = slide?.internalHeatmaps?.[key];
              return (
                !isEmpty(internalHeatmaps) && (
                  <Grid item key={key}>
                    <InternalHeatmaps
                      heatmaps={internalHeatmaps}
                      slideId={slide?.id}
                      viewerIndex={slide?.viewerIndex}
                      stainTypeId={slide?.stainingType}
                      title={key}
                      filterText={textSearch}
                    />
                  </Grid>
                )
              );
            })}

            {useDeckGL && !isEmpty(pmtTestUrls) && (
              <Grid item>
                <ProtomapTileControl
                  expandByDefault
                  pmtHeatmaps={map(pmtTestUrls, (url) => ({ id: url, heatmapUrl: url }))}
                  slideId={slide?.id}
                  viewerIndex={slide?.viewerIndex}
                  stainTypeId={slide?.stainingType}
                  textSearch={textSearch}
                />
              </Grid>
            )}
            {useDeckGL && !isEmpty(geoJSONTestUrls) && (
              <Grid item>
                <GeoJSONTestControl
                  geoJSONUrls={geoJSONTestUrls}
                  slideId={slide?.id}
                  viewerIndex={slide?.viewerIndex}
                  stainTypeId={slide?.stainingType}
                  textSearch={textSearch}
                />
              </Grid>
            )}
            {!isEmpty(slide?.channelsMetadata) && (
              <Grid item>
                <Multiplex slide={slide} filterText={textSearch} isPublishMode={isPublishMode} />
              </Grid>
            )}
          </>
        ))}
      {canViewAnnotations && useDeckGL && (
        <>
          <Divider sx={{ borderBottomWidth: 3 }} />
          <Grid item>
            <SlideAnnotation slide={slide} filterText={textSearch} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SlideInfobar;
