import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import React from 'react';

import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  autoGridRowHeight,
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import Loader from 'components/Loader';
import { SlideTag } from 'interfaces/slideTag';
import { slideTagFields } from 'interfaces/slideTag/slideTagFields';
import { uuidv4 } from 'utils/helpers';
import useSlideTagOptions from 'utils/queryHooks/useSlideTagOptions';
import { useSlideTagsColumns } from './useSlideTagsColumns';

const columnVisibilityModel = { id: false };

const generateDraftId = () => `draft-${uuidv4()}`;

const getRowId = (row: any) => row.id ?? generateDraftId();

export const SlideTagsDataGrid = () => {
  const { data: dbSlideTags, isLoading: isLoadingSlideTags } = useSlideTagOptions();
  const [draftSlideTags, setDraftSlideTags] = React.useState<SlideTag[]>([]);
  const slideTags = React.useMemo(() => [...draftSlideTags, ...(dbSlideTags || [])], [dbSlideTags, draftSlideTags]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleAddSlideTag = () => {
    const draftId = generateDraftId();
    const newSlideTag: SlideTag = { tagValue: '', perStudy: false, perExperimentResult: false, id: draftId };
    setDraftSlideTags((oldDraftSlideTags) => [newSlideTag, ...oldDraftSlideTags]);
    setRowModesModel((oldRowModesModel) => ({ ...oldRowModesModel, [newSlideTag.id]: { mode: GridRowModes.Edit } }));
  };

  const apiRef = useGridApiRef();

  const { getRowsWithChanges } = useTableEditingContext<SlideTag>();

  const rowsWithChanges = getRowsWithChanges(slideTags);

  const handleRowModesModelChange: DataGridProps<SlideTag>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) => {
      handleRowModesModelChangeWithoutDraftIds(newRowModesModel, setRowModesModel, draftSlideTags);
    },
    [draftSlideTags]
  );

  const columns = useSlideTagsColumns({
    noRows: isEmpty(rowsWithChanges),
    apiRef,
    rowsWithChanges,
    draftSlideTags,
    rowModesModel,
    setDraftSlideTags,
    setRowModesModel,
  });

  const getSlideTagCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames({
        apiRef,
        requiredFields: ['tagValue'],
        uniqueFieldGroups: [['tagValue']],
        draftRows: draftSlideTags,
        fieldsToCheckForErrors: slideTagFields,
      }),
    [apiRef, draftSlideTags]
  );

  return !isLoadingSlideTags ? (
    <SettingsDataGrid
      apiRef={apiRef}
      addText="Add Slide Tag"
      handleAdd={handleAddSlideTag}
      columnVisibilityModel={columnVisibilityModel}
      getCellClassName={getSlideTagCellClassName}
      rows={rowsWithChanges}
      columns={columns}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      getRowHeight={autoGridRowHeight}
      getRowId={getRowId}
    />
  ) : (
    <Loader />
  );
};
