import { TileJSONLayer } from '@loaders.gl/mvt/dist/lib/parse-tilejson';
import { useSignals } from '@preact/signals-react/runtime';
import { UseQueryResult } from '@tanstack/react-query';
import { find, forEach, isEmpty } from 'lodash';
import { useEffect } from 'react';
import usePrevious from 'utils/usePrevious';
import { PMTilesResult } from 'utils/useProtomapTiles';
import {
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from '../../slidesVisualizationAndConfiguration';
import { computeDefaultPmtLayerSettings, getPmtLayerId } from './ProtomapTileControl';

export const useUpdatePmtHeatmapsSettingsOnChange = ({
  slideId,
  viewerIndex,
  stainTypeId,
  layerIdsToUrlKeys,
  protomapTilesList,
}: {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  layerIdsToUrlKeys?: Record<string, string>;
  protomapTilesList: UseQueryResult<PMTilesResult, unknown>[];
}) => {
  useSignals();
  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const previousSlideParams = usePrevious({ slideId, viewerIndex });
  useEffect(() => {
    if (previousSlideParams?.slideId === slideId && previousSlideParams?.viewerIndex === viewerIndex) {
      return;
    }
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    const changes: Array<{
      layerId: string;
      layerUrlKey?: string;
      newSettings: Partial<LayerVisualizationSettings>;
    }> = [];
    forEach(protomapTilesList, ({ data }) => {
      if (!data) {
        return;
      }
      const { pmtHeatmap, pmtMetadata } = data;
      const heatmapOptions = pmtHeatmap?.nestedItems || [];
      if (slideId && !isEmpty(heatmapOptions)) {
        forEach(heatmapOptions, (heatmapOption, layerIndex) => {
          const pmtLayer = find(pmtMetadata?.tilejson?.layers, { name: heatmapOption.key }) as TileJSONLayer;
          const computedLayerSettings = computeDefaultPmtLayerSettings(pmtHeatmap, pmtMetadata, pmtLayer, layerIndex);
          const layerSettingsKey = getPmtLayerId(pmtHeatmap, pmtMetadata, pmtLayer);
          const previousSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey];
          const layerUrlKey = layerIdsToUrlKeys?.[layerSettingsKey] || layerSettingsKey;
          const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
          changes.push({
            layerId: layerSettingsKey,
            newSettings: {
              ...computedLayerSettings,
              ...previousSettings,
              ...urlSettings,
            },
          });
        });
      }
    });
    applyChangesToSlideLayerVisualizationSettings({ slideId, viewerIndex, changes, skipUrlUpdate: true });
  }, [viewerIndex, slideId, stainTypeId, protomapTilesList, layerIdsToUrlKeys, getLayerSettingsFromUrl]);
};
