import { Grid, Typography } from '@mui/material';
import { GridColDef, GridColumnHeaderParams, GridRowId } from '@mui/x-data-grid';
import { DisplayedField, Field, OnChange } from 'interfaces/genericFields';
import { find, get } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement } from 'react';
import { useTableEditingContext } from '../TableEditingContext';
import { BasicTableRow } from '../TableEditingContext/types';
import HeaderDateInput from './HeaderDateInput';
import HeaderDateRangeInput from './HeaderDateRangeInput';
import HeaderMultiTextInput from './HeaderMultiTextInput';
import HeaderNumericalRangeInput from './HeaderNumericalRangeInput';
import HeaderSelectInput from './HeaderSelectInput';
import Text from './Text';

export interface HeaderCellEditorProps<
  R,
  V = any,
  Context extends {} = {},
  F extends Field = DisplayedField<R, any, Context>
> {
  field: F;
  onChange: OnChange;
  value: any;
}

export type ColumnHeaderWithFieldEditorProps<
  R,
  V,
  Context extends {} = {},
  F extends DisplayedField<R, V, Context> = DisplayedField<R, V, Context>
> = Omit<HeaderCellEditorProps<R, V, Context, F>, 'onChange' | 'value'> & {
  shouldApplyBulkChangesToRow?: (id: GridRowId) => boolean;
  arrayFieldToUnwind?: string | number | symbol;
  context?: Context;
};

const ColumnHeaderWithFieldEditor = <
  R extends BasicTableRow,
  V,
  Context extends {} = {},
  F extends DisplayedField<R, V, Context> = DisplayedField<R, V, Context>
>({
  shouldApplyBulkChangesToRow,
  arrayFieldToUnwind,
  context,
  ...props
}: ColumnHeaderWithFieldEditorProps<R, V, Context, F>): ReactElement => {
  const { bulkChanges, applyBulkUpdates } = useTableEditingContext();

  const field = props.field;
  const isUnwoundField =
    field?.isFieldOfObjectInArray && arrayFieldToUnwind && field.objectArrayPath === arrayFieldToUnwind;

  const { enqueueSnackbar } = useSnackbar();
  const path = isUnwoundField ? field.objectArrayInnerPath : field.updatePath || field.dataKey;

  const [inputValue, setInputValue] = React.useState(null);

  const value = inputValue ?? get(bulkChanges, path);

  const [, startTransition] = React.useTransition();

  const onChange: OnChange = React.useCallback(
    (newValue) => {
      const error = field?.getError && field?.getError?.({ value: newValue, context: context });
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        setInputValue(newValue);

        startTransition(() => {
          applyBulkUpdates(
            { path, value: newValue },
            (rowId) => !shouldApplyBulkChangesToRow || shouldApplyBulkChangesToRow?.(rowId)
          );
          setInputValue(null);
        });
      }
    },
    [applyBulkUpdates, field?.getError, path, shouldApplyBulkChangesToRow, enqueueSnackbar, setInputValue]
  );

  if (props.field.customHeaderEditor) {
    return props.field.customHeaderEditor(context, onChange)({ value, field: props.field.dataKey, bulkChanges });
  }

  if (isUnwoundField) {
    const unwoundField: any = {
      ...props.field,
      cellEditType: props.field.unwoundRowCellEditType,
    };
    switch (unwoundField?.unwoundRowCellEditType) {
      case 'text':
        return <Text {...props} field={unwoundField} value={value} onChange={onChange} />;
      case 'number':
        return <Text {...props} field={unwoundField} value={value} onChange={onChange} fieldType="number" />;
      case 'range':
        return <HeaderNumericalRangeInput {...props} field={unwoundField} value={value} onChange={onChange} />;
      case 'date':
        return <HeaderDateInput {...props} field={unwoundField} value={value} onChange={onChange} />;
      case 'date-range':
        return <HeaderDateRangeInput {...props} field={unwoundField} value={value} onChange={onChange} />;
      case 'multiText':
        // TODO: remove this case once all relevant fields are typed
        return <HeaderMultiTextInput {...props} field={unwoundField} value={value} onChange={onChange} />;
      case 'select':
      case 'multiSelect':
        return <HeaderSelectInput<R, V, any> {...props} field={unwoundField} value={value} onChange={onChange} />;
      default:
        console.error(`Unknown edit type field: ${props.field?.unwoundRowCellEditType}`);
        return null;
    }
  } else {
    switch (props.field?.cellEditType) {
      case 'text':
        return <Text {...props} field={props.field} value={value} onChange={onChange} />;
      case 'number':
        return <Text {...props} field={props.field} value={value} onChange={onChange} fieldType="number" />;
      case 'range':
        return <HeaderNumericalRangeInput {...props} field={props.field} value={value} onChange={onChange} />;
      case 'date':
        return <HeaderDateInput {...props} field={props.field} value={value} onChange={onChange} />;
      case 'date-range':
        return <HeaderDateRangeInput {...props} field={props.field} value={value} onChange={onChange} />;
      case 'multiText':
        // TODO: remove this case once all relevant fields are typed
        return <HeaderMultiTextInput {...props} field={props.field} value={value} onChange={onChange} />;
      case 'select':
      case 'multiSelect':
        return <HeaderSelectInput<R, V, any> {...props} field={props.field} value={value} onChange={onChange} />;
      default:
        console.error(
          `Unknown edit type field: ${
            isUnwoundField
              ? props.field?.unwoundRowCellEditType ?? props.field?.cellEditType
              : props.field?.cellEditType
          }`
        );
        return null;
    }
  }
};

export interface ColumnHeaderWithEditorRendererProps<R, V, Context extends {} = {}> extends GridColumnHeaderParams<R> {
  fieldOptions?: Array<DisplayedField<R, V, Context>>;
  arrayFieldToUnwind?: keyof R;
  renderHeader?: GridColDef<R>['renderHeader'];
  shouldApplyBulkChangesToRow?: (id: GridRowId) => boolean;
  context?: Context;
}

export const BulkEditField = <R extends BasicTableRow, V, Context extends {} = {}>({
  customHeader,
  headerName,
  field,
  shouldApplyBulkChangesToRow,
  arrayFieldToUnwind,
  context,
}: {
  customHeader?: React.ReactNode;
  headerName?: string;
  field: DisplayedField<R, V, Context>;
  shouldApplyBulkChangesToRow?: (id: GridRowId) => boolean;
  arrayFieldToUnwind?: keyof R;
  context?: Context;
}) => {
  const hasUnwoundCellEditType =
    (field?.unwoundRowCellEditType ?? field?.cellEditType) &&
    (field?.unwoundRowCellEditType ?? field?.cellEditType) !== 'custom';

  const shouldRenderField = Boolean(
    (field?.cellEditType && !field?.isFieldOfObjectInArray) || (hasUnwoundCellEditType && Boolean(arrayFieldToUnwind))
  );

  return (
    <Grid container height={112} columns={1} gridRow={2} sx={{ maxWidth: '100%' }} alignItems="center">
      {customHeader || <Typography sx={{ fontSize: 'unset' }}>{headerName || field.label}</Typography>}
      <Grid item xs={1} height={'50%'}>
        {shouldRenderField ? (
          <ColumnHeaderWithFieldEditor<R, V, Context, DisplayedField<R, V, Context>>
            field={field as any}
            arrayFieldToUnwind={arrayFieldToUnwind}
            shouldApplyBulkChangesToRow={shouldApplyBulkChangesToRow}
            context={context}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export const ColumnHeaderWithEditorRenderer = <R extends BasicTableRow, V, Context extends {} = {}>({
  fieldOptions,
  renderHeader,
  arrayFieldToUnwind,
  shouldApplyBulkChangesToRow,
  context,
  ...props
}: ColumnHeaderWithEditorRendererProps<R, V, Context>) => {
  const colDef = props.colDef;

  const field = React.useMemo(
    () => find(fieldOptions, ({ label, dataKey }) => label === colDef.headerName && dataKey === colDef.field),
    [colDef]
  );

  const renderedHeader = renderHeader?.(props);

  return (
    <BulkEditField
      customHeader={
        renderedHeader && typeof renderedHeader !== 'string' ? (
          renderHeader(props)
        ) : (
          <Typography sx={{ fontSize: 'unset' }}>{renderedHeader || colDef.headerName}</Typography>
        )
      }
      headerName={colDef.headerName}
      field={field}
      shouldApplyBulkChangesToRow={shouldApplyBulkChangesToRow}
      arrayFieldToUnwind={arrayFieldToUnwind}
      context={context}
    />
  );
};
