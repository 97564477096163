import { Job, JobResponse } from 'interfaces/job';
import { stringify } from 'qs';
import { BooleanParam, JsonParam, NumberParam, StringParam, encodeQueryParams } from 'use-query-params';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getJobs = (
  params: {
    filters: {
      studyId: string;
      jobName?: string;
      orchestrationId?: string;
      users?: Record<string, string>[];
      senders?: string[];
      statuses?: string;
    };
    labId: string;
    page?: number;
    fullData?: boolean;
  },
  signal?: AbortSignal
) => {
  const stringParams = stringify(
    encodeQueryParams({ filters: JsonParam, labId: StringParam, page: NumberParam, fullData: BooleanParam }, params)
  );

  return apiRequestHandlerPromise<JobResponse>({
    url: `jobs?${stringParams}`,
    method: 'GET',
    signal,
  });
};

export const getJob = (jobId: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Job>({
    url: `jobs/${jobId}`,
    method: 'GET',
    signal,
  });
};

export const cancelJob = (jobId: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Job>({
    url: `jobs/${jobId}/cancel`,
    method: 'POST',
    signal,
  });
}
