import { AreaType } from 'interfaces/areaType';
import { ClassificationModel } from 'interfaces/classificationModel';
import { ClusterType } from 'interfaces/clusterType';
import { StainType } from 'interfaces/stainType';
import {
  find,
  first,
  flatMap,
  forEach,
  fromPairs,
  includes,
  isArray,
  isEmpty,
  join,
  keys,
  map,
  merge,
  mergeWith,
  omit,
  orderBy,
  replace,
  slice,
  split,
  startsWith,
  tail,
  toString,
  values,
} from 'lodash';
import { FormatBracketsOptions } from './formatBracketsOptions';

export const bracketsRegex = /[<>]+/;
const separatorRegex = /[_-]/g;

export const isBrackets = (key: string) => {
  return startsWith(key, '<');
};

const getTumorClassificationDisplayName = (tumorClassification: string) => {
  return replace(tumorClassification, 'tumor_cell-', '');
};

const intensityNumbersOnTheEndRegex = /\d+$/;
const getIntensityClassificationDisplayName = (intensityClassification: string) => {
  const classificationIntensities: string[] = [];
  const intensityClassificationParts = split(intensityClassification, '|');

  // Support backwards compatibility for intensity classifier (intensity=tumor_cell-intensity1) - take only the last digits

  forEach(intensityClassificationParts, (intensityClassificationPart) => {
    const intensityNumber = intensityNumbersOnTheEndRegex.exec(intensityClassificationPart);
    if (first(intensityNumber)) classificationIntensities.push(first(intensityNumber));
  });

  return join(classificationIntensities, ' and ');
};

const createClassifiers = (classifiers: unknown) => {
  const classifiersDict: Record<string, any> = {};
  classifiersDict['stains'] = {};
  if (isArray(classifiers)) {
    forEach(classifiers, (classifier: string) => {
      const [key, value] = split(classifier, '=');
      if (value === 'False' || value === 'True') classifiersDict['stains'][key] = value;
      else if (key === 'intensity') classifiersDict[key] = getIntensityClassificationDisplayName(value);
      else if (key === 'membrane') classifiersDict[key] = includes(value, 'membranous-partial') ? 'partial' : 'full';
      else if (key === 'tumor_classification') {
        const tumorClassificationDisplayName = getTumorClassificationDisplayName(value);
        if (includes(tumorStates, tumorClassificationDisplayName)) {
          classifiersDict['state'] = tumorClassificationDisplayName;
        } else if (includes(tumorTypes, tumorClassificationDisplayName)) {
          classifiersDict['type'] = tumorClassificationDisplayName;
        } else classifiersDict['tumor classification'] = tumorClassificationDisplayName;
      } else classifiersDict[key] = value;
    });
  }

  return classifiersDict;
};

export const dealWithClassifiers = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      dealWithClassifiers(obj[key]);
      if (key === 'c') {
        const value = obj[key];
        if (typeof value === 'object' && first(keys(value)) !== 'subset' && first(keys(value)) !== 'superset') {
          obj[key] = first(keys(value));
          obj['classifiers'] = createClassifiers(first(values(value)));
        }
      }
    }
  }
};

const createRecursiveObject = (array: string[]) => {
  const result: Record<string, any> = {};
  const arrayLength = array?.length ?? 0;
  const firstArrayElement = first(array);
  if (arrayLength === 1) {
    result[firstArrayElement] = true;
    return result;
  }
  if (arrayLength === 2) {
    result[firstArrayElement] = array[1];
    return result;
  }

  //c has classifiers
  if (firstArrayElement === 'c' && arrayLength > 2) {
    result[firstArrayElement] = { [array[1]]: slice(array, 2) };
    return result;
  }

  const [key, ...remaining] = isArray(array) ? array : [];
  result[key] = createRecursiveObject(remaining);
  return result;
};

const customizer = (objValue: string, srcValue: string) => {
  if (objValue) {
    return flatMap([objValue, srcValue]);
  } else return srcValue;
};

export const getDictBrackets = (featureParts: string[]) => {
  let dictBrackets: Record<string, any> = {};
  forEach(featureParts, (featurePart: string) => {
    const featurePartsList: string[] = split(featurePart, ':');

    if (first(featurePartsList) === 'sources_s') {
      dictBrackets = mergeWith(dictBrackets, createRecursiveObject(featurePartsList), customizer);
    } else {
      dictBrackets = merge(dictBrackets, createRecursiveObject(featurePartsList));
    }
  });

  dealWithClassifiers(dictBrackets);
  return dictBrackets;
};

export const buildClassifiersString = (
  classifiers: any,
  bracketTypesOptions: FormatBracketsOptions,
  additionalOptions?: { withParenthesis: boolean; isMarkerPositivity: boolean }
) => {
  const { withParenthesis, isMarkerPositivity } = additionalOptions || {
    withParenthesis: true,
    isMarkerPositivity: false,
  };

  let classifiersString = '';
  let classifiersStainString = '';
  let classifierClusterIdString = '';

  if (!isEmpty(classifiers)) {
    const stains = classifiers['stains'];
    let otherClassifiers = omit(classifiers, 'stains');
    if (!isEmpty(stains)) {
      classifiersStainString = withParenthesis ? '(' : '';
      for (const stainKey in stains) {
        const stainValue = stains[stainKey];
        const stainKeyFormatted = getFormattedStain(stainKey, bracketTypesOptions.stainTypeOptions);
        const classifiersStainSign = isMarkerPositivity ? '' : stainValue === 'True' ? '+' : '-';
        classifiersStainString += `${stainKeyFormatted}${classifiersStainSign}, `;
      }
      classifiersStainString = classifiersStainString.slice(0, -2);
      classifiersStainString += withParenthesis ? ') ' : ' ';
    }

    if (classifiers['cluster_id']) {
      classifierClusterIdString = withParenthesis ? '(' : '';
      classifierClusterIdString += getFormattedCluster(
        classifiers['cluster_id'],
        bracketTypesOptions.clusterTypeOptions
      );
      classifierClusterIdString += withParenthesis ? ') ' : ' ';

      otherClassifiers = omit(otherClassifiers, 'cluster_id');
    }

    if (!isEmpty(otherClassifiers)) {
      classifiersString = withParenthesis ? '(' : '';
      for (const classifierKey in otherClassifiers) {
        const classifierValue = otherClassifiers[classifierKey];
        const classifierKeyFormatted = getFormattedClassificationModels(
          classifierKey,
          bracketTypesOptions.classificationModelOptions
        );
        classifiersString += `${classifierKeyFormatted}: ${classifierValue}, `;
      }
      classifiersString = classifiersString.slice(0, -2);
      classifiersString += withParenthesis ? ') ' : ' ';
    }
  }

  return `${classifiersStainString}${classifierClusterIdString}${classifiersString}`;
};

export const applyStainTypeIfRequired = (
  formattedFeatureDisplayName: string,
  stainIndex: string,
  options: FormatBracketsOptions
) => {
  if (!options.addStain) {
    return formattedFeatureDisplayName;
  }
  return `${getFormattedStain(stainIndex, options.stainTypeOptions)} ${formattedFeatureDisplayName}`;
};

export const getFeatureNameBrackets = (key: string) => {
  const featureParts = slice(split(key, bracketsRegex), 1, -1);
  return getDictBrackets(featureParts);
};

export const getGridBasedString = (dictBrackets: any) => {
  const gridBased = { startWith: '', endWith: '', threshold: '' };
  if (dictBrackets?.grid_based) {
    if (dictBrackets?.reduction && dictBrackets?.reduction !== 'None') {
      let reduction = dictBrackets?.reduction;
      if (dictBrackets?.reduction === 'fraction_above_threshold') {
        reduction = 'percent';
      }
      gridBased.startWith = `${reduction} of `;
    }
    if (dictBrackets?.grid_size) gridBased.endWith = ` (grid size: ${dictBrackets?.grid_size} um)`;

    if (dictBrackets?.reduction_threshold) {
      gridBased.threshold = ` above ${dictBrackets?.reduction_threshold}`;
    }
  }
  return gridBased;
};

export const getStatistic = (statistic: string) => {
  return statistic ? `(${getFormattedText(statistic)}) ` : '';
};

const getPositiveOrNegativeSign = (cellType: string) => {
  return includes(cellType, 'positive') ? '+' : '-';
};

const positiveOrNegativeRegex = / (positive|negative)/g;
export const getFormattedCellWithoutPositiveOrNegative = (cellType: string) => {
  return replace(getFormattedCell(cellType), positiveOrNegativeRegex, '');
};

export const getFormattedCell = (cellType: string) => {
  return replace(cellType, separatorRegex, ' ');
};

const isPositiveOrNegative = (cellType: string) => {
  return includes(cellType, 'positive') || includes(cellType, 'negative') || includes(cellType, 'other');
};

export const getFormattedCellWithStain = (cellType: string, stainType: string, stainTypeOptions: StainType[]) => {
  const cellTypes = orderBy(split(cellType, '|'));
  const formattedCellWithStain: string[] = [];
  const stainToDisplay = isEmpty(stainType) || stainType === '1' || stainType === '2' ? false : true;

  if (stainToDisplay) {
    forEach(cellTypes, (currentCellType) => {
      const stain = isPositiveOrNegative(currentCellType)
        ? ` (${getFormattedStain(stainType, stainTypeOptions)}${getPositiveOrNegativeSign(currentCellType)})`
        : '';
      formattedCellWithStain.push(`${getFormattedCellWithoutPositiveOrNegative(currentCellType)}${stain}`);
    });
  } else if (isEmpty(stainType)) {
    cellTypes.forEach((currentCellType) => {
      formattedCellWithStain.push(`${getFormattedCell(currentCellType)}`);
    });
  } else {
    cellTypes.forEach((currentCellType) => {
      formattedCellWithStain.push(`${getFormattedCellWithoutPositiveOrNegative(currentCellType)}`);
    });
  }

  return join(formattedCellWithStain, ' and ');
};

export const getFormattedStain = (stainKey: string, stainTypeOptions: StainType[]) => {
  const stainIndex = fromPairs(
    map(stainTypeOptions, (stainTypeOption) => [stainTypeOption.index, stainTypeOption.displayName])
  );
  const stainId = fromPairs(
    map(stainTypeOptions, (stainTypeOption) => [stainTypeOption.id, stainTypeOption.displayName])
  );

  return stainIndex[stainKey] || stainId[stainKey] || replace(stainKey, separatorRegex, ' ');
};

export const getFormattedCluster = (id: string, clusterTypeOptions: ClusterType[]) => {
  const cluster = find(clusterTypeOptions, (clusterTypeOption) => toString(clusterTypeOption.id) === id);
  return cluster?.displayName || `cluster id: ${id}`;
};

export const getFormattedArea = (index: string, areaTypeOptions: AreaType[]) => {
  const a = find(areaTypeOptions, (areaTypeOption) => toString(areaTypeOption.index) === index);
  return a?.displayName || replace(index, separatorRegex, ' ');
};

export const getFormattedClassificationModels = (id: string, classificationModelOptions: ClassificationModel[]) => {
  const classificationModel = find(
    classificationModelOptions,
    (classificationModelOption) => classificationModelOption.id === id
  );
  return classificationModel?.displayName || replace(id, separatorRegex, ' ');
};

const getFormattedTextRegex = /[_]/g;
export const getFormattedText = (text: string) => {
  return replace(text, getFormattedTextRegex, ' ');
};

const gridSizeUnits = ['um'];

const uppercaseRegex = /[A-Z]/;
const wordWithoutSymbolsRegex = /[-_)()]/g;
const noNeedToUppercase = (word: string) => {
  const wordWithoutSymbols = replace(word, wordWithoutSymbolsRegex, '');
  return uppercaseRegex.test(word) || includes(gridSizeUnits, wordWithoutSymbols);
};

// upper the first letter you find, ignore the rest
const firstLetterRegex = /[a-zA-Z]/;
const upperFirstLetter = (word: string) => {
  let found = false;
  return join(
    map(word, (char) => {
      if (!found && firstLetterRegex.test(char)) {
        found = true;
        return char.toUpperCase();
      } else {
        return char;
      }
    }),
    ''
  );
};

export const formatWord = (word: string) => {
  if (noNeedToUppercase(word)) {
    return word;
  } else {
    return upperFirstLetter(word);
  }
};

export const capitalization = (sentence: string) => {
  const arraySentence = split(sentence, ' ');
  return formatWord(first(arraySentence)) + ' ' + join(tail(arraySentence), ' ');
};

export const titleCase = (title: string) => {
  return join(
    map(split(title, ' '), (word) => {
      return formatWord(word);
    }),
    ' '
  );
};

const tumorStates = ['apoptotic', 'mitotic'];
const tumorTypes = ['immunoblast', 'centroblast'];
