import { useSignals } from '@preact/signals-react/runtime';
import { compact, filter, map, omit, uniqBy } from 'lodash';
import { ArrayParam, useQueryParam } from 'use-query-params';

import { getPmtHeatmapId } from 'components/Procedure/Infobar/SlideInfobar/Heatmaps/ProtomapTileControl';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import {
  SlideWithChannelAndResults,
  getAllFlatMapDeepHeatmapsFromSlide,
} from 'components/Procedure/useSlideChannelsAndResults/utils';
import { useProtomapTilesList } from 'utils/useProtomapTiles';
import { deckGLPMTLayer } from './deckGLPMTLayer';

export const usePmtLayers = ({
  slide,
  maxLevel,
  idPrefix,
  rescaleFactor,
}: {
  slide: SlideWithChannelAndResults;
  maxLevel: number;
  idPrefix: string;
  rescaleFactor?: number;
}) => {
  useSignals();
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings.value?.[slide.id];

  const pmtHeatmaps = filter(
    map(getAllFlatMapDeepHeatmapsFromSlide(slide), (heatmap) => ({
      slideInfo: omit(slide, 'viewerIndex'),
      ...heatmap,
    })),
    ({ heatmapUrl, heatmapType }) => Boolean(heatmapUrl) && heatmapType === 'pmt'
  );
  const pmtTilesList = useProtomapTilesList(pmtHeatmaps);
  const pmtLayersData = uniqBy(compact(map(pmtTilesList, 'data')), ({ pmtHeatmap, pmtMetadata }) =>
    getPmtHeatmapId(pmtHeatmap, pmtMetadata)
  );
  const pmtLayers = map(pmtLayersData, ({ pmtHeatmap, pmtMetadata, pmtTileSource }) =>
    deckGLPMTLayer({
      idPrefix: `${idPrefix}-${slide.id}`,
      slideLayerVisualizationSettings,
      pmtHeatmap,
      pmtMetadata,
      pmtTileSource,
      maxLevel,
      rescaleFactor,
    })
  );

  // https://app.nucleai.cloud/#/procedures/5766?labId=f6493444-7801-11ea-99c1-0a27be703864&pmtTestUrl=http%3A%2F%2F127.0.0.1%3A8081%2F1e3f251c-a808-11eb-b9eb-0a27be703864-areas.pmtiles&useDeckGL=1
  const [pmtTestUrls] = useQueryParam('pmtTestUrl', ArrayParam);
  const pmtTestTilesList = useProtomapTilesList(map(pmtTestUrls, (url) => ({ id: url, url, dynamicUrl: url })));
  const pmtTestLayersData = uniqBy(compact(map(pmtTestTilesList, 'data')), ({ pmtHeatmap, pmtMetadata }) =>
    getPmtHeatmapId(pmtHeatmap, pmtMetadata)
  );
  const pmtTestLayers = map(pmtTestLayersData, ({ pmtHeatmap, pmtMetadata, pmtTileSource }) =>
    deckGLPMTLayer({
      idPrefix: `test-slide-layer-${slide.id}`,
      slideLayerVisualizationSettings,
      pmtHeatmap,
      pmtMetadata,
      pmtTileSource,
      maxLevel,
      rescaleFactor,
    })
  );

  return compact([...pmtLayers, ...pmtTestLayers]);
};
