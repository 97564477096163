import { useSignals } from '@preact/signals-react/runtime';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BooleanParam, StringParam, UrlUpdateType, useQueryParam } from 'use-query-params';

import Loader from 'components/Loader';
import { Accession } from 'interfaces/accession';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import setPreferences from 'redux/actions/preferences';
import { useAppSelector } from 'redux/hooks';
import { Point } from 'utils/slideTransformation';
import { usePermissions } from 'utils/usePermissions';
import Header from './Header';
import NotFoundMessage from './NotFoundMessage';
import SlidesViewer from './SlidesViewer/SlidesViewer';
import { SlideWithChannelAndResults } from './useSlideChannelsAndResults/utils';
import { useSlideImages } from './useSlideImages';
import { useSlideMagnification } from './useSlideMagnification';

interface CasePageBodyProps {
  procedure: Procedure | Accession;
  isAccessionViewer: boolean;
  isPlaceholderData?: boolean;
  selectedSlides: SlideWithChannelAndResults[];
  selectedSlideIds: string[];
  setSelectedSlideIds: (newSetSelectedSlideIds: string[], updateType?: UrlUpdateType) => void;
}

export const CasePageBody: React.FunctionComponent<React.PropsWithChildren<CasePageBodyProps>> = ({
  procedure,
  isPlaceholderData,
  isAccessionViewer,
  selectedSlides,
  selectedSlideIds,
  setSelectedSlideIds,
}) => {
  useSignals();
  const dispatch = useDispatch();
  const [fieldOfView, setFieldOfView] = useState(null);

  const { magnificationValue, handleMagnificationChangedInHeader, updateMagnificationFromZoom } =
    useSlideMagnification(selectedSlides);

  const { showNavigator } = useAppSelector((state) => state.preferences);
  const [showNavigation, setShowNavigation] = useState(
    showNavigator && !procedure.presentationInfo?.navigatorOffByDefault
  );

  const { hasPermission } = usePermissions();
  const publishResults = hasPermission(Permission.PublishResults);

  const onUnmountViewportBounds = React.useCallback(
    (center: Point, zoom: number) => !isAccessionViewer && setFieldOfView({ center, zoom }),
    [isAccessionViewer, setFieldOfView]
  );

  const [slideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);
  const [slideTagsDrawerOpen] = useQueryParam('slideTagsDrawerOpen', BooleanParam);
  const [fullscreenViewer] = useQueryParam('fullscreenViewer', BooleanParam);

  const {
    slidesBaseImagePyramids,
    slidesHeatmapsImagePyramids,
    baseSlideLoadingStates,
    error: slideImagesError,
    canLoadSlides,
    isLoadingSlides,
  } = useSlideImages(selectedSlides, isPlaceholderData);

  /* Slide registration cannot occur with placeholder data, so we display a loader */
  const isLoadingRegistration = isLoadingSlides && isPlaceholderData && selectedSlides?.length > 1;

  return (
    <>
      {!fullscreenViewer && (
        <Header
          selectedSlideIds={selectedSlideIds}
          setSelectedSlideIds={setSelectedSlideIds}
          procedure={procedure}
          showNavigation={showNavigation}
          onChangeNavigationVisibility={setShowNavigation}
          isAccession={isAccessionViewer}
          isPlaceholderData={isPlaceholderData}
          magnificationValue={magnificationValue}
          onChangeMagnification={handleMagnificationChangedInHeader}
          onShowNavigatorChange={(show: boolean) => dispatch(setPreferences(show))}
        />
      )}
      {!isLoadingRegistration && (slideImagesError || (!isPlaceholderData && !canLoadSlides) || !procedure) ? (
        <NotFoundMessage hideHeader />
      ) : /* Slide registration cannot occur with placeholder data, so we display a loader */
      isLoadingRegistration ? (
        <Loader />
      ) : (
        <SlidesViewer
          procedureId={procedure.id}
          slides={selectedSlides}
          slidesBaseImagePyramids={slidesBaseImagePyramids}
          slidesHeatmapsImagePyramids={slidesHeatmapsImagePyramids}
          baseSlideLoadingStates={baseSlideLoadingStates}
          showNavigation={showNavigation}
          magnificationValue={magnificationValue}
          displaySlideId={publishResults}
          hideComments={isAccessionViewer}
          updateMagnificationFromZoom={updateMagnificationFromZoom}
          unmountViewportBounds={onUnmountViewportBounds}
          fieldOfView={fieldOfView}
          focusedSlideId={slideTagsDrawerOpen ? slideIdForReviewing : undefined}
        />
      )}
    </>
  );
};
