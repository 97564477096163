import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, ListSubheader, MenuItem, Tooltip } from '@mui/material';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { grey } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { forEach, isEmpty } from 'lodash';
import * as React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 2;
const NUMBER_OF_ITEMS_TO_SHOW = 8;
const ROW_HEIGHT_SMALL = 30;
const ROW_HEIGHT_LARGE = 40;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface VirtualizedAutocompleteOption {
  key: string;
  displayName: string;
  customNode?: React.ReactNode;
  isCheckbox?: boolean;
  checked?: boolean;
}

interface VirtualizedAutocompleteRenderOptionParams {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: VirtualizedAutocompleteOption;
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet: VirtualizedAutocompleteRenderOptionParams | AutocompleteRenderGroupParams = data[index];
  const inlineStyle: React.CSSProperties = {
    ...style,
    top: ((style.top as number) ?? 0) + LISTBOX_PADDING,
  };

  // eslint-disable-next-line no-prototype-builtins
  if (dataSet.hasOwnProperty('group')) {
    const groupInlineStyle: React.CSSProperties = {
      ...inlineStyle,
      backgroundColor: grey[100],
      display: 'flex',
      alignItems: 'center',
    };

    const renderGroupParams = dataSet as AutocompleteRenderGroupParams;
    return (
      <ListSubheader key={renderGroupParams.key} component="div" style={groupInlineStyle}>
        <span>{renderGroupParams.group}</span>
      </ListSubheader>
    );
  } else {
    const renderOptionParams: VirtualizedAutocompleteRenderOptionParams =
      dataSet as VirtualizedAutocompleteRenderOptionParams;
    const option = renderOptionParams.option;

    return (
      <Tooltip
        key={option.key}
        title={option.displayName}
        placement="left"
        enterDelay={500}
        enterNextDelay={500}
        leaveDelay={0}
        arrow
      >
        <MenuItem key={option.key} {...renderOptionParams.props} style={inlineStyle}>
          {option.isCheckbox && (
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={option.checked} />
          )}
          {option.displayName}
          {option.customNode}
        </MenuItem>
      </Tooltip>
    );
  }
}
// this code is taken from the mui docs - https://mui.com/material-ui/react-autocomplete/ - Virtualization section
// will be replaced when refactoring to use virtuoso
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const listBoxFromHeight = (rowHeightSmall: number, rowHeightLarge: number, rowHeightGroup: number) =>
  React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    forEach(children as React.ReactChild[], (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? rowHeightSmall : rowHeightLarge;

    const getChildSize = (child: React.ReactChild) => {
      // eslint-disable-next-line no-prototype-builtins
      if (child.hasOwnProperty('group')) {
        return rowHeightGroup;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > NUMBER_OF_ITEMS_TO_SHOW) {
        return NUMBER_OF_ITEMS_TO_SHOW * itemSize;
      }
      if (itemCount === 0) {
        return rowHeightSmall; // for displaying the no options message
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index: number) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount === 0 ? 1 : itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

const ListboxComponentDefaultHeight = listBoxFromHeight(ROW_HEIGHT_SMALL, ROW_HEIGHT_LARGE, ROW_HEIGHT_SMALL);

const StyledPopper = styled(Popper)({
  offset: 5,
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export const useVirtualizeAutocomplete = () => {
  return { ListboxComponent: ListboxComponentDefaultHeight, StyledPopper };
};

interface VirtualizedAutocompleteProps<
  T,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<T, false, DisableClearable, FreeSolo>,
    'ListboxComponent' | 'PopperComponent' | 'disableListWrap' | 'renderOption' | 'renderGroup' | 'multiple'
  > {
  renderOption: (option: T, state?: AutocompleteRenderOptionState) => VirtualizedAutocompleteOption;
  itemHeight?: number;
}

export function VirtualizedAutocomplete<
  T,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({ renderOption, itemHeight, ...props }: VirtualizedAutocompleteProps<T, DisableClearable, FreeSolo>) {
  const ListboxComponent = React.useMemo(
    () => (itemHeight ? listBoxFromHeight(itemHeight, itemHeight, ROW_HEIGHT_SMALL) : ListboxComponentDefaultHeight),
    [itemHeight]
  );

  const hasOptions = !isEmpty(props.options);

  if (!hasOptions) {
    return <Autocomplete {...(props as any)} freeSolo={false} />;
  }

  return (
    <Autocomplete
      {...props}
      ListboxComponent={ListboxComponent}
      PopperComponent={StyledPopper}
      disableListWrap
      renderOption={(renderOptionProps, option, state) => {
        const renderedOption: VirtualizedAutocompleteRenderOptionParams = {
          props: renderOptionProps,
          option: renderOption(option, state),
        };
        return renderedOption as unknown as React.ReactNode;
      }}
      renderGroup={(params: AutocompleteRenderGroupParams) => params as unknown as React.ReactNode}
    />
  );
}
