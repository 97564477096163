import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { MainPageFilterKey } from 'interfaces/cohort';
import { getCancerSubTypes, useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { includes, isEmpty } from 'lodash';
import React from 'react';
import { Approval } from 'services/filters';
import OrchestrationIdAutoComplete from '../AdvancedSearch/OrchestrationIdAutoComplete';
import CancerSubtypesAutocomplete from '../CancerSubtypesField';
import useMainFilters from '../hooks/useMainFilters';
import useOrchestrationFilter from '../hooks/useOrchestrationFilter';
import useSearchFiltersFromQueryParams from '../hooks/useSearchFiltersFromQueryParams';
import AutocompleteFilter from './AutocompleteFilter';
import FullSearch from './FullSearch';

const autocompleteFilterKeys = [MainPageFilterKey.cancerTypes, MainPageFilterKey.stains];

interface Props {
  filterKey: string;
  filterValue: any;
  onChange: (key: string, value: any) => void;
  controlledValues?: Record<string, any>;
  label?: string;
  onTextFieldChange?: () => void;
  handleApprovalFieldChange?: (value: Approval) => void;
  enableQueries?: boolean;
}

const MainFilter = ({ filterKey, filterValue, onChange, controlledValues, label, enableQueries }: Props) => {
  const { queryParams } = useSearchFiltersFromQueryParams();

  const isDrawer = queryParams.advancedSearchDrawerOpen;

  const {
    stainsOptions,
    allStudyOptions,
    cancerTypeViewOptions,
    cancerTypesLoading,
    isLoadingStainTypeOptions,
    studiesLoading,
  } = useMainFilters();

  const { allCancerTypes, cancerSubtypes } = useProceduresFieldsContext();

  const { orchestrationIdOptions, orchestrationsLoading } = useOrchestrationFilter(
    queryParams.filters?.studyId,
    queryParams.resultsMode
  );

  const cancerTypesFromQueryParams = queryParams.filters?.cancerTypes || [];

  const cancerTypes: number[] = isDrawer ? controlledValues?.cancerTypes : cancerTypesFromQueryParams;

  const { relevantSubtypes, mainCancerTypeCodes } = getCancerSubTypes(cancerTypes, allCancerTypes, cancerSubtypes);

  const studyId = queryParams.filters?.studyId || 0;

  if (filterKey === MainPageFilterKey.searchTerm) {
    return (
      <FullSearch
        currentStudyId={studyId}
        enabled={enableQueries}
        controlledValue={controlledValues['searchTerm']}
        isDrawer={isDrawer}
      />
    );
  }

  if (filterKey === MainPageFilterKey.studyId) {
    return (
      <LabelledDropdown
        size="small"
        key="studyId"
        label={label}
        options={allStudyOptions}
        onOptionSelected={(optionValue) => {
          onChange(filterKey, optionValue);
        }}
        loading={studiesLoading}
        value={studiesLoading ? '' : filterValue}
      />
    );
  }

  if (includes(autocompleteFilterKeys, filterKey)) {
    return (
      <AutocompleteFilter
        label={label}
        options={filterKey === 'cancerTypes' ? cancerTypeViewOptions : stainsOptions}
        limitTags={2}
        handleChange={(value) => onChange(filterKey, value)}
        value={filterValue}
        loading={filterKey === 'cancerTypes' ? cancerTypesLoading : isLoadingStainTypeOptions}
      />
    );
  }

  if (filterKey === 'cancerSubtypes') {
    return (
      <CancerSubtypesAutocomplete
        multiple
        disabled={isEmpty(mainCancerTypeCodes)}
        subTree={relevantSubtypes}
        mainCancers={mainCancerTypeCodes}
        limitTags={isDrawer ? 5 : 1}
        labelMaxWidth={isDrawer ? '160px' : '90px'}
        handleChange={onChange.bind(null, 'cancerSubtypes')}
        value={filterValue}
        popperPlacement={isDrawer ? 'right' : 'bottom-start'}
      />
    );
  }

  if (filterKey === 'orchestrationId') {
    return (
      <OrchestrationIdAutoComplete
        options={orchestrationIdOptions}
        value={filterValue}
        onChange={onChange.bind(null, 'orchestrationId')}
        loading={orchestrationsLoading && Boolean(queryParams.filters?.studyId)}
      />
    );
  }
};

export default MainFilter;
