import { Button } from '@mui/material';
import * as Sentry from '@sentry/browser';
import Login from 'components/Auth/Login';
import PrivateRoute from 'components/Auth/PrivateRoute';
import ProcedurePage from 'components/Procedure/ProcedurePage';
import StudyDashboard from 'components/StudyDashboard';
import { Permission } from 'interfaces/permissionOption';
import { isEmpty, keys } from 'lodash';
import React, { useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { Auth0Profile } from 'utils/auth/AuthService';
import { useSetInitialLabId } from 'utils/useSetInitialLabId';
import './App.scss';
import CookieNotice from './CookieNotice';
import ErrorSnackbar from './ErrorSnackbar';
import Loader from './Loader';
import MainPageRoute from './MainPage';
import NotFound from './NotFound';
import AnalysisStudioPage from './Pages/AnalysisStudio';
import CalculateFeatures from './Pages/CalculateFeatures';
import Jobs from './Pages/Jobs';
import { OperationsSettings } from './Pages/OperationsSettings';
import { UserCredentialsRolesAndPermissions } from './Pages/UserCredentialsManagement';
import PrivacyPolicy from './Pages/static/PrivacyPolicy';
import TermsAndConditions from './Pages/static/TermsAndConditions';
import { SlidePage } from './Procedure/SlidePage';
import { useUploadFilesContext } from './UploadFilesContext';
import UploadProcess from './UploadProcess/UploadProcess';
import UploadsDashboard from './UploadsDashboard';

interface Props {
  hasError: boolean;
  errorMessage?: string;
  isAuthenticated: boolean;
  triedAuthenticateFromLocalStorage: boolean;
  userProfile: Auth0Profile;
  tryAuthenticateFromLocalStorage: (currentProfile?: Auth0Profile) => void;
}

const AppRouter: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const isAuthenticated = props.isAuthenticated;

  useSetInitialLabId();

  return (
    <Routes>
      <Route
        path="/terms-and-conditions"
        element={<PrivateRoute component={TermsAndConditions} isAuthenticated={isAuthenticated} loginRoute="/login" />}
      />
      <Route
        path="/privacy-policy"
        element={<PrivateRoute component={PrivacyPolicy} isAuthenticated={isAuthenticated} loginRoute="/login" />}
      />
      <Route
        path="/uploads-dashboard"
        element={<PrivateRoute component={UploadsDashboard} isAuthenticated={isAuthenticated} loginRoute="/login" />}
      />

      <Route
        path="/features-dashboard"
        element={<PrivateRoute component={AnalysisStudioPage} isAuthenticated={isAuthenticated} loginRoute="/login" />}
      />

      <Route
        path="/admin/user-credentials"
        element={
          <PrivateRoute
            component={UserCredentialsRolesAndPermissions}
            isAuthenticated={isAuthenticated}
            loginRoute="/login"
          />
        }
      />
      <Route
        path="/admin/operations-settings"
        element={
          <PrivateRoute
            component={OperationsSettings}
            permissionsRequired={[
              Permission.EditStainTypeOptions,
              Permission.EditExternalLabelOptions,
              Permission.EditSlideTagOptions,
              Permission.EditAreaTypeOptions,
              Permission.EditClusterTypeOptions,
            ]}
            isAuthenticated={isAuthenticated}
            loginRoute="/login"
          />
        }
      />
      <Route
        path="/jobs"
        element={<PrivateRoute loginRoute="/login" component={Jobs} isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/calculate-features"
        element={<PrivateRoute loginRoute="/login" component={CalculateFeatures} isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/procedures"
        element={<PrivateRoute loginRoute="/login" component={StudyDashboard} isAuthenticated={isAuthenticated} />}
      />

      <Route path="/" element={<MainPageRoute isAuthenticated={isAuthenticated} />} />

      <Route
        path="/procedures/:id"
        element={
          <PrivateRoute
            permissionsRequired={[Permission.ViewAccessionDashboard]}
            component={ProcedurePage}
            loginRoute="/login"
            isAuthenticated={isAuthenticated}
          />
        }
      />

      <Route
        path="/slides/:slideId"
        element={
          <PrivateRoute
            permissionsRequired={[Permission.ViewAccessionDashboard]}
            component={SlidePage}
            loginRoute="/login"
            isAuthenticated={isAuthenticated}
          />
        }
      />

      <Route path="/login" element={<Login />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const AppView: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { isAuthenticated } = props;

  const { fileUploads, uploadsCompleted, isPending } = useUploadFilesContext();

  const uploadSnackbarVisible = !isEmpty(keys(fileUploads));

  const { userProfile } = props;

  useEffect(() => {
    if (isAuthenticated) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          ...userProfile,
        });
      });

      return () => {
        Sentry.configureScope((scope) => {
          scope.setUser(null);
        });
      };
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isPending || (!isEmpty(fileUploads) && !uploadsCompleted)) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadsCompleted, isEmpty(fileUploads), isPending]);

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    props.tryAuthenticateFromLocalStorage(props.userProfile);
  }, []);

  if (!isAuthenticated && !props.triedAuthenticateFromLocalStorage) {
    return <Loader />;
  }
  return (
    <>
      {uploadSnackbarVisible && <UploadProcess />}

      {props.hasError && (
        <ErrorSnackbar
          message={props.errorMessage}
          action={
            // TODO: once we have a better error handling, we should do a proper retry instead of a reload: https://nucleai.atlassian.net/browse/ATOM-1034
            <Button sx={{ color: 'white' }} onClick={() => window.location.reload()}>
              Try again
            </Button>
          }
        />
      )}
      <HashRouter>
        <CookieNotice />
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <AppRouter {...props} />
        </QueryParamProvider>
      </HashRouter>
    </>
  );
};

// eslint-disable-next-line no-undef
export default AppView;
