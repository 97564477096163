import { flatMap, map, trim } from 'lodash';
import { useMemo } from 'react';
import { filterSearchOptions } from 'services/matchSort';
import { CaseSearchItem } from 'services/searchIndex';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useSearchIndex } from 'utils/queryHooks/useSearchIndex';

const fullSearchKeys = ['caseLabel', 'caseId', 'slides.*.id', 'slides.*.fileName'];

export const useFullSearchResults = (searchQuery: string, { enabled = true }: { enabled?: boolean } = {}) => {
  const { index, isSuccess: searchIndexSuccess, isLoading } = useSearchIndex({ enabled });
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);
  const allCaseItems: CaseSearchItem[] = index ?? [];
  // if slidesMode is on, we need to flatten the search index to have a single slide per item
  const allSearchItems = slidesMode
    ? flatMap(allCaseItems, (caseItem) => map(caseItem?.slides, (slide) => ({ ...caseItem, slides: [slide] })))
    : allCaseItems;

  const isEnabled = !!index && trim(searchQuery) !== '';

  const getFilteredOptionsForForcedSearch = (forceSearchTerm: string) =>
    filterSearchOptions<CaseSearchItem>(fullSearchKeys, allSearchItems, forceSearchTerm);

  const filteredOptionsData = useMemo(() => {
    if (isEnabled) {
      return getFilteredOptionsForForcedSearch(searchQuery);
    }
    return [];
  }, [allSearchItems, searchQuery, isEnabled]);

  const filteredOptions = isEnabled ? filteredOptionsData : [];

  const isSuccess = searchIndexSuccess;

  return {
    filteredOptions,
    allOptions: allSearchItems,
    isLoading: isLoading && isEnabled,
    isSuccess,
    getFilteredOptionsForForcedSearch,
  } as const;
};
