import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { compact, filter, isEmpty, isEqual, isString, keys, map, pickBy, some, uniq } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BooleanParam, JsonParam, NumberParam, useQueryParam, withDefault } from 'use-query-params';

import { updateSlideChannelMarkers, updateStudyMarkers } from 'api/markerTypes';
import { getStudyProcedureQueryKey } from 'api/study';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Procedure } from 'interfaces/procedure';
import useStudyChannelMarkerTypes from 'utils/queryHooks/useStudyChannelMarkerTypes';
import { useEncodedFilters } from 'utils/useEncodedFilters';

export type UpdateSlideChannelMarkersMutation = UseMutateFunction<any, unknown, void, unknown>;
export type UpdateStudyMarkersMutation = UseMutateFunction<any, unknown, void, unknown>;

interface Props {
  slide: SlideWithChannelAndResults;
}

export const useChannelMarkerEditor = ({ slide }: Props) => {
  const { queryParams } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);
  const slideId = slide.id;
  const params = useParams();
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);

  const procedureQueryKey = !isNaN(caseId)
    ? getStudyProcedureQueryKey(queryParams?.filters?.studyId, caseId, queryParams)
    : undefined;

  const hasMissingMarkersInDb = some(slide.channelsMetadata, ({ id }) => !slide?.channelMarkerTypes?.[id]);
  // If the slide has no markers assigned in the DB, it is a newly ingested slide
  const [editChannelMarkerMode, setEditChannelMarkerModeInner] = useQueryParam(
    'editChannelMarkerMode',
    withDefault(BooleanParam, hasMissingMarkersInDb)
  );

  const [draftChannelMarkersInner, setDraftChannelMarkers] = useQueryParam<{ [id: string]: string }>(
    'draftChannelMarkers',
    JsonParam
  );
  const draftChannelMarkers = React.useMemo(
    () => (editChannelMarkerMode ? draftChannelMarkersInner ?? (slide?.channelMarkerTypes || {}) : {}),
    [editChannelMarkerMode, draftChannelMarkersInner, slide]
  );

  const handleChannelMarkerTypeChange = (channelId: string, markerType: string) =>
    setDraftChannelMarkers({ ...draftChannelMarkers, [channelId]: markerType });

  const draftAssignedMarkers = React.useMemo(
    () => map(slide.channelsMetadata, ({ id }) => draftChannelMarkers?.[id]),
    [draftChannelMarkers, slide]
  );

  const hasMissingMarkers =
    hasMissingMarkersInDb ||
    (editChannelMarkerMode && compact(draftAssignedMarkers).length !== draftAssignedMarkers?.length);

  const setEditChannelMarkerMode = React.useCallback(
    (newEditChannelMarkerMode: boolean) => {
      setEditChannelMarkerModeInner(newEditChannelMarkerMode);
      if (!newEditChannelMarkerMode) {
        setDraftChannelMarkers(undefined);
      }
    },
    [setEditChannelMarkerModeInner]
  );

  const updateSlideChannelMarkersInstance = React.useCallback(
    () =>
      updateSlideChannelMarkers({
        slideId,
        markerTypes: pickBy(draftChannelMarkers, isString),
      }),
    [slideId, draftChannelMarkers]
  );

  const queryClient = useQueryClient();

  const updateSlideChannelMarkersMutation = useMutation(updateSlideChannelMarkersInstance, {
    onSuccess: () => {
      if (!isNaN(caseId)) {
        queryClient.setQueryData(procedureQueryKey, (prev: Procedure) => {
          return {
            ...prev,
            slides: map(prev.slides, (previousSlide) =>
              previousSlide.id === slideId
                ? { ...previousSlide, channelMarkerTypes: draftChannelMarkers }
                : previousSlide
            ),
          };
        });
        queryClient.invalidateQueries({ queryKey: ['procedure', caseId], exact: false });
      } else {
        // TODO: Invalidate slide query
      }
      setEditChannelMarkerMode(false);
    },
  });

  const studyId = slide.studyId;

  const updateStudyMarkersInstance = React.useCallback(
    () =>
      updateStudyMarkers({
        studyId,
        markerTypes: pickBy(draftChannelMarkers, isString),
      }),
    [studyId, draftChannelMarkers]
  );

  const updateStudyMarkersMutation = useMutation(updateStudyMarkersInstance, {
    onSuccess: () => {
      if (!isNaN(caseId)) {
        if (queryClient.getQueryData(procedureQueryKey)) {
          queryClient.setQueryData(procedureQueryKey, (prev: Procedure) => {
            return {
              ...prev,
              slides: map(prev.slides, (s) => {
                if (s.id === slideId) {
                  return {
                    ...s,
                    channelMarkerTypes: draftChannelMarkers,
                  };
                }
                return s;
              }),
            };
          });
        }
        queryClient.invalidateQueries({ queryKey: ['procedure', caseId], exact: false });
      } else {
        // TODO: Invalidate slide query
      }
      queryClient.removeQueries({
        predicate: (query) => query.queryKey[0] === 'procedure' && query.queryKey[1] !== caseId,
      });
      setEditChannelMarkerMode(false);
    },
  });

  const updateSlideChannelMarkersFunction: UpdateSlideChannelMarkersMutation = updateSlideChannelMarkersMutation.mutate;

  const { channelMarkersResponse, isCheckingStudyChannels, isLoadingStainTypeOptions } = useStudyChannelMarkerTypes(
    studyId || queryParams?.filters?.studyId
  );

  const updateStudyMarkersFunction: UpdateStudyMarkersMutation = studyId ? updateStudyMarkersMutation.mutate : null;

  const isSavingChannelMarkers = updateSlideChannelMarkersMutation.isLoading || updateStudyMarkersMutation.isLoading;

  const hasDuplicateMarkers = compact(draftAssignedMarkers).length !== uniq(compact(draftAssignedMarkers)).length;
  const hasMarkerChanges = editChannelMarkerMode && !isEqual(draftChannelMarkers, slide?.channelMarkerTypes);

  const isMissingChannelNames =
    editChannelMarkerMode &&
    slide.channelsMetadata.length !==
      filter(slide.channelsMetadata, ({ id }) => Boolean(draftChannelMarkers[id])).length;

  // Channel markers that are in the DB but not in the current draftChannelMarkers state
  const hasPreviouslyAssignedChannelsMissingMarkers =
    editChannelMarkerMode &&
    hasMarkerChanges &&
    !isEmpty(slide?.channelMarkerTypes) &&
    some(
      keys(slide?.channelMarkerTypes),
      (channelId) => Boolean(slide?.channelMarkerTypes?.[channelId]) && !draftChannelMarkers[channelId]
    );

  return {
    isSavingChannelMarkers,
    editChannelMarkerMode,
    hasMissingMarkers,
    hasMarkerChanges,
    hasDuplicateMarkers,
    isMissingChannelNames,
    hasPreviouslyAssignedChannelsMissingMarkers,
    draftAssignedMarkers,
    draftChannelMarkers,
    dbChannelMarkers: slide?.channelMarkerTypes,
    updateSlideChannelMarkers: updateSlideChannelMarkersFunction,
    updateStudyMarkers: updateStudyMarkersFunction,
    setEditChannelMarkerMode,
    handleChannelMarkerTypeChange,
    isCheckingStudyChannels,
    isLoadingStainTypeOptions,
    doAllSlidesHaveSameChannels: Boolean(channelMarkersResponse?.doAllSlidesHaveSameChannels),
  };
};
