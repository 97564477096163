import { InferenceModelData, SlideInferenceResults } from 'interfaces/calculateFeatures';
import { Dictionary, filter, flatMap, forEach, groupBy, includes, keys, map, some, sortBy, values } from 'lodash';
import { AssignmentByStain, OrchestrationBySlideByType } from '..';

export interface ModelSelection {
  inference: boolean;
  assignment: boolean;
}

interface SlidesModels {
  slideId: string;
  stainingType: string;
  caseLabel: string;
  caseId: string;
  countModelsInCase?: Record<string, number>;
  countModelsInStain?: Record<string, number>;
  countModelsInSlide?: number;
  [key: string]: boolean | string | Record<string, number> | number | ModelSelection;
}

export const getSlidesModelsData = (
  slides: Dictionary<SlideInferenceResults>,
  selectedOrchestrations: OrchestrationBySlideByType,
  selectedAssignments: AssignmentByStain, // optional
  modelsType: string[]
) => {
  const slidesModels: SlidesModels[] = map(slides, (slideInfo, slideId) => {
    const models: Record<string, Record<string, boolean>> = {};
    forEach(selectedOrchestrations[slideId], (orchestrationsByModel, modelType) => {
      if (orchestrationsByModel?.orchestration.orchestrationId)
        models[modelType] = {
          ...models[modelType],
          inference: true,
        };
    });

    forEach(flatMap(values(selectedAssignments)), (selectedAssignment) => {
      if (selectedAssignment.modelType && includes(selectedAssignment.assignment?.slideIds, slideId))
        models[selectedAssignment.modelType] = {
          ...models[selectedAssignment.modelType],
          assignment: true,
        };
    });

    return {
      slideId,
      stainingType: slideInfo.stainingType,
      caseLabel: slideInfo.caseLabel,
      caseId: slideInfo.caseId,
      ...models,
    };
  });

  const slidesByCaseLabel = groupBy(sortBy(slidesModels, ['caseLabel', 'stainingType']), 'caseLabel');

  // Add count of models in case to each slide
  forEach(slidesByCaseLabel, (slidesOfCase, caseLabel) => {
    let countModelsInCase: Record<string, number> = {};
    forEach(modelsType, (modelType) => {
      forEach(slidesOfCase, (slide) => {
        const modelSelected = slide[modelType] as ModelSelection;
        if (modelSelected?.inference || modelSelected?.assignment) {
          countModelsInCase[modelType] = countModelsInCase[modelType] ? countModelsInCase[modelType] + 1 : 1;
        }
      });

      forEach(slidesOfCase, (slide) => {
        slide.countModelsInCase = countModelsInCase;
      });
    });
  });

  const slidesByStain = groupBy(slidesModels, 'stainingType');

  const countModelsByStain: Record<string, Record<string, number>> = {};
  forEach(slidesByStain, (slidesOfStain, stain) => {
    countModelsByStain[stain] = { slidesCount: slidesOfStain.length };
    forEach(modelsType, (modelType) => {
      countModelsByStain[stain][modelType] = 0;
      forEach(slidesOfStain, (slide) => {
        const modelSelected = slide[modelType] as ModelSelection;
        if (modelSelected?.inference || modelSelected?.assignment) {
          countModelsByStain[stain][modelType]++;
        }
      });
    });

    forEach(slidesOfStain, (slide) => {
      slide.countModelsInStain = countModelsByStain[stain];
      slide.countModelsInSlide = 0;

      forEach(modelsType, (modelType) => {
        const modelSelected = slide[modelType] as ModelSelection;
        if (modelSelected?.inference || modelSelected?.assignment) {
          slide.countModelsInSlide++;
        }
      });
    });
  });

  return slidesModels;
};

export const getSidesWithoutSomeModels = (modelsType: string[], slidesModels: SlidesModels[]) => {
  return filter(slidesModels, (slide) =>
    some(
      modelsType,
      (modelType) =>
        slide.countModelsInSlide > 0 &&
        !(slide[modelType] as ModelSelection)?.inference &&
        !(slide[modelType] as ModelSelection)?.assignment &&
        slide.countModelsInStain[modelType] > 0
    )
  );
};

export const getModelsTypeByModelInferences = (inferenceArtifacts: InferenceModelData[]): string[] => {
  return keys(groupBy(inferenceArtifacts, 'modelType'));
};
