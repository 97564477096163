import { QueryKey } from '@tanstack/react-query';
import { stringify } from 'qs';

import { Annotation } from 'interfaces/annotation';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getSlideAnnotations = ({
  slideId,
  includeEmpty,
  signal,
}: {
  slideId: string;
  includeEmpty?: boolean;
  signal?: AbortSignal;
}) => {
  const stringifiedParams = stringify({ includeEmpty });
  return apiRequestHandlerPromise<Annotation[]>({
    url: `slides/${slideId}/annotation${stringifiedParams ? `?${stringifiedParams}` : ''}`,
    method: 'GET',
    signal,
  });
};

export const getSlideAnnotationsQueryKey = ({
  slideId,
  includeEmpty,
}: {
  slideId: string;
  includeEmpty?: boolean;
}): QueryKey => ['slide', slideId, 'annotations', includeEmpty];
