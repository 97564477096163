import ErrorIcon from '@mui/icons-material/Error';
import { Checkbox, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { includes } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';

import PrepareCustomerResultsForDownload from 'components/atoms/PrepareCustomerResultsForDownload';
import ErrorSnackbar from 'components/ErrorSnackbar';
import NoCasesFound from 'components/SearchFilters/MainFilters/NoCasesFound';
import SaveCohortModal from 'components/SearchFilters/SaveCohortModal';
import { Permission } from 'interfaces/permissionOption';
import { Study } from 'interfaces/study';

import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import StudyExportMenu from 'components/StudiesDashboard/StudyExportMenu/StudyExportMenu';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useForms } from 'utils/queryHooks/form/useForm';
import { useCurrentStateCases } from 'utils/useCurrentStateCases';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { useStudyPlatformSettings } from 'utils/useStudyPlatformSettings';
import { CasesDisplay } from './CasesDisplay';
import { FiltersAndExport } from './FiltersAndExports';
import { PageStats } from './PagesStats';
import SelectAllCardsCheckbox from './ProcedureCard/Carousel/AllCardsCheckbox';
import { ToggleCaseDisplayMode } from './ToggleCaseDisplayMode';
import { useCasesViewMode } from './useCasesViewMode';
import { usePendingSlides } from './usePendingSlides';

interface Props {
  displayProcedureModeToggle?: boolean;
  study?: Study;
}

const ProceduresPage = ({ displayProcedureModeToggle, study }: Props) => {
  const specificStudySelected = Boolean(study);

  const activeStudyId = study?.id;
  const qcLabelConfig = study?.qcLabelsConfig;
  const hasLabelsConfigured = Boolean(study?.qcLabelsConfig?.length);
  const allowExport = study?.allowExport;

  const { hasPermission } = usePermissions();

  const { casesViewMode, setCasesViewMode } = useCasesViewMode();
  const { clearSelection } = useRowSelectionContext();
  const { clearChanges, setBulkEditMode } = useTableEditingContext();

  const canUseExperimentalFeatures = hasPermission(Permission.UseNucleaiExperimentalFeatures);
  const canPrepareApprovedCustomerResultsForDownload = hasPermission(
    Permission.PrepareApprovedCustomerResultsForDownload
  );
  const procedureViewMode = displayProcedureModeToggle ? casesViewMode : 'card';
  const canUseGalleryMode = displayProcedureModeToggle && canUseExperimentalFeatures;

  const { cheatMode, cheatStage } = useAppSelector((state) => state.cheatMode);

  const [isSaveCohortModalOpen, setIsSaveCohortModalOpen] = useState(false);
  const [pendingSlidesMode, setPendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const { queryParams, setQueryParams, convertToQueryObject } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const queryObject = useMemo(() => convertToQueryObject(queryParams), [queryParams]);

  const [slidesMode, setSlidesMode] = useQueryParam('slidesMode', BooleanParam);

  const canViewPendingSlides = hasPermission(Permission.ViewPendingSlides);

  const cheatModeSmallerThanTwo = includes(cheatMode, activeStudyId) && cheatStage < 2;

  const { totalPendingSlides } = usePendingSlides();

  const {
    isLoading: casesLoading,
    totalCasesCount: totalCases,
    totalSlidesCount: totalSlides,
    procedures: pageCases,
    slidesInPreviousPages,
    casesInPreviousPages,
    error,
  } = useCurrentStateCases();

  const { formsWithViewableResponses } = useForms(
    {
      studyId: activeStudyId,
    },
    {
      enabled: !casesLoading,
    }
  );

  const isStudyExists = queryParams.filters?.studyId && queryParams.filters.studyId !== 'none';

  useStudyPlatformSettings(activeStudyId);

  const numRows = slidesMode ? totalSlides : totalCases;

  return (
    <div>
      {error && <ErrorSnackbar message={error.toString()} />}

      <SaveCohortModal
        isOpen={isSaveCohortModalOpen}
        onClose={() => setIsSaveCohortModalOpen(false)}
        queryObject={queryObject}
      />
      <div>
        <FiltersAndExport
          specificStudySelected={specificStudySelected}
          hasLabelsConfigured={hasLabelsConfigured}
          qcLabelConfig={qcLabelConfig}
          totalCases={totalCases}
          setIsSaveCohortModalOpen={setIsSaveCohortModalOpen}
        />

        <Grid container direction="column" spacing={1} my={1}>
          <Grid item container justifyContent="space-between" wrap="nowrap">
            <Grid item container alignItems="center" spacing={3}>
              {displayProcedureModeToggle && (
                <Grid item>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={procedureViewMode}
                    exclusive
                    onChange={(_, newViewMode) => {
                      setCasesViewMode(newViewMode);
                    }}
                    aria-label="Procedure-View-Mode"
                  >
                    <ToggleButton value="table" data-cy="cases table view">
                      Table
                    </ToggleButton>
                    <ToggleButton value="card" data-cy="cases cards view">
                      Cards
                    </ToggleButton>
                    {canUseGalleryMode && <ToggleButton value="gallery">Gallery</ToggleButton>}
                  </ToggleButtonGroup>
                </Grid>
              )}
              <Grid item>
                <ToggleCaseDisplayMode />
              </Grid>
            </Grid>
            <Grid container item alignItems="center" justifyContent="end" wrap="nowrap" spacing={2}>
              {canPrepareApprovedCustomerResultsForDownload && (
                <Grid item>
                  <PrepareCustomerResultsForDownload disabled={!isStudyExists} studyId={activeStudyId} />
                </Grid>
              )}

              <Grid item>
                <StudyExportMenu
                  forms={formsWithViewableResponses}
                  study={study}
                  disabled={!isStudyExists || !allowExport || (includes(cheatMode, activeStudyId) && cheatStage < 3)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between" wrap="nowrap">
            <Grid item>
              <PageStats
                totalCases={totalCases}
                totalSlides={totalSlides}
                slidesInPreviousPages={slidesInPreviousPages}
                proceduresInPreviousPages={casesInPreviousPages}
                pageCases={pageCases}
                casesLoading={casesLoading}
              />
            </Grid>
            {canViewPendingSlides && totalPendingSlides > 0 && (
              <Grid item justifyItems="center">
                <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    size="small"
                    checked={Boolean(pendingSlidesMode)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPendingSlidesMode(!pendingSlidesMode);
                      if (event.target.checked) {
                        setSlidesMode(true);
                      }
                      clearChanges();
                      setBulkEditMode(false);
                      clearSelection();
                      // Reset page to 1 when switching between pending slides and regular slides
                      setQueryParams({ page: 1 });
                    }}
                  />
                  {` ${totalPendingSlides} Lab's pending slides`}
                  <ErrorIcon sx={{ color: (theme) => theme.palette.warning.main }} />
                </Typography>
              </Grid>
            )}
          </Grid>

          {casesViewMode != 'table' && <SelectAllToggle numRows={numRows} />}

          <CasesDisplay
            displayProcedureModeToggle={displayProcedureModeToggle}
            cheatModeSmallerThanTwo={cheatModeSmallerThanTwo}
            pendingSlidesMode={pendingSlidesMode}
            forms={formsWithViewableResponses}
          />
          {!casesLoading && totalCases === 0 && <NoCasesFound />}
        </Grid>
      </div>
    </div>
  );
};

const SelectAllToggle = ({ numRows }: { numRows: number }) => {
  const { someSelected } = useRowSelectionContext();

  const someSelectedInPage = someSelected(numRows);

  return (
    <Grid container sx={{ px: 2, mb: 1, height: 40 }}>
      {someSelectedInPage && (
        <Grid item container>
          <Grid item>
            <SelectAllCardsCheckbox numRows={numRows} />
          </Grid>

          <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography>Select All</Typography>{' '}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ProceduresPage;
