import RemoveIcon from '@mui/icons-material/Remove';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import {
  AutomaticConditionCell,
  CategoricalActions,
  allCategoricalActions,
  filterActionCategorical,
} from 'interfaces/automaticCondition';
import { MappingFilterMetadata } from 'interfaces/postProcessingAction';
import { cloneDeep, every, find, flatMap, includes, isEmpty, join, keyBy, map, remove } from 'lodash';
import React, { FunctionComponent } from 'react';
import { AutomaticConditionBuilderProps } from './AutomaticConditionBuilder';

type CellConditionBuilderProps = AutomaticConditionBuilderProps<AutomaticConditionCell>;

export interface MappingFilterOption {
  id: string;
  label: string;
  sourceName: string;
  originalLabel: string;
  groupBy?: string;
}

const CellConditionBuilder: FunctionComponent<React.PropsWithChildren<CellConditionBuilderProps>> = ({
  mappingFiltersMetadata,
  onRemove,
  editable,
  condition,
  onConditionChange,
  categoricalActions,
}) => {
  const mappingFilterOptions = getMappingFilterOptions(mappingFiltersMetadata);
  const mappingFilterOptionsById = keyBy(mappingFilterOptions, 'id');

  const filterActionOptions = getFilterActionOptions(
    condition?.mappingFilter,
    mappingFiltersMetadata,
    mappingFilterOptions,
    categoricalActions
  );

  const filterOptions = getFilterOptions(condition?.mappingFilter, mappingFiltersMetadata, mappingFilterOptions);
  const filterOptionsById = keyBy(filterOptions, 'id');

  const isMappingFilterError =
    !isEmpty(condition?.mappingFilter) && isEmpty(mappingFilterOptionsById[condition?.mappingFilter]);
  const isFilterActionError = !isEmpty(condition.filterAction) && isEmpty(filterActionOptions);
  const isToFilterError =
    !isEmpty(condition.toFilter) && !every(condition.toFilter, (value: string | number) => filterOptionsById[value]);

  return (
    <Paper
      elevation={1}
      sx={{
        p: 1,
        mb: 1,
      }}
    >
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item>
          <IconButton disabled={!editable} onClick={() => onRemove()}>
            <RemoveIcon />
          </IconButton>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            fullWidth
            variant="standard"
            error={isMappingFilterError}
            className={isMappingFilterError ? 'error' : undefined}
          >
            <Autocomplete
              size="small"
              options={mappingFilterOptions}
              value={
                condition?.mappingFilter
                  ? {
                      label: mappingFilterOptionsById[condition?.mappingFilter]?.label || condition?.mappingFilter,
                      id: condition?.mappingFilter,
                      sourceName: mappingFilterOptionsById[condition?.mappingFilter]?.sourceName,
                      originalLabel: mappingFilterOptionsById[condition?.mappingFilter]?.originalLabel,
                      groupBy: mappingFilterOptionsById[condition?.mappingFilter]?.groupBy,
                    }
                  : null
              }
              isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
              renderInput={(params) => (
                <TextField
                  error={isEmpty(condition.mappingFilter) || !mappingFilterOptionsById[condition.mappingFilter]}
                  required
                  {...params}
                  size="medium"
                />
              )}
              onChange={(event, selected) => {
                let newCondition;

                if (selected) {
                  newCondition = {
                    ...condition,
                    mappingFilter: selected.id,
                  };
                } else {
                  newCondition = { ...condition, mappingFilter: null, filterAction: null, toFilter: null };
                }
                newCondition.displayValue = getCellName(newCondition, mappingFiltersMetadata);
                onConditionChange(newCondition);
              }}
            />
            {isMappingFilterError && (
              <FormHelperText error id="class-does-not-exist-error-text">
                This option does not exist anymore
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl
            fullWidth
            variant="standard"
            error={isFilterActionError}
            className={isFilterActionError ? 'error' : undefined}
          >
            <Select
              size="medium"
              error={isEmpty(condition.filterAction)}
              value={condition?.filterAction}
              onChange={(e) => {
                const newCondition = { ...condition, filterAction: e.target.value };
                newCondition.displayValue = getCellName(newCondition, mappingFiltersMetadata);
                onConditionChange(newCondition);
              }}
            >
              {map(filterActionOptions, (filterAction) => (
                <MenuItem value={filterAction.id}>{filterAction.label}</MenuItem>
              ))}
            </Select>
            {isFilterActionError && (
              <FormHelperText error id="class-does-not-exist-error-text">
                This option does not exist anymore
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            variant="standard"
            error={isToFilterError}
            className={isToFilterError ? 'error' : undefined}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={2}
              size="small"
              options={filterOptions}
              value={
                condition.toFilter
                  ? map(condition.toFilter, (val: string | number) => {
                      return {
                        label: filterOptionsById[val]?.label || val,
                        id: val,
                      };
                    })
                  : []
              }
              isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
              renderInput={(params) => (
                <TextField
                  error={
                    isEmpty(condition.toFilter) ||
                    !every(condition.toFilter, (val: string | number) => filterOptionsById[val])
                  }
                  required
                  {...params}
                  size="medium"
                />
              )}
              onChange={(event, selected) => {
                const newCondition = { ...condition, toFilter: map(selected, 'id') as string[] | number[] };
                newCondition.displayValue = getCellName(newCondition, mappingFiltersMetadata);
                onConditionChange(newCondition);
              }}
            />
            {isToFilterError && (
              <FormHelperText error id="class-does-not-exist-error-text">
                This option does not exist anymore
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const getMappingFilterOptions = (mappingFiltersMetadata: any) => {
  return flatMap(mappingFiltersMetadata, (mappingFilterMetadata) => {
    return map(mappingFilterMetadata.data, (data) => {
      return {
        id: `${mappingFilterMetadata.sourceName}.${data.label}`,
        label: data.label == 'label' ? mappingFilterMetadata.sourceName : data.label,
        sourceName: mappingFilterMetadata.sourceName,
        originalLabel: data.label,
        groupBy: data.label != 'label' && mappingFilterMetadata.sourceName,
      };
    });
  });
};

export const getFilterActionOptions = (
  mappingFilter: string,
  mappingFiltersMetadata: MappingFilterMetadata[],
  mappingFilterOptions: MappingFilterOption[],
  categoricalActions: CategoricalActions[]
) => {
  const mappingFilterOptionsById = keyBy(mappingFilterOptions, 'id');

  if (!mappingFilter) return [];
  if (!mappingFilterOptionsById[mappingFilter]) return [];
  const currentMappingFilter = mappingFilterOptionsById[mappingFilter];
  const mappingFilterMetadata = find(mappingFiltersMetadata, { sourceName: currentMappingFilter.sourceName });
  const FilterActionType = find(mappingFilterMetadata.data, { label: currentMappingFilter.originalLabel })?.type;

  const filterActionByType = cloneDeep(filterActionCategorical);
  if (categoricalActions)
    remove(filterActionByType.categorical, (action) => !includes(categoricalActions, action.label));

  return filterActionByType[FilterActionType];
};

export const getFilterOptions = (
  mappingFilter: string,
  mappingFiltersMetadata: MappingFilterMetadata[],
  mappingFilterOptions: MappingFilterOption[]
) => {
  const mappingFilterOptionsById = keyBy(mappingFilterOptions, 'id');

  if (!mappingFilter) return [];
  if (!mappingFilterOptionsById[mappingFilter]) return [];
  const currentMappingFilter = mappingFilterOptionsById[mappingFilter];
  const mappingFilterMetadata = find(mappingFiltersMetadata, { sourceName: currentMappingFilter.sourceName });
  return find(mappingFilterMetadata.data, { label: currentMappingFilter.originalLabel })?.options;
};

export const getCellName = (cellCondition: AutomaticConditionCell, mappingFiltersMetadata: MappingFilterMetadata[]) => {
  const mappingFilterOptions = getMappingFilterOptions(mappingFiltersMetadata);
  const mappingFilterOptionsById = keyBy(mappingFilterOptions, 'id');

  const filterActionOptions = getFilterActionOptions(
    cellCondition?.mappingFilter,
    mappingFiltersMetadata,
    mappingFilterOptions,
    allCategoricalActions
  );

  const filterActionOptionsById = keyBy(filterActionOptions, 'id');

  const filterOptions = getFilterOptions(cellCondition?.mappingFilter, mappingFiltersMetadata, mappingFilterOptions);
  const filterOptionsById = keyBy(filterOptions, 'id');

  return `${mappingFilterOptionsById[cellCondition.mappingFilter]?.label || cellCondition.mappingFilter} ${
    filterActionOptionsById[cellCondition.filterAction]?.label || cellCondition.filterAction
  } ${join(
    map(cellCondition.toFilter, (filter: string | number) => filterOptionsById[filter]?.label || filter),
    ', '
  )}`;
};

export default CellConditionBuilder;
