import { Box, Button, Grid } from '@mui/material';
import {
  AutomaticCondition,
  AutomaticConditionCell,
  CategoricalActions,
  ConditionOperator,
  ConditionType,
} from 'interfaces/automaticCondition';
import { MappingFilterMetadata } from 'interfaces/postProcessingAction';
import { Study } from 'interfaces/study';
import { isEmpty, map } from 'lodash';
import React, { FunctionComponent } from 'react';
import AutomaticConditionBuilder from './AutomaticConditionBuilder';

interface AutomaticConditionGroupProps {
  operands: AutomaticCondition[];
  onOperandsChange: (operands: AutomaticCondition[]) => void;
  editable: boolean;
  study?: Study;
  orchestrationId?: string;
  shouldShowErrors?: boolean;
  featuresOnly?: boolean;
  singleOperand: boolean;
  conditionOptions: ConditionType[];
  mappingFiltersMetadata?: MappingFilterMetadata[];
  conditionOperators?: ConditionOperator[];
  logicalConditionRecursively?: boolean;
  categoricalActions?: CategoricalActions[];
}

export const defaultRangeCondition = { source: 'experimentResult' as const, field: '' };
export const defaultLogicCondition = {
  operator: ConditionOperator.AND,
  operands: [] as AutomaticCondition[],
};
export const defaultTagCondition = { tagId: '', isAssigned: true };
export const defaultCellCondition: AutomaticConditionCell = {
  conditionType: 'cell',
  mappingFilter: '',
  filterAction: '',
  toFilter: [],
  displayValue: '',
};

const AutomaticConditionGroup: FunctionComponent<React.PropsWithChildren<AutomaticConditionGroupProps>> = ({
  operands,
  onOperandsChange,
  singleOperand,
  ...props
}) => {
  const wrapWithLogicalCondition = () => {
    onOperandsChange([{ operator: ConditionOperator.AND, operands }]);
  };

  const addRangeCondition = () => {
    onOperandsChange([...operands, defaultRangeCondition]);
  };

  const addLogicalCondition = () => {
    onOperandsChange([...operands, defaultLogicCondition]);
  };

  const addTagCondition = () => {
    onOperandsChange([...operands, defaultTagCondition]);
  };

  const addCellCondition = () => {
    onOperandsChange([...operands, defaultCellCondition]);
  };

  const removeCondition = (index: number) => {
    const updatedConditions = [...operands];
    updatedConditions.splice(index, 1);
    onOperandsChange(updatedConditions);
  };

  const shouldDisplayAddButtons = !singleOperand || (singleOperand && isEmpty(operands));

  return (
    <Grid item>
      <Box
        width="100%"
        pl={1}
        bgcolor="#f5f5f5"
        my={1}
        p={1}
        sx={
          isEmpty(operands) && {
            border: 1,
            borderColor: 'red',
          }
        }
      >
        {!isEmpty(operands) && props.conditionOptions.includes(ConditionType.LOGICAL) && (
          <Button
            disabled={!props.editable}
            variant="outlined"
            color="primary"
            size="small"
            onClick={wrapWithLogicalCondition}
            sx={{ mb: 1 }}
          >
            Wrap with Logical Condition
          </Button>
        )}
        {map(operands, (condition, index) => (
          <AutomaticConditionBuilder
            {...props}
            key={index}
            condition={condition}
            onConditionChange={(newCondition) => {
              const updatedConditions = [...operands];
              updatedConditions[index] = newCondition;
              onOperandsChange(updatedConditions);
            }}
            onRemove={() => {
              removeCondition(index);
            }}
          />
        ))}
        {isEmpty(operands) && <p>No conditions</p>}
      </Box>
      {shouldDisplayAddButtons && (
        <Grid item>
          {props.conditionOptions.includes(ConditionType.LOGICAL) && (
            <Button
              disabled={!props.editable}
              variant="outlined"
              color="primary"
              size="small"
              onClick={addLogicalCondition}
            >
              Add Logical Condition
            </Button>
          )}
          {props.conditionOptions.includes(ConditionType.RANGE) && (
            <Button
              disabled={!props.editable}
              variant="outlined"
              color="primary"
              size="small"
              onClick={addRangeCondition}
            >
              Add Range Condition
            </Button>
          )}
          {props.conditionOptions.includes(ConditionType.CELL) && (
            <Button
              disabled={!props.editable}
              variant="outlined"
              color="primary"
              size="small"
              onClick={addCellCondition}
            >
              Add Cell Condition
            </Button>
          )}
          {!props.featuresOnly && props.conditionOptions.includes(ConditionType.TAG) && (
            <Button
              disabled={!props.editable}
              variant="outlined"
              color="primary"
              size="small"
              onClick={addTagCondition}
            >
              Add Tag Condition
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default AutomaticConditionGroup;
