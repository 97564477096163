import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, FormControl, TextField } from '@mui/material';
import { Taxonomy } from 'interfaces/taxonomy';
import { compact, find, map, some } from 'lodash';
import React, { useMemo, useState, useTransition } from 'react';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';

interface TaxonomyMultiSelectDropdownProps {
  selectedTaxonomiesIds: string[];
  onSelectTaxonomiesIds: (taxonomiesIds: string[]) => void;
}

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const isParentTaxonomySelected = (taxonomy: Taxonomy, selectedTaxonomies: Taxonomy[]): boolean => {
  return some(
    selectedTaxonomies,
    (parent) => taxonomy.path.startsWith(`${parent.path}.`) && parent.path !== taxonomy.path
  );
};

const TaxonomyMultiSelectDropdown: React.FC<TaxonomyMultiSelectDropdownProps> = ({
  selectedTaxonomiesIds: externalTaxonomiesIds,
  onSelectTaxonomiesIds,
}) => {
  const { data: taxonomies } = useTaxonomy();

  const [selectedInternalTaxonomiesIds, setSelectedInternalTaxonomiesIds] = useState<string[]>(null);

  const [, startTransition] = useTransition(); // we use useTransition to make it so the UI doesn't freeze when selecting a taxonomy

  const handleSelectTaxonomies = (newValue: Taxonomy[]) => {
    startTransition(() => {
      onSelectTaxonomiesIds(map(newValue, 'path'));
    });
    setSelectedInternalTaxonomiesIds(map(newValue, 'path'));
  };

  const selectedTaxonomiesIds = selectedInternalTaxonomiesIds ?? externalTaxonomiesIds;

  const selectedTaxonomies = useMemo(
    () => compact(map(selectedTaxonomiesIds, (id) => find(taxonomies, (t) => t.path === id))),
    [selectedTaxonomiesIds, taxonomies]
  );

  return (
    <FormControl sx={{ width: '100%' }}>
      <Autocomplete
        multiple
        options={taxonomies}
        getOptionLabel={(option) => option.name}
        value={selectedTaxonomies}
        onChange={(event, newValue) => handleSelectTaxonomies(newValue as Taxonomy[])}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.path === value.path}
        renderOption={(props, option, { selected }) => {
          const isDisabled = isParentTaxonomySelected(option, selectedTaxonomies);
          return (
            <li {...props} key={option.path}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected || isDisabled}
                size="small"
                disabled={isDisabled}
              />
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label="Taxonomies" />}
      />
    </FormControl>
  );
};

export default TaxonomyMultiSelectDropdown;
