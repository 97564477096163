import {
  compact,
  filter,
  find,
  findIndex,
  flatMap,
  flatMapDeep,
  groupBy,
  includes,
  isEmpty,
  map,
  partition,
  replace,
  some,
  uniqBy,
} from 'lodash';

import {
  ExperimentResult,
  ExperimentResultsResultType,
  FlowClassName,
  PresentationInfoLayer,
  ResultColorMap,
  publishableFlowClasses,
  publishableResultTypes,
} from 'interfaces/experimentResults';
import { isBrackets } from 'utils/formatBrackets';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { formatBracketsVisualization } from 'utils/formatBrackets/formatBracketsVisualization';

const extensionRegex = /\.\S+$/g;
const underscoreAndPathSepRegex = /[_/]/g;

export const experimentResultToGroup = (result: FeatureMetadata) =>
  !result.approved && (result.resultType || result.flowClassName);

export interface FeatureMetadata {
  key?: string;
  id: string;
  value?: number | string;
  heatmapType?: 'dzi' | 'pmt' | 'geojson' | 'pmt-layer';
  heatmapUrl?: string;
  cellsDataUrl?: string;
  nestedItems?: FeatureMetadata[];
  displayName?: string;
  orchestrationId?: string;
  primaryRunOrchestrationId?: string;
  numFeatures?: number;
  createdAt?: string;
  approved?: boolean;
  internallyApproved?: boolean;
  experimentResultId?: number;
  flowClassName?: FlowClassName;
  resultType?: ExperimentResultsResultType;
  color?: string | ResultColorMap;
}

export interface Results {
  publishedResults: FeatureMetadata[];
  internalResults: {
    [key: string]: FeatureMetadata[];
  };
}

export interface ParsedResults {
  heatmaps: Results;
  features: Results;
  internalHeatmaps?: {
    [key: string]: FeatureMetadata[];
  };
}

export const emptyParsedResults: ParsedResults = {
  heatmaps: {
    publishedResults: [],
    internalResults: {},
  },
  features: {
    publishedResults: [],
    internalResults: {},
  },
};

const getHeatmapDisplayName = (context: FormatBracketsOptions, key: string, mainHeatmapKey?: string) => {
  if (isBrackets(key)) {
    return formatBracketsVisualization(key, context, mainHeatmapKey);
  }

  // legacy name
  const itemConfig = context.uiSettings.webappSidebarConfig.heatmapsConfig[key];
  return itemConfig?.displayName || replace(key, /_/g, ' ');
};

const parseBaseFeatureMetadataFromExperimentResult = (
  experimentResult: ExperimentResult,
  keyPrefix: string // keyPrefix is used to differentiate between different types of features / heatmaps for the same experiment result
): FeatureMetadata => ({
  id: `${keyPrefix}${experimentResult.experimentResultId}`,
  key: `${keyPrefix}${experimentResult.experimentResultId}`,
  experimentResultId: experimentResult.experimentResultId,
  orchestrationId: experimentResult.orchestrationId,
  numFeatures: experimentResult.numFeatures,
  createdAt: experimentResult.createdAt,
  approved: Boolean(experimentResult.approved),
  internallyApproved: Boolean(experimentResult.internallyApproved),
  flowClassName: experimentResult.flowClassName,
  resultType: experimentResult.resultType,
  primaryRunOrchestrationId: experimentResult.primaryRunOrchestrationId,
});

const parseFeatures = (slideResults: ExperimentResult[]) => {
  const resultsWithFeatures = filter(slideResults, (result) => result.numFeatures > 0);

  // we filter out duplicate orchestration ids to avoid showing the same results twice
  // we do this to avoid a bug when calculated results where saved each heatmap in a different experiment result (all with the same features)
  const slideUniqOrchIdResults = uniqBy(resultsWithFeatures, 'orchestrationId');

  if (slideUniqOrchIdResults.length !== resultsWithFeatures.length) {
    const duplicateOrchIds = filter(
      resultsWithFeatures,
      (result) => filter(resultsWithFeatures, { orchestrationId: result.orchestrationId }).length > 1
    );
    console.warn('Found duplicate orchestration ids in slide feature results', duplicateOrchIds);
  }

  const allFeaturesResults: FeatureMetadata[] = map(slideUniqOrchIdResults, (er) =>
    parseBaseFeatureMetadataFromExperimentResult(er, 'parseFeatures-')
  );

  const [secondaryResults, primaryResults] = partition(allFeaturesResults, (result) =>
    Boolean(result.primaryRunOrchestrationId)
  );

  const secondaryResultsWithoutPrimary = filter(
    secondaryResults,
    (result) => !some(primaryResults, { orchestrationId: result.primaryRunOrchestrationId })
  );

  const resultsWithSecondaryAsNested = map(primaryResults, (primaryResult) => {
    const secondaryResultsForPrimary = filter(secondaryResults, {
      primaryRunOrchestrationId: primaryResult.orchestrationId,
    });

    return {
      ...primaryResult,
      nestedItems: secondaryResultsForPrimary,
    };
  });

  const finalResults = [...resultsWithSecondaryAsNested, ...secondaryResultsWithoutPrimary];

  const publishedFeatures = filter(finalResults, 'approved');
  const internalFeatures = groupBy<FeatureMetadata>(
    filter(finalResults, (feature) => Boolean(experimentResultToGroup(feature))),
    experimentResultToGroup
  );

  return { publishedFeatures, internalFeatures };
};

const parsePmtHeatmap = (
  experimentResult: ExperimentResult,
  layerInfo: PresentationInfoLayer,
  geoJsonLayers?: FeatureMetadata[]
): FeatureMetadata | null => {
  const pmtHeatmapComponents =
    layerInfo || (experimentResult.presentationInfo?.['composite_layer_info'] as PresentationInfoLayer);
  const pmtHeatmapUrl = pmtHeatmapComponents?.storage_url;
  if (!pmtHeatmapUrl) {
    console.warn('Dynamic heatmap url not found', { layerInfo, experimentResult });
    return null;
  }
  if (layerInfo?.file_type && layerInfo.file_type !== 'pmt') {
    console.warn('Unsupported file type for pmt heatmap', { layerInfo, experimentResult });
    return null;
  }

  const nestedItems: FeatureMetadata[] = map(experimentResult.options, (heatmapOption) => {
    const matchingLayer = find(geoJsonLayers, { key: heatmapOption.key });
    return {
      id: `${experimentResult.experimentResultId}-${heatmapOption.key}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      show: false,
      selected: false,
      displayName: heatmapOption?.key,
      ...(matchingLayer || {}),
    };
  });

  return {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parsePmtHeatmap-'),
    displayName: `Vector Heatmap (beta) - ${
      experimentResult?.name ||
      // If the experiment result name is not available, use the artifact url as the name by replacing the file extension and underscores with spaces
      `${experimentResult.experimentResultId} - ${replace(
        replace(pmtHeatmapComponents?.artifact_url, extensionRegex, ''),
        underscoreAndPathSepRegex,
        ' '
      )}`
    }`,
    heatmapUrl: pmtHeatmapUrl,
    heatmapType: 'pmt',
    nestedItems,
  };
};

export const parseGeoJsonHeatmap = (
  experimentResult: ExperimentResult,
  layerInfo: PresentationInfoLayer,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadata | null => {
  const geoJsonUrl = layerInfo?.storage_url;
  if (!geoJsonUrl) {
    console.warn('Dynamic heatmap url not found', { layerInfo, experimentResult });
    return null;
  }
  if (layerInfo?.file_type && layerInfo.file_type !== 'geojson') {
    console.warn('Unsupported file type for geojson heatmap', { layerInfo, experimentResult });
    return null;
  }

  const matchingOption = find(experimentResult.options, { key: layerInfo.class_name });

  const layerIndex = findIndex(experimentResult?.presentationInfo?.layers, { class_name: layerInfo.class_name });

  if (!matchingOption && layerIndex === -1) {
    console.warn('No matching option found for geojson layer', { layerInfo, experimentResult });
    return null;
  }

  return {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseGeoJsonHeatmap-'),
    id: `parseGeoJsonHeatmap-${experimentResult.experimentResultId}=geojson-${layerInfo.class_name}`,
    key: matchingOption?.key || layerInfo?.class_name || `${experimentResult.experimentResultId}-${layerIndex}`,
    displayName:
      getHeatmapDisplayName(formatBracketsOptions, matchingOption?.key || layerInfo?.class_name) ||
      // If the experiment result name is not available, use the artifact url as the name by replacing the file extension and underscores with spaces
      `${replace(replace(layerInfo?.artifact_url, extensionRegex, ''), underscoreAndPathSepRegex, ' ')}`,
    heatmapUrl: geoJsonUrl,
    heatmapType: 'geojson',
  };
};

export const parseLayeredVectorHeatmaps = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadata | null => {
  const layers: PresentationInfoLayer[] = experimentResult.presentationInfo?.layers;

  if (isEmpty(layers)) {
    return null;
  }

  const nestedItems: FeatureMetadata[] = compact(
    map(experimentResult.options, (option) => {
      const matchingLayer = find(layers, { class_name: option.key });
      // For each option, find the matching layer and parse it as a heatmap if it's a geojson layer
      if (matchingLayer) {
        if (matchingLayer?.file_type === 'geojson') {
          // If the layer is a geojson layer, parse it as a geojson heatmap
          return parseGeoJsonHeatmap(experimentResult, matchingLayer, formatBracketsOptions);
        } else if (matchingLayer && matchingLayer.file_type !== 'pmt') {
          // If the layer is not a geojson layer, log a warning, since we don't support other types of layers yet
          console.warn('Unsupported file type for layered heatmap', { matchingLayer, experimentResult, option });
        }
      }

      // If no layer is found, parse the option as a 'pmt-layer' heatmap, which contains the option's color and key
      return {
        ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLayeredVectorHeatmaps-nested-'),
        id: `parseLayeredVectorHeatmaps-nested-${experimentResult.experimentResultId}-${option.key}`,
        displayName: getHeatmapDisplayName(formatBracketsOptions, option?.key),
        heatmapType: 'pmt-layer',
      };
    })
  );

  const pmtLayer = find(layers, { file_type: 'pmt' });
  const pmtHeatmap = pmtLayer ? parsePmtHeatmap(experimentResult, pmtLayer, nestedItems) : null;
  return (
    pmtHeatmap || {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLayeredVectorHeatmaps-'),
      displayName: `${experimentResult.name || experimentResult.flowClassName}`,
      nestedItems,
    }
  );
};

const parseLegacyRasterHeatmaps = (experimentResult: ExperimentResult): FeatureMetadata[] => {
  if (!experimentResult) {
    return [];
  }
  const singleHeatmapUrl = experimentResult?.presentationInfo?.['heatmap_url'];

  if (isEmpty(singleHeatmapUrl)) {
    return [];
  }

  const nestedItems: FeatureMetadata[] = map(experimentResult.options, (heatmapOption) => {
    return {
      id: `${experimentResult.experimentResultId}-${heatmapOption.key}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      show: false,
      selected: false,
      displayName: heatmapOption?.key,
    };
  });

  const newHMResult: FeatureMetadata = {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLegacyRasterHeatmaps-'),
    displayName: experimentResult.name || experimentResult.flowClassName,
    heatmapUrl: singleHeatmapUrl,
    heatmapType: 'dzi',
    nestedItems,
  };

  return [newHMResult];
};

const parseCompositeRasterHeatmapUrls = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadata[] => {
  return flatMap(experimentResult.options, (heatmapOption) => {
    const nestedItems: FeatureMetadata[] = map(heatmapOption.layers, (nestedHeatmap) => {
      return {
        id: `${experimentResult.experimentResultId}-${heatmapOption.key}-${nestedHeatmap.key}`,
        key: nestedHeatmap.key,
        color: nestedHeatmap.color,
        heatmapUrl:
          experimentResult.presentationInfo[heatmapOption.key.concat(nestedHeatmap.key)] ??
          experimentResult.presentationInfo[nestedHeatmap.key],
        show: false,
        selected: false,
        displayName: getHeatmapDisplayName(formatBracketsOptions, nestedHeatmap.key, heatmapOption.key),
        heatmapType: 'dzi',
      };
    });

    const newHMResult: FeatureMetadata = {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseCompositeRasterHeatmapUrls-'),
      id: `${experimentResult.experimentResultId}-${heatmapOption.key}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      heatmapUrl: heatmapOption.with_composite ? experimentResult.presentationInfo[heatmapOption.key] : '',
      heatmapType: 'dzi',
      nestedItems,
      displayName: getHeatmapDisplayName(formatBracketsOptions, heatmapOption.key),
    };

    const hasUrls = Boolean(newHMResult?.heatmapUrl) || some(newHMResult.nestedItems, 'heatmapUrl');

    return hasUrls ? newHMResult : [];
  });
};

export const parseHeatmaps = (slideResults: ExperimentResult[], formatBracketsOptions: FormatBracketsOptions) => {
  const heatmapsResults: FeatureMetadata[] = flatMap(slideResults, (experimentResult) => {
    const heatmaps: FeatureMetadata[] = [];

    const topLevelPmtHeatmap = experimentResult.presentationInfo?.['composite_layer_info'];

    if (topLevelPmtHeatmap) {
      const topLevelPmt = parsePmtHeatmap(experimentResult, topLevelPmtHeatmap);
      if (topLevelPmt) {
        heatmaps.push(topLevelPmt);
      }
    }

    heatmaps.push(...parseLegacyRasterHeatmaps(experimentResult));

    heatmaps.push(...parseCompositeRasterHeatmapUrls(experimentResult, formatBracketsOptions));

    // PMT heatmaps aren't production ready yet, so we don't show them to customers
    if (!experimentResult?.approved) {
      const layeredVectorHeatmap = parseLayeredVectorHeatmaps(experimentResult, formatBracketsOptions);
      if (layeredVectorHeatmap) {
        heatmaps.push(layeredVectorHeatmap);
      }
    }

    return heatmaps;
  });

  const publishedHeatmaps = filter(heatmapsResults, 'approved');
  const unpublishedHeatmaps = filter(heatmapsResults, (heatmap) => !heatmap.approved && Boolean(heatmap.flowClassName));

  const [publishableHeatmapsList, unpublishableHeatmapsList] = partition(
    unpublishedHeatmaps,
    (heatmap) =>
      includes(publishableFlowClasses, heatmap.flowClassName) || includes(publishableResultTypes, heatmap.resultType)
  );
  const publishableHeatmaps = groupBy(publishableHeatmapsList, experimentResultToGroup);
  const internalHeatmaps = groupBy(unpublishableHeatmapsList, experimentResultToGroup);

  return { publishedHeatmaps, publishableHeatmaps, internalHeatmaps };
};

export const parseSlideExperimentResults = (
  slideResults: ExperimentResult[],
  formatBracketsOptions: FormatBracketsOptions
): ParsedResults => {
  const { publishedFeatures, internalFeatures } = parseFeatures(slideResults);

  const { publishedHeatmaps, publishableHeatmaps, internalHeatmaps } = parseHeatmaps(
    slideResults,
    formatBracketsOptions
  );

  const results: ParsedResults = {
    heatmaps: {
      publishedResults: publishedHeatmaps,
      internalResults: publishableHeatmaps,
    },
    features: {
      publishedResults: publishedFeatures,
      internalResults: internalFeatures,
    },
    internalHeatmaps,
  };

  return results;
};

export const getAllFlatMapDeepHeatmaps = (heatmapOverlays: FeatureMetadata[]): FeatureMetadata[] => {
  return flatMapDeep(heatmapOverlays, (heatmap) => [
    heatmap,
    ...(heatmap.nestedItems ? getAllFlatMapDeepHeatmaps(heatmap.nestedItems) : []),
  ]);
};
