import { GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { TaxonomyTag } from 'interfaces/taxonomyTag';
import React from 'react';
import { uuidv4 } from 'utils/helpers';
import { idField } from './taxonomyTagFields';
import { TaxonomyTagDraft } from './types';

export const generateDraftId = () => `draft-${uuidv4()}`;
export const getTaxonomyTagId = (row: TaxonomyTag | TaxonomyTagDraft) =>
  (row as TaxonomyTagDraft).draftId ?? row.id ?? generateDraftId();

export const getTaxonomyTagError = ({
  id,
  apiRef,
}: GridRowParams<TaxonomyTag> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateId = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['id'],
    getId: getTaxonomyTagId,
  });

  const idError = idField.getError?.({ value: apiRef.current.getRowWithUpdatedValues(id, 'id')?.id });

  const hasDuplicateName = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['displayName'],
    getId: getTaxonomyTagId,
  });

  const missingId = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['id'],
  });

  const missingName = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['displayName'],
  });

  return hasDuplicateId
    ? 'Duplicate ID'
    : missingId
    ? 'Missing ID'
    : idError
    ? idError
    : hasDuplicateName
    ? 'Duplicate name'
    : missingName
    ? 'Missing name'
    : '';
};
