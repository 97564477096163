import { SxProps, Theme, ToggleButton, ToggleButtonProps, Tooltip } from '@mui/material';
import React from 'react';

const menuItemSx: SxProps<Theme> = {
  paddingInline: 1.5,
};

export const SlideInteractionOption: React.FunctionComponent<{
  value: string;
  selected?: boolean;
  disabled?: boolean;
  title: string;
  icon: React.ReactNode;
  onClick?: ToggleButtonProps['onClick'];
}> = ({ icon, value, selected, disabled, title, onClick }) => {
  return (
    <Tooltip title={title} placement="bottom">
      {/* Add a span tag to support disabled ToggleButton */}
      <span>
        <ToggleButton
          value={value}
          title={title}
          disabled={disabled}
          selected={selected}
          sx={menuItemSx}
          onClick={onClick}
        >
          {icon}
        </ToggleButton>
      </span>
    </Tooltip>
  );
};
