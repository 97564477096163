import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chip, Grid, Popover, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { filter, find, first, fromPairs, isEmpty, join, map } from 'lodash';
import moment from 'moment';
import React, { useCallback, useRef } from 'react';

import { getCancerTypes } from 'api/cancerTypes';
import { fetchStudies } from 'api/study';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { getProcedureIdentifier } from 'utils/helpers';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { usePermissions } from 'utils/usePermissions';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import CaseDataEntry from './CaseDataEntry';
import CaseDataSectionTitle from './CaseDataSectionTitle';
import IdentifiersSuffix from './IdentifiersSuffix';
import Macro from './Macro';

interface Props {
  procedure: Procedure;
  selectedSlideIds: string[];
}

export const NASection: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <Grid item xs={5}>
    <Typography variant="body2" component="span">
      N/A
    </Typography>
  </Grid>
);

const CaseDataPopover: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ procedure, selectedSlideIds }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const procedureLabel = getProcedureIdentifier(procedure);
  const { biopsySiteTypes } = useProceduresFieldsContext({
    enabled: open,
  });

  const { data: studies } = useQuery(['studies', procedure.labId], fetchStudies(procedure.labId), {
    enabled: open,
  });

  const currentStudy = procedure.studyId
    ? find(studies, (study) => procedure.studyId === study.id)?.name ?? 'Loading...'
    : undefined;

  const { data: cancerTypes } = useQuery({
    queryKey: ['cancerTypes', procedure.labId],
    queryFn: ({ signal }) => getCancerTypes(procedure.labId, signal),
    initialData: {},
    enabled: open,
  });

  const cancerType = !isNaN(Number(procedure.cancerTypeId))
    ? cancerTypes?.[procedure.cancerTypeId]?.displayName ?? 'Loading...'
    : undefined;

  const biopsySites = join(
    map(
      filter(procedure.slides, (slide) => Boolean(slide?.biopsySiteId)),
      (slide) => find(biopsySiteTypes, { id: Number(slide.biopsySiteId) })?.displayName
    ),
    ', '
  );

  const buttonRef = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback(() => {
    setAnchorEl(buttonRef.current);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const clinicalData = procedure.clinicalData;
  const hasClinicalData = !isEmpty(clinicalData);

  const { uiSettings } = useUiSettings();
  const smokingStatusToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['smokingStatus'], ({ value, displayName }) => [value, displayName]) || []
  );

  const { hasPermission } = usePermissions();
  const showCaseIdentifiers = hasPermission(Permission.UseNucleaiExperimentalFeatures);

  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const firstSlide = find(procedure.slides, { id: first(selectedSlideIds) });

  return (
    <Grid item>
      <Chip
        ref={buttonRef}
        label={procedureLabel}
        onClick={handleClick}
        deleteIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onDelete={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: -8,
        }}
      >
        <Grid container direction="row">
          <Grid container paddingX={3} paddingTop={1} paddingBottom={2} columns={5} width="375px">
            <Grid item xs={5}>
              <Typography variant="h6" component="h2">
                {procedureLabel}
              </Typography>
            </Grid>

            <CaseDataSectionTitle>Metadata</CaseDataSectionTitle>
            <CaseDataEntry label="Cancer Type" value={cancerType} />
            <CaseDataEntry label="Primary Diagnosis" value={clinicalData?.primaryDiagnosis} />
            <CaseDataEntry label="Biopsy Site" value={biopsySites || undefined} />
            <CaseDataEntry
              label="Stain Types"
              value={
                isLoadingStainTypeOptions
                  ? 'Loading...'
                  : join(
                      map(
                        filter(procedure.slides, (slide) => Boolean(slide?.stainingType)),
                        (slide) => stainTypeIdToDisplayName(slide.stainingType)
                      ),
                      ', '
                    ) || undefined
              }
            />
            <CaseDataEntry
              label="Date of Biopsy"
              value={clinicalData?.biopsyDate ? moment(clinicalData?.biopsyDate).format('ll') : undefined}
            />
            <CaseDataEntry label="Study" value={currentStudy} />

            <CaseDataSectionTitle>General Information</CaseDataSectionTitle>
            {hasClinicalData ? (
              <>
                <CaseDataEntry label="Year of Birth" value={clinicalData?.yearOfBirth} />
                <CaseDataEntry label="Sex" value={clinicalData?.sex} />
                <CaseDataEntry label="BMI" value={clinicalData?.bmi} />
                <CaseDataEntry label="Smoking Status" value={smokingStatusToDisplayText[clinicalData?.smokingStatus]} />
              </>
            ) : (
              <NASection />
            )}

            <CaseDataSectionTitle>Clinical Data</CaseDataSectionTitle>
            {hasClinicalData ? (
              <>
                <CaseDataEntry label="Treatment" value={clinicalData?.treatmentRegimen} />
                <CaseDataEntry
                  label="Beginning of Treatment Date"
                  value={
                    clinicalData?.treatmentStartDate ? moment(clinicalData?.treatmentStartDate).format('ll') : undefined
                  }
                />
                <CaseDataEntry
                  label="End of Treatment Date"
                  value={
                    clinicalData?.treatmentEndDate ? moment(clinicalData?.treatmentEndDate).format('ll') : undefined
                  }
                />
                <CaseDataEntry label="ECOG" value={clinicalData?.ecogScore} />
                <CaseDataEntry label="Genomics" value={'-'} />
              </>
            ) : (
              <NASection />
            )}

            <CaseDataSectionTitle>Outcome</CaseDataSectionTitle>

            {hasClinicalData ? (
              <>
                <CaseDataEntry
                  label="PFS (days)"
                  value={!isNaN(Number(clinicalData?.pfsDays)) ? `${clinicalData?.pfsDays} days` : undefined}
                />
                <CaseDataEntry
                  label="Overall Survival (days)"
                  value={
                    !isNaN(Number(clinicalData?.overallSurvivalDays))
                      ? `${clinicalData?.overallSurvivalDays} days`
                      : undefined
                  }
                />
                <CaseDataEntry label="Objective Response" value={clinicalData?.recistObjectiveResponse} />
                <CaseDataEntry label="Overall Survival Event" value={clinicalData?.overallSurvivalEvent} />
              </>
            ) : (
              <NASection />
            )}
            {showCaseIdentifiers && (
              <IdentifiersSuffix
                procedureId={procedure.id}
                studyId={procedure.studyId}
                slides={procedure.slides}
                labId={procedure.labId}
              />
            )}
          </Grid>
          <Macro slide={firstSlide} />
        </Grid>
      </Popover>
    </Grid>
  );
};

export default CaseDataPopover;
