import { compact, find, map } from 'lodash';
import { useMemo } from 'react';

import { Accession } from 'interfaces/accession';
import { Procedure } from 'interfaces/procedure';
import { useFormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { SlideWithChannelAndResults, getSlidesChannelsAndResults } from './utils';

export const useSlideChannelsAndResults = (procedure: Procedure | Accession, selectedIds: string[]) => {
  const formatBracketsOptions = useFormatBracketsOptions(false);

  const slidesChannelsAndResults = useMemo(
    () => getSlidesChannelsAndResults(procedure, formatBracketsOptions),
    [procedure, JSON.stringify(formatBracketsOptions)]
  );

  const selectedSlidesWithChannelsAndResults: SlideWithChannelAndResults[] = useMemo(
    () =>
      compact(
        map(selectedIds, (slideId, viewerIndex) => {
          const slide = find(procedure.slides, { id: slideId });
          if (!slide) {
            return undefined;
          }

          return {
            viewerIndex,
            ...(slidesChannelsAndResults[slide.id] || {}),
            ...slide,
          };
        })
      ),
    [slidesChannelsAndResults, selectedIds]
  );

  return { selectedSlidesWithChannelsAndResults };
};
