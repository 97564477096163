import { Slide } from 'interfaces/slide';
import { first, flatMap, last, split } from 'lodash';
import React, { useState } from 'react';
import ImageWithAdjustedLevels, { adjustmentSettingsPresets } from './ImageWithAdjustedLevels';

const slideEncodingToBitDepth: Record<Slide['encoding'], 8 | 16> = {
  rgb: 8,
  uint8: 8,
  uint16: 16,
  float: 8,
};

const fileFormatMaxBitDepth: Record<string, 8 | 16> = {
  jpg: 8,
  png: 16,
};

const getImageBitDepth = (slideEncoding: Slide['encoding'], imageUrl: string): 8 | 16 => {
  if (!imageUrl) {
    return 8;
  }
  try {
    const fileFormat = last(split(new URL(imageUrl).pathname, '.'));
    const maxBitDepth = fileFormatMaxBitDepth[fileFormat] || 8;
    const slideBitDepth = slideEncodingToBitDepth[slideEncoding];
    return Math.min(slideBitDepth, maxBitDepth) as 8 | 16;
  } catch (error) {
    console.error('Error getting image bit depth, returning 8 as default', error);
    return 8;
  }
};

interface ImageWithFallbackProps {
  imageUrls: string[];
  isMultiplex?: boolean;
  slideEncoding?: Slide['encoding'];
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  imageUrls,
  isMultiplex = false,
  slideEncoding = 'uint8',
}) => {
  const [currentImageUrlIndex, setCurrentImageUrlIndex] = useState(0);
  // we are starting to save our thumbnails in png format so in the in between phase we need to check for jpg as well
  const imageUrlsWithJpgFallback = flatMap(imageUrls, (url) => [url, url?.replace('.png', '.jpg')]);
  const [bitDepth, setBitDepth] = useState<8 | 16>(getImageBitDepth(slideEncoding, first(imageUrlsWithJpgFallback)));
  const [colorAdjustmentSettings, setColorAdjustmentSettings] = useState(
    isMultiplex ? adjustmentSettingsPresets.multiplex?.[bitDepth] || {} : {}
  );

  const handleImageError = () => {
    if (currentImageUrlIndex === imageUrlsWithJpgFallback.length - 1) {
      return;
    }
    setCurrentImageUrlIndex(currentImageUrlIndex + 1);
    setBitDepth(getImageBitDepth(slideEncoding, imageUrlsWithJpgFallback[currentImageUrlIndex]));
    setColorAdjustmentSettings(isMultiplex ? adjustmentSettingsPresets.multiplex[bitDepth] : {});
  };

  return (
    <ImageWithAdjustedLevels
      src={imageUrlsWithJpgFallback[currentImageUrlIndex]}
      onError={handleImageError}
      bitDepth={bitDepth}
      {...colorAdjustmentSettings}
    />
  );
};

export default ImageWithFallback;
