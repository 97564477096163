import { Controller, LinearInterpolator } from '@deck.gl/core/typed';
import { OrthographicMapState } from './OrthographicMapState';

export class OrthographicMapController extends Controller<OrthographicMapState> {
  ControllerState = OrthographicMapState;

  transition = {
    transitionDuration: 300,
    transitionInterpolator: new LinearInterpolator({
      transitionProps: {
        compare: ['zoom', 'bearing', 'pitch', 'target'],
        required: ['target', 'zoom'],
      },
    }),
  };

  dragMode: 'pan' | 'rotate' = 'pan';

  setProps(props: any) {
    // setProps(props: ControllerProps & OrthographicMapStateProps) {
    props.target = props.target || [0, 0, 0];
    const oldProps = this.props;

    super.setProps(props);

    const dimensionChanged = !oldProps || oldProps.height !== props.height || oldProps.width !== props.width;
    if (dimensionChanged) {
      // Dimensions changed, normalize the props
      this.updateViewport(
        new this.ControllerState({
          makeViewport: this.makeViewport,
          ...props,
          ...this.state,
        })
      );
    }
  }
}
