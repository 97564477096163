/* eslint-disable no-undef */
import { CompositeLayer, Layer } from '@deck.gl/core/typed';
import { Matrix4 } from '@math.gl/core';
import { compact, isEmpty, map, omit, some } from 'lodash';
import { ClassicComponentClass } from 'react';

import { ColorPaletteExtension } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/extensions';
import {
  MultiScaleImageLayerProps,
  PhotometricInterpretation,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/types';
import { isInterleaved } from '../utils';
import { BackgroundLayer } from './backgroundLayer';
import CustomTileLayer from './customTileLayer';
import getTileDataFromUrls from './getTileData';
import { getTileDataFromUrlsWebWorker } from './getTileDataFromUrlsWebWorker';
import { renderBitmapBinarySubLayers, renderXRSubLayers } from './sublayerRenderers';
import { MultiScaleImageData } from './utils';

export const BACKGROUND_LAYER_ID = '__background__' as const;
export const OVERVIEW_LAYER_ID = '__overview-only__' as const;

const defaultProps: ClassicComponentClass['defaultProps'] = {
  pickable: { type: 'boolean', value: true, compare: true },
  onHover: { type: 'function', value: null, compare: false },
  contrastLimits: { type: 'array', value: [], compare: true },
  layerOpacities: { type: 'array', value: [], compare: true },
  layersVisible: { type: 'array', value: [], compare: true },
  onClick: { type: 'function', value: null, compare: true },
  excludeBackground: { type: 'boolean', value: false, compare: true },
  extensions: { type: 'array', value: [new ColorPaletteExtension()], compare: true },
  skipWebWorker: { type: 'boolean', value: true, compare: true },
};

const MultiScaleImageLayer = class<S extends string[] = []> extends CompositeLayer<MultiScaleImageLayerProps<S>> {
  state: {
    getTileData: ({
      index: { x, y, z },
    }: {
      index: { x: number; y: number; z: number };
    }) => Promise<MultiScaleImageData>;
    backgroundData: Promise<MultiScaleImageData>;
  };

  updateState: Layer<MultiScaleImageLayerProps<S>>['updateState'] = ({ props, oldProps }) => {
    if (
      Boolean(props.skipWebWorker) !== Boolean(oldProps.skipWebWorker) ||
      props.layerSource !== oldProps.layerSource ||
      props.selections !== oldProps.selections ||
      props.layersVisible !== oldProps.layersVisible ||
      Boolean(props.loadChannelsWhenHidden) !== Boolean(oldProps.loadChannelsWhenHidden)
    ) {
      const isRgb = props.layerSource.meta.photometricInterpretation === PhotometricInterpretation.RGB;
      const isPng = props.layerSource.meta.isPng;
      const minZoom = props.layerSource.minLevel - props.layerSource.maxLevel;

      const getTileData: typeof this.state.getTileData = ({ index: { x, y, z } }) => {
        // Early return if no selections
        if (isEmpty(props.selections)) {
          console.warn('No selections provided to getTileDataFromUrls');
          return null;
        }
        const urls = map(props.selections, (selection, channelIndex) =>
          props.loadChannelsWhenHidden || props.layersVisible[channelIndex]
            ? props.layerSource.getTileURL({ x, y, z, ...selection })
            : null
        );
        return props.skipWebWorker
          ? getTileDataFromUrls(urls, isRgb, isPng, props.viewerIndex)
          : getTileDataFromUrlsWebWorker(urls, isRgb, isPng, props.viewerIndex);
      };
      const newState: typeof this.state = {
        getTileData,
        backgroundData: getTileData({ index: { x: 0, y: 0, z: minZoom } }),
      };
      this.setState(newState);
    }
  };

  renderLayers() {
    const propWithoutZoomBounds = omit(this.props, 'minZoom', 'maxZoom');
    const { baseImageSource, layerSource, onTileError, id, layerOpacities, layersVisible, overviewLayer } =
      propWithoutZoomBounds;

    const minZoom = layerSource.minLevel - layerSource.maxLevel;
    // Max zoom is 0 because that is the basis for the coordinate system (based on the image pixel size)
    const maxZoom = 0;

    const multipleChannelsVisible = some(
      layersVisible,
      (visible, layerIndex) => visible && layerOpacities[layerIndex] !== 0
    );

    const baseImageSize = baseImageSource ? baseImageSource.getImageSize() : undefined;
    const layerSourceSize = baseImageSize ? layerSource.getImageSize() : undefined;

    const scale =
      baseImageSize && layerSourceSize
        ? [baseImageSize.height / layerSourceSize.height, baseImageSize.width / layerSourceSize.width, 1]
        : undefined;

    const isRgb = this.props.layerSource.meta.photometricInterpretation === PhotometricInterpretation.RGB;

    // Does the source have interleaved data (rgb) or intensity values (R only)?
    const interleavedSource = isInterleaved(layerSource.shape);

    const avoidOverlappingTiles =
      (interleavedSource && multipleChannelsVisible) || some(layerOpacities, (op) => op !== 100);

    const baseTileLayerProps = {
      id: `Tiled-Image-${id}-${layerSource.getUniqueId()}`,
      tileSize: layerSource.getTileSize(),
      minZoom,
      preloadZoomLevels: 1,
      maxZoom,
      modelMatrix: scale ? new Matrix4().scale(scale) : undefined,

      renderSubLayers: (isRgb ? renderBitmapBinarySubLayers : renderXRSubLayers) as any,
      onTileError: onTileError || layerSource.onTileError,
      refinementStrategy: avoidOverlappingTiles ? ('no-overlap' as const) : ('best-available' as const),
      getTileData: this.state.getTileData,
      updateTriggers: { getTileData: this.state.getTileData },
    };

    const tiledLayer = new CustomTileLayer<MultiScaleImageData, MultiScaleImageLayerProps<S>>(propWithoutZoomBounds, {
      ...baseTileLayerProps,
      maxCacheSize: 5000,
    });

    const excludeBackground = propWithoutZoomBounds.excludeBackground;
    const backgroundLayerId = `${overviewLayer ? OVERVIEW_LAYER_ID : BACKGROUND_LAYER_ID}-${baseTileLayerProps.id}`;
    const bgLayer =
      (overviewLayer || !excludeBackground) && this.state.getTileData
        ? new BackgroundLayer({
            ...propWithoutZoomBounds,
            ...baseTileLayerProps,
            id: backgroundLayerId,
            data: this.state.backgroundData,
          })
        : undefined;

    const foregroundLayer = !overviewLayer ? tiledLayer : null;
    return compact([bgLayer, foregroundLayer]);
  }
};

MultiScaleImageLayer.layerName = 'MultiScaleImageLayer';
MultiScaleImageLayer.defaultProps = defaultProps;
export default MultiScaleImageLayer;
