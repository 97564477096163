import { find } from 'lodash';

import { FilterKey } from 'interfaces/cohort';
import { BooleanParam, JsonParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { getFeatureObjectFromFeatureFiltersQueryParams, getFiltersFromQueryObject } from 'utils/advancedFilterAdapter';
import { resultsModeQueryParam } from 'utils/useEncodedFilters';
import useCohortFilters from './useCohortFilters';

const useSearchFiltersFromQueryParams = (includeMainFilters: boolean = true) => {
  const { cohorts } = useCohortFilters();

  const paramsConfig = {
    advancedSearchDrawerOpen: BooleanParam,
    saveCohortModalOpen: BooleanParam,
    queryId: StringParam,
    cohortId: StringParam,
    filters: JsonParam,
    features: JsonParam,
    clinicalData: JsonParam,
    labId: StringParam,
    resultsMode: resultsModeQueryParam,
    page: NumberParam,
    slidesMode: BooleanParam,
    searchTerm: StringParam,
  };

  const [queryParams, setQueryParams] = useQueryParams(paramsConfig);

  const cohort = find(cohorts, { id: queryParams.cohortId });

  const isQuery = !cohort?.casesSnapshot;

  const initialQueryObjectForFilters =
    cohort && !isQuery
      ? {
          queryId: queryParams.queryId,
          ...cohort?.queryObject.filters,
          ...cohort?.queryObject.features,
          ...cohort?.queryObject.clinicalData,
        }
      : {
          queryId: queryParams.queryId,
          ...queryParams.filters,
          ...queryParams.features,
          ...queryParams.clinicalData,
        };

  const searchFiltersFromQueryParams: Record<FilterKey, any> = getFiltersFromQueryObject(
    initialQueryObjectForFilters,
    includeMainFilters
  );

  const featuresFromQueryParams = getFeatureObjectFromFeatureFiltersQueryParams(
    queryParams?.features || (cohort?.queryObject?.features as Record<string, string>)
  );

  return {
    searchFiltersFromQueryParams,
    setQueryParams,
    queryParams,
    featuresFromQueryParams,
    paramsConfig,
  };
};

export default useSearchFiltersFromQueryParams;
