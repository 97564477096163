import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { groupBy, orderBy } from 'lodash';
import { HeatmapTree } from '../Heatmaps/Heatmaps';
import { ProtomapTree } from '../Heatmaps/ProtomapTileControl';

interface Props {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  title: string;
  heatmaps: FeatureMetadata[];
  filterText: string;
  hideOrchestrationId?: boolean;
}

const InternalHeatmaps: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slideId,
  viewerIndex,
  stainTypeId,
  title,
  heatmaps,
  filterText,
  hideOrchestrationId,
}) => {
  const [expand, setExpand] = useState(false);

  const { dzi: dziHeatmaps, pmt: pmtHeatmaps } = useMemo(
    () => groupBy(orderBy(heatmaps, 'createdAt', 'desc'), 'heatmapType'),
    [heatmaps]
  );

  return (
    <Accordion
      square
      expanded={expand}
      onChange={() => setExpand((prev) => !prev)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={6}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid item>
            <Chip label="Internal Results" />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        <HeatmapTree
          hideOpacityWhenNotSelected
          slideId={slideId}
          viewerIndex={viewerIndex}
          stainTypeId={stainTypeId}
          heatmaps={dziHeatmaps}
          filterText={filterText}
          hideOrchestrationId={hideOrchestrationId}
        />
        <ProtomapTree
          pmtHeatmaps={pmtHeatmaps}
          slideId={slideId}
          viewerIndex={viewerIndex}
          textSearch={filterText}
          stainTypeId={stainTypeId}
          hideOrchestrationId={hideOrchestrationId}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default InternalHeatmaps;
