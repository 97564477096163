import {
  Dictionary,
  compact,
  filter,
  fromPairs,
  isEmpty,
  isEqual,
  join,
  keyBy,
  map,
  some,
  sortBy,
  uniqBy,
} from 'lodash';
import React from 'react';

import { OSDPixelSource } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/OSDPixelSource';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import {
  SlideDziChannel,
  canLoadBaseSlide,
  useSlidesBaseSources,
  useSlidesHeatmapDziSources,
} from './SlidesViewer/DeckGLViewer/utils/setupSlideSources';
import { generateSlidePixelSource } from './SlidesViewer/DeckGLViewer/utils/slidePixelSource';

export interface ImagePyramid {
  slideId: string;
  layerSource: OSDPixelSource<SlideDziChannel>;
  layersOrder: string[];
}

export type HeatmapsImagePyramids = Dictionary<OSDPixelSource<SlideDziChannel>>;

export const useSlideImages = (slides?: SlideWithChannelAndResults[], isPlaceholderData?: boolean) => {
  const { isLoadingStainTypeOptions, stainTypeIdToDisplayName } = useStainTypeIdToDisplayName();

  const slideBaseDziQueries = useSlidesBaseSources({ slides, enabled: !isEmpty(slides), isPlaceholderData });

  const isLoadingSlides = some(slideBaseDziQueries, 'isLoading');

  const [slidesBaseImagePyramids, setSlidesBaseImagePyramids] = React.useState<Dictionary<ImagePyramid>>({});
  React.useEffect(() => {
    const uniqueSlides = uniqBy(slides, 'id');
    const slideBaseImagePyramidsUpdates = compact(
      map(uniqueSlides, (slide) => {
        const slideId = slide.id;

        const slideBaseDziResponses = sortBy(
          map(
            filter(slideBaseDziQueries, (query) => query.data?.slideId === slideId),
            'data'
          ),
          'id'
        );
        if (isEmpty(slideBaseDziResponses)) {
          return undefined;
        }
        const layersOrder = map(slideBaseDziResponses || {}, 'id');
        const previousLayerOrder = slidesBaseImagePyramids[slideId]?.layersOrder;
        if (isEqual(layersOrder, previousLayerOrder)) {
          // No need to update the image pyramid if the layers order hasn't changed
          return undefined;
        }

        const layerSource = generateSlidePixelSource({
          slideTileSources: map(slideBaseDziResponses, 'tileSource'),
          sourceName:
            slideBaseDziQueries.length > 1
              ? `Channels ${slideId}`
              : `Slide ${slideId}${
                  isLoadingStainTypeOptions ? '' : `(${stainTypeIdToDisplayName(slide.stainingType)})`
                }`,
        });

        return { slideId, layerSource, layersOrder };
      })
    );
    if (isEmpty(slideBaseImagePyramidsUpdates)) {
      return;
    }
    const updatedBaseImagePyramid = keyBy(slideBaseImagePyramidsUpdates, 'slideId');
    if (!isEmpty(updatedBaseImagePyramid)) {
      setSlidesBaseImagePyramids((prev) => ({ ...prev, ...updatedBaseImagePyramid }));
    }
  }, [slides, slideBaseDziQueries, stainTypeIdToDisplayName, isLoadingStainTypeOptions]);

  // Wait for all base slide images to be loaded
  const slideHeatmapDziQueries = useSlidesHeatmapDziSources({ slides, enabled: !isLoadingSlides, isPlaceholderData });
  const [slidesHeatmapsImagePyramids, setSlidesHeatmapsImagePyramids] = React.useState<
    Dictionary<HeatmapsImagePyramids>
  >({});

  React.useEffect(() => {
    const uniqueSlides = uniqBy(slides, 'id');
    const updatedHeatmapsImagePyramid = fromPairs(
      compact(
        map(uniqueSlides, (slide) => {
          const slideId = slide.id;
          const slideHeatmapDziResponses = sortBy(
            map(
              filter(slideHeatmapDziQueries, (query) => query.data?.slideId === slideId),
              'data'
            ),
            'id'
          );
          if (isEmpty(slideHeatmapDziResponses)) {
            return undefined;
          }
          const heatmapLayerSources = fromPairs(
            map(
              // Only load the heatmaps that haven't been loaded yet
              filter(slideHeatmapDziResponses, ({ id }) => !slidesHeatmapsImagePyramids?.[slideId]?.[id]),
              ({ id, tileSource }) => [
                id,
                generateSlidePixelSource({ slideTileSources: [tileSource], sourceName: `Heatmaps ${slideId}` }),
              ]
            )
          );

          if (isEmpty(heatmapLayerSources)) {
            return undefined;
          }

          return [slideId, heatmapLayerSources];
        })
      )
    );
    if (!isEmpty(updatedHeatmapsImagePyramid)) {
      setSlidesHeatmapsImagePyramids((prev) =>
        fromPairs(
          map(uniqueSlides, ({ id }) => [
            id,
            // Merge the new heatmaps with the previous ones
            { ...(prev?.[id] || {}), ...(updatedHeatmapsImagePyramid[id] || {}) },
          ])
        )
      );
    }
  }, [slides, slidesHeatmapsImagePyramids, slideBaseDziQueries, slideHeatmapDziQueries, stainTypeIdToDisplayName]);

  return {
    slidesBaseImagePyramids,
    slidesHeatmapsImagePyramids,
    canLoadSlides: !isEmpty(slides) && some(slides, canLoadBaseSlide),
    error:
      join(compact(map(slideBaseDziQueries, (query) => (query.error ? String(query.error) : undefined))), ', ') || '',
    isLoadingSlides,
    isLoadingHeatmaps: some(slideHeatmapDziQueries, 'isLoading'),
    baseSlideLoadingStates: map(
      slides,
      (slide) =>
        !canLoadBaseSlide(slide, isPlaceholderData) ||
        some(
          filter(slideBaseDziQueries, (query) => query.data?.slideId === slide.id),
          'isLoading'
        )
    ),
  };
};
