import { createTheme, PaletteOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    custom?: {
      background?: {
        transparent: {
          white: string;
          black: string;
        };
      };
    };

    softContrast?: Palette['primary'];
  }
  interface PaletteOptions {
    custom?: {
      background?: {
        transparent: {
          white: string;
          black: string;
        };
      };
    };
    softContrast?: PaletteOptions['primary'];
  }
}

const themeColors: Partial<PaletteOptions> = {
  primary: {
    main: '#be62a5',
    light: '#d8a4ca',
  },
  secondary: {
    main: '#6b7c93',
    light: '#d8dce2',
  },
  warning: {
    main: '#f3bb45',
    light: '#f9d79c',
  },
  error: {
    main: '#d32f2f',
    light: '#f79999',
    dark: '#a85959',
  },
  softContrast: {
    light: '#F9F9FB',
    main: '#D8DCE2',
    contrastText: '#6B7C93',
  },
};

const customStyles = {
  background: {
    transparent: {
      white: 'rgba(255, 255, 255, 0.5)',
      black: 'rgba(0, 0, 0, 0.5)',
    },
  },
};

const themeTypography = {
  typography: {
    fontFamily: 'Ubuntu, sans-serif',
    h1: {
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '0.8rem',
    },
    subtitle1: {
      fontSize: 16,
    },
  },
};

const components = {
  MuiCardHeader: {
    styleOverrides: {
      title: {
        fontSize: 16,
        fontWeight: 700,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 20,
        minWidth: 600,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: 16,
        fontWeight: 700,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        maxWidth: 300,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      PaperProps: {
        onClick: (e: any) => {
          e.stopPropagation();
        },
      },
    },
  },
};

export const lightTheme = createTheme({
  ...themeTypography,
  palette: {
    mode: 'light',
    ...themeColors,
    custom: customStyles,
  },
  components: {
    ...components,
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        fullWidth: true,
      },
    },
  },
});

export const darkTheme = createTheme({
  ...themeTypography,
  palette: {
    mode: 'dark',
    ...themeColors,
    custom: customStyles,
  },
  components: {
    ...components,
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
        fullWidth: true,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled',
      },
    },
  },
});
