import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React from 'react';

import { getExternalLabelOptions } from 'api/externalLabels';
import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import Loader from 'components/Loader';
import { ExternalLabel } from 'interfaces/externalLabel';
import { externalLabelFields } from 'interfaces/externalLabel/externalLabelFields';
import { uuidv4 } from 'utils/helpers';
import { useExternalLabelsColumns } from './useExternalLabelsColumns';

const columnVisibilityModel = { id: false };

const generateDraftId = () => `draft-${uuidv4()}`;

const getRowId = (row: any) => row.id ?? generateDraftId();

export const ExternalLabelsDataGrid = () => {
  const { data: dbExternalLabels, isLoading: isLoadingExternalLabels } = useQuery(['externalLabels'], () =>
    getExternalLabelOptions()
  );
  const [draftExternalLabels, setDraftExternalLabels] = React.useState<ExternalLabel[]>([]);
  const externalLabels = React.useMemo(
    () => [...draftExternalLabels, ...(dbExternalLabels || [])],
    [dbExternalLabels, draftExternalLabels]
  );

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const apiRef = useGridApiRef();

  const { getRowsWithChanges } = useTableEditingContext<ExternalLabel>();

  const rowsWithChanges = getRowsWithChanges(externalLabels);

  const handleRowModesModelChange: DataGridProps<ExternalLabel>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) => {
      handleRowModesModelChangeWithoutDraftIds(newRowModesModel, setRowModesModel, draftExternalLabels);
    },
    [draftExternalLabels]
  );

  const columns = useExternalLabelsColumns({
    noRows: isEmpty(rowsWithChanges),
    apiRef,
    rowsWithChanges,
    draftExternalLabels,
    rowModesModel,
    setDraftExternalLabels,
    setRowModesModel,
  });

  const getExternalLabelCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames({
        apiRef,
        requiredFields: ['text'],
        uniqueFieldGroups: [['text']],
        draftRows: draftExternalLabels,
        fieldsToCheckForErrors: externalLabelFields,
      }),
    [apiRef, draftExternalLabels]
  );
  const handleAddExternalLabel = () => {
    const draftId = generateDraftId();
    const newExternalLabel: ExternalLabel = { text: '', id: draftId };
    setDraftExternalLabels((oldDraftExternalLabels) => [newExternalLabel, ...oldDraftExternalLabels]);
    setRowModesModel((oldRowModesModel) => ({
      ...oldRowModesModel,
      [newExternalLabel.id]: { mode: GridRowModes.Edit },
    }));
  };

  return !isLoadingExternalLabels ? (
    <SettingsDataGrid
      apiRef={apiRef}
      addText="Add External Label"
      handleAdd={handleAddExternalLabel}
      columnVisibilityModel={columnVisibilityModel}
      getCellClassName={getExternalLabelCellClassName}
      rows={rowsWithChanges}
      columns={columns}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      getRowId={getRowId}
    />
  ) : (
    <Loader />
  );
};
