import { filter, findIndex, first } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { useFilteredCaseSlideThumbnailsData } from 'components/Procedure/useFilteredCaseIds';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { CaseSlideThumbnailData, Procedure } from 'interfaces/procedure';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { BaseNavigationPanel } from './BaseNavigationPanel';

export const SlideNavigationPanel: React.FunctionComponent<
  React.PropsWithChildren<{
    currentCase: Procedure;
    studyId?: string;
    selectedSlideIds: string[];
  }>
> = ({ currentCase, studyId, selectedSlideIds }) => {
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const currentCaseId = pendingSlidesMode ? null : currentCase?.id;

  const { queryParams, setQueryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const currentSlideId = first(selectedSlideIds) || first(currentCase?.slides)?.id;

  const { isInitialLoading: isInitialLoadingSlideIds, data: allSlideThumbnailsData } =
    useFilteredCaseSlideThumbnailsData(studyId);

  const caseSlideThumbnailsData = filter(allSlideThumbnailsData, ({ caseId }) =>
    pendingSlidesMode ? caseId == null : caseId !== null
  );

  const pageSize = queryParams.pageSize || DEFAULT_PAGE_SIZE;

  const { getUrlToSlidePage } = useNavigationToViewerPage();

  const currentSlideIndex = findIndex(caseSlideThumbnailsData, { caseId: currentCaseId, slideId: currentSlideId });
  const totalSlides = caseSlideThumbnailsData ? caseSlideThumbnailsData.length : 0;

  const previousCaseIdSlideId: CaseSlideThumbnailData | null =
    totalSlides > 0 ? caseSlideThumbnailsData[(currentSlideIndex - 1 + totalSlides) % totalSlides] : null;
  const previousCaseIdSlideIdPage = caseSlideThumbnailsData
    ? Math.floor(((currentSlideIndex - 1 + totalSlides) % totalSlides) / pageSize) + 1
    : null;
  const previousLinkTo = previousCaseIdSlideId
    ? getUrlToSlidePage({
        slideId: previousCaseIdSlideId.slideId,
        caseId: previousCaseIdSlideId.caseId,
        newPage: previousCaseIdSlideIdPage,
      })
    : null;

  const nextCaseIdSlideId: CaseSlideThumbnailData | null =
    totalSlides > 0 ? caseSlideThumbnailsData[(currentSlideIndex + 1 + totalSlides) % totalSlides] : null;
  const nextCaseIdSlideIdPage = caseSlideThumbnailsData
    ? Math.floor(((currentSlideIndex + 1 + totalSlides) % totalSlides) / pageSize) + 1
    : null;
  const nextLinkTo = nextCaseIdSlideId
    ? getUrlToSlidePage({
        slideId: nextCaseIdSlideId.slideId,
        caseId: nextCaseIdSlideId.caseId,
        newPage: nextCaseIdSlideIdPage,
      })
    : null;

  // The default value is for the case that somehow the navigation list is empty but we still have slide data and the page is loaded
  let navigationStageText = '1/1';
  if (currentSlideIndex !== -1 && totalSlides > 0) {
    navigationStageText = `${currentSlideIndex + 1} / ${totalSlides} slides`;
  }

  const indexInSlideThumbnailsData = findIndex(caseSlideThumbnailsData, {
    caseId: currentCase?.id,
    slideId: currentSlideId,
  });
  const currentPage = caseSlideThumbnailsData ? Math.floor(indexInSlideThumbnailsData / pageSize) : null;
  const totalPages = Math.floor(totalSlides / pageSize) + 1;
  React.useEffect(() => {
    // If the current image's page is not filtered, update the filter
    if (currentPage !== null && currentPage >= 0 && currentPage < totalPages && currentPage + 1 !== queryParams.page) {
      setQueryParams({ page: currentPage + 1 });
    }
  }, [queryParams.page, currentPage, totalPages]);

  return (
    <BaseNavigationPanel
      nextLinkTo={nextLinkTo}
      previousLinkTo={previousLinkTo}
      isInitialLoading={isInitialLoadingSlideIds}
      navigationStageText={navigationStageText}
    />
  );
};
