import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TreeView } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import {
  ClassificationBinningParams,
  InferenceModelData,
  ModelInference,
  OrchestrationInference,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import { Dictionary, map } from 'lodash';
import React from 'react';
import { ModelClassificationById, OrchestrationBySlideByType } from '..';
import { modelTypesByApiModelValue } from '../../Jobs/inferenceFieldsOptions';
import ModelRow from './model/ModelRow';

export interface InferenceModelProps {
  studyId: string;
  stain: string;
  modelType: string;
  modelInferences: {
    // model url
    [key: string]: ModelInference;
  };
  slides: Dictionary<SlideInferenceResults>;
  selectedOrchestrations: OrchestrationBySlideByType;
  setSelectedOrchestrations: (
    slideIds: string[],
    model: InferenceModelData,
    modelType: string,
    orchestration: OrchestrationInference
  ) => void;
  setModelClassificationByModelId?: (modelId: string, classification: string) => void;
  modelClassificationByModelId?: ModelClassificationById;
  setSelectedOrchestrationsByClassificationType?: (modelType: string, previousModelType?: string) => void;
  setIntensityClassificationBinning?: (modelId: string, binning: ClassificationBinningParams) => void;
}

const InferenceModel: React.FC<React.PropsWithChildren<InferenceModelProps>> = ({
  studyId,
  stain,
  modelType,
  modelInferences,
  slides,
  selectedOrchestrations,
  setSelectedOrchestrations,
  modelClassificationByModelId,
  setModelClassificationByModelId,
  setSelectedOrchestrationsByClassificationType,
  setIntensityClassificationBinning,
}) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {modelTypesByApiModelValue[modelType]?.text ?? modelType}
      </AccordionSummary>
      <AccordionDetails>
        <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
          {map(modelInferences, (modelInference, modelInferenceUrl) => (
            <ModelRow
              key={modelInferenceUrl}
              stain={stain}
              modelType={modelType}
              modelInference={modelInference}
              slides={slides}
              selectedOrchestrations={selectedOrchestrations}
              setSelectedOrchestrations={setSelectedOrchestrations}
              studyId={studyId}
              modelClassificationByModelId={modelClassificationByModelId}
              setModelClassificationByModelId={setModelClassificationByModelId}
              setSelectedOrchestrationsByClassificationType={setSelectedOrchestrationsByClassificationType}
              setIntensityClassificationBinning={setIntensityClassificationBinning}
            />
          ))}
        </TreeView>
      </AccordionDetails>
    </Accordion>
  );
};

export default InferenceModel;
