import { Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getImages } from 'api/platform';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { Study } from 'interfaces/study';
import { map } from 'lodash';
import React from 'react';

export const PlatformSettings: React.FC<
  React.PropsWithChildren<{
    updatedSettings: Study['platformSettings'];
    setUpdatedSettings: React.Dispatch<React.SetStateAction<Study['platformSettings']>>;
  }>
> = ({ updatedSettings, setUpdatedSettings }) => {
  const { data: platformImages, isLoading } = useQuery({
    queryKey: ['platformImages'],
    queryFn: ({ signal }) => getImages(signal),
  });

  return (
    <Grid
      sx={{
        p: 2,
      }}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle2">Platform Images</Typography>
      </Grid>
      <Grid item xs={12}>
        <LabelledDropdown
          label="Docker Image Selection"
          options={map(platformImages, (image) => ({
            value: image,
            text: image,
          }))}
          value={updatedSettings?.dockerImage}
          onOptionSelected={(optionValue) => {
            setUpdatedSettings((prevSettings) => ({
              ...prevSettings,
              dockerImage: optionValue,
            }));
          }}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  );
};
