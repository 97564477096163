import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import { ResultsMode } from 'interfaces/experimentResults';
import { getInnerRowIdFromUnwoundRowMetadata } from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import { difference, isEmpty, map } from 'lodash';
import { ArrayParam, StringParam } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { filtersQueryParamsSchema, useEncodedFilters } from 'utils/useEncodedFilters';

export interface CasesParams {
  studyId?: string;
  labId?: string;
  filters?: any;
  features?: any;
  clinicalData?: any;
  slidesMode?: boolean;
  resultsMode?: ResultsMode;
  caseIdsToInclude?: number[];
  caseIdsToExclude?: number[];
  slideIdsToInclude?: string[];
  slideIdsToExclude?: string[];
}

export const casesSchema = {
  ...filtersQueryParamsSchema,
  studyId: StringParam,
  caseIdsToExclude: ArrayParam,
  slideIdsToExclude: ArrayParam,
};

export const casesOptions = {
  arrayFormat: 'repeat',
};

export const useCasesParams = (differentQueryParams?: {
  filters?: any;
  features?: any;
  clinicalData?: any;
  slidesMode?: boolean;
  resultsMode?: ResultsMode;
}) => {
  const { labId } = useCurrentLabId();
  const { selectionMode, selectedRows, omittedRows } = useRowSelectionContext();
  const {
    queryParams: {
      filters,
      features,
      clinicalData,
      slidesMode,
      resultsMode,
      caseIdsToInclude: caseIdsToIncludeFilter,
      slideIdsToInclude: slideIdsToIncludeFilter,
    },
  } = useEncodedFilters();

  const baseData = {
    studyId: filters?.studyId,
    filters: {
      ...filters,
      ...differentQueryParams?.filters,
    },
    features: {
      ...features,
      ...differentQueryParams?.features,
    },
    clinicalData: {
      ...clinicalData,
      ...differentQueryParams?.clinicalData,
    },
    labId,
    slidesMode: differentQueryParams?.slidesMode || slidesMode,
    resultsMode: differentQueryParams?.resultsMode || resultsMode || ResultsMode.Manual,
  };

  let casesParams: CasesParams = baseData;

  if (!slidesMode) {
    const caseIdsToIncludeSelect = selectionMode === 'select' ? (selectedRows as number[]) : undefined;
    const caseIdsToExcludeSelect = selectionMode === 'omit' ? (omittedRows as number[]) : undefined;

    if (caseIdsToIncludeFilter) {
      let caseIdsToIncludeWithFilteredOptions;
      if (isEmpty(caseIdsToIncludeSelect) && isEmpty(caseIdsToExcludeSelect)) {
        caseIdsToIncludeWithFilteredOptions = map(caseIdsToIncludeFilter, Number);
      } else if (!isEmpty(caseIdsToExcludeSelect)) {
        caseIdsToIncludeWithFilteredOptions = difference(map(caseIdsToIncludeFilter, Number), caseIdsToExcludeSelect);
      } else {
        caseIdsToIncludeWithFilteredOptions = caseIdsToIncludeSelect;
      }

      casesParams = {
        ...baseData,
        caseIdsToInclude: map(caseIdsToIncludeWithFilteredOptions, (caseId) => Number(caseId)),
      };
    } else {
      casesParams = {
        ...baseData,
        caseIdsToInclude: caseIdsToIncludeSelect,
        caseIdsToExclude: caseIdsToExcludeSelect,
      };
    }
  } else {
    const slideIdsToInclude =
      selectionMode === 'select' ? getInnerRowIdFromUnwoundRowMetadata(selectedRows as string[]) : undefined;
    const slideIdsToExclude =
      selectionMode === 'omit' ? getInnerRowIdFromUnwoundRowMetadata(omittedRows as string[]) : undefined;

    if (slideIdsToIncludeFilter) {
      let slideIdsToIncludeWithFilteredOptions;
      if (isEmpty(slideIdsToInclude) && isEmpty(slideIdsToExclude)) {
        slideIdsToIncludeWithFilteredOptions = slideIdsToIncludeFilter;
      } else if (!isEmpty(slideIdsToExclude)) {
        slideIdsToIncludeWithFilteredOptions = difference(slideIdsToIncludeFilter, slideIdsToExclude);
      } else {
        slideIdsToIncludeWithFilteredOptions = slideIdsToInclude;
      }

      casesParams = {
        ...baseData,
        slideIdsToInclude: slideIdsToIncludeWithFilteredOptions,
      };
    } else {
      casesParams = {
        ...baseData,
        slideIdsToInclude,
        slideIdsToExclude,
      };
    }
  }

  return { casesParams, schema: casesSchema, options: casesOptions };
};
