// eslint-disable-next-line import/order
import { Box, Divider } from '@mui/material';
import { headerHeight } from 'components/Procedure/constants';
import { Slide } from 'interfaces/slide';
import { map } from 'lodash';
import React, { Fragment } from 'react';
import VerticalCarouselItem from './VerticalCarouselItem';

interface Props {
  slides: Slide[];
  selectedSlideId: string;
  onSelected: (index: string) => void;
}

const VerticalCarousel: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slides,
  selectedSlideId,
  onSelected,
}) => {
  return (
    <Box
      sx={{
        padding: 1,
        overflowY: 'scroll',
        maxHeight: `calc(100vh - ${headerHeight})`,
      }}
    >
      {map(slides, (slide) => (
        <Fragment key={slide.id}>
          <VerticalCarouselItem
            slide={slide}
            isSelected={slide.id === selectedSlideId}
            onSelected={() => onSelected(slide.id)}
          />
          <Divider variant="middle" />
        </Fragment>
      ))}
    </Box>
  );
};

export default VerticalCarousel;
