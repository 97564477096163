import CloseIcon from '@mui/icons-material/Close';
import { Button, Drawer, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { BulkEditField } from 'components/atoms/EditableDataGrid/ColumnHeaderEditor';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { mainMarginTop, overviewDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import { useBulkEditControl } from 'components/StudyDashboard/ProceduresPage/DataGrids/useBulkEditControl';
import { useEditableFields } from 'components/StudyDashboard/ProceduresPage/DataGrids/useEditableFields';
import { useCasesViewMode } from 'components/StudyDashboard/ProceduresPage/useCasesViewMode';
import { filter, map } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

interface Props {
  totalItemsToEdit: number;
}

const EditDrawer = ({ totalItemsToEdit }: Props) => {
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);
  const { casesViewMode } = useCasesViewMode();

  const { bulkEditMode, setBulkEditMode, hasChanges, saveBulkEdits, undoBulkEdits } = useBulkEditControl();

  const { fieldsContext } = useTableEditingContext();

  const { isRowSelected, clearSelection, noneSelected } = useRowSelectionContext();

  const { orderedFields, slidesFields } = useEditableFields();

  const theme = useTheme();

  const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];

  const handleClose = () => {
    setBulkEditMode(false);
  };

  const fields = slidesMode ? slidesFields : orderedFields;

  const editableFields = filter(fields, (field) => {
    return !field.tableViewOnly && slidesMode
      ? field.unwoundRowCellEditType != undefined
      : field.cellEditType != undefined;
  });

  return (
    <Drawer
      anchor={'right'}
      open={bulkEditMode && casesViewMode !== 'table'}
      onClose={handleClose}
      variant="persistent"
      data-cy="bulk-edit-drawer"
      sx={{
        width: overviewDrawerWidth,
        [`& .MuiDrawer-paper`]: {
          backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
          width: overviewDrawerWidth,
          zIndex: theme.zIndex.appBar - 1,
          height: `calc(100% - ${mainMarginTop}px)`,
          marginTop: `${mainMarginTop}px`,
          overflowX: 'hidden',
        },
      }}
    >
      <Grid container direction={'column'} item xs={12} sx={{ overflowX: 'hidden' }}>
        <Grid
          item
          container
          p={2}
          sx={{
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
          }}
        >
          <Grid item xs={10} alignItems={'center'}>
            <Typography variant="h3">{`Edit ${slidesMode ? 'Slides' : 'Cases'}`}</Typography>
          </Grid>

          <Grid item xs={2} textAlign="right">
            <IconButton onClick={handleClose} sx={{ padding: 0 }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={true} px={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
          {map(editableFields, (field) => {
            return (
              <BulkEditField
                field={field}
                shouldApplyBulkChangesToRow={isRowSelected}
                key={field.dataKey}
                arrayFieldToUnwind={slidesMode ? 'slides' : undefined}
                context={fieldsContext}
              />
            );
          })}
        </Grid>

        <Grid container p={2} justifyContent="space-between" sx={{ borderTop: '1px solid ' + borderColor }}>
          <Grid item>
            <Button
              size="small"
              onClick={() => {
                undoBulkEdits();
                clearSelection();
                setBulkEditMode(false);
              }}
              variant="text"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="primary"
              data-cy="edit-drawer-save-button"
              disabled={(bulkEditMode && !hasChanges) || noneSelected(totalItemsToEdit)}
              onClick={() => {
                saveBulkEdits();
                clearSelection();
                setBulkEditMode(false);
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default EditDrawer;
