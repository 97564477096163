import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { compact, first, groupBy, includes, isEmpty, map, uniq } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { BooleanParam, useQueryParam } from 'use-query-params';
import Features from '../Features/Features';
import Heatmaps from '../Heatmaps';
import { ProtomapHeatmapControl } from '../Heatmaps/ProtomapTileControl';

import { ExperimentResultsResultType, FlowClassName } from 'interfaces/experimentResults';
import OrchestrationApproval from './OrchestrationApproval';
import SecondaryAnalysisTrigger from './SecondaryAnalysisTrigger';

interface SlideOrchIdProps {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  title: string;
  orchestrationId: string;
  heatmapSettings: FeatureMetadata[];
  featuresSettings: FeatureMetadata[];
  filterText: string;
  isPublishMode: boolean;
  doesHaveApprovedResultType: boolean;
  flowClassNames: FlowClassName[];
  resultTypes: ExperimentResultsResultType[];
  highlightedFeatures?: string[];
  hiddenFeatures?: string[];
  studyId: string;
  doesCaseHaveMultipleStainResults: boolean;
  slideStainTypeId: string;
}

const SlideOrchIdResults: React.FunctionComponent<React.PropsWithChildren<SlideOrchIdProps>> = ({
  slideId,
  viewerIndex,
  stainTypeId,
  orchestrationId,
  heatmapSettings,
  featuresSettings,
  filterText,
  isPublishMode,
  doesHaveApprovedResultType,
  flowClassNames,
  resultTypes,
  highlightedFeatures,
  hiddenFeatures,
  studyId,
  doesCaseHaveMultipleStainResults,
  slideStainTypeId,
}) => {
  const [expand, setExpand] = useState(false);

  // some cases has features and no heatmaps and some cases has the opposite
  const createdAt = first(heatmapSettings)?.createdAt || first(featuresSettings)?.createdAt;

  const { pmt: pmtHeatmaps, dzi: dziHeatmaps } = groupBy(heatmapSettings, 'heatmapType');

  const clipboard = useClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard: React.MouseEventHandler<HTMLDivElement> = (e: any) => {
    e.stopPropagation();
    clipboard.copy(orchestrationId);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);

  const isInternallyApproved = heatmapSettings?.[0]?.internallyApproved || featuresSettings?.[0]?.internallyApproved;

  const showFeatures = !isEmpty(featuresSettings);
  const showLegacyHeatmaps = !isEmpty(dziHeatmaps);
  const showPmtHeatmaps = useDeckGL && !isEmpty(pmtHeatmaps);
  const hasContentsToShow = showFeatures || showLegacyHeatmaps || showPmtHeatmaps;

  const secondaryAnalysisMissingPrimaryIds = uniq(compact(map(featuresSettings, 'primaryRunOrchestrationId')));

  return (
    hasContentsToShow && (
      <Accordion expanded={expand} onChange={() => setExpand((prev) => !prev)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item xs={isPublishMode ? 6 : isInternallyApproved ? 10 : 12} container direction="column">
              <Grid item container alignItems="end">
                <Typography variant={'subtitle2'}>{`Orch Id:`}</Typography>{' '}
                <Tooltip title={isCopied ? 'copied!' : 'click to copy'} onClick={handleCopyToClipboard}>
                  <Typography variant="subtitle2" ml={1}>
                    {`${orchestrationId?.slice(0, 8)}...`}
                  </Typography>
                </Tooltip>
              </Grid>
              {createdAt && (
                <Grid item>
                  <Typography variant="caption">{moment(createdAt).format('ll')}</Typography>
                </Grid>
              )}
            </Grid>
            {!isPublishMode && isInternallyApproved && (
              <Grid item xs={2} justifyContent="flex-end">
                <CheckCircleRoundedIcon color={'primary'} />
              </Grid>
            )}

            {!isEmpty(secondaryAnalysisMissingPrimaryIds) && (
              <Grid item xs={2} justifyContent="flex-end">
                <Tooltip title={`Secondary analysis without their primary run: ${secondaryAnalysisMissingPrimaryIds}`}>
                  <WarningIcon color={'warning'} />
                </Tooltip>
              </Grid>
            )}

            {includes(flowClassNames, FlowClassName.CalculateFeatures) && (
              <SecondaryAnalysisTrigger
                slideId={slideId}
                viewerIndex={viewerIndex}
                orchestrationId={orchestrationId}
                studyId={studyId}
              />
            )}
            <OrchestrationApproval
              slideId={slideId}
              orchestrationId={orchestrationId}
              isInternallyApproved={isInternallyApproved}
              isPublishMode={isPublishMode}
              studyId={studyId}
              doesSlideHaveApprovedResults={doesHaveApprovedResultType}
              flowClassNames={flowClassNames}
              resultTypes={resultTypes}
            />
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 1 }}>
          {showLegacyHeatmaps && (
            <Heatmaps
              key={`heatmaps-${orchestrationId}`}
              slideId={slideId}
              studyId={studyId}
              stainTypeId={stainTypeId}
              viewerIndex={viewerIndex}
              title="Overlays"
              heatmaps={dziHeatmaps}
              filterText={filterText}
              internalHeatmaps={true}
              isPublishMode={isPublishMode}
            />
          )}
          {showPmtHeatmaps && (
            <ProtomapHeatmapControl
              title="Vector Overlays"
              key={`heatmaps-${orchestrationId}`}
              heatmaps={pmtHeatmaps}
              slideId={slideId}
              studyId={studyId}
              stainTypeId={stainTypeId}
              viewerIndex={viewerIndex}
              textSearch={filterText}
              internalHeatmaps={true}
              isPublishMode={isPublishMode}
            />
          )}
          {showFeatures && (
            <React.Fragment key={`slide-results-features-${orchestrationId}`}>
              {map(featuresSettings, (featureData) => {
                const isSecondaryAnalysis = !isEmpty(featureData.nestedItems);
                return isSecondaryAnalysis ? (
                  <Grid key={`${featureData.displayName}=${featureData.createdAt}=${featureData.orchestrationId}`}>
                    <Features
                      highlightedFeatures={highlightedFeatures}
                      hiddenFeatures={hiddenFeatures}
                      key={`${featureData.displayName}=${featureData.createdAt}=${featureData.experimentResultId}=${featureData.orchestrationId}`}
                      title={featureData.displayName}
                      experimentResultIds={
                        featureData.experimentResultId !== undefined ? [featureData.experimentResultId] : []
                      }
                      internalFeatures={true}
                      doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
                      slideStainTypeId={slideStainTypeId}
                    />
                    <Typography variant="caption">{`Secondary analysis for ${featureData.displayName}`}</Typography>
                    {map(featureData.nestedItems, (nestedFeatureData) => (
                      <Features
                        highlightedFeatures={highlightedFeatures}
                        hiddenFeatures={hiddenFeatures}
                        key={`${nestedFeatureData.displayName}=${nestedFeatureData.createdAt}=${nestedFeatureData.experimentResultId}=${nestedFeatureData.orchestrationId}`}
                        title={nestedFeatureData.displayName}
                        experimentResultIds={
                          nestedFeatureData.experimentResultId !== undefined
                            ? [nestedFeatureData.experimentResultId]
                            : []
                        }
                        internalFeatures={true}
                        doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
                        slideStainTypeId={slideStainTypeId}
                      />
                    ))}
                  </Grid>
                ) : (
                  <Features
                    highlightedFeatures={highlightedFeatures}
                    hiddenFeatures={hiddenFeatures}
                    key={`${featureData.displayName}=${featureData.createdAt}=${featureData.experimentResultId}=${featureData.orchestrationId}`}
                    title={featureData.displayName}
                    experimentResultIds={
                      featureData.experimentResultId !== undefined ? [featureData.experimentResultId] : []
                    }
                    internalFeatures={true}
                    doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
                    slideStainTypeId={slideStainTypeId}
                  />
                );
              })}
            </React.Fragment>
          )}
        </AccordionDetails>
      </Accordion>
    )
  );
};

export default SlideOrchIdResults;
