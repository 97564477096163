import { map } from 'lodash';

import { AnnotationAssignment } from 'interfaces/annotation';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getAnnotationAssignments = (queryParams: string) => {
  return apiRequestHandlerPromise<AnnotationAssignment[]>({
    url: `annotation_assignments?${queryParams}`,
    method: 'GET',
  });
};

export const getAllAnnotationAssignments = async (): Promise<AnnotationAssignment[]> => {
  return map(
    await apiRequestHandlerPromise<AnnotationAssignment[]>({
      url: `annotation_assignments/all`,
      method: 'GET',
    }),
    (assignment) => ({
      ...assignment,
      id: assignment.annotationAssignmentId,
    })
  );
};

export const getUnarchivedAnnotationAssignments = async (): Promise<AnnotationAssignment[]> => {
  return map(
    await apiRequestHandlerPromise<AnnotationAssignment[]>({
      url: `annotation_assignments/all_unarchived`,
      method: 'GET',
    }),
    (assignment) => ({
      ...assignment,
      id: assignment.annotationAssignmentId,
    })
  );
};
