import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { getRoles } from 'api/roles';
import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  autoGridRowHeight,
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { Role } from 'interfaces/roles';
import { rolesFields } from 'interfaces/roles/rolesFields';
import { RolesFieldsContext } from 'interfaces/roles/rolesFieldsContext';
import { uuidv4 } from 'utils/helpers';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useRolesColumns } from './useRolesColumns';

const columnVisibilityModel = { id: false };

const generateDraftId = () => `draft-${uuidv4()}`;

const getRowId = (row: any) => row.id ?? generateDraftId();

export const RolesCreationDataGrid = () => {
  const { labId } = useCurrentLabId();
  const { data: dbRoles } = useQuery(['roles', labId], () => getRoles(labId));
  const [draftRoles, setDraftRoles] = React.useState<Role[]>([]);

  const roles = React.useMemo(() => [...draftRoles, ...(dbRoles || [])], [dbRoles, draftRoles]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const handleAddRole = () => {
    const draftId = generateDraftId();
    const newRole: Role = { name: '', description: '', permissions: [], labSpecific: true, id: draftId };
    setDraftRoles((oldDraftRoles) => [newRole, ...oldDraftRoles]);
    setRowModesModel((oldRowModesModel) => ({ ...oldRowModesModel, [newRole.id]: { mode: GridRowModes.Edit } }));
  };

  const apiRef = useGridApiRef();

  const { getRowsWithChanges } = useTableEditingContext<Role, RolesFieldsContext>();

  const rowsWithChanges = getRowsWithChanges(roles);

  const handleRowModesModelChange: DataGridProps<Role>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) =>
      handleRowModesModelChangeWithoutDraftIds(newRowModesModel, setRowModesModel, draftRoles),
    [draftRoles]
  );

  const columns = useRolesColumns({
    noRows: isEmpty(rowsWithChanges),
    apiRef,
    rowsWithChanges,
    draftRoles,
    rowModesModel,
    setDraftRoles,
    setRowModesModel,
  });

  const getRoleCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames({
        apiRef,
        requiredFields: ['name'],
        uniqueFieldGroups: [['name', 'labSpecific']],
        draftRows: draftRoles,
        fieldsToCheckForErrors: rolesFields,
      }),
    [apiRef, draftRoles]
  );

  return (
    <SettingsDataGrid
      autoHeight
      apiRef={apiRef}
      height={'unset'}
      columnVisibilityModel={columnVisibilityModel}
      getCellClassName={getRoleCellClassName}
      rows={rowsWithChanges}
      columns={columns}
      addText="Add Role"
      handleAdd={handleAddRole}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      getRowHeight={autoGridRowHeight}
      getRowId={getRowId}
    />
  );
};
