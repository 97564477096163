import { GridRowId, GridRowModesModel, GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { find, omit, reject, some } from 'lodash';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createSlideTagOption, deleteSlideTagOption, updateSlideTagOption } from 'api/slideTags';
import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { rowEditingControlsColumn } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { SlideTag } from 'interfaces/slideTag';
import { slideTagFields } from 'interfaces/slideTag/slideTagFields';
import { slideTagOptionsQueryKey } from 'utils/queryHooks/useSlideTagOptions';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { SlideTagRowChangesSummary } from './SlideTagsRowChangesSummary';

const getSlideTagError = ({
  id,
  apiRef,
}: GridRowParams<SlideTag> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateSlideTag = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['tagValue', 'perStudy', 'perExperimentResult'],
  });

  const missingRequiredFields = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['tagValue'],
  });

  const currentPerStudy = apiRef.current.getRowWithUpdatedValues(id, 'perStudy')['perStudy'];
  const currentPerExperimentResult = apiRef.current.getRowWithUpdatedValues(id, 'perExperimentResult')[
    'perExperimentResult'
  ];
  const experimentResultsWithoutStudy = !currentPerStudy && Boolean(currentPerExperimentResult);

  return hasDuplicateSlideTag
    ? 'Duplicate slide tag'
    : missingRequiredFields
    ? 'Missing required fields'
    : experimentResultsWithoutStudy
    ? 'Cannot assign a slide tag to an experiment result without assigning it to a study'
    : '';
};

export const useSlideTagsColumns = ({
  apiRef,
  noRows,
  rowsWithChanges,
  draftSlideTags,
  rowModesModel,
  setDraftSlideTags,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowsWithChanges: SlideTag[];
  draftSlideTags: SlideTag[];
  rowModesModel: GridRowModesModel;
  setDraftSlideTags: React.Dispatch<React.SetStateAction<SlideTag[]>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const { clearRowChanges, rowsChanges } = useTableEditingContext<SlideTag>();
  const confirmWithModal = useConfirmation();

  const queryClient = useQueryClient();

  const commonMutationOptions = {
    onError: () => {
      setMutatingRowId(undefined);
    },
  };

  const createSlideTagMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newSlideTag) => {
      queryClient.invalidateQueries(slideTagOptionsQueryKey);
      queryClient.setQueryData(slideTagOptionsQueryKey, (oldData: SlideTag[]) => [...oldData, newSlideTag]);
      queryClient.invalidateQueries(['slideTags']);
    },
    mutationFn: createSlideTagOption,
    mutationDescription: 'create slide tag',
  });
  const updateSlideTagMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(slideTagOptionsQueryKey);
      queryClient.setQueryData(slideTagOptionsQueryKey, (oldData: SlideTag[]) => {
        const updatedSlideTag = find(rowsWithChanges, { id: mutatingRowId });
        return [...reject(oldData, { id: mutatingRowId }), updatedSlideTag];
      });
      queryClient.invalidateQueries(['slideTags']);
    },
    mutationFn: updateSlideTagOption,
    mutationDescription: 'update slide tag',
  });
  const deleteSlideTagMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(slideTagOptionsQueryKey);
      queryClient.setQueryData(slideTagOptionsQueryKey, (oldData: SlideTag[]) => reject(oldData, { id: variables }));
      queryClient.invalidateQueries(['slideTags']);
    },
    mutationFn: deleteSlideTagOption,
    mutationDescription: 'delete slide tag',
  });

  const deleteOperation = (id: GridRowId) => async () => {
    if (
      await confirmWithModal({
        title: 'Delete Slide Tag',
        text: 'Are you sure you want to delete this slide tag?',
      })
    ) {
      if (some(draftSlideTags, { id })) {
        setDraftSlideTags(reject(draftSlideTags, { id: id as string }));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [id]: draftSlideTagRowMode, ...rest } = rowModesModel;
        setRowModesModel(rest);
      } else if (id) {
        setMutatingRowId(id);
        await deleteSlideTagMutation.mutateAsync(`${id}`);
        setMutatingRowId(undefined);
      }
      clearRowChanges(id);
      return true;
    } else {
      return false;
    }
  };

  const isDraftRow = (id: GridRowId) => some(draftSlideTags, { id });

  const confirmCancelEdit = async (id: GridRowId) => {
    const isDraft = isDraftRow(id);
    const slideTag = find(rowsWithChanges, { id: id as string });
    return (
      !rowsChanges[id] ||
      (await confirmWithModal({
        title: `Cancel slideTag ${isDraft ? 'creation' : 'update'}`,
        text: <SlideTagRowChangesSummary slideTagValue={`${slideTag?.tagValue || id}`} changes={rowsChanges[id]} />,
      }))
    );
  };

  const saveOperation = async (id: GridRowId) => {
    const isDraft = isDraftRow(id);
    const slideTag = find(rowsWithChanges, { id: id as string });
    if (
      await confirmWithModal({
        title: `${isDraft ? 'Create' : 'Update'} Slide Tag`,
        text: <SlideTagRowChangesSummary slideTagValue={`${slideTag?.tagValue || id}`} changes={rowsChanges[id]} />,
      })
    ) {
      setMutatingRowId(id);
      if (isDraft) {
        await createSlideTagMutation.mutateAsync(omit(slideTag, 'id'));
        setDraftSlideTags(reject(draftSlideTags, { id: id as string }));
      } else {
        await updateSlideTagMutation.mutateAsync(slideTag);
      }
      setMutatingRowId(undefined);
      clearRowChanges(id);
      return true;
    } else {
      return false;
    }
  };

  const slideTagFieldsColumns = useEditableFieldsDataGridColumns({
    fields: slideTagFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode: false,
    useValueSetter: true,
  });

  return [
    ...slideTagFieldsColumns,
    rowEditingControlsColumn({
      mutatingRowId,
      rowModesModel,
      setRowModesModel,
      apiRef,
      getRowError: getSlideTagError,
      deleteOperation,
      isDraftRow,
      onCancelEdit: (id: GridRowId) => {
        const isDraft = isDraftRow(id);
        if (isDraft) {
          setDraftSlideTags(reject(draftSlideTags, { id: id as string }));
        }
        clearRowChanges(id);
      },
      saveOperation,
      confirmCancelEdit,
    }),
  ];
};
