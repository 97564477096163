import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { Procedure } from 'interfaces/procedure';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { isEmpty, omit } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { ProcedureSlideBulkChangesSummary } from './DataGrids/ChangeSummaries';

export const ToggleCaseDisplayMode: React.FC<React.PropsWithChildren<unknown>> = () => {
  const confirmWithModal = useConfirmation();

  const { bulkChanges, fieldsContext, hasChanges, clearChanges } = useTableEditingContext<
    Procedure,
    ProceduresFieldsContext
  >();
  const { clearSelection } = useRowSelectionContext();
  const {
    queryParams: { slidesMode },
    setQueryParams,
  } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);

  const changeGridType = async (newDataGridView: 'case' | 'slide' | null) => {
    if (newDataGridView !== null) {
      const relevantChanges = {
        ...omit(bulkChanges, 'slide'),
        ...(slidesMode ? bulkChanges['slides'] || {} : {}),
      };
      if (
        !hasChanges ||
        isEmpty(relevantChanges) ||
        (await confirmWithModal({
          title: 'Undo Bulk Edits',
          text: (
            <Box>
              <p>You have unsaved changes. Are you sure you want to undo them?</p>
              <ProcedureSlideBulkChangesSummary bulkChanges={relevantChanges} fieldsContext={fieldsContext} />
            </Box>
          ),
        }))
      ) {
        // Reset page to 1 when switching between case and slide view
        setQueryParams({ slidesMode: newDataGridView === 'slide', page: 1 });
      }
    }
  };

  return (
    <ToggleButtonGroup
      sx={{
        paddingInline: 1,
      }}
      exclusive
      size="small"
      color="primary"
      value={slidesMode ? 'slide' : 'case'}
      onChange={async (_, newDataGridView) => {
        clearChanges();
        clearSelection();
        changeGridType(newDataGridView);
      }}
      aria-label="table-column-groups"
    >
      <ToggleButton value="slide">Slides</ToggleButton>
      <ToggleButton value="case" disabled={pendingSlidesMode}>
        Cases
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
