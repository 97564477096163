import InfoIcon from '@mui/icons-material/Info';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { TreeItem } from '@mui/lab';
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getModel } from 'api/platform';
import { modelTypeClassification } from 'components/Pages/Jobs/inferenceFieldsOptions';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  ClassificationBinningParams,
  InferenceModelData,
  ModelInference,
  OrchestrationInference,
} from 'interfaces/calculateFeatures';
import { Model } from 'interfaces/model';
import { Slide } from 'interfaces/slide';
import {
  every,
  filter,
  find,
  first,
  forEach,
  includes,
  isEmpty,
  join,
  keys,
  map,
  merge,
  some,
  transform,
  uniq,
  values,
  xor,
} from 'lodash';
import React, { useState } from 'react';
import { useClassificationModelOptions } from 'utils/queryHooks/uiConstantsHooks';
import { ModelClassificationById, OrchestrationBySlideByType } from '../..';
import { getModelId, getOverrideAnnotationsTimestamp } from '../../utils';
import OrchestrationRow from '../OrchestrationRow';
import {
  ConfirmationModalMessageText,
  getClassConfigErrorValidation,
  getTSMModelErrorValidation,
  getTSMModelErrorWithOverrideAnnotationsValidation,
} from './ConfirmationModalMessageText';
import { IntensityBins } from './IntensityBins';
import { ModelDetails } from './ModelDetails';
export interface ModelRowProps {
  studyId: string;
  stain: string;
  modelType: string;
  modelInference: ModelInference;
  slides: Record<string, Pick<Slide, 'stainingType' | 'originalFileName'>>;
  selectedOrchestrations: OrchestrationBySlideByType;
  setSelectedOrchestrations: (
    slideIds: string[],
    model: Partial<InferenceModelData>,
    modelType: string,
    orchestration: OrchestrationInference
  ) => void;
  setModelClassificationByModelId?: (modelId: string, classification: string) => void;
  modelClassificationByModelId?: ModelClassificationById;
  setSelectedOrchestrationsByClassificationType?: (modelType: string, previousModelType?: string) => void;
  setIntensityClassificationBinning?: (modelId: string, binning: ClassificationBinningParams) => void;
}

const ModelRow: React.FC<React.PropsWithChildren<ModelRowProps>> = ({
  studyId,
  stain,
  modelType,
  modelInference,
  slides,
  selectedOrchestrations,
  setSelectedOrchestrations,
  modelClassificationByModelId = {},
  setModelClassificationByModelId,
  setSelectedOrchestrationsByClassificationType,
  setIntensityClassificationBinning,
}) => {
  const modelId = modelInference.modelId ?? getModelId(modelInference?.modelUrl);
  const placeholderDataModel: Model = {
    meta: {
      deps: {
        classesConfig: transform(
          modelInference?.classes,
          function (result: Record<string, {}>, className) {
            result[className] = {};
            return result;
          },
          {}
        ),
      },
      modelId: modelId,
      modelType: modelInference?.modelType,
    },
    url: modelInference?.modelUrl,
  };

  const { data } = useQuery({
    queryKey: ['model', modelId],
    queryFn: ({ signal }) => getModel(modelId, signal),
    placeholderData: placeholderDataModel,
  });

  const [confirmationModal, setConfirmationModal] = useState(null);
  const [anchorElModelDetails, setAnchorElModelDetails] = useState<null | HTMLElement>(null);
  const [anchorElIntensityBins, setAnchorElIntensityBins] = useState<null | HTMLElement>(null);

  const { classificationModelOptions } = useClassificationModelOptions();

  const selectedModelType = modelClassificationByModelId[modelId]?.readAs ?? modelType;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorElModelDetails(event.currentTarget);
  };

  const handleCloseModelDetails = () => {
    setAnchorElModelDetails(null);
  };

  const handleCloseIntensityBins = () => {
    setAnchorElIntensityBins(null);
  };

  const validateNewOrchestration = (newOrchestration: OrchestrationInference, slideIds: string[]) => {
    let message: { title?: string; text?: React.ReactNode; onConfirm?: () => void } = {};
    let currentTsmModelsForSpecificModel: string[] = [];
    let currentClassConfig: string[] = [];
    let currentTsmModelUrlPerSlide: Record<string, string> = {};
    let currentTsmModelPerSlide: Record<
      string,
      {
        modelUrl: string;
        overrideAnnotationsTimestamp: string;
      }
    > = {};

    // validate tsm model only if the new orchestration has a tsm model
    if (!isEmpty(newOrchestration?.params?.deps?.modelTsm)) {
      // validate tsm model per slide
      forEach(slideIds, (slideId) => {
        if (
          !isEmpty(selectedOrchestrations?.[slideId]) &&
          // check if the slide has only one model type selected and it's the same model type as the new orchestration
          !(
            keys(selectedOrchestrations[slideId]).length === 1 &&
            !isEmpty(selectedOrchestrations[slideId][selectedModelType])
          )
        ) {
          // get the first selected orchestration that have a tsm model
          const curOrchestration = first(
            filter(
              values(selectedOrchestrations[slideId]),
              (selectedOrchestration) => !isEmpty(selectedOrchestration?.orchestration?.params?.deps?.modelTsm)
            )
          );

          const curModelTsmUrlPattern =
            curOrchestration?.orchestration?.tsmSlideOverrides?.[slideId] ??
            curOrchestration?.orchestration?.orchestrationTsmArtifactUrlPattern;
          const curModelTsmUrl = curOrchestration?.orchestration?.params?.deps?.modelTsm;
          const curModelTsmId = getModelId(curModelTsmUrl);

          const newOrchestrationTsmUrlPattern =
            newOrchestration?.tsmSlideOverrides?.[slideId] ?? newOrchestration?.orchestrationTsmArtifactUrlPattern;

          if (!isEmpty(curModelTsmId) && curModelTsmId !== getModelId(newOrchestration?.params?.deps?.modelTsm)) {
            currentTsmModelUrlPerSlide[slideId] = curModelTsmUrl;
          }
          // else, check if the tsm url pattern is different- mean that the tsm url is the same but the override_annotations_timestamp is different
          else if (!isEmpty(curModelTsmUrlPattern) && curModelTsmUrlPattern !== newOrchestrationTsmUrlPattern) {
            currentTsmModelPerSlide[slideId] = {
              modelUrl: curModelTsmUrl,
              overrideAnnotationsTimestamp: getOverrideAnnotationsTimestamp(curModelTsmUrlPattern),
            };
          }
        }
      });
    }

    forEach(selectedOrchestrations, (orchestrationsByModel, slideId) => {
      if (
        slides?.[slideId]?.stainingType === stain &&
        !isEmpty(orchestrationsByModel?.[selectedModelType]) &&
        !includes(slideIds, slideId)
      ) {
        // validate class config- Check if there are any classes that don't match each other between the new orchestration and the previous selected orchestrations
        if (
          orchestrationsByModel[selectedModelType].model?.modelUrl !== modelInference?.modelUrl &&
          !isEmpty(xor(orchestrationsByModel[selectedModelType].model?.classes, modelInference?.classes))
        ) {
          currentClassConfig = orchestrationsByModel[selectedModelType]?.model?.classes;
          return false;
        }

        // validate tsm model per model type
        if (
          orchestrationsByModel[selectedModelType]?.orchestration.orchestrationId !== newOrchestration?.orchestrationId
        ) {
          currentTsmModelsForSpecificModel.push(
            orchestrationsByModel[selectedModelType]?.orchestration?.params?.deps?.modelTsm
          );
        }
      }
    });

    // class config validation and tsm model per slide validation
    if (!isEmpty(currentClassConfig) && (!isEmpty(currentTsmModelUrlPerSlide) || !isEmpty(currentTsmModelPerSlide))) {
      message.title = `Class Config and TSM Model Error${
        isEmpty(currentTsmModelPerSlide) && ' With Override Annotations'
      }`;
      message.onConfirm = () => {
        // unselect the previous selected orchestrations
        const previousSelectedOrchestrationsSlideIds: string[] = [];
        forEach(selectedOrchestrations, (orchestrationsByModel, slideId) => {
          if (slides[slideId].stainingType === stain && !isEmpty(orchestrationsByModel[selectedModelType])) {
            previousSelectedOrchestrationsSlideIds.push(slideId);
          }
        });
        setSelectedOrchestrations(previousSelectedOrchestrationsSlideIds, { modelType }, selectedModelType, null);
        setSelectedOrchestrations(
          keys(currentTsmModelUrlPerSlide ?? currentTsmModelPerSlide),
          null,
          selectedModelType,
          null
        );
        setSelectedOrchestrations(slideIds, modelInference, selectedModelType, newOrchestration);
        setConfirmationModal(null);
      };

      message.text = (
        <ConfirmationModalMessageText
          modelType={selectedModelType}
          modelId={modelId}
          stain={stain}
          slideIds={keys(currentTsmModelUrlPerSlide ?? currentTsmModelPerSlide)}
          messageType="Error"
          validations={[
            getClassConfigErrorValidation(currentClassConfig, modelInference?.classes),
            currentTsmModelUrlPerSlide
              ? getTSMModelErrorValidation(currentTsmModelUrlPerSlide, newOrchestration)
              : getTSMModelErrorWithOverrideAnnotationsValidation(currentTsmModelPerSlide, newOrchestration),
          ]}
        />
      );
    } else if (!isEmpty(currentTsmModelUrlPerSlide)) {
      message.title = 'TSM Model Error';
      message.onConfirm = () => {
        setSelectedOrchestrations(keys(currentTsmModelUrlPerSlide), null, selectedModelType, null);
        setSelectedOrchestrations(slideIds, modelInference, selectedModelType, newOrchestration);
        setConfirmationModal(null);
      };
      message.text = (
        <ConfirmationModalMessageText
          modelType={selectedModelType}
          modelId={modelId}
          stain={stain}
          slideIds={keys(currentTsmModelUrlPerSlide)}
          messageType="Error"
          validations={[getTSMModelErrorValidation(currentTsmModelUrlPerSlide, newOrchestration)]}
        />
      );
    } else if (!isEmpty(currentTsmModelPerSlide)) {
      message.title = 'TSM Model Error With Override Annotations';
      message.onConfirm = () => {
        setSelectedOrchestrations(keys(currentTsmModelPerSlide), null, selectedModelType, null);
        setSelectedOrchestrations(slideIds, modelInference, selectedModelType, newOrchestration);
        setConfirmationModal(null);
      };
      message.text = (
        <ConfirmationModalMessageText
          modelType={selectedModelType}
          modelId={modelId}
          stain={stain}
          slideIds={keys(currentTsmModelPerSlide)}
          messageType="Error"
          validations={[getTSMModelErrorWithOverrideAnnotationsValidation(currentTsmModelPerSlide, newOrchestration)]}
        />
      );
    } else if (!isEmpty(currentClassConfig)) {
      message.title = 'Class Config Error';
      message.onConfirm = () => {
        // unselect the previous selected orchestrations
        const previousSelectedOrchestrationsSlideIds: string[] = [];
        forEach(selectedOrchestrations, (orchestrationsByModel, slideId) => {
          if (slides?.[slideId]?.stainingType === stain && !isEmpty(orchestrationsByModel?.[selectedModelType])) {
            previousSelectedOrchestrationsSlideIds.push(slideId);
          }
        });
        setSelectedOrchestrations(previousSelectedOrchestrationsSlideIds, { modelType }, selectedModelType, null);
        setSelectedOrchestrations(slideIds, modelInference, selectedModelType, newOrchestration);
        setConfirmationModal(null);
      };
      message.text = (
        <ConfirmationModalMessageText
          modelType={selectedModelType}
          modelId={modelId}
          stain={stain}
          slideIds={slideIds}
          messageType="Error"
          validations={[getClassConfigErrorValidation(currentClassConfig, modelInference?.classes)]}
        />
      );
    } else if (
      !isEmpty(currentTsmModelsForSpecificModel) &&
      !includes(currentTsmModelsForSpecificModel, newOrchestration.params.deps.modelTsm)
    ) {
      // check if the new orchestration have the same tsm model as the other selected orchestrations for the same stain slides
      message.title = 'TSM Model Warning';
      message.onConfirm = () => {
        setSelectedOrchestrations(slideIds, modelInference, selectedModelType, newOrchestration);
        setConfirmationModal(null);
      };
      message.text = (
        <ConfirmationModalMessageText
          modelType={selectedModelType}
          modelId={modelId}
          stain={stain}
          slideIds={slideIds}
          messageType="Warning"
          validations={[
            {
              type: 'TsmModel',
              currentTypeData: (
                <Typography variant="h4"> {join(uniq(currentTsmModelsForSpecificModel), ', ')}</Typography>
              ),
              newTypeData: newOrchestration.params.deps.modelTsm,
            },
          ]}
        />
      );
    }

    return message;
  };

  const onCheckOrchestration = (newOrchestration: OrchestrationInference, slideIds: string[], checked: boolean) => {
    if (checked) {
      const message = validateNewOrchestration(newOrchestration, slideIds);
      if (isEmpty(message)) {
        setSelectedOrchestrations(slideIds, modelInference, selectedModelType, checked ? newOrchestration : null);
      } else {
        setConfirmationModal({
          title: message.title,
          text: message.text,
          onConfirm: message.onConfirm,
        });
      }
    } else {
      setSelectedOrchestrations(slideIds, modelInference, selectedModelType, checked ? newOrchestration : null);
    }
  };

  const isModelChecked = every(modelInference?.orchestrations, (orchestration) =>
    every(
      orchestration?.slideIds,
      (slideId) => selectedOrchestrations?.[slideId]?.[selectedModelType]?.model?.modelUrl === modelInference?.modelUrl
    )
  );

  const isModelIndeterminate = some(modelInference?.orchestrations, (orchestration) =>
    some(
      orchestration?.slideIds,
      (slideId) => selectedOrchestrations?.[slideId]?.[selectedModelType]?.model?.modelUrl === modelInference?.modelUrl
    )
  );

  return (
    <>
      <TreeItem
        key={modelInference?.modelUrl}
        nodeId={modelInference?.modelUrl}
        label={
          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
              <Checkbox disabled checked={isModelChecked} indeterminate={!isModelChecked && isModelIndeterminate} />
            </Grid>
            <Grid item>{modelId}</Grid>
            <Grid item>
              <IconButton onClick={handleClick}>
                <InfoIcon fontSize="small" />
              </IconButton>
              <Popover
                onClick={(e) => e.stopPropagation()}
                key={modelInference?.modelUrl}
                open={Boolean(anchorElModelDetails)}
                anchorEl={anchorElModelDetails}
                onClose={handleCloseModelDetails}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: { width: '500px' },
                }}
              >
                <ModelDetails modelDetails={merge(data, placeholderDataModel)} onClosed={handleCloseModelDetails} />
              </Popover>
            </Grid>
            <Grid item xs={5}>
              {modelType === modelTypeClassification.apiModelValue && (
                <FormControl
                  required={isModelChecked || isModelIndeterminate}
                  error={(isModelChecked || isModelIndeterminate) && isEmpty(modelClassificationByModelId[modelId])}
                  fullWidth
                >
                  <InputLabel>Classification Model</InputLabel>
                  <Select
                    size="small"
                    value={modelClassificationByModelId[modelId]?.readAs ?? null}
                    label="Classification Model"
                    onChange={(event) => {
                      setSelectedOrchestrationsByClassificationType(event.target.value, selectedModelType);
                      setModelClassificationByModelId(modelId, event.target.value);
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {map(classificationModelOptions, (option) => (
                      <MenuItem value={option.id}>{option.displayName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            {find(classificationModelOptions, { id: modelClassificationByModelId[modelId]?.readAs })
              ?.needBinningParams && (
              <Grid item>
                <Tooltip title="Intensity Bins">
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setAnchorElIntensityBins(event.currentTarget);
                    }}
                  >
                    <LinearScaleIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  onClick={(e) => e.stopPropagation()}
                  key={modelInference.modelUrl}
                  open={Boolean(anchorElIntensityBins)}
                  anchorEl={anchorElIntensityBins}
                  onClose={handleCloseIntensityBins}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    style: { width: '500px' },
                  }}
                >
                  <IntensityBins
                    binning={{
                      numBins: modelClassificationByModelId[modelId]?.numBins,
                      mapValuesToBins: modelClassificationByModelId[modelId]?.mapValuesToBins,
                    }}
                    setBinning={(binning: ClassificationBinningParams) => {
                      setIntensityClassificationBinning(modelId, binning);
                    }}
                    classes={modelInference.classes}
                    onClosed={handleCloseIntensityBins}
                  />
                </Popover>
              </Grid>
            )}
          </Grid>
        }
      >
        {map(modelInference?.orchestrations, (orchestration) => {
          return (
            <OrchestrationRow
              key={orchestration.orchestrationId}
              modelUrl={modelInference?.modelUrl}
              modelType={selectedModelType}
              orchestration={orchestration}
              selectedOrchestrations={selectedOrchestrations}
              onCheck={(newOrchestration: OrchestrationInference, slideIds: string[], checked: boolean) => {
                onCheckOrchestration(newOrchestration, slideIds, checked);
              }}
              slides={slides}
              studyId={studyId}
            />
          );
        })}
      </TreeItem>
      {!isEmpty(confirmationModal) && (
        <ConfirmationModal
          title={confirmationModal.title}
          text={confirmationModal?.text}
          onConfirm={confirmationModal?.onConfirm}
          onCancel={() => setConfirmationModal(null)}
        />
      )}
    </>
  );
};

export default ModelRow;
