import { PMTilesMetadata, PMTilesSource } from '@loaders.gl/pmtiles';
import { useQueries, useQuery } from '@tanstack/react-query';
import { map, uniqBy } from 'lodash';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';

export interface PMTilesResult {
  pmtHeatmap?: FeatureMetadata;
  pmtTileSource: PMTilesSource | null;
  pmtMetadata: PMTilesMetadata | null;
}

const nullResponse: PMTilesResult = { pmtTileSource: null, pmtMetadata: null };

export const setupProtomapTiles = async (pmtHeatmap: FeatureMetadata): Promise<PMTilesResult> => {
  const pmtTileSource = new PMTilesSource({ url: pmtHeatmap.heatmapUrl });
  const pmtMetadata = await pmtTileSource.metadata;
  return { pmtHeatmap, pmtTileSource, pmtMetadata };
};

export const useProtomapTiles = (pmtHeatmap: FeatureMetadata): PMTilesResult => {
  const { data } = useQuery(['pmtiles', pmtHeatmap.heatmapUrl], async () => setupProtomapTiles(pmtHeatmap));

  return data || nullResponse;
};

export const useProtomapTilesList = (pmtHeatmaps: FeatureMetadata[]) => {
  return useQueries({
    queries: map(
      uniqBy(pmtHeatmaps, (pmtHeatmap) => pmtHeatmap.heatmapUrl),
      (pmtHeatmap) => ({
        queryKey: ['pmtiles', pmtHeatmap.heatmapUrl],
        queryFn: async () => setupProtomapTiles(pmtHeatmap),
      })
    ),
  });
};
