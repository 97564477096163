import InfoIcon from '@mui/icons-material/Info';
import { TreeItem } from '@mui/lab';
import { Checkbox, Grid, IconButton, Popover, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getJobs } from 'api/jobs';
import { OrchestrationInference } from 'interfaces/calculateFeatures';
import { InferenceJob, Job, JobResponse, JobType } from 'interfaces/job';
import { Slide } from 'interfaces/slide';
import { every, isArray, map, some, toString } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { OrchestrationBySlideByType } from '..';
import { JobDetails } from '../../Jobs/JobDetails';

export interface OrchestrationRowProps {
  orchestration: OrchestrationInference;
  modelUrl: string;
  modelType: string;
  selectedOrchestrations: OrchestrationBySlideByType;
  onCheck: (orchestration: OrchestrationInference, slideIds: string[], checked: boolean) => void;
  slides: Record<string, Pick<Slide, 'stainingType' | 'originalFileName'>>;
  studyId: string;
}

const NO_SLIDE_IDS: string[] = [];

const OrchestrationRow: React.FC<React.PropsWithChildren<OrchestrationRowProps>> = ({
  orchestration,
  modelUrl,
  modelType,
  selectedOrchestrations,
  onCheck,
  slides,
  studyId,
}) => {
  const { labId } = useCurrentLabId();

  const placeholderDataJob = {
    orchestrationId: orchestration.orchestrationId,
    params: {
      modelPaths: modelUrl ? [modelUrl] : [],
      tissueSegmentationModelOverride: orchestration.params.deps?.modelTsm,
      useDynamicCellDetection: orchestration.params.deps?.useDynamicCellDetection,
      dynamicCellDetectionConfig: orchestration.params.deps?.dynamicCellDetectionConfig,
      dedupValue: toString(orchestration.params.deps?.dedupValue),
    },
    type: JobType.Inference,
  } as InferenceJob;

  const { data }: { data: JobResponse } = useQuery({
    queryKey: ['jobs', orchestration.orchestrationId],
    queryFn: ({ signal }) =>
      getJobs(
        { filters: { studyId: studyId, orchestrationId: orchestration.orchestrationId }, labId, fullData: true },
        signal
      ),
    placeholderData: (): JobResponse => ({
      jobs: [placeholderDataJob],
      totalJobs: 1,
    }),
  });

  const jobData: Job = data.jobs?.[0] || placeholderDataJob;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const orchestrationSlideIds = isArray(orchestration?.slideIds) ? orchestration.slideIds : NO_SLIDE_IDS;
  const numOrchestrationSlideIds = orchestrationSlideIds.length;
  const orchestrationId = orchestration?.orchestrationId;
  const isOrchestrationChecked = every(
    orchestrationSlideIds,
    (slideId) => selectedOrchestrations[slideId]?.[modelType]?.orchestration.orchestrationId === orchestrationId
  );

  return (
    <TreeItem
      key={orchestrationId}
      nodeId={orchestrationId}
      label={
        <Grid container wrap="nowrap" alignItems="center" justifyContent="flex-start">
          <Grid item>
            <Checkbox
              indeterminate={
                !isOrchestrationChecked &&
                some(
                  orchestrationSlideIds,
                  (slideId) =>
                    selectedOrchestrations[slideId]?.[modelType]?.orchestration.orchestrationId === orchestrationId
                )
              }
              checked={isOrchestrationChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onCheck(orchestration, orchestrationSlideIds, event.target.checked);
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>{orchestrationId}</Grid>
              {jobData?.startedAt && (
                <Grid item>
                  <Typography variant="body2">{moment(jobData?.startedAt).format('lll')}</Typography>
                </Grid>
              )}
              <Grid item>
                <Typography variant="caption">
                  {numOrchestrationSlideIds} slide{numOrchestrationSlideIds > 1 && 's'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <IconButton onClick={handleClick}>
              <InfoIcon fontSize="small" />
            </IconButton>
            <Popover
              onClick={(e) => e.stopPropagation()}
              key={orchestrationId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: { width: '500px' },
              }}
            >
              <JobDetails job={jobData} onClosed={handleClose} />
            </Popover>
          </Grid>
        </Grid>
      }
    >
      {map(orchestrationSlideIds, (slideId) => {
        return (
          <TreeItem
            key={`${orchestrationId}-${slideId}`}
            nodeId={`${orchestrationId}-${slideId}`}
            label={
              <Grid container wrap="nowrap" spacing={1} alignItems="center">
                <Grid item>
                  <Checkbox
                    checked={
                      selectedOrchestrations[slideId]?.[modelType]?.orchestration?.orchestrationId === orchestrationId
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onCheck(orchestration, [slideId], event.target.checked);
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Grid>
                <Grid item>{slideId}</Grid>
                <Grid item>
                  <Typography variant="caption">Slide Filename: {slides?.[slideId]?.originalFileName}</Typography>
                </Grid>
              </Grid>
            }
          ></TreeItem>
        );
      })}
    </TreeItem>
  );
};

export default OrchestrationRow;
