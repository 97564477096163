import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import React from 'react';

export const rowEditingControlsColumn = ({
  disabledEditing,
  mutatingRowId,
  rowModesModel,
  setRowModesModel,
  apiRef,
  getRowError,
  saveOperation,
  deleteOperation,
  isDeletedRow,
  isDraftRow,
  onCancelEdit,
  confirmCancelEdit,
  hideRowActions,
}: {
  disabledEditing?: boolean;
  mutatingRowId?: GridRowId;
  rowModesModel: GridRowModesModel;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  apiRef?: React.MutableRefObject<GridApiCommunity>;
  getRowError?: (
    params: GridRowParams & {
      apiRef?: React.MutableRefObject<GridApiCommunity>;
    }
  ) => string | undefined;
  deleteOperation?: (id: GridRowId) => () => Promise<boolean>;
  isDraftRow?: (id: GridRowId) => boolean;
  isDeletedRow?: (id: GridRowId) => boolean;
  confirmCancelEdit?: (id: GridRowId) => Promise<boolean>;
  // A function that is called when the user clicks the cancel button
  onCancelEdit?: (id: GridRowId) => void;
  // A function that returns a promise that resolves to true if the save operation was successful
  saveOperation: (id: GridRowId) => Promise<boolean>;
  hideRowActions?: (id: GridRowId) => boolean;
}): GridColDef => {
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel((curr) => ({ ...curr, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    if (await saveOperation(id)) {
      const isDraft = Boolean(isDraftRow?.(id));
      if (isDraft) {
        // Remove the draft row from the row model as it will be replaced with the saved row
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setRowModesModel(({ [id]: draftRowMode, ...rest }) => rest);
      } else {
        setRowModesModel((curr) => ({ ...curr, [id]: { mode: GridRowModes.View } }));
      }
    }
  };

  const handleCancelEditClick = (id: GridRowId) => async () => {
    if (!confirmCancelEdit || (await confirmCancelEdit(id))) {
      const isDraft = Boolean(isDraftRow?.(id));
      if (isDraft) {
        // Remove the draft row from the row model as it will be deleted (canceling the creation of the row)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setRowModesModel(({ [id]: draftRowMode, ...rest }) => rest);
      } else {
        // Set the row mode to view and ignore modifications
        setRowModesModel((curr) => ({
          ...curr,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        }));
      }
      onCancelEdit?.(id);
    }
  };

  return {
    field: 'actions',
    type: 'actions',
    sortable: false,
    editable: false,
    width: 150,
    cellClassName: 'actions',
    getActions: (params) => {
      const { id } = params;
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      const hideActions = hideRowActions?.(id);

      const error = getRowError?.({ ...params, apiRef });

      if (mutatingRowId === id) {
        return [
          <GridActionsCellItem
            label="Saving..."
            key="Saving..."
            icon={<CircularProgress title="Saving..." size={16} />}
            disabled
            showInMenu={false}
          />,
        ];
      } else if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip open={Boolean(error) || undefined} title={error || 'Save'} key="Save">
                <CheckIcon titleAccess="Save" color={error ? 'secondary' : 'primary'} />
              </Tooltip>
            }
            label="Save"
            key="Save"
            onClick={!error && handleSaveClick(id)}
            showInMenu={false}
            disabled={Boolean(error)}
            title={error}
          />,
          <GridActionsCellItem
            icon={<CancelIcon titleAccess="Cancel" />}
            showInMenu={false}
            label="Cancel"
            key="Cancel"
            className="textPrimary"
            onClick={handleCancelEditClick(id)}
            color="inherit"
          />,
        ];
      } else if (hideActions) {
        return [];
      } else {
        return [
          <GridActionsCellItem
            icon={<EditIcon titleAccess="Edit" />}
            label="Edit"
            key="Edit"
            disabled={disabledEditing || isDeletedRow?.(id)}
            showInMenu={false}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          ...(deleteOperation
            ? [
                <GridActionsCellItem
                  key="Delete"
                  icon={<DeleteIcon />}
                  label="Delete"
                  disabled={disabledEditing || isDeletedRow?.(id)}
                  onClick={deleteOperation(id)}
                  color="inherit"
                />,
              ]
            : []),
        ];
      }
    },
  };
};
