import { Button, Grid } from '@mui/material';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { ExperimentResultUpdate } from 'interfaces/experimentResults';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { NumberParam, useQueryParam } from 'use-query-params';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import useResultsMutation from './useResultsMutation';

export const APPROVE_RESULTS_TEXT = 'Approve';
export const DISAPPROVE_RESULTS_TEXT = 'Disapprove';
export const PUBLISH_RESULTS_TEXT = 'Publish';
export const UNPUBLISH_RESULTS_TEXT = 'Unpublish';

export interface OrchestrationApprovalProps {
  slideId: string;
  orchestrationId?: string;
  experimentResultIds?: number[];
  isInternallyApproved: boolean;
  isPublishMode: boolean;
  doesSlideHaveApprovedResults: boolean;
  isOrchestrationIdPublished?: boolean;
  flowClassNames?: string[];
  resultTypes?: string[];
  studyId: string;
  onApprovalMutation?: () => void;
}

export const OrchestrationApproval: React.FunctionComponent<OrchestrationApprovalProps> = ({
  slideId,
  orchestrationId,
  experimentResultIds,
  isInternallyApproved,
  isOrchestrationIdPublished,
  isPublishMode,
  doesSlideHaveApprovedResults,
  flowClassNames,
  resultTypes,
  studyId,
  onApprovalMutation,
}) => {
  const { queryParams } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);

  const params = useParams();
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);

  const { handleFieldSave } = useResultsMutation(caseId, studyId, onApprovalMutation);

  const handleUpdateOrchestration = <T extends keyof ExperimentResultUpdate>(
    fieldName: T,
    value: ExperimentResultUpdate[T]
  ) => {
    handleFieldSave({ orchestrationId, experimentResultIds, slideId, fieldName, value, flowClassNames, resultTypes });
  };

  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const confirmationModalText = isPublished
    ? doesSlideHaveApprovedResults
      ? 'This slide has published results. By publishing these results, the old ones will be unpublished. Are you sure you want to replace the published results?'
      : 'By publishing results, all users will see the results. Are you sure you want to publish the results?'
    : !isInternallyApproved
    ? 'Are you sure you want to approve these results?'
    : 'Are you sure you want to disapprove these results?';

  return (
    <>
      {isPublishMode && (
        <Grid item xs={6} container>
          <Grid item>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setOpenConfirmationDialog(true);
                setIsPublished(false);
              }}
            >
              {isInternallyApproved ? DISAPPROVE_RESULTS_TEXT : APPROVE_RESULTS_TEXT}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isOrchestrationIdPublished}
              onClick={(e) => {
                e.stopPropagation();
                setOpenConfirmationDialog(true);
                setIsPublished(true);
              }}
            >
              Publish
            </Button>
          </Grid>
        </Grid>
      )}
      {openConfirmationDialog && (
        <ConfirmationModal
          title={`${isPublished ? PUBLISH_RESULTS_TEXT : APPROVE_RESULTS_TEXT} Results`}
          text={confirmationModalText}
          onConfirm={() => {
            handleUpdateOrchestration(
              isPublished ? 'approved' : 'internallyApproved',
              isPublished || !isInternallyApproved
            );
            setOpenConfirmationDialog(false);
          }}
          onCancel={() => setOpenConfirmationDialog(false)}
        />
      )}
    </>
  );
};

export default OrchestrationApproval;
