// eslint-disable-next-line import/order
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid, IconButton, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { generateUnwoundRowIdFromMetadata } from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { find, first, map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { CardMode } from '..';
import CardCheckbox from './CardCheckbox';
import ItemsCountBadge from './ItemsCountBadge';
import SlideCarouselItem from './SlideCarouselItem';

export const StyledCarousel = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  borderRadius: '100%',
  backgroundColor: `${theme.palette.custom.background.transparent.white} !important`,
  color: 'black',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  '&.back': {
    left: '4px',
  },
  '&.next': {
    right: '4px',
  },
}));

interface Props {
  slides: Slide[];
  onChangeSlide?: (s: Slide) => void;
  galleryView?: boolean;
  mode?: CardMode;
  caseData?: Procedure;
  forceDisplayCheckbox?: boolean;
  selectable?: boolean;
}

const getStainIndex = (slides: Slide[], stainingType: string): number | undefined => {
  if (!stainingType) return undefined;
  const currentStainIndex = slides.findIndex((slide) => slide.stainingType === stainingType);
  return currentStainIndex === -1 ? undefined : currentStainIndex;
};

const CarouselSliderControls: React.FC<
  React.PropsWithChildren<{
    slides: Slide[];
    currentIndex: number;
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    mode?: CardMode;
  }>
> = ({ slides, currentIndex, setCurrentIndex, mode }) => {
  const slideCount = slides?.length ?? 0;
  const lastSlideIndex = slideCount - 1;

  const handleNextClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (currentIndex >= lastSlideIndex) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (currentIndex === 0) {
      setCurrentIndex(lastSlideIndex);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
      <ArrowButton onClick={handlePrevClick} className="back">
        <ArrowBackIosIcon sx={{ marginLeft: '5px' }} />
      </ArrowButton>

      <ArrowButton onClick={handleNextClick} className="next">
        <ArrowForwardIosIcon />
      </ArrowButton>

      <ItemsCountBadge
        slideCount={slideCount}
        caseHasQcFailedSlide={Boolean(find(slides, (slide) => slide.qcFailed))}
        showWarning={mode === 'full'}
      />
    </>
  );
};
const SlideCarousel: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  slides,
  onChangeSlide,
  galleryView,
  mode = 'full',
  caseData,
  forceDisplayCheckbox,
  selectable,
}) => {
  const { queryParams } = useEncodedFilters();
  const filteredStainingType: string | undefined = first(queryParams?.filters?.stains);
  const [defaultStainingType] = useQueryParam('defaultStainingType', StringParam);

  const stainingTypeToDisplay = filteredStainingType || defaultStainingType;

  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);

  const slidesCount = slides?.length ?? 0;
  if (slidesMode && slidesCount > 1) {
    console.warn('slides mode is on but there are more than one slide');
  }

  const { hasMetadataEditingPermissions } = usePermissions();

  const [hovered, setHovered] = React.useState(false);

  const ref = useRef(null);

  const [slidingCurrentIndex, setSlidingCurrentIndex] = useState(
    stainingTypeToDisplay ? getStainIndex(slides, stainingTypeToDisplay) ?? 0 : 0
  );
  const currentIndex = slidingCurrentIndex % slidesCount;

  const rowId = slidesMode
    ? generateUnwoundRowIdFromMetadata({
        baseRowId: caseData?.id,
        innerRowId: first(slides)?.id,
      })
    : caseData?.id;

  // Reset the current index when the stain type filter / default stain type changes
  useEffect(() => {
    setSlidingCurrentIndex((previous) =>
      stainingTypeToDisplay ? getStainIndex(slides, stainingTypeToDisplay) ?? previous : previous
    );
  }, [slides, stainingTypeToDisplay]);

  // Animate the carousel when the current index changes
  useEffect(() => {
    ref.current.style.transition = 'all 0.2s ease-in-out';
    ref.current.style.transform = `translateX(-${currentIndex}00%)`;

    if (onChangeSlide && slides[currentIndex]) {
      onChangeSlide(slides[currentIndex]);
    }
  }, [slides, currentIndex, onChangeSlide]);

  const shouldShowCheckbox = selectable && (forceDisplayCheckbox || hovered);

  return (
    <StyledCarousel>
      <Box
        data-cy={`slide-carousel-rowId-${rowId}`}
        onMouseOver={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        <Grid container ref={ref} flexWrap="nowrap">
          {map(slides, (slide, i) => (
            <SlideCarouselItem
              key={slide.id}
              slide={slide}
              active={currentIndex === i}
              galleryView={galleryView}
              mode={mode}
            >
              {children}
            </SlideCarouselItem>
          ))}
        </Grid>
        {shouldShowCheckbox && hasMetadataEditingPermissions && (
          <Grid item container sx={{ position: 'absolute', top: 0, left: 0 }}>
            <CardCheckbox id={rowId} />
          </Grid>
        )}
      </Box>
      {/* Don't display controls if there is only one slide or if we are displaying a static slide */}
      {slidesCount > 1 && onChangeSlide && (
        <CarouselSliderControls
          currentIndex={currentIndex}
          setCurrentIndex={setSlidingCurrentIndex}
          slides={slides}
          mode={mode}
        />
      )}
    </StyledCarousel>
  );
};

export default SlideCarousel;
