import { AnnotationAssignment } from 'interfaces/annotation';
import { AutomaticCondition } from 'interfaces/automaticCondition';
import { Input } from 'interfaces/calculateFeatures';
import PostProcessingAction, {
  InputSource,
  InputType,
  MappingFilterMetadata,
  PostProcessingActionCreated,
} from 'interfaces/postProcessingAction';
import {
  Dictionary,
  cloneDeep,
  filter,
  find,
  findKey,
  first,
  forEach,
  get,
  isEmpty,
  isEqual,
  isObject,
  last,
  map,
  set,
  split,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { NewCell, SelectedAssignmentDetails } from '.';
import { getNameByValue } from './FeaturesStep/NewCellsStain';
import { convertLogicalQuery, convertLogicalQueryFromBackend } from './PostProcessingActions/utils';

// example to modelUrl - "artifact://models/e2e/Seagen_B6A/Lung/PDL1/segmentation/2695841c?hash_data=1dbcc14147d685590257aa679be77345"
// example to modelId - "2695841c"
export const getModelId = (modelUrl: string) => {
  return first(split(last(split(modelUrl, '/')), '?'));
};

// example to modelUrl - "artifact://tsm/{slide_id}.pickle?latest&meta.deps.model_id=75f8b1dc&meta.deps.override_annotations_timestamp=2023-08-27 14:48:53"
// example to override_annotations_timestamp - "2023-08-27 14:48:53"
export const getOverrideAnnotationsTimestamp = (modelUrl: string) => {
  return last(split(modelUrl, 'override_annotations_timestamp=')) ?? 'None';
};

export const convertFeatureActionsToBackend = (
  postProcessingActionsById: Dictionary<PostProcessingAction>,
  featureActions: PostProcessingActionCreated[],
  newCellsCreated: Record<string, NewCell>
) => {
  return map(featureActions, (action) => {
    const intermediateCellsInput = filter(postProcessingActionsById?.[action.actionId]?.inputs, {
      inputSource: InputSource.INTERMEDIATE_CELLS_FEATURES_PER_TARGET_STAIN,
    });

    if (!isEmpty(intermediateCellsInput)) {
      const newAction = cloneDeep(action);
      forEach(intermediateCellsInput, (input) => {
        if (input.inputType === InputType.SELECT) {
          const selectedValue = get(action, input.inputKey);
          if (newCellsCreated[selectedValue]) {
            set(newAction, input.inputKey, convertLogicalQuery(newCellsCreated[selectedValue].value));
          }
        } else if (input.inputType === InputType.MULTISELECT) {
          const selectedValues = get(action, input.inputKey);
          const newValue = map(selectedValues, (selectedOption) => {
            if (newCellsCreated[selectedOption]) {
              return convertLogicalQuery(newCellsCreated[selectedOption].value);
            }
            return selectedOption;
          });
          set(newAction, input.inputKey, newValue);
        }
      });

      return newAction;
    }

    return action;
  });
};

export const convertPostProcessingActionsToBackend = (
  postProcessingActionsById: Dictionary<PostProcessingAction>,
  postProcessingActions: PostProcessingActionCreated[]
) => {
  return map(postProcessingActions, (action) => {
    const logicalQueryInput = find(postProcessingActionsById?.[action.actionId]?.inputs, {
      inputType: InputType.LOGICAL_QUERY,
    });

    if (logicalQueryInput) {
      const newAction = cloneDeep(action);
      const convertedLogicalQuery = convertLogicalQuery(get(action, logicalQueryInput.inputKey));
      set(newAction, logicalQueryInput.inputKey, convertedLogicalQuery);
      return newAction;
    }

    return action;
  });
};

export const convertPostProcessingActionsFromBackend = (
  postProcessingActionsById: Dictionary<PostProcessingAction>,
  postProcessingActions: PostProcessingActionCreated[],
  getMappingFiltersMetadataForLogicalQuery: (
    stain: string,
    index: number,
    postProcessingActions: PostProcessingActionCreated[],
    actionInput: string
  ) => any,
  getOptionsByValue: (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actionInput: string,
    inputSourceDependentOn?: string
  ) => Dictionary<{ id: string | number; label: string }>
) => {
  return map(postProcessingActions, (action, index) => {
    const newAction = cloneDeep(action);

    const logicalQueryInput = find(postProcessingActionsById?.[action.actionId]?.inputs, {
      inputType: InputType.LOGICAL_QUERY,
    });

    const freeSoloInput = find(postProcessingActionsById?.[action.actionId]?.inputs, {
      freeSolo: true,
    });

    if (logicalQueryInput) {
      set(
        newAction,
        logicalQueryInput.inputKey,
        convertLogicalQueryFromBackend(
          get(action, logicalQueryInput.inputKey),
          getMappingFiltersMetadataForLogicalQuery(action?.stain, index, postProcessingActions, action.actionInput)
        )
      );
    }
    if (freeSoloInput) {
      const options = getOptionsByValue(
        freeSoloInput.inputSource,
        [action.stain],
        index,
        action.actionInput,
        get(action, freeSoloInput.inputSourceDependentOn)
      );
      // if the value is not in the options, it means it is a new option value
      if (isEmpty(options) || !options[get(action, freeSoloInput.inputKey)]) {
        if (get(action, freeSoloInput.inputKey)) set(newAction, 'newOptionValue', get(action, freeSoloInput.inputKey));

        const attributeToMapInput = find(postProcessingActionsById?.[action.actionId]?.inputs, {
          inputKey: 'action_params.attribute_to_map',
        });

        if (attributeToMapInput && get(action, attributeToMapInput.inputKey)) {
          set(newAction, 'newOptionValueInput', get(action, attributeToMapInput.inputKey));
        }
      } else {
        delete newAction.newOptionValue;
        delete newAction.newOptionValueInput;
      }
    } else {
      delete newAction.newOptionValue;
      delete newAction.newOptionValueInput;
    }

    return newAction;
  });
};

const getNewCellIdIfAlreadyCreated = (newCellsCreatedToSearch: Record<string, NewCell>, value: AutomaticCondition) => {
  const newCellKey = findKey(newCellsCreatedToSearch, (newCellCreated) => isEqual(newCellCreated.value, value));

  if (newCellKey) {
    newCellsCreatedToSearch[newCellKey].name = getNameByValue(value);
  }

  return newCellKey;
};

const getNewCellsCreated = (
  newCellsCreatedToAdd: Record<string, NewCell>,
  cellValue: any,
  getMappingFiltersMetadataForLogicalQuery: (stain: string) => MappingFilterMetadata[],
  stain: string
) => {
  const newCellValue = convertLogicalQueryFromBackend(cellValue, getMappingFiltersMetadataForLogicalQuery(stain));

  let newCellId = getNewCellIdIfAlreadyCreated(newCellsCreatedToAdd, newCellValue);

  if (!newCellId) {
    newCellId = uuidv4();
    newCellsCreatedToAdd[newCellId] = {
      name: getNameByValue(newCellValue),
      stain: stain,
      value: newCellValue,
    };
  }

  return newCellId;
};

export const convertFeatureActionsFromBackend = (
  postProcessingActionsById: Dictionary<PostProcessingAction>,
  featureActions: PostProcessingActionCreated[],
  getMappingFiltersMetadataForLogicalQuery: (stain: string) => MappingFilterMetadata[]
) => {
  let newCellsCreated: Record<string, NewCell> = {};

  const convertedFeatureActions = map(featureActions, (action) => {
    const intermediateCellsInput = filter(postProcessingActionsById?.[action.actionId]?.inputs, {
      inputSource: InputSource.INTERMEDIATE_CELLS_FEATURES_PER_TARGET_STAIN,
    });

    if (!isEmpty(intermediateCellsInput)) {
      const newAction = cloneDeep(action);
      forEach(intermediateCellsInput, (input) => {
        const value = get(action, input.inputKey);
        if (input.inputType === InputType.SELECT) {
          // if value is new cell
          if (isObject(value)) {
            const newCellId = getNewCellsCreated(
              newCellsCreated,
              value,
              getMappingFiltersMetadataForLogicalQuery,
              first(get(action, 'stains'))
            );

            set(newAction, input.inputKey, newCellId);
          }
        } else if (input.inputType === InputType.MULTISELECT) {
          const newValues = map(value, (selectedOption) => {
            if (isObject(selectedOption)) {
              const newCellId = getNewCellsCreated(
                newCellsCreated,
                selectedOption,
                getMappingFiltersMetadataForLogicalQuery,
                first(get(action, 'stains'))
              );

              return newCellId;
            }

            return selectedOption;
          });
          set(newAction, input.inputKey, newValues);
        }
      });

      return newAction;
    }

    return action;
  });

  return {
    newCellsCreated,
    convertedFeatureActions,
  };
};

export const getSelectedAssignmentDetails = (
  assignmentInput: Input,
  slideIdStainingType: string,
  annotationAssignmentsByStain: {
    [stainType: string]: AnnotationAssignment[];
  }
): SelectedAssignmentDetails => {
  return {
    id: uuidv4(),
    modelType: assignmentInput.modelType,
    assignment: find(annotationAssignmentsByStain[slideIdStainingType], {
      annotationAssignmentId: assignmentInput.assignmentId,
    }),
    useClassesFromIgnore: isEmpty(assignmentInput.classesToUse) ? true : false,
    classToIgnore: assignmentInput.classesToIgnore,
    classToUse: assignmentInput.classesToUse,
  };
};
