import { PickingInfo } from '@deck.gl/core/typed';
import DeckGL, { DeckGLProps, DeckGLRef } from '@deck.gl/react/typed';
import { Matrix4 } from '@math.gl/core';
import { Box, Skeleton } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { min } from 'lodash';
import React from 'react';

import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from 'components/Procedure/useSlideImages';
import OrthographicMapView, { OrthographicMapViewState } from './OrthographicMapview';
import { useOverviewLayers } from './layers/useOverviewLayers';
import { calculateInitialZoomLevel, deckGLViewers } from './slidesViewerState';

const OVERVIEW_VIEW_ID = 'overview';

export const SlideDeckGLOverview: React.FC<
  React.PropsWithChildren<{
    slide: SlideWithChannelAndResults;
    procedureId: number;
    viewSize: { width: number; height: number };
    onViewStateChange: DeckGLProps['onViewStateChange'];
    overviewSizeFactor?: number;
    darkBackground?: boolean;
    baseImagePyramids: ImagePyramid;
    heatmapsImagePyramids: HeatmapsImagePyramids;
  }>
> = ({
  slide,
  baseImagePyramids,
  heatmapsImagePyramids,
  procedureId,
  onViewStateChange,
  viewSize: fullViewSize,
  overviewSizeFactor = 5,
  darkBackground,
}) => {
  useSignals();
  const deckGLViewerState = deckGLViewers[slide?.viewerIndex];

  const viewerStates = deckGLViewerState?.value;
  const parentViewerState = viewerStates?.[slide?.id];
  const overviewDimension = min([fullViewSize.width, fullViewSize.height]) / overviewSizeFactor;

  const viewState: OrthographicMapViewState | undefined = React.useMemo(() => {
    if (!parentViewerState) {
      return undefined;
    }

    const canCalculateOverviewZoom = Boolean(fullViewSize?.height && fullViewSize?.width);

    if (!canCalculateOverviewZoom) {
      return undefined;
    }

    const imageSize = { width: slide.sizeCols, height: slide.sizeRows };

    const overviewZoom = calculateInitialZoomLevel(
      overviewDimension,
      overviewDimension,
      imageSize.width,
      imageSize.height
    );

    return {
      ...(parentViewerState || {}),
      zoom: !isNaN(overviewZoom) ? overviewZoom : 0,
      minZoom: Math.floor(overviewZoom),
      maxZoom: Math.floor(overviewZoom),
      doubleClickZoom: false,
      target: new Matrix4().transform(
        [imageSize.width / 2, imageSize.height / 2, 0],
        undefined
      ) as OrthographicMapViewState['target'],
    };
  }, [parentViewerState, fullViewSize?.width, fullViewSize?.height, overviewDimension, slide.sizeCols, slide.sizeRows]);

  const deckGlRef = React.useRef<DeckGLRef>(null);

  const { layers } = useOverviewLayers({
    slide,
    baseImagePyramids,
    heatmapsImagePyramids,
    viewSize: fullViewSize,
    // We use the actual state so that the viewport rectangle syncs immediately
    viewState: parentViewerState,
    procedureId,
    useLightOverviewMarker: slide.stainingType === 'mplex',
    overviewSizeFactor,
  });

  const overviewView = React.useMemo(
    () =>
      new OrthographicMapView({
        id: OVERVIEW_VIEW_ID,
        controller: { scrollZoom: false, touchZoom: false, doubleClickZoom: true },
        ignorePitch: true,
        width: overviewDimension,
        height: overviewDimension,
      }),
    [overviewDimension]
  );

  const panMainViewer = (info: PickingInfo) => {
    if (info?.coordinate && parentViewerState) {
      onViewStateChange({
        viewId: OVERVIEW_VIEW_ID,
        // We use the actual state so that the viewport state that we update is the one that is currently being used
        viewState: { ...parentViewerState, target: info.coordinate },
        oldViewState: parentViewerState,
        interactionState: { isDragging: false, isPanning: true, isRotating: false, isZooming: false },
      });
    }
  };

  return (
    <Box
      sx={{
        width: overviewDimension,
        height: overviewDimension,
        position: 'absolute',
        left: `calc(100% - ${overviewDimension}px - ${overviewDimension}px / 20)`,
        top: `calc(${overviewDimension}px / 20)`,
        border: 1,
        borderColor: darkBackground ? 'white' : 'black',
        backgroundColor: darkBackground ? 'black' : 'white',
      }}
    >
      {viewState ? (
        <DeckGL
          ref={deckGlRef}
          views={overviewView}
          layers={layers}
          viewState={viewState}
          getCursor={() => 'pointer'}
          onClick={panMainViewer}
          onDrag={panMainViewer}
        />
      ) : (
        <Skeleton variant="rectangular" width={overviewDimension ?? '100%'} height={overviewDimension ?? '100%'} />
      )}
    </Box>
  );
};
