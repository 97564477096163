import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { CircularProgress, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createCustomerResults } from 'api/customerResults';
import { cancelJob, getJob } from 'api/jobs';
import { getInternalUsers } from 'api/userCredentials';
import JobModal from 'components/EditSlidesAndCasesDisplay/JobsModal';
import PageHeader from 'components/PageHeader';
import { Main, jobDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import { Job, JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { fromPairs, get, isEmpty, keyBy, map } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { BooleanParam, JsonParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { usePermissions } from 'utils/usePermissions';
import Filters from './Filters';
import { JobDrawer } from './JobDrawer';
import { defaultRowsPerPage, jobsQueryKey, useJobs } from './useJobs';


const Jobs: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [page, setPage] = useState(1);
  const [currentJobModal, setCurrentJobModal] = useState<{ jobType: JobType; jobData: Job }>(null);

  const { userId: loggedInUserId } = useAppSelector((state) => state.auth.profile);
  const { uiSettings, isLoadingUiSettings } = useUiSettings();
  const { labSearch, labId } = useCurrentLabId();

  const navigate = useNavigate();
  const { encodedFilters } = useEncodedFilters();
  const location = useLocation();

  const [filters] = useQueryParam('filters', JsonParam);

  const { data: internalUsers } = useQuery(['internalUsers'], getInternalUsers);
  const internalUsersById = useMemo(() => keyBy(internalUsers, 'id'), [internalUsers]);

  const { hasPermission, isLoading: isLoadingPermissions } = usePermissions();
  const canContinueJob = hasPermission(Permission.ContinueJob);
  const canCancelAllUsersJobs = hasPermission(Permission.CancelAllUsersJobs);
  const canCancelOwnJobs = hasPermission(Permission.CancelOwnJobs);
  const hasCancelJobPermission = canCancelAllUsersJobs || canCancelOwnJobs;


  const { data, isLoading, isFetching } = useJobs({ page });

  const [queryParams, setQueryParams] = useQueryParams({
    jobDrawerOpen: BooleanParam,
    currentJobId: StringParam,
  });

  const rowCount = data?.totalJobs ?? data?.jobs?.length ?? 0;
  const jobStatusToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['jobStatus'], ({ value, label }) => [value, label]) || []
  );
  const requestSenderToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['requestSender'], ({ value, label }) => [value, label]) || []
  );

  const createCustomerResultsMutation = useMutationWithErrorSnackbar({
    mutationFn: createCustomerResults,
    mutationDescription: 'create customer results',
  });

  const jobMutation = useMutationWithErrorSnackbar({
    mutationFn: getJob,
    mutationDescription: 'get job',
  });

  const queryClient = useQueryClient();

  const cancelJobMutation = useMutationWithErrorSnackbar({
    onSuccess: () => {
      queryClient.invalidateQueries([jobsQueryKey]);
    },
    mutationFn: cancelJob,
    mutationDescription: 'cancel job',
  });

  const columns: GridColDef[] = [
    {
      field: 'startedAt',
      headerName: 'Started At',
      width: 160,
      renderCell: (params) => <Typography variant="body2">{moment(params.value).format('lll')}</Typography>,
    },
    { field: 'name', headerName: 'Name', width: 230 },
    { field: 'description', headerName: 'Description', width: 250 },
    {
      field: 'userId',
      headerName: 'User',
      width: 150,
      renderCell: (params) => (
        <Typography variant="body2">
          {internalUsersById[params.value]?.name ?? internalUsersById[params.value]?.primaryEmail ?? params.value}
        </Typography>
      ),
    },
    { field: 'orchestrationId', headerName: 'Orchestration Id', width: 270 },
    {
      field: 'requestSender',
      headerName: 'Source',
      width: 100,
      renderCell: (params) => <Typography variant="body2">{requestSenderToDisplayText[params.value]}</Typography>,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => <Typography variant="body2">{jobStatusToDisplayText[params.value]}</Typography>,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      editable: false,
      width: 150,
      cellClassName: 'actions',
      getActions: (params) => {
        const type = params.row.type;
        const externalTaskLink = params.row.externalTaskLink;
        const status = params.row.status;
        const jobId = params.row.id;
        const userId = params.row.userId;

        const action = [
          <Tooltip placement="top-start" title="Go to clearML" key={`externalTaskLink-${params.id}`}>
            <Link
              color="inherit"
              underline="hover"
              href={externalTaskLink}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <OpenInNewIcon />
            </Link>
          </Tooltip>,
        ];

        if (type === 'calculate_features') {
          action.push(
            <Tooltip placement="top-start" title="Rebuild" key={`rebuild-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    { pathname: `/calculate-features`, search: `${labSearch}` },
                    { state: { jobId: params.row.id } }
                  );
                }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          );
        }

        if (type === JobType.Inference || type === JobType.MultiplexNormalization) {
          action.push(
            <Tooltip placement="top-start" title="Rebuild" key={`rebuild-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentJobModal({ jobType: type, jobData: params.row });
                }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          );
        }

        if (canContinueJob && type === 'prepare_export_results' && !isLoadingPermissions) {
          action.push(
            <Tooltip placement="top-start" title="Continue" key={`continue-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  jobMutation.mutate(params.row.id.toString(), {
                    onSuccess: (job) => {
                      createCustomerResultsMutation.mutate({
                        studyId: job.studyId,
                        resultTypesToExport: get(job.params, 'resultTypesToExport'),
                        jobId: params.row.id,
                      });
                    },
                  });
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Tooltip>
          );
        }

        if (hasCancelJobPermission) {
          const canCancelJob = canCancelAllUsersJobs || (canCancelOwnJobs && userId === loggedInUserId);
          action.push(
            <Tooltip placement="top-start" title="Cancel job" key={`cancel-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  cancelJobMutation.mutate(jobId);
                }}
                disabled={
                  !(status === 'running') ||
                  !canCancelJob ||
                  !externalTaskLink ||
                  cancelJobMutation.isLoading
                }
              >
                {
                  cancelJobMutation.isLoading ?
                    <CircularProgress size={20} /> :
                    <CloseIcon />
                }
              </IconButton>
            </Tooltip>
          );
        }

        return action;
      },
    },
  ];

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setQueryParams({ jobDrawerOpen: true });
    setQueryParams({ currentJobId: params.row.id });
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item mt={4}>
          <PageHeader
            pageTitle="Jobs"
            onBackClick={() => {
              const search = location.state?.cameFrom === '/procedures' ? encodedFilters : labSearch;
              navigate({ pathname: location.state?.cameFrom || '/', search: search });
            }}
          />
        </Grid>
        <Grid width={queryParams.jobDrawerOpen ? `calc(100% - ${jobDrawerWidth}px)` : 'auto'} item>
          <Filters />
        </Grid>
        <Main rightDrawerOpen={queryParams.jobDrawerOpen} rightDrawerWidth={jobDrawerWidth}>
          <Grid item container spacing={1} p={1} height="78.5vh" width="100%">
            <DataGrid
              autoHeight
              paginationModel={{
                page: page - 1,
                pageSize: defaultRowsPerPage,
              }}
              onPaginationModelChange={({ page: newPage }) => handleChangePage(newPage + 1)}
              loading={((isLoading || isFetching) && !isEmpty(filters?.studyId)) || isLoadingUiSettings}
              pagination
              rows={data?.jobs || []}
              rowCount={rowCount}
              columns={columns}
              onRowClick={handleRowClick}
              paginationMode="server"
            />
          </Grid>
        </Main>
      </Grid>
      {!isEmpty(queryParams.currentJobId) && <JobDrawer key={queryParams.currentJobId} />}
      {currentJobModal && (
        <JobModal
          casesParams={{
            labId: labId,
            studyId: currentJobModal.jobData.studyId,
            filters: { studyId: currentJobModal.jobData.studyId },
            slideIdsToInclude: currentJobModal.jobData.manifest,
            slidesMode: true,
          }}
          jobType={currentJobModal.jobType}
          jobId={currentJobModal.jobData.id}
          onClose={() => {
            setCurrentJobModal(null);
          }}
        />
      )}
    </>
  );
};

export default wrapPage(Jobs, { maxWidth: '1500px' }, false);
