import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  nextLinkTo: string | null;
  previousLinkTo: string | null;
  isInitialLoading: boolean;
  navigationStageText: string;
}

export const BaseNavigationPanel: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  nextLinkTo,
  previousLinkTo,
  isInitialLoading,
  navigationStageText,
}) => {
  const navigate = useNavigate();

  const navClickHandler = (target: string) => {
    const [pathname, params] = target.split('?');
    navigate({ pathname, search: `?${params}` });
  };

  return (
    <Grid container item alignItems="center">
      <Grid item>
        <IconButton disabled={!previousLinkTo} color="inherit" onClick={() => navClickHandler(previousLinkTo)}>
          <NavigateBeforeIcon />
        </IconButton>
      </Grid>
      {isInitialLoading ? (
        <Grid item alignSelf="center" sx={{ lineHeight: '18px' }}>
          <CircularProgress size={18} />
        </Grid>
      ) : (
        navigationStageText && (
          <Grid item alignItems="center">
            <Typography>{navigationStageText}</Typography>
          </Grid>
        )
      )}
      <Grid item>
        <IconButton disabled={!nextLinkTo} color="inherit" onClick={() => navClickHandler(nextLinkTo)}>
          <NavigateNextIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
