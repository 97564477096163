import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { useSignals } from '@preact/signals-react/runtime';
import { Dictionary, isEmpty, map, toPairs } from 'lodash';
import React, { useEffect, useState } from 'react';

import { slidesLayerVisualizationSettings } from '../slidesVisualizationAndConfiguration';
import { LayerGroupControl } from './LayerGroupControl';
import LayerVisualizationControl from './LayerVisualizationControl';

export interface GroupedLayersVisualControlsProps {
  viewerIndex: number;
  slideId: string;
  groupedLayers: Dictionary<string[]>;
  layerIdsToDisplayNames?: Dictionary<string>;
  // In case layers have slide specific ids, we can map them to the global ids - to allow for persistent settings when navigating between slides
  layerIdsToUrlKeys?: Dictionary<string>;
  stainTypeId: string;
  optionDisplayNames?: Dictionary<string>;
  groupDisplayNames?: Dictionary<string>;
  groupOrchestrationIds?: Dictionary<string>;
  defaultExpanded?: string[];
  disableToggle?: boolean;
  hideOrchestrationId?: boolean;
}

export const GroupedLayersVisualControls: React.FC<React.PropsWithChildren<GroupedLayersVisualControlsProps>> = ({
  viewerIndex,
  slideId,
  groupedLayers,
  layerIdsToDisplayNames,
  layerIdsToUrlKeys,
  stainTypeId,
  optionDisplayNames,
  groupDisplayNames,
  groupOrchestrationIds,
  defaultExpanded,
  disableToggle,
  hideOrchestrationId,
}) => {
  useSignals();
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex]?.value;
  const [layersExpanded, setLayersExpanded] = useState<string[]>(defaultExpanded || []);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setLayersExpanded(nodeIds);
  };

  useEffect(() => {
    setLayersExpanded(defaultExpanded || []);
  }, [defaultExpanded]);

  return (
    <TreeView
      expanded={layersExpanded}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeToggle={handleToggle}
    >
      {map(
        toPairs(groupedLayers),
        ([parentKey, layers]) =>
          !isEmpty(layers) && (
            <TreeItem
              key={parentKey}
              nodeId={parentKey}
              label={
                <LayerGroupControl
                  disableToggle={disableToggle}
                  viewerIndex={viewerIndex}
                  slideId={slideId}
                  displayName={groupDisplayNames?.[parentKey]}
                  parentKey={parentKey}
                  layerIdsToDisplayNames={layerIdsToDisplayNames}
                  layerIdsToUrlKeys={layerIdsToUrlKeys}
                  layers={layers}
                  stainTypeId={stainTypeId}
                  orchestrationId={groupOrchestrationIds?.[parentKey]}
                  hideOrchestrationId={hideOrchestrationId}
                />
              }
            >
              {map(layers, (layerName) => {
                const layerId = `${parentKey}-${layerName}`;
                const selected =
                  disableToggle || viewerSlideLayerVisualizationSettings?.[slideId]?.[layerId]?.value?.selected;
                return (
                  <TreeItem
                    key={layerName}
                    nodeId={layerName}
                    label={
                      <LayerVisualizationControl
                        disableToggle={disableToggle}
                        key={layerName}
                        parentKey={parentKey}
                        layerName={layerName}
                        layerDisplayName={optionDisplayNames?.[layerName]}
                        slideId={slideId}
                        viewerIndex={viewerIndex}
                        stainTypeId={stainTypeId}
                        layerUrlKey={layerIdsToUrlKeys?.[layerId] || layerId}
                        selected={selected}
                      />
                    }
                  />
                );
              })}
            </TreeItem>
          )
      )}
    </TreeView>
  );
};
