import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

import { getClusterTypeOptions } from 'api/clusterTypes';
import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import Loader from 'components/Loader';
import { ClusterType } from 'interfaces/clusterType';
import { clusterTypeFields } from 'interfaces/clusterType/clusterTypeFields';
import { ClusterTypeDraft } from './types';
import { useClusterTypesColumns } from './useClusterTypesColumns';
import { generateDraftId, getClusterTypeId } from './utils';

export const ClusterTypesDataGrid = () => {
  const { data: dbClusterTypes, isLoading: isLoadingClusterTypes } = useQuery(['clusterTypes'], () =>
    getClusterTypeOptions()
  );
  const [draftClusterTypes, setDraftClusterTypes] = React.useState<ClusterTypeDraft[]>([]);
  const clusterTypes = React.useMemo(
    () => [...draftClusterTypes, ...(dbClusterTypes || [])],
    [dbClusterTypes, draftClusterTypes]
  );
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleAddClusterType = useCallback(() => {
    // We use the draftId field to distinguish between draft rows and db rows
    // This is necessary because we need to be able to edit ids in new rows but not in existing rows
    const draftId = generateDraftId();
    const newClusterType: ClusterTypeDraft = { displayName: '', id: '', draftId };
    setDraftClusterTypes((oldDraftClusterTypes) => [newClusterType, ...oldDraftClusterTypes]);
    setRowModesModel((oldRowModesModel) => ({
      ...oldRowModesModel,
      [newClusterType.draftId]: { mode: GridRowModes.Edit },
    }));
  }, [setDraftClusterTypes, setRowModesModel]);

  const apiRef = useGridApiRef();

  const { getRowsWithChanges } = useTableEditingContext<ClusterType | ClusterTypeDraft>();

  const rowsWithChanges = getRowsWithChanges(clusterTypes);

  const handleRowModesModelChange: DataGridProps<ClusterType>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) => {
      handleRowModesModelChangeWithoutDraftIds(
        newRowModesModel,
        setRowModesModel,
        draftClusterTypes,
        (row: ClusterTypeDraft) => row.draftId
      );
    },
    [draftClusterTypes]
  );

  const columns = useClusterTypesColumns({
    noRows: isEmpty(rowsWithChanges),
    apiRef,
    rowsWithChanges,
    draftClusterTypes,
    rowModesModel,
    setDraftClusterTypes,
    setRowModesModel,
  });

  const getClusterTypeCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames<ClusterType | ClusterTypeDraft>({
        apiRef,
        requiredFields: ['id', 'displayName'],
        uniqueFieldGroups: [['id'], ['displayName']],
        draftRows: draftClusterTypes,
        fieldsToCheckForErrors: clusterTypeFields,
        idGetter: getClusterTypeId,
      }),
    [apiRef, draftClusterTypes]
  );

  return !isLoadingClusterTypes ? (
    <SettingsDataGrid
      apiRef={apiRef}
      addText="Add Cluster Type"
      handleAdd={handleAddClusterType}
      getCellClassName={getClusterTypeCellClassName}
      rows={rowsWithChanges}
      columns={columns}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      getRowId={getClusterTypeId}
    />
  ) : (
    <Loader />
  );
};
