import { Autocomplete, TextField } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { find, map } from 'lodash';
import React from 'react';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { viewerAnnotationData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { Annotation } from 'interfaces/annotation';
import { getAnnotationLabel } from './helpers';

export interface AnnotationSelectionProps {
  slideAnnotations: Annotation[];
  viewerIndex: number;
}

export const AnnotationSelection: React.FC<React.PropsWithChildren<AnnotationSelectionProps>> = ({
  slideAnnotations,
  viewerIndex,
}) => {
  // TODO: maybe remove this once annotation list works
  useSignals();
  const [editAnnotationsMode] = useQueryParam('editAnnotationsMode', StringParam);

  const [activeAnnotation, setActiveAnnotation] = useQueryParam(
    `activeAnnotationAssignmentIdViewer${viewerIndex}`,
    NumberParam
  );

  const annotationOptions: Array<{
    label: string;
    value: number | '';
  }> = [
    { label: 'Review Annotations', value: '' },
    ...map(slideAnnotations, (annotation) => ({
      label: getAnnotationLabel(annotation),
      value: annotation.annotationAssignment.annotationAssignmentId,
    })),
  ];

  return (
    <Autocomplete<number | '', false, false, false>
      disabled={Boolean(editAnnotationsMode) || activeAnnotation === -1}
      value={activeAnnotation || ''}
      options={map(annotationOptions, 'value')}
      renderInput={(params) => <TextField {...params} label="Active Annotation" />}
      getOptionLabel={(option) => find(annotationOptions, { value: option })?.label || ''}
      onChange={(event, option) => {
        setActiveAnnotation(option !== '' ? option : null);
        if (viewerAnnotationData[viewerIndex]) {
          viewerAnnotationData[viewerIndex].value = null;
        }
      }}
    />
  );
};
