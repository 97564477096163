import { first, isEmpty, map, trim, uniq } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { CaseSearchItem } from 'services/searchIndex';
import { useDebouncedCallback } from 'use-debounce';
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { useFullSearchResults } from './useFullSearchResults';

// stateful hook for full search
export const useFullSearch = ({
  enabled = true,
  controlledValue,
}: { enabled?: boolean; controlledValue?: string } = {}) => {
  const [searchQuery, setSearchQuery] = useQueryParam('searchTerm', StringParam);
  const [, setCaseIdsToInclude] = useQueryParam('caseIdsToInclude', ArrayParam);
  const [, setSlideIdsToInclude] = useQueryParam('slideIdsToInclude', ArrayParam);
  const [, setPage] = useQueryParam('page', NumberParam);
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);

  const [localSearch, setLocalSearch] = useState<string | null>(null);
  useEffect(() => {
    if (controlledValue !== localSearch) {
      setLocalSearch(controlledValue);
    }
  }, [controlledValue]);

  useEffect(() => {
    const filteredOptionsOfForcedSearch = getFilteredOptionsForForcedSearch(searchQuery);
    applyTerm(searchQuery, filteredOptionsOfForcedSearch);
  }, [searchQuery]);

  const search: string = localSearch ?? searchQuery ?? '';

  const { filteredOptions, allOptions, isLoading, isSuccess, getFilteredOptionsForForcedSearch } = useFullSearchResults(
    search,
    { enabled }
  );

  const setLocalSearchDebounced = useDebouncedCallback((value) => {
    setLocalSearch(value);
  }, 50);

  const applySearch = useCallback(() => {
    applyForceSearch(localSearch);
  }, [localSearch]);

  const applyForceSearch = (forceSearchTerm: string) => {
    setPage(1, 'replaceIn');
    if (forceSearchTerm === '') {
      setSearchQuery(undefined);
    } else {
      setSearchQuery(forceSearchTerm);
    }
  };

  const applyTerm = (term: string, options: CaseSearchItem[]) => {
    setLocalSearch(undefined);
    if (slidesMode) {
      const slideIdsToFilter = map(options, (option) => first(option.slides)?.id);
      if (isEmpty(slideIdsToFilter)) {
        setSlideIdsToInclude(undefined);
      } else {
        setSlideIdsToInclude(slideIdsToFilter);
      }
    } else {
      const caseIdsToFilter = uniq(map(options, (option) => option.caseId.toString()));
      if (isEmpty(caseIdsToFilter)) {
        setCaseIdsToInclude(undefined);
      } else {
        setCaseIdsToInclude(caseIdsToFilter);
      }
    }
  };

  return {
    filteredOptions,
    allOptions,
    localSearch: search,
    searchQuery,
    setLocalSearch,
    setLocalSearchDebounced,
    applySearch,
    applyForceSearch,
    noResults: search && trim(search) !== '' && isEmpty(filteredOptions),
    isLoading,
    isSuccess,
  };
};
