import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';

export const getRasterHeatmapUrlKey = (heatmap: FeatureMetadata, parentHeatmap?: FeatureMetadata) => {
  if (!heatmap) {
    return '';
  }
  const parentHeatmapUrlKey = parentHeatmap?.key || parentHeatmap?.id;
  const heatmapBaseUrlKey = heatmap.key || heatmap.id;
  return parentHeatmapUrlKey && heatmap?.id !== parentHeatmap?.id
    ? `${parentHeatmapUrlKey}-${heatmapBaseUrlKey}`
    : heatmapBaseUrlKey;
};
