import { useQuery } from '@tanstack/react-query';
import { compact, Dictionary, filter, find, get, includes, isEmpty, keyBy, map, values } from 'lodash';
import React from 'react';

import { getCancerTypes } from 'api/cancerTypes';
import { fetchStudies } from 'api/study';
import { BiopsySiteType } from 'interfaces/biopsySiteType';
import { CancerSubtype, CancerType, CancerTypeMap } from 'interfaces/cancerType';
import { FieldOption } from 'interfaces/genericFields';
import { Procedure } from 'interfaces/procedure';
import { Slide, SlideQcLabel } from 'interfaces/slide';
import { SlideTag } from 'interfaces/slideTag';
import { Study } from 'interfaces/study';
import {
  EnumDisplayNames,
  useAllCancerTypes,
  useBiopsySiteTypes,
  useStainTypeOptions,
  useUiSettings,
} from 'utils/queryHooks/uiConstantsHooks';
import { useCurrentLabId } from 'utils/useCurrentLab';

import { FormResponseWithAuthor } from 'interfaces/reviewForm';
import { StainType } from 'interfaces/stainType';
import { ALL_FORMS_ID, useFormResponses } from 'utils/queryHooks/form/useFormResponse';
import { useStudyStainTypeIds } from 'utils/queryHooks/stainType/useStudyStainTypes';
import useSlideTagOptions from 'utils/queryHooks/useSlideTagOptions';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { biopsyTypes } from './biopsyTypes';
import { scannerModels } from './scannerModels';

export const getCancerSubTypes = (
  cancerTypeIds: number[],
  allCancerTypes: CancerTypeMap,
  cancerSubtypes: Record<string, CancerSubtype>
) => {
  const allCancerTypesList: CancerType[] = values(allCancerTypes);
  const cancerSubtypesList = get(cancerSubtypes, 'TISSUE')?.children;

  const cancerTypeObjects = filter(allCancerTypesList, (c) => includes(cancerTypeIds, c?.id));
  const mainCancerTypeCodes = map(cancerTypeObjects, (c) => c?.code?.toUpperCase());

  const relevantSubtypes: CancerSubtype[] = compact(map(mainCancerTypeCodes, (code) => cancerSubtypesList?.[code]));

  return { mainCancerTypeCodes, relevantSubtypes };
};

export const getProcedureSlideFieldValues = <T extends keyof Slide>(
  procedure: Procedure,
  field: T,
  getDefaultValue?: (slide: Slide) => any
): Array<Slide[T]> => {
  const fieldValues = map(procedure?.slides, (slide) => slide?.[field] ?? getDefaultValue?.(slide));
  return !isEmpty(filter(fieldValues, (v) => v !== undefined)) ? fieldValues : [];
};

export const getProcedureSlideQcLabelsValues = (procedure: Procedure, labelToCheck: string): Array<boolean | null> => {
  const slidesQcLabels: SlideQcLabel[][] = map(procedure?.slides, 'qcLabels');

  return map(slidesQcLabels, (slideQcLabels) => {
    const matchedLabel: SlideQcLabel = find(slideQcLabels, { text: labelToCheck });
    return matchedLabel ? (matchedLabel?.errorCalculatingLabel ? null : true) : false;
  });
};

export const getUpdatePathForProcedureClinicalDataField = <T extends keyof Procedure['clinicalData']>(
  field: T
): string => `clinicalData.${field}`;

export interface ProceduresFieldsContext {
  studies: Study[];
  studyById?: Dictionary<Study>;
  cancerTypes?: CancerTypeMap;
  allCancerTypes?: CancerTypeMap;
  cancerSubtypes?: Record<string, CancerSubtype>;
  biopsyTypes?: FieldOption[];
  scannerModels?: string[];
  biopsySiteTypes?: BiopsySiteType[];
  stainTypesInStudy?: string[];
  slideTagOptions?: SlideTag[];
  enumDisplayNames?: EnumDisplayNames;
  stainTypes?: StainType[];
  stainTypesNotDeprecated?: StainType[];
  cancerTypesLoading?: boolean;
  allCancerTypesLoading?: boolean;
  stainTypesInStudyLoading?: boolean;
  studiesLoading?: boolean;
  slideTagOptionsLoading?: boolean;
  formResponses?: FormResponseWithAuthor[];
  formResponsesLoading?: boolean;
}

export const useProceduresFieldsContext = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}): ProceduresFieldsContext => {
  const { labId } = useCurrentLabId();
  const { queryParams } = useEncodedFilters();
  const studyId = queryParams?.filters?.studyId;

  const { data: cancerTypes, isLoading: cancerTypesLoading } = useQuery(
    ['cancerTypes', labId],
    ({ signal }) => getCancerTypes(labId, signal),
    { enabled }
  );

  const { allCancerTypes, isLoadingAllCancerTypes } = useAllCancerTypes({ enabled });
  const { data: stainTypesInStudy, isLoading: stainTypesInStudyLoading } = useStudyStainTypeIds(studyId, {
    enabled,
  });
  const { data: studies, isLoading: studiesLoading } = useQuery(['studies', labId], fetchStudies(labId), { enabled });
  const studyById = React.useMemo(() => keyBy(studies, 'id'), [studies]);
  const { slideTagOptions, isLoading: slideTagOptionsLoading } = useSlideTagOptions();

  const { uiSettings } = useUiSettings();
  const { enumDisplayNames, cancerSubtypes } = uiSettings;
  const { biopsySiteTypes } = useBiopsySiteTypes();
  const { stainTypeOptions } = useStainTypeOptions();
  const stainTypesNotDeprecated = filter(stainTypeOptions, (stainType) => !stainType.deletedAt);

  const { data: formResponses, isLoading: formResponsesLoading } = useFormResponses(
    ALL_FORMS_ID,
    {
      labId,
      ...(studyId ? { studyId } : {}),
    },
    { enabled }
  );

  return {
    stainTypesInStudy,
    biopsyTypes,
    scannerModels,
    biopsySiteTypes,
    cancerTypes,
    allCancerTypes,
    enumDisplayNames,
    studyById,
    studies,
    slideTagOptions,
    stainTypes: stainTypeOptions,
    stainTypesNotDeprecated,
    cancerTypesLoading,
    allCancerTypesLoading: isLoadingAllCancerTypes,
    stainTypesInStudyLoading,
    studiesLoading,
    slideTagOptionsLoading,
    cancerSubtypes,
    formResponses,
    formResponsesLoading,
  };
};
