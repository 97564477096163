import { ClinicalData } from 'interfaces/clinical';
import { ExperimentResultUpdate, FlowClassName, ResultsExistence, ResultsMode } from 'interfaces/experimentResults';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export type SlideResult = {
  slideId: string;
  caseId: number;
  caseName: string;
  orchestrationId: string;
  flowClassName: FlowClassName;
  createdAt?: string;
  approved?: boolean;
  internallyApproved?: boolean;
};

export type Orchestration = {
  orchestrationId: string;
  slidesData: SlideResult[];
  createdAtMinimum?: string;
};

export type OrchestrationResponse = {
  orchestrations: Orchestration[];
};

export const getCasesOrchestrations = (data: {
  features?: any;
  filters?: any;
  clinicalData?: ClinicalData;
  labId?: string;
  caseIdsToInclude?: number[];
  caseIdsToExclude?: number[];
  shouldOnlyGetByStudyIdAndResultsMode?: boolean;
  resultsMode?: ResultsMode;
}) => {
  const queryObject: Record<string, any> = {
    features: JSON.stringify(data.features),
    filters: JSON.stringify(data.filters),
    clinicalData: JSON.stringify(data.clinicalData),
    labId: data.labId,
    shouldOnlyGetByStudyIdAndResultsMode: data.shouldOnlyGetByStudyIdAndResultsMode,
    resultsMode: data.resultsMode,
  };

  if ('caseIdsToInclude' in data) {
    queryObject['caseIdsToInclude'] = data.caseIdsToInclude;
  } else if ('caseIdsToExclude' in data) {
    queryObject['caseIdsToExclude'] = data.caseIdsToExclude;
  }

  return apiRequestHandlerPromise<OrchestrationResponse>({
    url: `orchestrations?${stringify(queryObject, {
      arrayFormat: 'repeat',
    })}`,
    method: 'GET',
  });
};

export const getSlidesOrchestrations = (data: {
  features?: any;
  filters?: any;
  clinicalData?: ClinicalData;
  labId?: string;
  slideIdsToInclude?: string[];
  slideIdsToExclude?: string[];
  slidesMode?: boolean;
  resultsMode?: ResultsMode;
}) => {
  return apiRequestHandlerPromise<OrchestrationResponse>({
    url: `orchestrations?${stringify(
      {
        features: JSON.stringify(data.features),
        filters: JSON.stringify(data.filters),
        clinicalData: JSON.stringify(data.clinicalData),
        labId: data.labId,
        slideIdsToInclude: data.slideIdsToInclude,
        slideIdsToExclude: data.slideIdsToExclude,
        slidesMode: data.slidesMode,
        resultsMode: data.resultsMode,
      },
      {
        arrayFormat: 'repeat',
      }
    )}`,
    method: 'GET',
  });
};

export const bulkUpdateResultsByOrchestration = ({
  studyId,
  labId,
  orchestrationId,
  ...data
}: {
  orchestrationId: string;
  flowClassNames?: string[];
  resultTypes?: string[];
  slideIds: string[];
  studyId: string;
  labId: string;
  updatedData: ExperimentResultUpdate;
}) => {
  return apiRequestHandlerPromise<OrchestrationResponse>({
    url: `studies/${studyId}/results/orchestrations/${orchestrationId}?${stringify({ labId })}`,
    method: 'PATCH',
    data: JSON.stringify(data),
  });
};

export const bulkUpdateResultsByExperimentResultIds = ({
  studyId,
  labId,
  ...data
}: {
  experimentResultIds?: number[];
  studyId: string;
  labId: string;
  updatedData: ExperimentResultUpdate;
}) => {
  return apiRequestHandlerPromise<OrchestrationResponse>({
    url: `studies/${studyId}/results/by_ids?${stringify({ labId })}`,
    method: 'PATCH',
    data: JSON.stringify(data),
  });
};

export const getResultsExistence = (stringParams: string, studyId: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<ResultsExistence>({
    url: `studies/${studyId}/results-existence-by-flow-class-name?${stringParams}`,
    method: 'GET',
    signal,
  });
};
