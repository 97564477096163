import { Button, Grid, Switch, TextField, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { Taxonomy, TaxonomyTree } from 'interfaces/taxonomy';
import { compact, filter, flatMap, includes, isEmpty, map, split } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';
import { buildTaxonomyTree } from 'utils/taxonomy';
import TaxonomyTreeViewItem from './TaxonomyTreeViewItem';

function getParentPathComponents(item: string) {
  const components = split(item, '.');
  const paths = [];

  // Build the paths step by step
  for (let i = 1; i <= components.length; i++) {
    paths.push(components.slice(0, i).join('.'));
  }

  return paths;
}

function trimTreeToSearchResults(tree: TaxonomyTree, matchedItemPaths: string[]): TaxonomyTree {
  const children = filter(tree.children, (child) => includes(matchedItemPaths, child.path));
  if (!includes(matchedItemPaths, tree.path)) return null;
  if (isEmpty(children)) return { ...tree, children: [] };
  return {
    ...tree,
    children: map(children, (child) => trimTreeToSearchResults(child, matchedItemPaths)),
  };
}

const TaxonomyEditor: React.FC = () => {
  const { data: taxonomy } = useTaxonomy();
  const [expandedItems, setExpandedItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [taxonomyFilteredItems, setTaxonomyFilteredItems] = useState<string[]>([]);
  const [filterSearchResults, setFilterSearchResults] = useState(true);

  const taxonomyTrees: TaxonomyTree[] = useMemo(() => {
    if (!taxonomy) return [];
    return buildTaxonomyTree(taxonomy);
  }, [taxonomy]);

  const allPaths = useMemo(() => map(taxonomy, 'path'), [taxonomy]);

  const applySearch = (value: string) => {
    if (!value) {
      setTaxonomyFilteredItems([]);
      setExpandedItems([]);
      return;
    }
    const matchedItems: Taxonomy[] = matchSorter(taxonomy, value, {
      keys: ['name'],
      threshold: matchSorter.rankings.CONTAINS,
    });
    const matchedItemsWithParents = flatMap(map(matchedItems, 'path'), (matchedPath) =>
      getParentPathComponents(matchedPath)
    );
    setTaxonomyFilteredItems(matchedItemsWithParents);
    setExpandedItems(matchedItemsWithParents);
  };

  const handleDebouncedSearch = useDebouncedCallback((searchTerm) => {
    applySearch(searchTerm);
  }, 300);

  const onSearchChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    handleDebouncedSearch(value);
  };

  const handleExpandedItemsChange = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  const handleExpandClick = () => {
    if (isEmpty(expandedItems)) {
      setExpandedItems(allPaths);
    } else {
      setExpandedItems([]);
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterSearchResults(event.target.checked);
  };

  const taxonomyTreesFiltered = useMemo(() => {
    if (!filterSearchResults || isEmpty(taxonomyFilteredItems)) return taxonomyTrees;
    return compact(map(taxonomyTrees, (tree) => trimTreeToSearchResults(tree, taxonomyFilteredItems)));
  }, [taxonomyFilteredItems, filterSearchResults, taxonomyTrees]);

  return (
    <Grid container spacing={1} direction="column">
      <Grid item container justifyContent="space-between">
        <Grid item xs={10} container direction="row" spacing={1} alignItems="center">
          <Grid item xs={4}>
            <TextField fullWidth size="small" label="Search" value={searchValue} onChange={onSearchChange} />
          </Grid>
          <Grid item ml={2}>
            <Typography variant="body2">filter search results</Typography>
          </Grid>
          <Grid item>
            <Switch checked={filterSearchResults} onChange={handleSwitchChange} />
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={handleExpandClick}>{isEmpty(expandedItems) ? 'Expand all' : 'Collapse all'}</Button>
        </Grid>
      </Grid>
      <Grid item>
        <SimpleTreeView
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
          sx={{
            width: '100%',
            minWidth: 700,
            bgcolor: 'background.paper',
          }}
        >
          {map(taxonomyTreesFiltered, (tree) => (
            <TaxonomyTreeViewItem key={tree.path} taxonomyTree={tree} searchValue={searchValue} />
          ))}
        </SimpleTreeView>
      </Grid>
    </Grid>
  );
};

export default TaxonomyEditor;
