import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, Typography } from '@mui/material';
import { concat, groupBy, keys, map, orderBy, uniq } from 'lodash';
import React, { useState } from 'react';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { ExperimentResultsResultType, FlowClassName } from 'interfaces/experimentResults';
import SlideOrchIdResults from './OrchestrationResults';

interface Props {
  slideId: string;
  viewerIndex: number;
  title: string;
  heatmaps: FeatureMetadata[];
  features: FeatureMetadata[];
  filterText: string;
  isPublishMode: boolean;
  highlightedFeatures?: string[];
  hiddenFeatures?: string[];
  doesHaveApprovedResultType: boolean;
  flowClassNames: FlowClassName[];
  resultTypes: ExperimentResultsResultType[];
  studyId: string;
  doesCaseHaveMultipleStainResults: boolean;
  slideStainTypeId: string;
}

const SlideResults: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slideId,
  viewerIndex,
  title,
  heatmaps,
  features,
  filterText,
  isPublishMode,
  highlightedFeatures,
  hiddenFeatures,
  doesHaveApprovedResultType,
  flowClassNames,
  resultTypes,
  studyId,
  doesCaseHaveMultipleStainResults,
  slideStainTypeId,
}) => {
  const [expand, setExpand] = useState(false);

  const groupedHeatmapsData = groupBy(
    orderBy(heatmaps, 'createdAt', 'desc'),
    (heatmapData) => heatmapData.orchestrationId
  );

  const groupedFeaturesData = groupBy(
    orderBy(features, 'createdAt', 'desc'),
    (featuresData) => featuresData.orchestrationId
  );

  const uniqOrchestrationIds = uniq(concat(keys(groupedHeatmapsData), keys(groupedFeaturesData)));

  return (
    <Accordion
      square
      expanded={expand}
      onChange={() => setExpand((prev) => !prev)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={6}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid item>
            <Chip label="Internal Results" />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        {map(uniqOrchestrationIds, (orchestrationId) => (
          <SlideOrchIdResults
            key={`slide-results-${orchestrationId}`}
            slideId={slideId}
            viewerIndex={viewerIndex}
            stainTypeId={slideStainTypeId}
            title={orchestrationId}
            orchestrationId={orchestrationId}
            heatmapSettings={groupedHeatmapsData[orchestrationId]}
            featuresSettings={groupedFeaturesData[orchestrationId]}
            filterText={filterText}
            doesHaveApprovedResultType={doesHaveApprovedResultType}
            flowClassNames={flowClassNames}
            resultTypes={resultTypes}
            isPublishMode={isPublishMode}
            highlightedFeatures={highlightedFeatures}
            hiddenFeatures={hiddenFeatures}
            studyId={studyId}
            doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
            slideStainTypeId={slideStainTypeId}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default SlideResults;
