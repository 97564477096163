import { Close } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { filter, find, first, fromPairs, includes, isEmpty, join, map, some } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BooleanParam, NumberParam, useQueryParam, useQueryParams } from 'use-query-params';

import { getAccession, getAccessions } from 'api/accessions';
import { getStudyProcedure, getStudyProcedureQueryKey } from 'api/study';
import { RouteProps } from 'components/Auth/PrivateRoute';
import Loader from 'components/Loader';
import { headerHeight } from 'components/Procedure/constants';
import ReviewSidebar from 'components/ReviewSidebar';
import useReviewSidebarWidth from 'components/ReviewSidebar/useReviewSidebarWidth';
import VerticalCarousel from 'components/StudyDashboard/ProceduresPage/ProcedureCard/Carousel/VerticalCarousel';
import DrawerToggleButton from 'components/atoms/DrawerToggleButton';
import { Accession } from 'interfaces/accession';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { useSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { useEncodedFilters, useFiltersForAccessions } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { useProceduresWithQAExperimentResultsOnly } from 'utils/useProceduresWithQAExperimentResultsOnly';
import { useStudyPlatformSettings } from 'utils/useStudyPlatformSettings';
import { CasePageBody } from './CasePageBody';
import Infobar from './Infobar/Infobar';
import NotFoundMessage from './NotFoundMessage';
import { SlideThumbnails } from './SlideThumbnails';
import { useSlideChannelsAndResults } from './useSlideChannelsAndResults';
import { SlideWithChannelAndResults } from './useSlideChannelsAndResults/utils';

interface CaseProps {
  procedure: Procedure | Accession;
  refetchProcedure: () => void;
  isAccessionViewer: boolean;
  isPlaceholderData?: boolean;
}

export const INFOBAR_WIDTH = '370px';
export const SLIDES_THUMBNAILS_HEIGHT = '110px';

export const CasePage: React.FunctionComponent<React.PropsWithChildren<CaseProps & RouteProps>> = ({
  procedure,
  refetchProcedure,
  isPlaceholderData,
}) => {
  const theme = useTheme();

  const { rightDrawerWidthPx } = useReviewSidebarWidth();

  const { isLoadingStudySettings } = useStudyPlatformSettings(procedure.studyId);

  const [showInfoBar, setShowInfoBar] = useState(true);

  const { hasPermission } = usePermissions();
  const isAccessionViewer = hasPermission(Permission.ViewAccessionDashboard);
  const { selectedSlideIds, setFirstSlideId, setSelectedSlideIds } = useSelectedSlideIds(procedure);
  const { selectedSlidesWithChannelsAndResults } = useSlideChannelsAndResults(procedure, selectedSlideIds);

  const [annotationParams] = useQueryParams(
    fromPairs(
      map(selectedSlidesWithChannelsAndResults, (slide) => [
        `activeAnnotationAssignmentIdViewer${slide.viewerIndex}`,
        NumberParam,
      ])
    )
  );
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const anyValidForSidebar = (slides: SlideWithChannelAndResults[]): boolean => {
    const valid = some(slides, (currentSlide) => {
      const { channels, experimentResults, qcLabels, viewerIndex } = currentSlide;

      const sidebarInfoChannels = !isEmpty(channels);
      const sidebarInfoProcedureResults = !isEmpty(experimentResults);
      const sidebarInfoQcLabels = !isEmpty(qcLabels);
      const sidebarAnnotationParams =
        canAnnotateSlides && !isNaN(annotationParams[`activeAnnotationAssignmentIdViewer${viewerIndex}`]);
      return sidebarInfoChannels || sidebarInfoProcedureResults || sidebarInfoQcLabels || sidebarAnnotationParams;
    });
    return valid;
  };

  const infoBarVisible = anyValidForSidebar(selectedSlidesWithChannelsAndResults);

  const canUseSlideThumbnails = hasPermission(Permission.ViewSlideThumbnailsNavigation);

  const [fullscreenViewer, setFullscreenViewer] = useQueryParam('fullscreenViewer', BooleanParam);
  const showReviewSidebar = hasPermission(Permission.ViewReviewMenu);

  React.useEffect(() => {
    if (fullscreenViewer) {
      const exitOnEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setFullscreenViewer(false);
        }
      };
      document.addEventListener('keydown', exitOnEscape);
      return () => document.removeEventListener('keydown', exitOnEscape);
    }
  }, [fullscreenViewer]);

  // Study settings will affect the display of the slides in a case, so we need to wait for them to load before rendering the slides
  // TODO: check if this can be removed once deck.gl becomes the default viewer
  if (isLoadingStudySettings) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflowY: 'hidden',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          marginTop: !fullscreenViewer ? headerHeight : undefined,
          '& .viewer.displaying-tagged-slide': {
            borderColor: theme.palette.mode === 'dark' ? 'primary.main' : 'secondary.main',
            borderWidth: 1,
            borderStyle: 'solid',

            '& .MuiChip-root': {
              color: theme.palette.mode === 'dark' ? 'primary.main' : 'secondary.main',
            },
          },
        }}
      >
        <div id="ppi" style={{ height: '1in', width: '1in', left: '100%', position: 'fixed', top: '100%' }} />
        {!fullscreenViewer && infoBarVisible && (
          <>
            <Infobar
              drawerWidth={INFOBAR_WIDTH}
              isPlaceholderData={isPlaceholderData}
              key={`infobar-${procedure.id}-${join(map(selectedSlidesWithChannelsAndResults, 'id'), '-')}`}
              slidesWithChannelsAndResults={selectedSlidesWithChannelsAndResults}
              openDrawer={showInfoBar}
            />
            <DrawerToggleButton
              direction="left"
              drawerWidth={INFOBAR_WIDTH}
              onClick={() => setShowInfoBar((prevShowSidebar) => !prevShowSidebar)}
              collapsed={!showInfoBar}
              text={!showInfoBar && 'Analysis Results'}
            />
          </>
        )}
        {!fullscreenViewer && isAccessionViewer && (
          <VerticalCarousel
            slides={procedure.slides}
            selectedSlideId={first(selectedSlideIds)}
            onSelected={(id: string) => setFirstSlideId(id)}
          />
        )}
        <CasePageBody
          procedure={procedure}
          isAccessionViewer={isAccessionViewer}
          isPlaceholderData={isPlaceholderData}
          selectedSlides={selectedSlidesWithChannelsAndResults}
          selectedSlideIds={selectedSlideIds}
          setSelectedSlideIds={setSelectedSlideIds}
        />
        {!fullscreenViewer && showReviewSidebar && (
          <ReviewSidebar
            procedure={procedure}
            selectedSlideIds={selectedSlideIds}
            refetchProcedure={refetchProcedure}
            isPlaceholderData={isPlaceholderData}
          />
        )}
      </Box>
      {!fullscreenViewer && canUseSlideThumbnails ? (
        <SlideThumbnails
          caseData={procedure}
          selectedSlideId={first(selectedSlideIds) ?? first(procedure.slides)?.id}
          marginLeft={infoBarVisible && showInfoBar ? INFOBAR_WIDTH : 0}
          marginRight={rightDrawerWidthPx}
          height={SLIDES_THUMBNAILS_HEIGHT}
        />
      ) : null}
      {fullscreenViewer && (
        <IconButton color="primary" onClick={() => setFullscreenViewer(false)} sx={{ position: 'fixed' }}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
};

const ProcedureLoader: React.FunctionComponent<React.PropsWithChildren<RouteProps>> = (props) => {
  const params = useParams();
  const procedureId = Number(params.id);

  const { data: filteredCases } = useProceduresWithQAExperimentResultsOnly({
    pageRadius: 1,
  });
  const currentCaseFromSearch = find(filteredCases?.procedures, {
    id: procedureId,
  });

  const { queryParams, encodedFilters } = useEncodedFilters();

  const studyId = currentCaseFromSearch?.studyId || queryParams.filters?.studyId;

  const {
    data,
    isLoading,
    isError,
    isPlaceholderData,
    refetch: refetchProcedure,
  } = useQuery(
    // this key is without the studyId because for now the backend fetches the case's studyId by itself.
    // and we can also be in a case page with studyId = ALL / NONE, so we don't want to refetch the case
    // this should be changed in the future
    getStudyProcedureQueryKey(studyId, procedureId, queryParams),
    ({ signal }) => getStudyProcedure(studyId, procedureId, encodedFilters, signal),
    {
      placeholderData: currentCaseFromSearch ? { procedure: currentCaseFromSearch } : undefined,
      enabled: !isNaN(procedureId),
    }
  );

  const caseData = useMemo(() => {
    if (!data?.procedure) {
      return undefined;
    }

    return data?.procedure;
  }, [data?.procedure]);

  return isLoading && !caseData ? (
    <Loader />
  ) : isError || isEmpty(caseData?.slides) ? (
    <NotFoundMessage />
  ) : (
    <CasePage
      procedure={caseData}
      refetchProcedure={refetchProcedure}
      isAccessionViewer={false}
      isPlaceholderData={isPlaceholderData}
      {...props}
    />
  );
};

export const AccessionLoader: React.FunctionComponent<React.PropsWithChildren<RouteProps>> = (props) => {
  const params = useParams();
  const procedureId = Number(params.id);

  const { encodedFilters } = useFiltersForAccessions();

  const { data: accessions } = useQuery(['accessions', encodedFilters], () => getAccessions(encodedFilters));

  const currentAccessionFromSearch = find(accessions?.accessions, { id: procedureId });

  const {
    data,
    isLoading,
    isError,
    isPlaceholderData,
    refetch: refetchProcedure,
  } = useQuery(['accession', procedureId], () => getAccession(procedureId), {
    placeholderData: currentAccessionFromSearch,
  });

  const caseData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const filteredSlideIds = map(currentAccessionFromSearch?.slides, 'id');

    return {
      ...data,
      slides: filter(data.slides, (slide: Slide) => includes(filteredSlideIds, slide.id)),
    };
  }, [data, currentAccessionFromSearch?.slides]);

  return !isError && isLoading && !data ? (
    <Loader />
  ) : isError ? (
    <NotFoundMessage />
  ) : (
    <CasePage
      procedure={caseData}
      refetchProcedure={refetchProcedure}
      isAccessionViewer
      isPlaceholderData={isPlaceholderData}
      {...props}
    />
  );
};

export const ProcedurePage: React.FunctionComponent<React.PropsWithChildren<RouteProps>> = (props) => {
  const { hasPermission } = usePermissions();
  const isAccessionViewer = hasPermission(Permission.ViewAccessionDashboard);
  return isAccessionViewer ? <AccessionLoader {...props} /> : <ProcedureLoader {...props} />;
};

export default ProcedurePage;
