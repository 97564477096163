import { useQuery } from '@tanstack/react-query';
import { getCaseSlideThumbnailsData, getProcedureIds } from 'api/procedures';
import { isEmpty } from 'lodash';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';

export const useFilteredCaseIds = (caseId: number, studyId?: string) => {
  const { generateEncodedParams, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const idsEncodedParams = generateEncodedParams({
    filters: {
      ...queryParams?.filters,
      ...(studyId && isEmpty(queryParams?.filters) ? { studyId: studyId } : {}),
    },
    // The page is not important, the response return all the ids
    page: 1,
  });

  return useQuery(['procedureIds', [idsEncodedParams]], {
    queryFn: () => (studyId ? getProcedureIds(idsEncodedParams) : getProcedureIds(idsEncodedParams, caseId)),
    keepPreviousData: true,
  });
};

export const useFilteredCaseSlideThumbnailsData = (studyId?: string) => {
  const { generateEncodedParams, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const idsEncodedParams = generateEncodedParams({
    filters: {
      ...queryParams?.filters,
      ...(studyId && isEmpty(queryParams?.filters) ? { studyId: studyId } : {}),
    },
    // The page is not important, the response return all the ids
    page: 1,
  });

  return useQuery(['slideThumbnailsData', [idsEncodedParams]], {
    queryFn: () => getCaseSlideThumbnailsData(idsEncodedParams),
    keepPreviousData: true,
  });
};
