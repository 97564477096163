import { useQuery } from '@tanstack/react-query';

import { getSlideAnnotations, getSlideAnnotationsQueryKey } from 'api/annotations';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';

export const useSlideAnnotationLayersData = ({ slideId }: { slideId: string }) => {
  const { hasPermission } = usePermissions();
  const canViewAnnotations = hasPermission(Permission.ViewAnnotations);

  const {
    data: slideAnnotations,
    isLoading,
    isError,
  } = useQuery(
    getSlideAnnotationsQueryKey({
      slideId: slideId,
      includeEmpty: true,
    }),
    {
      enabled: Boolean(slideId) && canViewAnnotations,
      queryFn: ({ signal: abortSignal }) =>
        getSlideAnnotations({
          slideId: slideId,
          includeEmpty: true,
          signal: abortSignal,
        }),
    }
  );

  return {
    slideAnnotations,
    isLoading,
    isError,
  };
};
