import { useQuery } from '@tanstack/react-query';
import { isEmpty, keyBy, map } from 'lodash';
import { useMemo } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

import { getJobs } from 'api/jobs';
import { getInternalUsers } from 'api/userCredentials';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useQueryWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';

export const defaultRowsPerPage = 10;
export const jobsQueryKey = 'jobs';

export const useJobs = ({
  enabled = true,
  page = 1,
  pageSize = defaultRowsPerPage,
  fullData,
  additionalFilters = {},
  refetchInterval = 60000,
}: {
  enabled?: boolean;
  page?: number;
  pageSize?: number;
  fullData?: boolean;
  additionalFilters?: {};
  refetchInterval?: number;
}) => {
  const { labId } = useCurrentLabId();
  const [filters] = useQueryParam('filters', JsonParam);

  const usersFilter = filters?.users;
  const hasUsersFilter = !isEmpty(usersFilter);
  const { data: internalUsers, isLoading: internalUsersLoading } = useQuery(['internalUsers'], getInternalUsers, {
    enabled: enabled && !hasUsersFilter,
  });
  const internalUsersById = useMemo(() => keyBy(internalUsers, 'id'), [internalUsers]);
  const users = useMemo(
    () => map(usersFilter, (userId) => ({ id: userId, email: internalUsersById[userId]?.primaryEmail })),
    [usersFilter, internalUsersById]
  );
  const filtersWithEmailUsers = { ...filters, users };

  const jobParams = {
    filters: { ...filtersWithEmailUsers, ...additionalFilters },
    labId,
    page,
    pageSize,
    fullData,
  };

  const jobsQuery = useQueryWithErrorAndRetrySnackbar([jobsQueryKey, jobParams], ({ signal }) => getJobs(jobParams, signal), {
    enabled: enabled && !isEmpty(jobParams?.filters?.studyId) && !(hasUsersFilter && internalUsersLoading),
    refetchInterval,
    queriedEntityName: 'job',
  });

  return jobsQuery;
};