import { Drawer, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getJob } from 'api/jobs';
import { jobDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import { SITE_HEADER_HEIGHT } from 'components/SiteHeader/SiteHeader';
import { isEmpty } from 'lodash';
import React from 'react';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { JobDetails } from './JobDetails';

export const JobDrawer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();

  const [queryParams, setQueryParams] = useQueryParams({
    jobDrawerOpen: BooleanParam,
    currentJobId: StringParam,
  });

  const {
    data: job,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['job', queryParams.currentJobId],
    queryFn: ({ signal }) => getJob(queryParams.currentJobId, signal),
    refetchOnMount: 'always',
    enabled: !isEmpty(queryParams.currentJobId),
  });

  return (
    <Drawer
      key={job?.id}
      anchor={'right'}
      open={queryParams.jobDrawerOpen}
      onClose={() => setQueryParams({ jobDrawerOpen: false })}
      variant={'persistent'}
      sx={{
        width: jobDrawerWidth,
        [`& .MuiDrawer-paper`]: {
          width: jobDrawerWidth,
          zIndex: theme.zIndex.appBar - 1,
          height: `calc(100% - ${SITE_HEADER_HEIGHT})`,
          marginTop: SITE_HEADER_HEIGHT,
        },
      }}
    >
      <JobDetails
        job={job}
        isLoading={isLoading || isFetching}
        onClosed={() => {
          setQueryParams({ jobDrawerOpen: false });
        }}
      />
    </Drawer>
  );
};
