import { useQuery } from '@tanstack/react-query';
import { getStudyProcedure, getStudyProcedureQueryKey } from 'api/study';
import { RouteProps } from 'components/Auth/PrivateRoute';
import Loader from 'components/Loader';
import { Permission } from 'interfaces/permissionOption';
import { find, findIndex, isEmpty, map, some } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';
import { useCurrentStateCases } from 'utils/useCurrentStateCases';

import { getSlideById, getSlideByIdKeys } from 'api/slides';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import NotFoundMessage from './NotFoundMessage';
import { CasePage } from './ProcedurePage';
import { useFilteredCaseSlideThumbnailsData } from './useFilteredCaseIds';

export const SlidePage: React.FunctionComponent<React.PropsWithChildren<RouteProps>> = (props) => {
  const params = useParams();
  const slideId: string = params.slideId;

  const { hasPermission } = usePermissions();
  const canViewPendingSlides: boolean = hasPermission(Permission.ViewPendingSlides);
  const canAccessAllLabs = hasPermission(Permission.ApplyPermissionsAcrossLabs);

  const [caseId, setCaseId] = useQueryParam('caseId', NumberParam);
  const [pendingSlidesMode, setPendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const [slidesMode, setSlidesMode] = useQueryParam('slidesMode', BooleanParam);

  // '!caseId' is for scenarios that caseId is null (isNaN(null) is false)
  const isMissingCase = !caseId || isNaN(caseId);

  const { isInitialLoading: isThumbnailsLoading, data: allSlideThumbnailsData } = useFilteredCaseSlideThumbnailsData();

  // This is to sync the caseId with the slideId if no caseId param is provided
  const caseIdOfSlide = useMemo(
    () => find(allSlideThumbnailsData, { slideId })?.caseId,
    [allSlideThumbnailsData, slideId]
  );

  // Sync params with real state
  useEffect(() => {
    if (!canViewPendingSlides && pendingSlidesMode) {
      setPendingSlidesMode(false);
    }

    if (!isThumbnailsLoading) {
      if (caseIdOfSlide) {
        // It means that this slide has a case and it is not pending
        if (isMissingCase) {
          setCaseId(caseIdOfSlide);
        }
        if (pendingSlidesMode) {
          setPendingSlidesMode(false);
        }
      } else {
        if (!isMissingCase) {
          setCaseId(undefined);
        }
        if (!pendingSlidesMode && canViewPendingSlides) {
          setPendingSlidesMode(true);
        }
      }
    }

    // Must be on slidesMode when on slide page
    if (!slidesMode) {
      setSlidesMode(true);
    }
  }, [slidesMode, caseIdOfSlide, isMissingCase, pendingSlidesMode, isThumbnailsLoading, canViewPendingSlides]);

  const { procedures, isLoading: isCasesLoading, allMockCasesOfPendingSlides } = useCurrentStateCases();

  const currentCase = find(procedures, (procedure) => some(procedure.slides, { id: slideId }));

  const { queryParams, encodedFilters, setQueryParams, generateEncodedParams } = useEncodedFilters();
  const studyId = currentCase?.studyId || queryParams.filters?.studyId;

  const page: number = queryParams.page || 1;
  const pageSize: number = queryParams.pageSize || DEFAULT_PAGE_SIZE;

  const caseIndex = findIndex(allMockCasesOfPendingSlides, (mockCase) => some(mockCase.slides, { id: slideId }));
  // Sync pending slide pagination in case we're asking to view a pending slide that's not in the current page
  const pendingSlidePage = pendingSlidesMode && caseIndex >= 0 ? Math.floor(caseIndex / pageSize) + 1 : page;

  React.useEffect(() => {
    if (pendingSlidesMode && page !== pendingSlidePage) {
      setQueryParams({ page: pendingSlidePage });
    }
  }, [pendingSlidesMode, page, pendingSlidePage]);

  const {
    data: studyCaseData,
    error: caseError,
    isLoading: isStudyCaseLoading,
    isError: isCaseError,
    isPlaceholderData: isCasePlaceholderData,
    refetch: refetchCaseData,
  } = useQuery(
    getStudyProcedureQueryKey(studyId, caseId, queryParams),
    ({ signal }) => getStudyProcedure(studyId, caseId, encodedFilters, signal),
    {
      placeholderData: currentCase ? { procedure: currentCase } : undefined,
      enabled: !isMissingCase,
    }
  );

  const fullSlideQueryEncodedFilters = generateEncodedParams({
    filters: { ...(queryParams?.filters || {}), studyId },
  });
  const {
    data: fullSlideData,
    isLoading: isFullSlideLoading,
    isError: isFullSlideError,
    error: fullSlideError,
    refetch: refetchPendingSlideData,
  } = useQuery(
    getSlideByIdKeys({
      slideId,
      encodedFilters: fullSlideQueryEncodedFilters,
    }),
    ({ signal }) =>
      getSlideById({
        slideId,
        encodedFilters: fullSlideQueryEncodedFilters,
        signal,
      }),
    {
      placeholderData: find(currentCase?.slides, { id: slideId }),
      // We only need to fetch the slide data if the slide isn't assigned to a case or if case is missing
      enabled: Boolean(slideId) && (isMissingCase || (!studyCaseData?.procedure && !isStudyCaseLoading)),
    }
  );

  const isDataLoading =
    isThumbnailsLoading || (isMissingCase ? isCasesLoading : isStudyCaseLoading || isFullSlideLoading);

  const caseData = useMemo(() => {
    if (!studyCaseData?.procedure) {
      return undefined;
    }

    return {
      ...studyCaseData.procedure,
      slides: map(studyCaseData.procedure.slides, (slide) => {
        // Full slide data is used to replace the placeholder data
        if (slide.id === slideId && isCasePlaceholderData) {
          return fullSlideData || slide;
        }
        return slide;
      }),
    };
  }, [studyCaseData?.procedure, isCasePlaceholderData, fullSlideData, slideId]);

  const isError = isFullSlideError || isCaseError;
  const isPlaceholderData = isCasePlaceholderData;

  const placeholderWithoutSlides =
    !isError && (isStudyCaseLoading || isFullSlideLoading) && isPlaceholderData && isEmpty(caseData?.slides);

  const refetch = React.useCallback(() => {
    refetchCaseData?.();
    refetchPendingSlideData?.();
  }, [refetchPendingSlideData, refetchCaseData]);

  const loadingFailed = isError || !caseData || isEmpty(caseData?.slides);
  const { labId, setLabId } = useCurrentLabId();

  const shouldChangeLab =
    canAccessAllLabs && !isDataLoading && loadingFailed && fullSlideData?.labId && fullSlideData?.labId !== labId;
  React.useEffect(() => {
    if (shouldChangeLab && fullSlideData?.labId) {
      setLabId(fullSlideData?.labId);
    }
  }, [shouldChangeLab, fullSlideData?.labId]);

  if ((isDataLoading && !caseData) || placeholderWithoutSlides || shouldChangeLab) {
    return <Loader />;
  } else if (loadingFailed) {
    if (isError) {
      console.error('Error loading slide page', { caseError, fullSlideError });
    } else {
      console.warn('No slide data found');
    }
    return <NotFoundMessage />;
  }

  return (
    <CasePage
      procedure={caseData}
      refetchProcedure={refetch}
      isAccessionViewer={false}
      isPlaceholderData={!isMissingCase && isPlaceholderData}
      {...props}
    />
  );
};
