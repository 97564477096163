import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { find, map } from 'lodash';
import React from 'react';

import { setUserCredentialsRolesAndPermissions } from 'api/userCredentials';
import { rowEditingControlsColumn } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { UserCredentials, UserCredentialsPaginatedResponse, UserSource } from 'interfaces/userCredentials';
import { userCredentialsFields } from 'interfaces/userCredentials/userCredentialsFields';
import { UserCredentialsFieldsContext } from 'interfaces/userCredentials/UserCredentialsFieldsContext';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { UserCredentialsRowChangesSummary } from './UserCredentialsRowChangesSummary';

export const useUserCredentialsColumns = ({
  manageNucleaiUsers,
  rowsWithChanges,
  isEditingRow,
  noRows,
  bulkEditMode,
  rowModesModel,
  shouldApplyBulkChangesToRow,
  setRowModesModel,
  paginatedUserCredentialsQueryKey,
}: {
  manageNucleaiUsers: boolean;
  rowsWithChanges: UserCredentials[];
  isEditingRow?: boolean;
  noRows?: boolean;
  bulkEditMode: boolean;
  rowModesModel: GridRowModesModel;
  shouldApplyBulkChangesToRow: (id: GridRowId) => boolean;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  paginatedUserCredentialsQueryKey: any;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const { rowsChanges, clearRowChanges, fieldsContext } = useTableEditingContext<
    UserCredentials,
    UserCredentialsFieldsContext
  >();
  const confirmWithModal = useConfirmation();

  const { labId } = useCurrentLabId();

  const queryClient = useQueryClient();

  const setUserCredentialsRolesAndPermissionsMutation = useMutation(setUserCredentialsRolesAndPermissions, {
    onSuccess: (changedId, userUpdate) => {
      queryClient.setQueryData(paginatedUserCredentialsQueryKey, (oldData: UserCredentialsPaginatedResponse) => ({
        ...oldData,
        users: map(oldData.users, (user) =>
          user.id === userUpdate.id ? { ...user, ...userUpdate, id: changedId, userSource: UserSource.DB } : user
        ),
      }));
      queryClient.invalidateQueries(paginatedUserCredentialsQueryKey);
      queryClient.invalidateQueries({
        queryKey: ['permissions'],
      });
    },
  });

  const confirmCancelEdit = async (id: GridRowId) => {
    const userCredentials = find(rowsWithChanges, { id: id as string });
    if (
      !rowsChanges[id] ||
      (await confirmWithModal({
        title: 'Cancel changes to user credentials?',
        text: (
          <UserCredentialsRowChangesSummary
            fieldsContext={fieldsContext}
            userDisplayString={userCredentials.primaryEmail}
            changes={rowsChanges[id]}
          />
        ),
      }))
    ) {
      clearRowChanges(id);
      return true;
    } else {
      return false;
    }
  };

  const saveOperation = async (id: GridRowId) => {
    const userCredentials = find(rowsWithChanges, { id: id as string });
    if (
      await confirmWithModal({
        title: 'Save changes to user credentials?',
        text: (
          <UserCredentialsRowChangesSummary
            fieldsContext={fieldsContext}
            userDisplayString={userCredentials.primaryEmail}
            changes={rowsChanges[id]}
          />
        ),
      })
    ) {
      setMutatingRowId(id);
      try {
        await setUserCredentialsRolesAndPermissionsMutation.mutateAsync({
          ...userCredentials,
          labId: !manageNucleaiUsers ? labId : undefined,
          applyToAllLabs: manageNucleaiUsers,
        });
        setMutatingRowId(undefined);
        clearRowChanges(id);
      } catch (err) {
        console.error(err);
        setMutatingRowId(undefined);
      }
      return true;
    } else {
      return false;
    }
  };

  const userCredentialsFieldsColumns = useEditableFieldsDataGridColumns({
    fields: userCredentialsFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode,
    shouldApplyBulkChangesToRow,
    useValueSetter: true,
  });

  return [
    ...userCredentialsFieldsColumns,
    rowEditingControlsColumn({
      mutatingRowId,
      disabledEditing: isEditingRow,
      rowModesModel,
      setRowModesModel,
      onCancelEdit: clearRowChanges,
      saveOperation,
      confirmCancelEdit,
    }),
  ];
};
