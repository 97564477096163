import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { find, omit, reject, some } from 'lodash';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createAreaTypeOption, deleteAreaTypeOption, updateAreaTypeOption } from 'api/areaTypes';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { rowEditingControlsColumn } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { AreaType } from 'interfaces/areaType';
import { areaTypeFields } from 'interfaces/areaType/areaTypeFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { AreaTypeRowChangesSummary } from './AreaTypeRowChangesSummary';
import { AreaTypeDraft } from './types';
import { getAreaTypeError, getAreaTypeId } from './utils';

export const useAreaTypesColumns = ({
  apiRef,
  noRows,
  rowsWithChanges,
  draftAreaTypes,
  rowModesModel,
  setDraftAreaTypes,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowsWithChanges: Array<Omit<AreaType, 'index'>>;
  draftAreaTypes: AreaTypeDraft[];
  rowModesModel: GridRowModesModel;
  setDraftAreaTypes: React.Dispatch<React.SetStateAction<Array<Omit<AreaType, 'index'>>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const { clearRowChanges, rowsChanges } = useTableEditingContext<Omit<AreaType, 'index'>>();
  const confirmWithModal = useConfirmation();

  const queryClient = useQueryClient();

  const createAreaTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newAreaType) => {
      queryClient.invalidateQueries(['areaTypes']);
      queryClient.setQueryData(['areaTypes'], (oldData: AreaType[]) => [...oldData, newAreaType]);
    },
    mutationFn: createAreaTypeOption,
    mutationDescription: 'create area type',
  });

  const updateAreaTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(['areaTypes']);
      queryClient.setQueryData(['areaTypes'], (oldData: AreaType[]) => {
        const updatedAreaType = find(rowsWithChanges, { id: mutatingRowId });
        return [...reject(oldData, { id: mutatingRowId }), updatedAreaType];
      });
    },
    mutationFn: updateAreaTypeOption,
    mutationDescription: 'update area type',
  });

  const deleteAreaTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['areaTypes']);
      queryClient.setQueryData(['areaTypes'], (oldData: AreaType[]) => reject(oldData, { id: variables }));
    },
    mutationFn: deleteAreaTypeOption,
    mutationDescription: 'delete area type',
  });

  const areaTypeFieldsColumns = useEditableFieldsDataGridColumns<AreaType | AreaTypeDraft>({
    fields: areaTypeFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode: false,
    idGetter: getAreaTypeId,
    useValueSetter: true,
  });

  const isDraftRow = React.useCallback((id: GridRowId) => some(draftAreaTypes, { draftId: id }), [draftAreaTypes]);

  const columns = React.useMemo(() => {
    const deleteOperation = (id: GridRowId) => async () => {
      if (
        await confirmWithModal({
          title: 'Delete Area Type',
          text: 'Are you sure you want to delete this area type?',
        })
      ) {
        if (some(draftAreaTypes, { draftId: id })) {
          setDraftAreaTypes(reject(draftAreaTypes, { draftId: id as string }));
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [id]: draftRoleRowMode, ...rest } = rowModesModel;
          setRowModesModel(rest);
        } else if (id) {
          setMutatingRowId(id);
          await deleteAreaTypeMutation.mutateAsync(`${id}`);
          setMutatingRowId(undefined);
        }
        clearRowChanges(id);
        return true;
      } else {
        return false;
      }
    };

    const confirmCancelEdit = async (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      const areaType = isDraft
        ? (rowsChanges[id] as AreaType)
        : (find(rowsWithChanges, { id: id as string }) as AreaType);
      return (
        !rowsChanges[id] ||
        (await confirmWithModal({
          title: `Cancel area type ${isDraft ? 'creation' : 'update'}`,
          text: (
            <AreaTypeRowChangesSummary
              areaTypeDisplayString={`Area Type "${areaType?.displayName}" (${areaType?.id ?? id})`}
              changes={rowsChanges[id]}
            />
          ),
        }))
      );
    };

    const saveOperation = async (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      const areaType = isDraft
        ? (find(rowsWithChanges, { draftId: id as string }) as AreaType)
        : (find(rowsWithChanges, { id: id as string }) as AreaType);
      if (
        await confirmWithModal({
          title: `${isDraft ? 'Create' : 'Update'} area type`,
          text: (
            <AreaTypeRowChangesSummary
              areaTypeDisplayString={`Area Type "${areaType?.displayName}" (${areaType?.id ?? id})`}
              changes={rowsChanges[id]}
            />
          ),
        })
      ) {
        setMutatingRowId(id);
        if (isDraft) {
          await createAreaTypeMutation.mutateAsync(omit(areaType, 'index', 'draftId'));
          setDraftAreaTypes(reject(draftAreaTypes, { draftId: id as string }));
        } else {
          await updateAreaTypeMutation.mutateAsync(omit(areaType, 'index'));
        }
        setMutatingRowId(undefined);
        clearRowChanges(id);
        return true;
      } else {
        return false;
      }
    };

    return [
      ...areaTypeFieldsColumns,
      rowEditingControlsColumn({
        mutatingRowId,
        rowModesModel,
        setRowModesModel,
        apiRef,
        getRowError: getAreaTypeError,
        deleteOperation,
        isDraftRow,
        onCancelEdit: (id: GridRowId) => {
          const isDraft = isDraftRow(id);
          if (isDraft) {
            setDraftAreaTypes(reject(draftAreaTypes, { draftId: id as string }));
          }
          clearRowChanges(id);
        },
        saveOperation,
        confirmCancelEdit,
      }),
    ];
  }, [
    apiRef,
    clearRowChanges,
    confirmWithModal,
    createAreaTypeMutation,
    deleteAreaTypeMutation,
    updateAreaTypeMutation,
    draftAreaTypes,
    mutatingRowId,
    rowsChanges,
    rowModesModel,
    rowsWithChanges,
    setDraftAreaTypes,
    setRowModesModel,
    areaTypeFieldsColumns,
  ]);

  return columns;
};
