import { Tooltip } from '@mui/material';
import { ConditionalTextEditor } from 'components/atoms/DataGridRenderers/ConditionalTextEditor';
import { DisplayedField } from 'interfaces/genericFields';
import { ModelType } from 'interfaces/modelType';
import { map } from 'lodash';
import React from 'react';

const idRegex = /^[a-z\d/]+(?:[-_][a-z\d/]+)*$/;

export const idField: DisplayedField<ModelType & { draftId?: string }, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'id',
  label: 'Model Type ID',
  columnWidth: { width: 150 },
  getError: ({ value }) =>
    !idRegex.test(value)
      ? 'Model type id must be lowercase and contain only letters, numbers. ' +
        'Hyphens, and underscores are allowed as separators.'
      : undefined,
  customCellEditor:
    (field, context) =>
    ({ isEditable: isEditableParam, ...params }) => {
      const { value, row } = params;
      const isEditable = (model: ModelType & { draftId?: string }) => isEditableParam && !!model.draftId;
      const error = field.getError?.({ context, value, row });
      const hasError = isEditable(row) && Boolean(error);
      return (
        <Tooltip open={Boolean(hasError)} color="error" title={hasError ? error : undefined}>
          <ConditionalTextEditor {...params} isEditable={isEditable} />
        </Tooltip>
      );
    },
};

export const displayNameField: DisplayedField<ModelType, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'displayName',
  label: 'Display Name',
  columnWidth: { width: 200 },
};

export const typeField: DisplayedField<ModelType, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'type',
  label: 'Type',
  columnWidth: { width: 150 },
};

export const trainingTypeField: DisplayedField<ModelType, string, { trainingTypes: string[] }> = {
  cellEditType: 'select',
  filterType: 'select',
  optionsGetter: (context) => {
    return map(context?.trainingTypes, (value) => ({
      value,
      label: value,
    }));
  },
  dataKey: 'trainingType',
  label: 'Training Type',
  columnWidth: { width: 150 },
};

export const backgroundClassesToIgnoreField: DisplayedField<ModelType, string> = {
  cellEditType: 'multiText',
  filterType: 'multiText',
  dataKey: 'backgroundClassesToIgnore',
  label: 'Background Classes To Ignore',
  columnWidth: { width: 200 },
  getNumOfEntries: () => 50, // This is the maximum number of entries that can be entered
};

export const modelTypeFields: Array<DisplayedField<ModelType, any>> = [
  idField,
  displayNameField,
  typeField,
  trainingTypeField,
  backgroundClassesToIgnoreField,
];
