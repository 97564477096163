import { DeckGLProps } from '@deck.gl/react/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { Dictionary, compact, find, first, flatten, fromPairs, isEmpty, keys, map, some, sortBy, values } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { COORDINATE_SYSTEM, Layer } from '@deck.gl/core/typed';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from 'components/Procedure/useSlideImages';
import MultiScaleImageLayer, { OVERVIEW_LAYER_ID } from './StainsLayers/layers/multiScaleImageLayer';

type HeatmapLayerAndSettings = Dictionary<{
  settings: {
    layerOpacities: number[];
    layersVisible: boolean[];
  };
  layer: Layer<any>;
}>;

const heatmapSelections = [{ layerIndex: 0 }];

export const useHeatmapLayers = ({
  slide,
  baseImagePyramids,
  heatmapsImagePyramids,
  overview,
}: {
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  heatmapsImagePyramids: HeatmapsImagePyramids;
  overview?: boolean;
}): DeckGLProps['layers'] => {
  useSignals();
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

  const heatmapsSettings = viewerSlideLayerVisualizationSettings?.value?.[slide?.id];
  const sortedHeatmapIds = sortBy(keys(heatmapsImagePyramids));
  const sortedHeatmapSettings = map(sortedHeatmapIds, (heatmapId) => ({
    heatmapId,
    heatmapSettings: heatmapsSettings?.[heatmapId]?.value,
  }));

  const didVisualizeHeatmaps = useRef<Dictionary<boolean>>({});
  const [heatmapLayers, setHeatmapLayers] = useState<HeatmapLayerAndSettings>({});

  useEffect(() => {
    const allSlideHeatmaps = [
      ...(slide.heatmapResults?.publishedResults || []),
      ...flatten(values(slide.heatmapResults?.internalResults)),
      ...flatten(values(slide.internalHeatmaps)),
    ];

    const layerUpdates: HeatmapLayerAndSettings = fromPairs(
      compact(
        map(sortedHeatmapSettings, ({ heatmapId, heatmapSettings }) => {
          const layerSource = heatmapsImagePyramids?.[heatmapId];
          if (!layerSource) {
            return undefined;
          }

          const childHeatmap = find(allSlideHeatmaps, { id: heatmapId });
          const isRasterHeatmap = childHeatmap?.heatmapType === 'dzi';

          const parentHeatmap = find(allSlideHeatmaps, ({ nestedItems }) => some(nestedItems, { id: heatmapId }));
          const parentSettings = find(sortedHeatmapSettings, { heatmapId: parentHeatmap?.id })?.heatmapSettings;
          const parentSelected = parentHeatmap?.heatmapUrl ? parentSettings?.selected : false;

          // If the heatmap is a raster heatmap and it's parent is selected, don't consider it selected individually (the parent will render it)
          const isHeatmapSelected = (!isRasterHeatmap || !parentSelected) && heatmapSettings?.selected;

          const isSelectedAndShown = isHeatmapSelected && heatmapSettings?.show;
          // for nested raster heatmaps, we don't support individual opacity in UI, so we use the parent's opacity
          const settingsToUse = isRasterHeatmap ? parentSettings || heatmapSettings : heatmapSettings;
          const heatmapOpacity = isSelectedAndShown ? settingsToUse?.opacity ?? 0 : 0;

          const heatmapVisible = Boolean(
            // If there are a few heatmaps, load them all when hidden so that they can be toggled on without loading
            keys(heatmapsImagePyramids).length <= 5 ||
              // If the heatmap is published, load it
              find(slide.heatmapResults?.publishedResults, { id: heatmapId }) ||
              // If the heatmap is selected but not it's parent, load it
              isHeatmapSelected
          );

          if (!heatmapVisible && !didVisualizeHeatmaps.current[heatmapId]) {
            return undefined;
          }
          didVisualizeHeatmaps.current[heatmapId] = true;

          const previousSettings = heatmapLayers[heatmapId]?.settings;
          const settings = { ...previousSettings };
          let changed = false;
          if (first(previousSettings?.layerOpacities) !== heatmapOpacity) {
            settings.layerOpacities = [heatmapOpacity];
            changed = true;
          }
          if (first(previousSettings?.layersVisible) !== heatmapVisible) {
            settings.layersVisible = [heatmapVisible];
            changed = true;
          }
          if (!changed) {
            return undefined;
          }

          return [
            heatmapId,
            {
              settings,
              layer: new MultiScaleImageLayer({
                layerSource,
                baseImageSource: baseImagePyramids?.layerSource,
                selections: heatmapSelections,
                excludeBackground: !overview,
                overviewLayer: overview,
                viewerIndex: slide.viewerIndex,
                id: `${overview ? OVERVIEW_LAYER_ID : 'MultiScaleImageLayer'}-${layerSource.getUniqueId()}`,
                coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
                tileSize: layerSource.getTileSize(),
                zoomOffset: 0,
                pickable: true,
                ...settings,
              }),
            },
          ];
        })
      )
    );
    if (!isEmpty(layerUpdates)) {
      setHeatmapLayers((prev) => ({ ...prev, ...layerUpdates }));
    }
  }, [slide, baseImagePyramids, heatmapsImagePyramids, JSON.stringify(sortedHeatmapSettings)]);

  return map(values(heatmapLayers), 'layer');
};
