import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { VirtualizedAutocomplete } from 'components/FeaturesDashboard/KeySelect/VirtualizedAutocomplete';
import { useTransformedDataTransfer } from 'components/SearchFilters/hooks/useTransformedDataTransfer';
import { first, isEmpty, isString, map, orderBy } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { CaseSearchItem } from 'services/searchIndex';
import { BooleanParam, useQueryParam } from 'use-query-params';
import FullSearchHelperText from './FullSearchHelperText';
import SearchResult from './SearchResult';
import SearchTips from './SearchTips';
import { useFullSearch } from './useFullSearch';
import useSlideSearch from './useSlideSearch';

interface FullSearchProps {
  currentStudyId?: string;
  controlledValue?: string;
  enabled?: boolean;
  isDrawer?: boolean;
}

const FullSearch: FunctionComponent<React.PropsWithChildren<FullSearchProps>> = ({
  currentStudyId,
  controlledValue,
  enabled = true,
  isDrawer = false,
}) => {
  const fullSearch = useFullSearch({ enabled, controlledValue });
  const [open, setOpen] = React.useState(false);
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);

  const { filteredOptions: options, isSuccess, localSearch, searchQuery, noResults, isLoading } = fullSearch;

  const handleApply = (event: React.SyntheticEvent = null) => {
    event?.preventDefault();
    setOpen(false);
    fullSearch.applySearch();
  };

  const handleForceApply = (forceSearchTerm: string, event?: React.SyntheticEvent) => {
    event?.preventDefault();
    setOpen(false);
    fullSearch.applyForceSearch(forceSearchTerm);
  };

  const handleInputChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    fullSearch.setLocalSearchDebounced(value);
  };

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: string | CaseSearchItem) => {
    if (typeof value === 'string') {
      handleForceApply(value);
    } else {
      if (!value) {
        handleForceApply('');
      } else if (slidesMode) {
        handleForceApply(first(value.slides)?.id);
      } else {
        handleForceApply(value?.caseLabel);
      }
    }
  };

  const { handleOnDrop, handleOnPaste } = useTransformedDataTransfer(localSearch, fullSearch.setLocalSearchDebounced);

  const getOptionLabel = (option: CaseSearchItem | string) => {
    if (isString(option)) {
      return option;
    }
    if (slidesMode) {
      return first(option.slides)?.id;
    }
    const caseLabel = option?.caseLabel ?? '(Unlabeled Case)';

    return caseLabel;
  };

  const isStudyIdSpecific = Boolean(currentStudyId);

  const optionsSortedByCurrentStudy = useMemo(() => {
    if (isStudyIdSpecific) {
      return orderBy(options, (option) => option.studyId === currentStudyId, 'desc');
    }
    return options;
  }, [options, currentStudyId]);

  const groupByHandler = (option: CaseSearchItem) => {
    if (option.isSlideOnly) {
      return 'External/Pending slides';
    }
    if (!isStudyIdSpecific) {
      return 'From all studies';
    }
    if (option.studyId === currentStudyId) {
      return 'Current study';
    }
    return 'Other studies';
  };

  const {
    data: externalSlidesSearchResultsData,
    isSuccess: isSuccessOtherResults,
    isInitialLoading: isLoadingOtherResults,
  } = useSlideSearch(localSearch, Boolean(fullSearch.noResults));

  const otherSearchResults: CaseSearchItem[] = useMemo(() => {
    if (!isSuccessOtherResults) {
      return [];
    }
    return map(externalSlidesSearchResultsData.results, (result) => {
      return {
        studyId: 'other',
        studyName: 'other',
        caseId: null,
        caseLabel: null,
        slides: [
          {
            id: result.id,
            fileName: result.fileName,
            labId: result.labId,
          },
        ],
        isSlideOnly: true,
      };
    });
  }, [isSuccessOtherResults, externalSlidesSearchResultsData]);

  const noOtherResults = isEmpty(otherSearchResults);

  return (
    <Grid container>
      <Grid item xs>
        <VirtualizedAutocomplete<CaseSearchItem, false, boolean>
          options={!isEmpty(optionsSortedByCurrentStudy) ? optionsSortedByCurrentStudy : otherSearchResults}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleApply(event);
            }
          }}
          onInputChange={handleInputChange}
          onChange={handleChange}
          onSubmit={handleApply}
          getOptionDisabled={(option) => option.caseLabel === 'No results'}
          autoComplete
          freeSolo
          selectOnFocus={false}
          filterOptions={(x) => x} // see https://mui.com/material-ui/react-autocomplete/#search-as-you-type
          groupBy={groupByHandler}
          onPaste={handleOnPaste}
          onDrop={handleOnDrop}
          forcePopupIcon={false}
          noOptionsText={
            isLoading || isLoadingOtherResults ? (
              'Loading...'
            ) : noResults && noOtherResults ? (
              'No cases found'
            ) : (
              <SearchTips />
            )
          }
          blurOnSelect
          inputValue={localSearch}
          value={localSearch}
          getOptionLabel={(option) => getOptionLabel(option)}
          defaultValue={''}
          componentsProps={{
            paper: { style: { width: '40em', padding: 0 } },
          }}
          disabled={!isSuccess}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={'Search'}
              size="small"
              helperText={
                <FullSearchHelperText
                  isStudyIdSpecific={isStudyIdSpecific}
                  localSearch={localSearch}
                  options={options}
                  currentStudyId={currentStudyId}
                />
              }
              FormHelperTextProps={{ sx: { height: 0, overflow: 'visible' } }}
              InputProps={{
                ...params.InputProps,
                endAdornment:
                  localSearch === searchQuery || (isEmpty(localSearch) && isEmpty(searchQuery)) ? (
                    params.InputProps.endAdornment
                  ) : (
                    <React.Fragment>
                      {!isDrawer && (
                        <Tooltip title="Apply search">
                          <IconButton onClick={handleApply} size="small">
                            <ArrowCircleRightIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
              }}
            />
          )}
          itemHeight={60}
          renderOption={(option) => ({
            key: String(slidesMode ? first(option.slides)?.id : option.caseId),
            displayName: '',
            customNode: (
              <SearchResult
                searchQuery={localSearch}
                option={option}
                notInCurrentStudy={Boolean(currentStudyId) && option.studyId !== currentStudyId}
              />
            ),
          })}
          sx={{ mb: 1.5 }}
        />
      </Grid>
    </Grid>
  );
};

export default FullSearch;
