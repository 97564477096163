import { getCellName } from 'components/atoms/ConditionBuilder/CellConditionBuilder';
import { isCell, isLogical, validateCondition } from 'components/atoms/ConditionBuilder/utils';
import {
  AutomaticCondition,
  CategoricalActions,
  conditionOperatorFilterAction,
  conditionOperatorFilterActionOptions,
} from 'interfaces/automaticCondition';
import PostProcessingAction, {
  Input,
  InputType,
  MappingFilterMetadata,
  PostProcessingActionCreated,
  PostProcessingActionInputCondition,
} from 'interfaces/postProcessingAction';
import {
  Dictionary,
  difference,
  every,
  find,
  first,
  get,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isNumber,
  keys,
  map,
  set,
  some,
  values,
} from 'lodash';
import { uuidv4 } from 'utils/helpers';

export const createNewAction = (
  postProcessingActionId: string,
  postProcessingActionsById: Dictionary<PostProcessingAction>
) => {
  const newAction = {
    id: uuidv4(),
    actionId: postProcessingActionId,
    actionName: postProcessingActionsById[postProcessingActionId].actionName,
    actionInput: postProcessingActionsById[postProcessingActionId].actionInput,
  };

  // add default values of inputs
  map(postProcessingActionsById[postProcessingActionId].inputs, (input) => {
    if (!isEmpty(input?.defaultValue)) {
      const defaultValue =
        input.inputType === 'number'
          ? Number(input.defaultValue)
          : input.inputType === 'boolean'
          ? Boolean(input.defaultValue)
          : input.defaultValue;
      set(newAction, input.inputKey, defaultValue);
    }
  });

  return newAction;
};

const convertLogicalQueryRecursive = (logicalQuery: AutomaticCondition): any => {
  let logicalQueryConverted: Record<string, any> = {};
  if (isLogical(logicalQuery)) {
    set(
      logicalQueryConverted,
      conditionOperatorFilterAction[logicalQuery.operator],
      map(logicalQuery.operands, (operand) => convertLogicalQueryRecursive(operand))
    );
  }

  if (isCell(logicalQuery)) {
    logicalQueryConverted[logicalQuery.mappingFilter] = {
      [logicalQuery.filterAction]: logicalQuery.toFilter,
    };
  }

  return logicalQueryConverted;
};

export const convertLogicalQuery = (logicalQuery: AutomaticCondition) => {
  return convertLogicalQueryRecursive(logicalQuery);
};

// Convert from backend
// For example:
// logicalQuery = { 'cell.label': { $in: ['cell1', 'cell2'] } };
// cellCondition = {
//   conditionType: 'cell',
//   filterAction: '$in',
//   mappingFilter: 'cell.label',
//   toFilter: ['cell1', 'cell2'],
// };

export const convertLogicalQueryRecursiveFromBackend = (
  logicalQuery: Record<string, any>,
  mappingFiltersMetadataForLogicalQuery: MappingFilterMetadata[]
): AutomaticCondition => {
  const mappingFilterOrActionOperator = first(keys(logicalQuery));

  if (includes(map(conditionOperatorFilterActionOptions, 'value'), mappingFilterOrActionOperator)) {
    return {
      operator: find(conditionOperatorFilterActionOptions, (option) => option.value === mappingFilterOrActionOperator)
        ?.label,
      operands: map(logicalQuery[mappingFilterOrActionOperator], (query) =>
        convertLogicalQueryRecursiveFromBackend(query, mappingFiltersMetadataForLogicalQuery)
      ),
    };
  }

  const cellCondition = {
    conditionType: 'cell',
    mappingFilter: mappingFilterOrActionOperator,
    filterAction: first(keys(logicalQuery[mappingFilterOrActionOperator])),
    toFilter: first(values(logicalQuery[mappingFilterOrActionOperator])),
  };

  return {
    ...cellCondition,
    displayValue: getCellName(cellCondition, mappingFiltersMetadataForLogicalQuery),
  };
};

export const convertLogicalQueryFromBackend = (
  logicalQuery: Record<string, any>,
  mappingFiltersMetadataForLogicalQuery: MappingFilterMetadata[]
): AutomaticCondition => {
  return convertLogicalQueryRecursiveFromBackend(logicalQuery, mappingFiltersMetadataForLogicalQuery);
};

export const checkOptionExist = (inputValue: any, input: Input, options: any[]) => {
  const optionIds = map(options, 'id');

  if (isEmpty(inputValue) || input?.freeSolo) {
    return true;
  }

  return input?.inputSource && isArray(inputValue)
    ? isEmpty(difference(inputValue, optionIds))
    : some(options, { id: inputValue });
};

export const validateInput = (
  inputValue: any,
  input: Input,
  action: PostProcessingActionCreated,
  options?: any[],
  categoricalActions?: CategoricalActions[]
) => {
  if (isEmpty(inputValue) && !getIsRequired(input.required, action)) {
    return true;
  }

  if (input.inputType === InputType.NUMBER) {
    return isNumber(inputValue);
  }

  if (input.inputType === InputType.NUMBERS) {
    return isArray(inputValue) && every(inputValue, isNumber);
  }

  if (input.inputType === InputType.BOOLEAN) {
    return isBoolean(inputValue);
  }

  if (input.inputType === InputType.LOGICAL_QUERY && !validateCondition(inputValue, options, categoricalActions)[0]) {
    return false;
  }

  if (
    (input.inputType === InputType.SELECT || input.inputType === InputType.MULTISELECT) &&
    !checkOptionExist(inputValue, input, options)
  ) {
    return false;
  }

  if (input.inputType === InputType.RADIO && !checkOptionExist(inputValue, input, options)) {
    return false;
  }

  const isRequired = getIsRequired(input.required, action);

  if (isRequired && isEmpty(inputValue)) {
    return false;
  }

  return true;
};

export const getConditionValue = (
  inputCondition: PostProcessingActionInputCondition,
  action: PostProcessingActionCreated
) => {
  if (inputCondition.action === 'equals') {
    return get(action, inputCondition.key) === inputCondition.value;
  } else if (inputCondition.action === 'not equals') {
    return get(action, inputCondition.key) !== inputCondition.value;
  } else if (inputCondition.action === 'subset') {
    return includes(get(action, inputCondition.key), inputCondition.value);
  }
};

export const getIsRequired = (
  required: PostProcessingActionInputCondition | boolean,
  action: PostProcessingActionCreated
) => {
  return isBoolean(required) ? required : getConditionValue(required, action);
};
