import { Grid } from '@mui/material';
import { map } from 'lodash';
import React, { FunctionComponent } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { FormattedFeature } from 'interfaces/features';
import FeatureItem from './FeatureItem';

interface FeatureListProps {
  filteredFeatures: FormattedFeature[];
  skipVirtualization?: boolean;
  addStain: boolean;
}

export const featureListHeight = 300;

const FeatureList: FunctionComponent<React.PropsWithChildren<FeatureListProps>> = ({
  filteredFeatures,
  skipVirtualization,
  addStain,
}) => {
  if (skipVirtualization) {
    return (
      <Grid item xs={12}>
        {map(filteredFeatures, (feature) => (
          <FeatureItem key={feature.key} feature={feature} addStain={addStain} />
        ))}
      </Grid>
    );
  }
  return (
    <Grid item xs={12} height={featureListHeight}>
      <Virtuoso
        data={filteredFeatures}
        itemContent={(index, feature) => <FeatureItem key={feature.key} feature={feature} addStain={addStain} />}
      />
    </Grid>
  );
};

export default FeatureList;
