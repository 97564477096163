// eslint-disable-next-line import/order
import { ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Chip, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

type ToggleDirections = 'left' | 'right' | 'top' | 'bottom';

// Icon component for the arrow when open and collapsed (index 0 and 1 respectively)
const ArrowComponentMap: Record<ToggleDirections, [OverridableComponent<any>, OverridableComponent<any>]> = {
  left: [ArrowLeft, ArrowRight],
  right: [ArrowRight, ArrowLeft],
  bottom: [ArrowDropDown, ArrowDropUp],
  top: [ArrowDropUp, ArrowDropDown],
};

export const DrawerToggleButton: React.FunctionComponent<
  React.PropsWithChildren<{
    onClick(): void;
    collapsed: boolean;
    direction: ToggleDirections;
    drawerWidth?: string | number;
    drawerHeight?: string | number;
    text?: string;
    topOffset?: string | number;
    bottomOffset?: string | number;
    leftOffset?: string | number;
    rightOffset?: string | number;
    zIndex?: number;
    centerDirection?: ToggleDirections;
  }>
> = ({
  onClick,
  collapsed,
  direction,
  text,
  drawerWidth,
  drawerHeight,
  topOffset,
  bottomOffset,
  leftOffset,
  rightOffset,
  centerDirection,
  zIndex = 100,
}) => {
  const theme = useTheme();
  const ArrowComponent = ArrowComponentMap[direction][collapsed ? 1 : 0];
  return (
    <Chip
      sx={{
        zIndex,
        backgroundColor: theme.palette.background.default,
        boxShadow: 2,
        position: 'absolute',
        top: topOffset,
        bottom: bottomOffset,
        left: leftOffset,
        right: rightOffset,

        ...(collapsed && { justifyContent: 'flex-start' }),

        ...((direction === 'top' || direction === 'bottom') && { minWidth: '30px' }),

        ...(direction === 'top'
          ? {
              top: drawerHeight,
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',

              ...(collapsed && { top: topOffset || 0 }),
            }
          : direction === 'bottom'
          ? {
              bottom: drawerHeight,
              borderBottomRightRadius: '0px',
              borderBottomLeftRadius: '0px',

              ...(collapsed && { bottom: bottomOffset || 0 }),
            }
          : direction === 'left'
          ? {
              left: drawerWidth,
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',

              ...(collapsed && { left: leftOffset || 0 }),
            }
          : direction === 'right'
          ? {
              right: drawerWidth,
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              ...(collapsed && { right: rightOffset || 0 }),
            }
          : {}),

        ...(centerDirection === 'left'
          ? { transform: 'translateX(-50%)' }
          : centerDirection === 'right'
          ? { transform: 'translateX(50%)' }
          : centerDirection === 'top'
          ? { transform: 'translateY(-50%)' }
          : centerDirection === 'bottom'
          ? { transform: 'translateY(50%)' }
          : {}),

        '&:hover': {
          backgroundColor: theme.palette.background.default,
          boxShadow: 1,
          opacity: 0.8,
        },

        '& .MuiChip-icon': {
          marginRight: '0px',
          marginLeft: '0px',
        },

        '& .MuiChip-label': {
          paddingLeft: 0,

          ...(!text && { display: 'none' }),
        },
      }}
      onClick={onClick}
      icon={<ArrowComponent />}
      label={text}
    />
  );
};

export default DrawerToggleButton;
