import { Button, Grid, List, ListItem, Typography } from '@mui/material';
import { findIndex, map } from 'lodash';
import React, { forwardRef } from 'react';

import { AnnotationAssignment, AssignmentExtension } from 'interfaces/annotation';
import { useNavigate } from 'react-router-dom';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import AssignmentEditButton from './AssignmentEditButton';
import AssignmentRedashButton from './AssignmentRedashButton';
import { ASSIGNMENT_STATES } from './state';

interface Props {
  currentFilter: string;
  assignment: Partial<AnnotationAssignment>;
  slides: Partial<AssignmentExtension>[];
  expanded: boolean;
  activeSlide?: string;
  doesMatchState: boolean;
  annotationAssignmentId: number;
  setExpandedAssignments: React.Dispatch<
    React.SetStateAction<{
      [assignmentId: string]: boolean;
    }>
  >;
}

const AssignmentsListItem = forwardRef<HTMLLIElement, Props>(
  (
    {
      assignment,
      slides,
      expanded: manualExpanded,
      activeSlide,
      currentFilter,
      doesMatchState,
      annotationAssignmentId,
      setExpandedAssignments,
    },
    ref
  ) => {
    const { getUrlToSlidePage } = useNavigationToViewerPage();
    const navigate = useNavigate();

    const toggleExpanded = () => {
      setExpandedAssignments((prev) => {
        if (currentFilter && !doesMatchState && prev[annotationAssignmentId] === undefined) {
          return { ...prev, [annotationAssignmentId]: false };
        }

        return {
          ...prev,
          [annotationAssignmentId]: !prev[annotationAssignmentId],
        };
      });
    };
    const setActiveSlide = (newSlideId: string) => {
      const urlToPage = getUrlToSlidePage({
        slideId: newSlideId,
        labId: '',
        caseId: undefined,
        caseStudyId: undefined,
        activeAnnotationAssignment: annotationAssignmentId,
      });

      navigate(urlToPage);

      // dispatch(setAnnotationsModifiedSinceVersion(false));
    };
    const handleSelectSlide = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, slideId: string) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveSlide(slideId);
    };

    const isActiveSlideInList = findIndex(slides, { slideId: activeSlide }) !== -1;

    const expanded = manualExpanded || isActiveSlideInList;

    return (
      <ListItem color={activeSlide ? 'primary' : 'default'} onClick={() => toggleExpanded()}>
        <Grid item container direction="column">
          <Grid item container direction="row" justifyContent="space-between" xs={12}>
            <Grid item>
              <Typography data-test-class="assignments-list-item-title" variant="body2">
                {assignment.annotationAssignmentId} {assignment.name || ''}
              </Typography>
            </Grid>

            <Grid item container xs="auto">
              {Boolean(assignment?.annotationAssignmentId) && (
                <AssignmentEditButton assignmentId={assignment?.annotationAssignmentId} faded={!activeSlide} />
              )}

              <AssignmentRedashButton annotationAssignmentId={assignment.annotationAssignmentId} faded={!activeSlide} />
            </Grid>
          </Grid>

          {expanded && (
            <Grid>
              <List>
                {map(slides, (slide) => {
                  const IconComponent = ASSIGNMENT_STATES[slide.workingState].icon;
                  return (
                    <ListItem
                      color={slide.slideId === activeSlide ? 'primary' : 'default'}
                      key={slide.slideId}
                      ref={slide.slideId === activeSlide ? ref : null}
                    >
                      <Button
                        data-test-class="assignments-list-item-slide"
                        onClick={(event) => handleSelectSlide(event, slide.slideId)}
                        startIcon={<IconComponent />}
                      >
                        <Typography variant="caption">{slide.slideId}</Typography>
                      </Button>

                      <AssignmentRedashButton
                        annotationAssignmentId={assignment.annotationAssignmentId}
                        slideId={slide.slideId}
                        themed={slide.slideId === activeSlide}
                        faded={slide.slideId !== activeSlide}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}
        </Grid>
      </ListItem>
    );
  }
);

export default AssignmentsListItem;
