import { Box, Grid, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import { MainPageAnnotationDrawer } from 'components/AssignmentsList/MainPageAnnotationDrawer';
import { RoundedButton } from 'components/atoms/Buttons/MainButton';
import { Main, overviewDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import DashboardTabs from 'components/DashboardTabs';
import ExploratoryAnalysis from 'components/FeaturesDashboard/ExploratoryAnalysis';
import AdvancedSearchDrawer from 'components/SearchFilters/AdvancedSearch';
import FilterChipList from 'components/SearchFilters/FilterChipList';
import useSearchFiltersFromQueryParams from 'components/SearchFilters/hooks/useSearchFiltersFromQueryParams';
import SiteHeader from 'components/SiteHeader/SiteHeader';
import DashboardFooter from 'components/StudiesDashboard/DashboardFooter';
import { Permission } from 'interfaces/permissionOption';
import { indexOf, omit } from 'lodash';
import React from 'react';
import { BooleanParam, JsonParam, QueryParamConfig, useQueryParam, withDefault } from 'use-query-params';
import { allFiltersHasValue, removeNullValues } from 'utils/helpers';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import AnalysisStudioHeader from './AnalysisStudioHeader';
import OverviewTabContent from './Tabs/Overview';

type AnalysisStudioTab = "Nucleai's Results" | 'Overview' | 'Data Exploration' | 'My Saved Results';

const AnalysisStudioTabParam: QueryParamConfig<AnalysisStudioTab> = withDefault(
  JsonParam,
  'Overview'
) as QueryParamConfig<AnalysisStudioTab>;

const AnalysisStudioPage: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const { labId } = useCurrentLabId();

  const tabs: AnalysisStudioTab[] = ['Overview'];
  const [activeTab, setActiveTab] = useQueryParam<AnalysisStudioTab>('tab', AnalysisStudioTabParam);
  const activeTabIndex = indexOf(tabs, activeTab as string) !== -1 ? indexOf(tabs, activeTab as string) : 0;

  const [advancedSearchDrawerOpen] = useQueryParam('advancedSearchDrawerOpen', BooleanParam);

  const { queryParams, setQueryParams } = useEncodedFilters();

  const { hasPermission } = usePermissions();
  const canApproveCases = hasPermission(Permission.ApproveCases);

  const changeTab = (newTab: AnalysisStudioTab) => {
    setActiveTab(newTab);
  };

  const { searchFiltersFromQueryParams, featuresFromQueryParams } = useSearchFiltersFromQueryParams();

  const searchFiltersCopy = omit(
    {
      ...searchFiltersFromQueryParams,
    },
    ['studyId']
  );

  const hasFilters = allFiltersHasValue(searchFiltersCopy);

  const validFilters = removeNullValues(searchFiltersCopy);

  const { data: studies, isFetching: isLoadingStudies } = useQuery(
    ['studies', labId, 'withStatistics'],
    fetchStudies(labId, true)
  );

  const selectedStudyId = queryParams.filters?.studyId;
  const selectedStudy = studies?.find((study) => study.id === selectedStudyId);

  const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];

  return (
    <StaticPage>
      <Main leftDrawerOpen={advancedSearchDrawerOpen} rightDrawerOpen={false} rightDrawerWidth={overviewDrawerWidth}>
        <SiteHeader />
        <AdvancedSearchDrawer showApprovedFilter={canApproveCases} />
        <Grid
          container
          direction={'column'}
          my={0}
          sx={{
            height: '100vh',
            width: '100%',
            backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
          }}
        >
          <Toolbar />

          <AnalysisStudioHeader selectedStudyId={selectedStudyId} hasFilters={hasFilters} />

          {hasFilters && (
            <Grid container item sx={{ px: 6 }} justifyContent="space-between">
              <Grid item>
                <FilterChipList
                  allFilters={validFilters}
                  allFeatures={featuresFromQueryParams}
                  qcLabelConfig={selectedStudy?.qcLabelsConfig}
                />
              </Grid>
              <Grid item>
                <RoundedButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setQueryParams({
                      filters: { studyId: selectedStudyId },
                      features: null,
                      clinicalData: null,
                      queryId: null,
                    });
                  }}
                >
                  Clear Filters
                </RoundedButton>
              </Grid>
            </Grid>
          )}

          {selectedStudyId ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  borderBottom: '1px solid ' + borderColor,
                  '.MuiTab-root': {
                    fontSize: 14,
                    textTransform: 'none',
                    px: 7,
                  },
                }}
              >
                <DashboardTabs
                  tabsDisplayTexts={tabs}
                  tabs={tabs}
                  changeTab={changeTab}
                  defaultValueIndex={activeTabIndex}
                />
              </Box>

              <Grid
                item
                xs={true}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflowY: 'auto',
                  width: '100%',
                }}
              >
                <Grid item xs={true}>
                  {activeTab === 'Overview' ? (
                    <OverviewTabContent study={selectedStudy} isLoading={isLoadingStudies} />
                  ) : activeTab === 'Data Exploration' ? (
                    <Grid px={50} pt={3}>
                      <ExploratoryAnalysis selectedCohortIds={[`cohort${selectedStudyId}`]} />
                    </Grid>
                  ) : null}
                </Grid>
                <DashboardFooter />
              </Grid>
            </>
          ) : null}
        </Grid>
        <MainPageAnnotationDrawer />
      </Main>
    </StaticPage>
  );
};

export default AnalysisStudioPage;

const StaticPage = styled(Box)(() => ({
  overflow: 'hidden',
}));
