import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { compact, find, first, includes, isEmpty, isEqual, last, reverse, slice } from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  BooleanParam,
  DelimitedArrayParam,
  StringParam,
  UrlUpdateType,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import { useEncodedFilters } from './useEncodedFilters';
import { usePermissions } from './usePermissions';

/**
 * hook for managing the selectedSlideIds query param
 * @returns the current selectedSlideIds from the query param
 */
export const useSelectedSlideIds = (
  procedure: Procedure
): {
  selectedSlideIds: string[];
  setSelectedSlideIds: (newSetSelectedSlideIds: string[], updateType?: UrlUpdateType) => void;
  setFirstSlideId: (id: string) => void;
} => {
  const { hasPermission } = usePermissions();
  const isAccessionViewer = hasPermission(Permission.ViewAccessionDashboard);

  const hasTaggingPermissions = hasPermission(Permission.EditSlideTagsAssignments);
  const [slideTagsDrawerOpen] = useQueryParam('slideTagsDrawerOpen', BooleanParam);
  const [slideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);
  const isTaggingSlide = hasTaggingPermissions && slideTagsDrawerOpen && Boolean(slideIdForReviewing);

  const [displayOneSlideByDefault] = useQueryParam('displayOneSlideByDefault', BooleanParam);
  const [defaultStainingType] = useQueryParam('defaultStainingType', StringParam);
  const { queryParams } = useEncodedFilters();
  const filteredStainTypes: string[] = queryParams?.filters?.stainTypes || [];
  const stainTypeSelections = [...new Set([...filteredStainTypes, defaultStainingType])];

  const defaultFirstSlideId = isTaggingSlide
    ? slideIdForReviewing
    : find(procedure.slides, (slide) => includes(stainTypeSelections, slide.stainingType))?.id ||
      first(procedure.slides)?.id;

  const defaultSecondSlideId = find(procedure.slides, (slide) => slide.id !== defaultFirstSlideId)?.id;

  const defaultSelectedSlideIds = useMemo(
    () =>
      !isTaggingSlide && (procedure?.slides?.length ?? 0) > 1 && !isAccessionViewer && !displayOneSlideByDefault
        ? [defaultFirstSlideId, defaultSecondSlideId]
        : [defaultFirstSlideId],
    [isTaggingSlide, procedure, isAccessionViewer, displayOneSlideByDefault, defaultFirstSlideId, defaultSecondSlideId]
  );

  const SelectedSlideIdsParam = withDefault(DelimitedArrayParam, defaultSelectedSlideIds);
  const [selectedSlideIdsFromParams, setSelectedSlideIds] = useQueryParam('selectedSlideIds', SelectedSlideIdsParam);

  const selectedSlideIds = useMemo(() => {
    const resolvedSelectedSlideIds = compact(
      !isTaggingSlide || first(selectedSlideIdsFromParams) === slideIdForReviewing
        ? // If not slide is being tagged or it is already the first selection
          selectedSlideIdsFromParams
        : slideIdForReviewing === last(selectedSlideIdsFromParams)
        ? // If the slide being tagged is already selected, move it to the front
          reverse(selectedSlideIdsFromParams)
        : // If the slide being tagged is not selected, select it by itself
          [slideIdForReviewing]
    );
    return !isEmpty(resolvedSelectedSlideIds) ? resolvedSelectedSlideIds : defaultSelectedSlideIds;
  }, [isTaggingSlide, slideIdForReviewing, selectedSlideIdsFromParams, defaultSelectedSlideIds]);

  // Sync the selectedSlideIds query param with the selectedSlideIdsFromParams state
  useEffect(() => {
    if (selectedSlideIdsFromParams && !isEqual(selectedSlideIds, selectedSlideIdsFromParams)) {
      setSelectedSlideIds(selectedSlideIds, 'replaceIn');
    }
  }, [selectedSlideIds]);

  const setFirstSlideId = (id: string) => {
    setSelectedSlideIds([id, ...slice(selectedSlideIds, 1)]);
  };

  return {
    selectedSlideIds,
    setSelectedSlideIds,
    setFirstSlideId,
  };
};
