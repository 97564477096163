import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useSignals } from '@preact/signals-react/runtime';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import {
  defaultMultiplexChannelColors,
  getSlideChannelColorSettingsSignal,
  useUpdateSlideChannelColorSettings,
} from '../colorSettings';

export const MultiplexChannelColor: React.FC<
  React.PropsWithChildren<{
    viewerIndex: number;
    slideId: string;
    channelId: string;
    selected: boolean;
    markerType?: string;
  }>
> = ({ viewerIndex, slideId, channelId, selected, markerType }) => {
  useSignals();
  const slideChannelsSettingsSignal = getSlideChannelColorSettingsSignal(viewerIndex, slideId, channelId);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openColorPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeColorPopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const currentColor = selected ? slideChannelsSettingsSignal?.value?.color : undefined;

  const { handleColorChange } = useUpdateSlideChannelColorSettings();

  return (
    <Grid item>
      <Button
        role="color-picker"
        size="small"
        sx={{
          minWidth: 17,
          height: 17,
          mr: 1,
          display: 'flex',
          ...(selected ? { background: currentColor?.hex || currentColor } : {}),
        }}
        variant="contained"
        onClick={openColorPopOver}
        disabled={!selected}
      />
      <Popover
        id={id}
        open={open && selected}
        anchorEl={anchorEl}
        onClose={closeColorPopOver}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {selected ? (
          <SketchPicker
            color={currentColor}
            onChange={(color) => {
              handleColorChange(viewerIndex, slideId, channelId, color?.hex, markerType);
            }}
            presetColors={sortBy(defaultMultiplexChannelColors)}
          />
        ) : null}
      </Popover>
    </Grid>
  );
};
