import { Tooltip } from '@mui/material';
import { ConditionalTextEditor } from 'components/atoms/DataGridRenderers/ConditionalTextEditor';
import { DisplayedField } from 'interfaces/genericFields';
import { Taxonomy } from 'interfaces/taxonomy';
import { find, isEmpty, join, map } from 'lodash';
import React from 'react';
import TaxonomyMultiSelectDropdown from '../Taxonomy/TaxonomyMultiSelectDropdown';
import { TaxonomyTagDraft } from './types';

const idRegex = /^[a-z\d/]+(?:[-_][a-z\d/]+)*$/;

const idErrorMessage =
  'Taxonomy tag id must be lowercase and contain only letters, numbers. ' +
  'Hyphens, and underscores are allowed as separators.';

export const idField: DisplayedField<TaxonomyTagDraft, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'id',
  label: 'Taxonomy Tag ID',
  sortable: true,
  columnWidth: { width: 200 },
  getError: ({ value }) => (!idRegex.test(value) ? idErrorMessage : undefined),
  customCellEditor:
    (field, context) =>
    ({ isEditable: isEditableParam, ...params }) => {
      const { value, row } = params;
      const isEditable = (taxonomyTag: TaxonomyTagDraft) => isEditableParam && !!taxonomyTag.draftId;
      const error = field.getError?.({ context, value, row });
      const hasError = isEditable(row) && Boolean(error);
      return (
        <Tooltip open={Boolean(hasError)} color="error" title={hasError ? error : undefined}>
          <ConditionalTextEditor {...params} isEditable={isEditable} />
        </Tooltip>
      );
    },
};

export const displayNameField: DisplayedField<TaxonomyTagDraft, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'displayName',
  label: 'Display Name',
  columnWidth: { width: 200 },
};

export const labelsField: DisplayedField<
  TaxonomyTagDraft,
  string[],
  {
    taxonomies: Taxonomy[];
  }
> = {
  cellEditType: 'select',
  filterType: 'multiSelect',
  dataKey: 'labels',
  label: 'Labels',
  columnWidth: { width: 200 },
  customCellEditor: () => (params) => {
    const { value, row } = params;
    const onSelectTaxonomiesIds = (selectedTaxonomiesIds: string[]) => {
      params.api.setEditCellValue({
        id: params.id,
        field: params.field,
        value: selectedTaxonomiesIds,
      });
    };

    return <TaxonomyMultiSelectDropdown selectedTaxonomiesIds={value} onSelectTaxonomiesIds={onSelectTaxonomiesIds} />;
  },
  valueFormatter: ({ value }, context) => {
    const taxonomies = context?.taxonomies ?? [];
    if (!value || !value.length) {
      return 'No labels';
    }

    if (isEmpty(taxonomies)) {
      return join(value, ', ');
    }

    const taxonomyLabels = map(
      value,
      (label) => find(taxonomies, (taxonomy) => taxonomy.path === label)?.name ?? label
    );

    return join(taxonomyLabels, ', ');
  },
};

export const taxonomyTagFields: DisplayedField<TaxonomyTagDraft, any>[] = [idField, displayNameField, labelsField];
