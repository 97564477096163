import { CompositeMode, DrawPointMode, DrawPolygonMode, DrawRectangleMode } from 'nebula.gl';

import { ModifyOrthographicMode } from './ModifyOrthographicMode';

export class AnnotationPolygonMode extends CompositeMode {
  constructor() {
    super([new DrawPolygonMode(), new ModifyOrthographicMode()]);
  }

  handleClick(clickEvent: any, props: any): void {
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }
    super.handleClick(clickEvent, props);
  }
}

export class AnnotationRectangleMode extends CompositeMode {
  constructor() {
    super([new DrawRectangleMode(), new ModifyOrthographicMode()]);
  }

  handleClick(clickEvent: any, props: any): void {
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }
    super.handleClick(clickEvent, props);
  }
}

export class AnnotationPointMode extends CompositeMode {
  constructor() {
    super([new DrawPointMode(), new ModifyOrthographicMode()]);
  }

  handleClick(clickEvent: any, props: any): void {
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }
    super.handleClick(clickEvent, props);
  }
}
