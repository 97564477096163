import { Tooltip } from '@mui/material';
import { ConditionalTextEditor } from 'components/atoms/DataGridRenderers/ConditionalTextEditor';
import { ClusterType } from 'interfaces/clusterType';
import { DisplayedField } from 'interfaces/genericFields';
import React from 'react';

const idRegex = /^[a-z\d/]+(?:[-_][a-z\d/]+)*$/;

export const idField: DisplayedField<ClusterType & { draftId?: string }, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'id',
  label: 'Cluster Type ID',
  columnWidth: { width: 200 },
  getError: ({ value }) =>
    !idRegex.test(value)
      ? 'Cluster type id must be lowercase and contain only letters, numbers. ' +
        'Hyphens, and underscores are allowed as separators.'
      : undefined,
  customCellEditor:
    (field, context) =>
    ({ isEditable: isEditableParam, ...params }) => {
      const { value, row } = params;
      const isEditable = (cluster: ClusterType & { draftId?: string }) => isEditableParam && !!cluster.draftId;
      const error = field.getError?.({ context, value, row });
      const hasError = isEditable(row) && Boolean(error);
      return (
        <Tooltip open={Boolean(hasError)} color="error" title={hasError ? error : undefined}>
          <ConditionalTextEditor {...params} isEditable={isEditable} />
        </Tooltip>
      );
    },
};

export const displayNameField: DisplayedField<ClusterType, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'displayName',
  label: 'Display Name',
  columnWidth: { width: 200 },
};

export const indexField: DisplayedField<ClusterType, number> = {
  filterType: 'multiSelect',
  dataKey: 'index',
  label: 'Index',
  columnWidth: { width: 200 },
};

export const deletedAt: DisplayedField<ClusterType, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'deletedAt',
  label: 'Deleted At',
  columnWidth: { width: 200 },
};

export const clusterTypeFields: Array<DisplayedField<ClusterType, any>> = [
  idField,
  displayNameField,
  indexField,
  deletedAt,
];
