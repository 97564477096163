import { TileJSONLayer } from '@loaders.gl/mvt/dist/lib/parse-tilejson';
import { PMTilesMetadata } from '@loaders.gl/pmtiles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { filter, find, forEach, includes, isEmpty, lowerCase, map, sortBy } from 'lodash';
import React, { useMemo } from 'react';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { useProtomapTilesList } from 'utils/useProtomapTiles';
import { GroupedLayersVisualControls } from '../../GroupedLayersVisualControls';
import { defaultHeatmapOpacity } from '../../slidesVisualizationAndConfiguration';
import { defaultMultiplexChannelColors } from '../Multiplex/colorSettings';
import { UnpublishResultsButton } from './UnpublishResultsButton';
import { useUpdatePmtHeatmapsSettingsOnChange } from './useUpdatePmtHeatmapsSettingsOnChange';

export const getPmtHeatmapId = (pmtHeatmap: FeatureMetadata, pmtMetadata: PMTilesMetadata) =>
  `${pmtHeatmap?.id}-${pmtMetadata?.name ?? 'no-metadata'}-${pmtHeatmap?.heatmapUrl || 'missing-url'}`;

export const getPmtLayerId = (pmtHeatmap: FeatureMetadata, pmtMetadata: PMTilesMetadata, layer: TileJSONLayer) =>
  `${getPmtHeatmapId(pmtHeatmap, pmtMetadata)}-${layer?.name || (layer ? JSON.stringify(layer) : 'no-name')}`;

export const getPmtLayerDisplayName = (pmtHeatmap: FeatureMetadata, layer: TileJSONLayer) =>
  `${pmtHeatmap?.displayName}-${layer?.name}`;

export const computeDefaultPmtLayerSettings = (
  pmtHeatmap: FeatureMetadata,
  pmtMetadata: PMTilesMetadata,
  layer: TileJSONLayer,
  layerIndex: number
) => {
  const optionFromHeatmap = find(pmtHeatmap?.nestedItems, { key: layer?.name });
  return {
    id: getPmtLayerId(pmtHeatmap, pmtMetadata, layer),
    color:
      optionFromHeatmap?.color || defaultMultiplexChannelColors[+layerIndex % defaultMultiplexChannelColors.length],
    opacity: defaultHeatmapOpacity,
    show: false,
    select: false,
  };
};

const defaultHeader = (
  <Grid container alignItems="center" justifyContent="space-between">
    <Grid item md={12}>
      <Typography variant={'h4'}>Protomap Tiles (Beta)</Typography>
    </Grid>
  </Grid>
);

export const ProtomapTree: React.FC<{
  pmtHeatmaps: FeatureMetadata[];
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  textSearch: string;
  hideOrchestrationId?: boolean;
}> = ({ pmtHeatmaps, slideId, viewerIndex, textSearch, hideOrchestrationId, stainTypeId }) => {
  useSignals();
  const protomapTilesList = useProtomapTilesList(pmtHeatmaps);

  const layerIdsToDisplayNames = useMemo(() => {
    const res: { [key: string]: string } = {};

    forEach(protomapTilesList, ({ data }) => {
      if (!data) {
        return;
      }
      const { pmtHeatmap, pmtMetadata } = data;
      res[getPmtHeatmapId(pmtHeatmap, pmtMetadata)] = pmtHeatmap?.displayName;
      forEach(pmtMetadata?.tilejson?.layers, (layer) => {
        res[getPmtLayerId(pmtHeatmap, pmtMetadata, layer)] = getPmtLayerDisplayName(pmtHeatmap, layer);
      });
    });
    return res;
  }, [protomapTilesList]);

  useUpdatePmtHeatmapsSettingsOnChange({
    slideId,
    viewerIndex,
    stainTypeId,
    protomapTilesList,
  });

  const groupedFilterLayers = useMemo(() => {
    const res: { [key: string]: string[] } = {};

    forEach(protomapTilesList, ({ data }) => {
      if (!data) {
        return;
      }
      const { pmtHeatmap, pmtMetadata } = data;
      const pmtLayers: TileJSONLayer[] = pmtMetadata?.tilejson?.layers || [];
      const pmtLayerIncludesFilterText = (pmtLayer: TileJSONLayer) => {
        if (textSearch === '') return true;

        const lowerCaseFilter = lowerCase(textSearch);
        return (
          includes(lowerCase(pmtLayer?.name), lowerCaseFilter) ||
          includes(lowerCase(pmtLayer?.description), lowerCaseFilter)
        );
      };

      const filteredLayers = textSearch !== '' ? filter(pmtLayers, pmtLayerIncludesFilterText) : pmtLayers;
      return (res[getPmtHeatmapId(pmtHeatmap, pmtMetadata)] = map(filteredLayers, 'name'));
    });
    return res;
  }, [textSearch, protomapTilesList]);

  const groupDisplayNames = useMemo(() => {
    const res: { [key: string]: string } = {};

    forEach(protomapTilesList, ({ data }) => {
      if (!data) {
        return;
      }
      const { pmtHeatmap, pmtMetadata } = data;
      res[getPmtHeatmapId(pmtHeatmap, pmtMetadata)] = pmtHeatmap?.displayName;
    });
    return res;
  }, [protomapTilesList]);

  const groupOrchestrationIds = useMemo(() => {
    const res: { [key: string]: string } = {};

    forEach(protomapTilesList, ({ data }) => {
      if (!data) {
        return;
      }
      const { pmtHeatmap, pmtMetadata } = data;
      res[getPmtHeatmapId(pmtHeatmap, pmtMetadata)] = pmtHeatmap?.orchestrationId;
    });
    return res;
  }, [protomapTilesList]);

  return (
    !isEmpty(groupedFilterLayers) && (
      <GroupedLayersVisualControls
        viewerIndex={viewerIndex}
        slideId={slideId}
        groupedLayers={groupedFilterLayers}
        groupOrchestrationIds={groupOrchestrationIds}
        groupDisplayNames={groupDisplayNames}
        layerIdsToDisplayNames={layerIdsToDisplayNames}
        stainTypeId={stainTypeId}
        hideOrchestrationId={hideOrchestrationId}
      />
    )
  );
};

export const ProtomapTileControl: React.FC<{
  header?: React.ReactNode;
  pmtHeatmaps: FeatureMetadata[];
  slideId: string;
  viewerIndex: number;
  textSearch: string;
  expandByDefault?: boolean;
  hideOrchestrationId?: boolean;
  stainTypeId?: string;
}> = ({ header, pmtHeatmaps, slideId, viewerIndex, textSearch, expandByDefault, hideOrchestrationId, stainTypeId }) => {
  useSignals();
  const [expandAccordion, setExpandAccordion] = React.useState(Boolean(expandByDefault));

  const heatmapTree = (
    <ProtomapTree
      pmtHeatmaps={pmtHeatmaps}
      slideId={slideId}
      viewerIndex={viewerIndex}
      textSearch={textSearch}
      hideOrchestrationId={hideOrchestrationId}
      stainTypeId={stainTypeId}
    />
  );

  return (
    heatmapTree && (
      <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{header || defaultHeader}</AccordionSummary>
        <AccordionDetails sx={{ padding: 1 }}>{heatmapTree}</AccordionDetails>
      </Accordion>
    )
  );
};

export const ProtomapHeatmapControl: React.FC<{
  expandByDefault?: boolean;
  heatmaps: FeatureMetadata[];
  title: string;
  slideId: string;
  studyId: string;
  viewerIndex: number;
  stainTypeId: string;
  textSearch: string;
  isPublishMode?: boolean;
  internalHeatmaps?: boolean;
  hideOrchestrationId?: boolean;
}> = ({
  expandByDefault,
  heatmaps: unsortedHeatmaps,
  title,
  isPublishMode,
  internalHeatmaps,
  slideId,
  studyId,
  stainTypeId,
  viewerIndex,
  textSearch,
  hideOrchestrationId,
}) => {
  const heatmaps = useMemo(() => sortBy(unsortedHeatmaps, 'displayName'), [unsortedHeatmaps]);

  return (
    <ProtomapTileControl
      header={
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={isPublishMode && !internalHeatmaps ? 7 : 12}>
            <Typography variant={internalHeatmaps ? 'subtitle2' : 'h4'}>{title}</Typography>
          </Grid>
          <UnpublishResultsButton
            slideId={slideId}
            studyId={studyId}
            internalHeatmaps={internalHeatmaps}
            isPublishMode={isPublishMode}
            heatmaps={heatmaps}
          />
        </Grid>
      }
      pmtHeatmaps={heatmaps}
      slideId={slideId}
      viewerIndex={viewerIndex}
      stainTypeId={stainTypeId}
      textSearch={textSearch}
      expandByDefault={expandByDefault}
      hideOrchestrationId={hideOrchestrationId}
    />
  );
};
