import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid } from '@mui/material';
import { AnnotationAssignment } from 'interfaces/annotation';
import { filter, map, size } from 'lodash';
import React from 'react';
import { uuidv4 } from 'utils/helpers';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { SelectedAssignmentDetails } from '..';
import AssignmentSelection from './AssignmentSelection';

export interface AssignmentStainProps {
  stain: string;
  annotationAssignments: AnnotationAssignment[];
  selectedAssignments: SelectedAssignmentDetails[];
  addNewOverrideAssignment: (selectedAssignment: SelectedAssignmentDetails) => void;
  updateSelectedAssignment: (updatedAssignment: SelectedAssignmentDetails) => void;
  removeSelectedAssignment: (assignmentOverrideId: string) => void;
}

const AssignmentStain: React.FC<React.PropsWithChildren<AssignmentStainProps>> = ({
  stain,
  annotationAssignments,
  selectedAssignments,
  addNewOverrideAssignment,
  updateSelectedAssignment,
  removeSelectedAssignment,
}) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isLoadingStainTypeOptions ? <CircularProgress /> : stainTypeIdToDisplayName(stain)}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {map(selectedAssignments, (selectedAssignment) => {
            return (
              <Grid item xs={12} key={selectedAssignment?.id}>
                <AssignmentSelection
                  assignmentAnnotations={annotationAssignments}
                  selectedAssignment={selectedAssignment}
                  updateSelectedAssignment={updateSelectedAssignment}
                  removeSelectedAssignment={removeSelectedAssignment}
                  isDuplicateModelType={
                    size(
                      filter(selectedAssignments, (assignment) => assignment.modelType === selectedAssignment.modelType)
                    ) > 1
                  }
                />
              </Grid>
            );
          })}
          <Grid item>
            <Button
              aria-describedby="simple-popover"
              onClick={() => {
                addNewOverrideAssignment({
                  id: uuidv4(),
                  modelType: null,
                  assignment: null,
                  useClassesFromIgnore: true,
                  classToIgnore: [],
                  classToUse: [],
                });
              }}
            >
              Add New Assignment
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AssignmentStain;
